import { sendNewSetup } from "../store/entities/setup";
import { initiateProject } from "../store/entities/project";
import { initiateTask, newInitialTask } from "../store/entities/tasks";
import { initiateDeadline } from "../store/entities/deadlines";
import { initiateTeam } from "../store/entities/team";
import { initiateAllocation } from "../store/entities/allocations";
import { initiateMaterials } from "../store/entities/materials";
import { initiateTravel } from "../store/entities/travel";
import { initiateAssignments } from "../store/entities/assignments";
import { initiateCapex } from "../store/entities/capex";
import { initiateRevenue } from "../store/entities/revenue";
import { initiateOther } from "../store/entities/other";
import { initiateRisks } from "../store/entities/risks";
import { newProject } from "./newProject";
import { newSetup } from "./newSetup";
import { generateRandomId } from "./generalHelpers";

export function sendNewProject(dispatch) {
  const projectId = generateRandomId('project');
  const task = newInitialTask(projectId);
  const project = newProject(projectId);
  const setup = newSetup(projectId);

  dispatch(sendNewSetup(setup));
  dispatch(initiateTask(task));
  dispatch(initiateProject(project));

  dispatch(initiateDeadline(projectId));
  dispatch(initiateTeam(projectId));
  dispatch(initiateAllocation(projectId));
  dispatch(initiateMaterials(projectId));
  dispatch(initiateTravel(projectId));
  dispatch(initiateCapex(projectId));
  dispatch(initiateOther(projectId));
  dispatch(initiateRisks(projectId));
  dispatch(initiateAssignments(projectId));
  dispatch(initiateRevenue(projectId));
}
