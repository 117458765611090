import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import {
  getTotalsByLeader,
  getWPCost,
  numberToCurrency,
  roundTo,
} from "../../helpers";
import { getWorkPackageLabourCost } from "../../store/entities/allocations";
import { getWorkPackageIds } from "../../store/entities/tasks";

export default function OutputCostWorkPackages() {
  const totalCost = useSelector(getTotalsByLeader).object.totals.combined;
  const wpIds = useSelector(getWorkPackageIds);
  const labourCosts = useSelector(getWorkPackageLabourCost);
  const additionalCosts = useSelector(getWPCost);

  function returnCost(id) {
    const labour = labourCosts[id];
    const additional = additionalCosts[id].combined;
    const cost = labour + additional;
    const result = `£${numberToCurrency(cost)} (${roundTo(
      (cost / totalCost) * 100,
      0
    )}%).`;
    console.log(additional);
    return result;
  }

  return (
    <Container>
      <div className="section">
        <h3 className='title' >Work Package Cost Breakdown:</h3>
        <ul className="companies">
          {wpIds.map((id, index) => {
            return (
              <li key={index}>
                <span className="bold">WP{index + 1}: </span>
                <span>{returnCost(id)}</span>
              </li>
            );
          })}
        </ul>
      </div>
    </Container>
  );
}

const Container = styled.div``;
