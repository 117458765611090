import React from "react";
import styled from "styled-components";
import ResourcesRow from "./ganttResourcesModalRow";
import Close from "../general/close";
import {
  handleSendAllocations,
  tableInputUnderline,
  wpInfoColor,
} from "../../helpers";
import ResourcesRowAssAll from "./ganttResourcesModalAssAll";
import { useDispatch, useSelector } from "react-redux";
import {
  closeResourcesInput,
  getAllocationsByTaskId,
  addAllocation,
  deleteAllocation,
  updateAllocation,
} from "../../store/entities/allocations";
import { getOrderedTeam } from "../../store/entities/team";
import Tippy from "@tippy.js/react";
import { setShowComponent } from "../../store/user";
import GanttResourceTitle from "./ganttResourcesModalTitle";
// toast.configure();

function ResourcesModal(props) {
  const dispatch = useDispatch();
  const { packData } = props;
  const taskIds = [...new Set(packData.map((task) => task.taskId))];
  const allPeople = useSelector(getOrderedTeam);
  const resources = useSelector(getAllocationsByTaskId);
  const allocations = useSelector(getAllocationsByTaskId);

  let message = false;
  for (let i = 0; i < taskIds.length; i++) {
    if (resources[taskIds[i]].completion !== 100) {
      message = "All tasks must be 100%";
      break;
    }
  }

  console.log(useSelector(getOrderedTeam));

  function updateResourceAllocation(taskId, value, personId, allocationId) {
    if (value === 0) dispatch(deleteAllocation({ allocationId }));
    else if (allocationId === "new")
      dispatch(addAllocation({ taskId, personId, value }));
    else dispatch(updateAllocation({ allocationId, value }));
    handleSendAllocations();
  }

  function assignAll(value, personId) {
    packData.forEach(({ taskId }) => {
      const { allocationId } = allocations[taskId][personId];
      updateResourceAllocation(taskId, value, personId, allocationId);
    });
  }

  const data = {
    message,
    providedFunction: () => {
      dispatch(setShowComponent({ value: "" }));
      dispatch(closeResourcesInput());
    },
  };

  const partner = {
    lead: "Lead",
    pOne: "P2",
    pTwo: "P3",
  };

  return (
    <Container id="background">
      <div className="editWindow">
        <Close data={data} />
        <div className="modalRow title">
          <h3 className="description">Description</h3>
          {allPeople.map((person, index) => {
            return <GanttResourceTitle key={index} person={person} />;
          })}
          <h3 className="total">Total</h3>
        </div>

        {packData.map((task, index) => {
          return (
            <ResourcesRow
              updateResourceAllocation={updateResourceAllocation}
              task={task}
              allPeople={allPeople}
              key={index}
              // list={list}
            />
          );
        })}
        <ResourcesRowAssAll
          task={{ taskId: "all" }}
          allPeople={allPeople}
          // list={list}
          assignAll={assignAll}
          taskIds={taskIds}
        />
      </div>
    </Container>
  );
}

export default ResourcesModal;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(20, 20, 20, 0.6);
  z-index: 13;

  .editWindow {
    position: relative;
    /* max-height: 80vh; */

    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    /* padding-bottom: 10px; */
    background-color: white;
    /* border: 1px solid black; */
    border-radius: 6px;
  }
  .modalRow {
    min-height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 20px;
    .description {
      width: 200px;
    }
  }
  .assignAll {
    color: red;
    border-top: 1px solid ${tableInputUnderline};
    /* border: 1px solid red; */
    margin-top: 7px;
    min-height: 55px;
    /* padding-top: 25px; */
    /* margin-bottom: 20px; */

    button {
      border: none;
      background-color: ${wpInfoColor};
      color: white;
      margin-left: 20px;
      padding: 5px 8px;
      font-size: 10px;
    }
  }
  .bold {
    color: ${wpInfoColor};
    font-weight: bold;
  }
  .title {
    min-height: 50px;
    margin-bottom: 10px;
    background-color: ${wpInfoColor};
    color: white;
    padding: 0 20px;
    border-radius: 6px 6px 0px 0px;
  }
  .personTitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 3px;
    align-items: center;
    width: 45px;
    height: 30px;
  }
  .employment {
    width: 6px;
    height: 6px;
    border-radius: 100%;
  }
  .staff {
    background-color: lightgreen;
  }
  .subcontract {
    background-color: orange;
  }
  .selected {
    background-color: rgb(240, 240, 240);
    width: 90px;
    color: ${wpInfoColor};
  }
  .person {
    text-align: center;
    /* display: flex; */
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }
  .select {
    border: 1px solid lightgray;
    cursor: pointer;
    :focus {
      border-color: ${wpInfoColor};
    }
  }
  .total {
    width: 50px;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    font-weight: 600;
  }
  .under {
    color: orange;
  }
  .ok {
    color: green;
  }
  .over {
    color: red;
  }
  img {
    max-width: 100%;
    max-height: 100%;
  }
`;
