import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "./optionsStyling";
import OptionsInput from "./optionsInput";
import { generateArray, handleSendGlobal } from "../../helpers";
import { getSelectedProject, updateUserSelection } from "../../store/user";
import AdminModal from "../modals/adminModal";
import { updateGlobalValue } from "../../store/entities/global";
import TableTabs from "../table/TableTabs";

function CostsOptions() {
  const dispatch = useDispatch();
  const {
    maxMaterials,
    maxTravel,
    maxCapex,
    maxOther,
    maxMarkets,
    maxStreams,
    materialWarn,
    materialOver,
    travelWarn,
    travelOver,
    capexWarn,
    capexOver,
    otherWarn,
    otherOver,
    marketOptions,
    businessOver,
    academicOver,
    projectDesc,
  } = useSelector(getSelectedProject);

  const {
    fundingLevelMin,
    fundingLevelMax,
    fundingLevelInc,
    fundingLevelDefault,
    overheadRateMin,
    overheadRateMax,
    overheadRateInc,
    overheadRateDefault,
    matchFundingSources,
    matchFundingSourceDefault,
  } = useSelector((state) => state.entities.global.data);

  const { showComponent, globalSettings } = useSelector((state) => state.user);

  const funding = generateArray(
    fundingLevelMin,
    fundingLevelMax,
    fundingLevelInc
  );

  const overhead = generateArray(
    overheadRateMin,
    overheadRateMax,
    overheadRateInc
  );

  function handleGlobalChange(e) {
    const key = e.target.name;
    const value = e.target.value;
    dispatch(updateGlobalValue({ key, value }));
    handleSendGlobal()
  }

  function showModal(field) {
    const value = showComponent === field ? "" : field;
    dispatch(updateUserSelection({ key: "showComponent", value }));
  }

  const modalData = {
    matchFundingSources: {
      title: "Match Funding",
      global: true,
      list: matchFundingSources,
      defaultOption: matchFundingSourceDefault,
      listKey: "matchFundingSources",
      defaultKey: "matchFundingSourceDefault",
    },

    marketOptions: {
      title: "Markets",
      list: marketOptions,
      global: false,
      listKey: "marketOptions",
    },
  };

  // tabs
  const data = {
    tabs: [projectDesc, "Global"],
    selectedTab: globalSettings ? "global" : projectDesc,
    select: (selected) => {
      let value;
      if (selected === 'global') value = true
      else value = false
      dispatch(
        updateUserSelection({
          key: "globalSettings",
          value,
        })
      );
    },
  };

  return (
    <Container>
      {showComponent === "matchFundingSources" ||
      showComponent === "marketOptions" ? (
        <AdminModal data={modalData[showComponent]} />
      ) : null}
      <div className="rows adminContent">
        <TableTabs data={data} />

        {globalSettings ? (
          //  ******************** GLOBAL ********************

          <>
            <div className="row titles leaderTabMargin">
              <h3 className="title description">Finance Options</h3>
              <h3 className="title value">Minimum</h3>
              <h3 className="title value">Maximum</h3>
              <h3 className="title value">Increment</h3>
              <h3 className="title value">Default</h3>
            </div>

            <div className="row">
              <p className="field display description">Funding Levels</p>
              {/* <Global /> */}
              <OptionsInput
                global={true}
                multiple={true}
                field={"fundingLevelMin"}
                value={fundingLevelMin}
              />
              <OptionsInput
                global={true}
                multiple={true}
                field={"fundingLevelMax"}
                value={fundingLevelMax}
                characters={3}
              />
              <OptionsInput
                global={true}
                multiple={true}
                field={"fundingLevelInc"}
                value={fundingLevelInc}
              />
              <select
                dir="rtl"
                name={"fundingLevelDefault"}
                value={fundingLevelDefault}
                className="field value"
                onChange={handleGlobalChange}
              >
                {funding.map((value, index) => {
                  return (
                    <option key={index} value={value}>
                      {value}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="row">
              <p className="field display description">Overhead Rates</p>
              {/* <Global /> */}
              <OptionsInput
                global={true}
                multiple={true}
                field={"overheadRateMin"}
                value={overheadRateMin}
              />
              <OptionsInput
                global={true}
                multiple={true}
                field={"overheadRateMax"}
                value={overheadRateMax}
                characters={3}
              />
              <OptionsInput
                global={true}
                multiple={true}
                field={"overheadRateInc"}
                value={overheadRateInc}
              />
              <select
                dir="rtl"
                name={"overheadRateDefault"}
                value={overheadRateDefault}
                className="field value"
                onChange={handleGlobalChange}
              >
                {overhead.map((value, index) => {
                  return (
                    <option key={index} value={value}>
                      {value}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="row">
              {/* {global ? <Global /> : null} */}
              <p className="field display description">Match Funding</p>
              <button
                className="showModal value"
                onClick={() => showModal("matchFundingSources")}
              >
                Show options
              </button>
            </div>
          </>
        ) : (
          //  ******************** SETUP ********************
          <>
            <div className="row titles leaderTabMargin">
              <h3 className="title description">Finance Options</h3>
            </div>

            <div className="row">
              {/* {global ? <Global /> : null} */}
              <p className="field display description">Markets</p>
              <button
                className="showModal value"
                onClick={() => showModal("marketOptions")}
              >
                Show options
              </button>
            </div>
            <div className="row titles leaderTabMargin">
              <h3 className="title description">
                Finance Constraints and Thresholds
              </h3>
              <h3 className="title value">Entries</h3>
              <div className="title value"></div>
              <h3 className="title value">Amber</h3>
              <h3 className="title value">Red</h3>
            </div>

            <div className="row">
              <p className="field display description">Business</p>
              <div className="field display value" />
              <div className="field display value" />
              <div className="field display value" />

              <OptionsInput
                global={false}
                multiple={true}
                field={"businessOver"}
                value={businessOver}
                symbol='%'
                // characters={3}
              />
              <p className='symbol'>%</p>
            </div>

            <div className="row">
              <p className="field display description">Academic</p>
              <div className="field display value" />
              <div className="field display value" />
              <div className="field display value" />
              <OptionsInput
                global={false}
                multiple={true}
                field={"academicOver"}
                value={academicOver}
                symbol='%'
                // characters={3}
              />
              <p className='symbol'>%</p>
            </div>

            <div className="row">
              <p className="field display description">Materials</p>
              <OptionsInput
                global={false}
                multiple={true}
                field={"maxMaterials"}
                value={maxMaterials}
              />
              <div className="field display value" />
              <OptionsInput
                global={false}
                multiple={true}
                field={"materialWarn"}
                value={materialWarn}
              />
              <OptionsInput
                global={false}
                multiple={true}
                field={"materialOver"}
                value={materialOver}
              />
            </div>

            <div className="row">
              <p className="field display description">Travel</p>
              <OptionsInput
                global={false}
                multiple={true}
                field={"maxTravel"}
                value={maxTravel}
              />
              <div className="field display value" />
              <OptionsInput
                global={false}
                multiple={true}
                field={"travelWarn"}
                value={travelWarn}
              />
              <OptionsInput
                global={false}
                multiple={true}
                field={"travelOver"}
                value={travelOver}
              />
            </div>

            <div className="row">
              <p className="field display description">CapEx</p>
              <OptionsInput
                global={false}
                multiple={true}
                field={"maxCapex"}
                value={maxCapex}
              />
              <div className="field display value" />
              <OptionsInput
                global={false}
                multiple={true}
                field={"capexWarn"}
                value={capexWarn}
              />
              <OptionsInput
                global={false}
                multiple={true}
                field={"capexOver"}
                value={capexOver}
              />
            </div>

            <div className="row">
              <p className="field display description">Other</p>
              <OptionsInput
                global={false}
                multiple={true}
                field={"maxOther"}
                value={maxOther}
              />
              <div className="field display value" />
              <OptionsInput
                global={false}
                multiple={true}
                field={"otherWarn"}
                value={otherWarn}
              />
              <OptionsInput
                global={false}
                multiple={true}
                field={"otherOver"}
                value={otherOver}
              />
            </div>

            <OptionsInput
              global={false}
              title="Markets"
              field={"maxMarkets"}
              value={maxMarkets}
              characters={1}
            />

            <OptionsInput
              global={false}
              title="Revenue Streams"
              field={"maxStreams"}
              value={maxStreams}
              characters={1}
            />
          </>
        )}
      </div>
      {/* </div> */}
    </Container>
  );
}
export default CostsOptions;
