import { store } from "../store";
import { createSelector } from "reselect";
import { numberToCurrency, roundTo } from "./generalHelpers";
import { getWorkPackageDays } from "../store/entities/tasks";
import { getTotalsByLeader } from "./costTotals";

export const getSwapDetails = createSelector(
  (state) => state.entities.project.data.details,
  (details) => {
    const detailKeys = [
      { key: "applicationNumber", desc: "Application number" },
      { key: "competitor", desc: "Competitor" },
      { key: "funding", desc: "Commercialisation funding" },
      { key: "ganttRef", desc: "Gantt appendix number" },
      { key: "ipProtection", desc: "IP protection strategy" },
      { key: "matchFunding", desc: "Match funding source" },
      { key: "nature", desc: "Project nature" },
      { key: "productPlatformName", desc: "Product / platform name" },
      { key: "projectLength", desc: "Project length" },
      { key: "projectManager", desc: "Project manager" },
      { key: "projectName", desc: "Project name" },
      { key: "riskRef", desc: "Risk appendix number" },
      { key: "software", desc: "Management software" },
      { key: "startMonth", desc: "Project start month" },
      { key: "startYear", desc: "Project start year" },
    ];

    const variables = [];
    const mapObj = {};

    detailKeys.forEach((detail) => {
      const name = `{${detail.key}}`;
      const description = `${detail.desc}`;
      variables.push({ name, description });
      mapObj[name] = details[detail.key];
      if (detail.key === "funding")
        mapObj[name] = numberToCurrency(details[detail.key]);
    });

    const result = { variables, mapObj };
    return result;
  }
);

export const getSwapCompany = createSelector(
  (state) => state.entities.project.data.lead,
  (state) => state.entities.project.data.pOne,
  (state) => state.entities.project.data.pTwo,
  (lead, pOne, pTwo) => {
    const companyKeys = [
      { key: "annualLeave", desc: "Annual leave days" },
      { key: "bankHolidays", desc: "Bank holidays" },
      { key: "companyAcronym", desc: "Company acronym" },
      { key: "companyName", desc: "Company name" },
      { key: "fundingLevel", desc: "Funding level" },
      { key: "investorName", desc: "Investor name" },
      { key: "lastFinancialMonth", desc: "Last financial month" },
      { key: "lastFinancialYear", desc: "Last financial year" },
      { key: "lawyer", desc: "IP Lawyer" },
      { key: "matchFundingSource", desc: "Match funding source" },
      { key: "numEmployees", desc: "Number of employees" },
      { key: "organisationSize", desc: "Organisation size" },
      { key: "organisationType", desc: "Organisation type" },
      { key: "overheadRate", desc: "Overhead rate" },
      { key: "partnerFunding", desc: "Partner funding" },
      { key: "postcode", desc: "Postcode" },
      { key: "turnover", desc: "Turnover" },
    ];

    const variables = [];
    const mapObj = {};

    const companies = [
      { data: lead, prefix: "P1" },
      { data: pOne, prefix: "P2" },
      { data: pTwo, prefix: "P3" },
    ];

    companies.forEach((company) => {
      companyKeys.forEach((key) => {
        const name = `{${key.key}${company.prefix}}`;
        const description = `${company.prefix} ${key.desc}`;
        variables.push({ name, description });
        mapObj[name] = company.data[key.key];
      });
    });

    const result = { variables, mapObj };
    return result;
  }
);

export const getSwapWPShare = createSelector(
  (state) => state.entities,
  () => {
    const variables = [
      { name: "{wp1shareDays}", description: "WP1 % of project days" },
      { name: "{wp2shareDays}", description: "WP2 % of project days" },
      { name: "{wp3shareDays}", description: "WP3 % of project days" },
      { name: "{wp4shareDays}", description: "WP4 % of project days" },
      { name: "{wp5shareDays}", description: "WP5 % of project days" },
      { name: "{wp6shareDays}", description: "WP6 % of project days" },
      { name: "{wp7shareDays}", description: "WP7 % of project days" },
      { name: "{wp8shareDays}", description: "WP8 % of project days" },
      { name: "{wp9shareDays}", description: "WP9 % of project days" },
      { name: "{wp10shareDays}", description: "WP10 % of project days" },
    ];
    const days = getWorkPackageDays(store.getState());
    const totalDays = days.reduce((previous, current) => previous + current);

    const wp1share = roundTo((days[0] / totalDays) * 100, 0);
    const wp2share = roundTo((days[1] / totalDays) * 100, 0);
    const wp3share = roundTo((days[2] / totalDays) * 100, 0);
    const wp4share = roundTo((days[3] / totalDays) * 100, 0);
    const wp5share = roundTo((days[4] / totalDays) * 100, 0);
    const wp6share = roundTo((days[5] / totalDays) * 100, 0);
    const wp7share = roundTo((days[6] / totalDays) * 100, 0);
    const wp8share = roundTo((days[7] / totalDays) * 100, 0);
    const wp9share = roundTo((days[8] / totalDays) * 100, 0);
    const wp10share = roundTo((days[9] / totalDays) * 100, 0);

    const mapObj = {
      "{wp1shareDays}": wp1share,
      "{wp2shareDays}": wp2share,
      "{wp3shareDays}": wp3share,
      "{wp4shareDays}": wp4share,
      "{wp5shareDays}": wp5share,
      "{wp6shareDays}": wp6share,
      "{wp7shareDays}": wp7share,
      "{wp8shareDays}": wp8share,
      "{wp9shareDays}": wp9share,
      "{wp10shareDays}": wp10share,
    };

    const result = { variables, mapObj };
    return result;
  }
);

export const getSwapCosts = createSelector(
  (state) => state.entities,
  () => {
    const costs = getTotalsByLeader(store.getState()).object;

    const categories = [
      { key: "capexCost", desc: "Capital costs" },
      { key: "grants", desc: "Grant value" },
      { key: "materialsCost", desc: "Material costs" },
      { key: "otherCost", desc: "Other costs" },
      { key: "overheads", desc: "Overhead value" },
      { key: "staff", desc: "Staffing cost" },
      { key: "subcontract", desc: "Subcontract cost" },
      { key: "totals", desc: "Total cost" },
      { key: "travelCost", desc: "Travel costs" },
    ];

    const companies = [
      { key: "lead", prefix: "P1" },
      { key: "pOne", prefix: "P2" },
      { key: "pTwo", prefix: "P3" },
      { key: "combined", prefix: "Combined" },
    ];
    const variables = [];
    const mapObj = {};

    companies.forEach((company) => {
      categories.forEach((category) => {
        const name = `{${category.key}${company.prefix}}`;
        const description = `${company.prefix} ${category.desc}`;
        variables.push({ name, description });
        mapObj[name] = numberToCurrency(costs[category.key][company.key]);
      });
    });

    const result = { variables, mapObj };
    return result;
  }
);
