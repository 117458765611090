import styled from "styled-components";
import { fontColorGrey, menuBlueColor } from "../../helpers";

export const Container = styled.div`
  .rows {
    margin-top: 20px;
  }
  .notifications {
    margin-top: 0;
  }
  .toggle {
    width: 80px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    button {
      height: 25px;
      width: 60px;
      font-weight: bold;
      color: ${fontColorGrey};
      /* background-color: grey; */
      border: 1px solid ${fontColorGrey};
      transition: transform 0.3s;
      :hover {
        transform: scale(1.1);
      }
    }
    .yes {
      background-color: ${menuBlueColor};
      border-color: ${menuBlueColor};
      color: white;
    }
    .icon {
      position: absolute;
      right: 0;
      top: -5px;
      max-width: 15px;
      max-height: 15px;
    }
  }
  .hidden.deleteIcon {
    margin-left: 0;
  }
`;
