import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { lastFetchDuration, toastDelay } from "../helpers/settings";
import { store } from "../store";
import { updateUserSelection } from "../store/user";

export const baseURL = () => {
  if (process.env.NODE_ENV === "production") {
    return "https://wax-grant-database.herokuapp.com/api/";
  } else {
    return "http://localhost:3330/api";
  }
};

export const recentFetch = (lastFetch) => {
  const diffInMinutes = moment().diff(moment(lastFetch), "minutes");
  const recent = diffInMinutes < lastFetchDuration ? true : false;
  // true if recent to prevent api call
  const { selectedProjectTime } = store.getState().user;
  const projectUnchanged = selectedProjectTime < lastFetch;
  // true if unchanged to prevent api call
  const prevent = recent && projectUnchanged;
  // console.log(prevent);
  return prevent;
};

toast.configure();

export const toastNotification = (type, message) => {
  const { lastToastMessage, toastMessageTime } = store.getState().user;
  const diffInSeconds = moment().diff(moment(toastMessageTime), "seconds");

  if (diffInSeconds > 5 || lastToastMessage !== message) {
    toast[type](message, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: toastDelay,
    });
    store.dispatch(
      updateUserSelection({ key: "toastMessageTime", value: Date.now() })
    );
    store.dispatch(
      updateUserSelection({ key: "lastToastMessage", value: message })
    );
  }
};

export const reducerNotification = (type, message) => {
  const time = type === 'warn' || type === 'error' ? false : toastDelay
  toast[type](message, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: time,
  });
};

// export function url() {
//   if (process.env.NODE_ENV === 'production') {
//     return 'https://riskdatabase.herokuapp.com/'
//   } else {
//     return 'http://localhost:3300/'
//   }
// }