import React from "react";
import styled from "styled-components";
import { css } from "@emotion/react";
import BarLoader from "react-spinners/BarLoader";

const override = css`
  display: block;
  margin: 0 auto;
  /* border-color: white; */
`;

function App() {
  return (
    <Container>
      <h3>Loading...</h3>
      <BarLoader color={"white"} loading={true} css={override} size={150} />
    </Container>
  );
}

export default App;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  /* border: 1px solid red; */
  margin-top: 150px;
  h3 {
    margin: auto;
    margin-bottom: 20px;
  }
`;