import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  dragElement,
  generatePDF,
  menuBlueColor,
  navBackground,
  navHeight,
  rev2,
} from "../../helpers";
import plus from "../../images/plus.png";
import minus from "../../images/minus.png";
import question from "../../images/qMark.png";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { updatePrintGantt } from "../../store/user";
import Tippy from "@tippy.js/react";
// import PrintGanttColor from "./PrintGanttColor";

export default function PrintRiskOptions(props) {
  const { riskWidth, top, left } = useSelector(
    (state) => state.user.gantt
    );
    const { projectName } = useSelector(
    (state) => state.entities.project.data.details
  );
  
  const dispatch = useDispatch();
  
  function adjust(key, value) {
    dispatch(updatePrintGantt({ key, value }));
  }
  
  function save() {
    const filename = projectName + " Risk Table";
    const elementId = "pdf";
    generatePDF({ filename, elementId });
  }
  
  const history = useHistory();
  function close() {
    history.push('risks')
  }
  
  useEffect(() => {
    dragElement(document.getElementById("printOptions"));
  });

  return (
    <Container id="printOptions" left={left} top={top}>
      <div className="info">
        <Tippy content="For optimum results use Google Chrome">
          <img src={question} alt="info" />
        </Tippy>
      </div>
      <button onClick={close}>Close Risk Preview</button>

      {rev2 ? (
        <>
          <div className="open">
            <h3 id="">Sizing</h3>
          </div>

          <div className="option">
            <p>Description width</p>
            <div className="adjust">
              <div
                onClick={() => adjust("riskWidth", riskWidth - 1)}
                className="icon"
              >
                <img src={minus} alt="adjust" />
              </div>
              <div className="value">
                <p>{riskWidth}</p>
              </div>
              <div
                onClick={() => adjust("riskWidth", riskWidth + 1)}
                className="icon"
              >
                <img src={plus} alt="adjust" />
              </div>
            </div>
          </div>
        </>
      ) : null}
      <button onClick={save}>Download Risk PDF</button>
    </Container>
  );
}

const Container = styled.div`
  z-index: 1;
  position: absolute;
  top: calc(${navHeight} + 20px);
  left: 3%;
  background-color: rgba(255, 255, 255, 0.95);
  color: ${navBackground};
  display: flex;
  flex-direction: column;

  padding: 10px;
  border-radius: 8px;
  overflow: hidden;
  width: 270px;

  padding-top: 20px;

  cursor: move;

  h3 {
    margin: 10px 0;
    min-width: 60px;
  }
  button {
    background-color: ${menuBlueColor};
    color: white;
    font-weight: bold;
    border: none;
    padding: 8px;
    transition: transform 0.3s;
    :hover {
      transform: scale(1.05);
    }
    margin-top: ${rev2 ? "10px" : "20px"};
  }
  .open {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
  }
  .random {
    position: absolute;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    font-size: 12px;
    width: 70px;
    padding: 5px;
  }
  .info {
    position: absolute;
    top: 3px;
    right: 3px;
    max-height: 20px;
    max-width: 20px;
    cursor: initial;
  }
  .adjust {
    display: flex;
    align-items: center;
  }
  .value {
    display: flex;
    justify-content: center;
    width: 35px;
  }
  .icon {
    max-height: 18px;
    max-width: 18px;
    cursor: pointer;
    transition: transform 0.3s;
    :hover {
      transform: scale(1.3);
    }
  }
  .option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    height: 25px;
  }
`;
