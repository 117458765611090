import React from "react";
import styled from "styled-components";
import Wizard from "../general/wizard";
import Summary from "../general/wizardSummary";
import Reset from "../general/wizardReset";
import Intro from "../general/wizardIntro";
import End from "../general/wizardEnd";
import { useSelector } from "react-redux";
import {
  navBackground,
  questionColor,
  wizardBlue,
  wizardBlueLight,
} from "../../helpers";

function wizardModal() {

  const { showComponent } = useSelector((state) => state.user);
  const showWizard = showComponent === "wizard";
  const heightOffset = showWizard ? "0vh" : "-100vh";
  const background = showWizard ? navBackground : null;

  const { component, progress } = useSelector(
    (state) => state.entities.project.data.wizard
  );

  return (
    <Container
      id="wizardBackground"
      heightOffset={heightOffset}
      background={background}
      progress={progress}
    >
      {component === "reset" ? <Reset /> : null}
      {component === "summary" ? <Summary /> : null}
      {component === "questions" ? <Wizard progress={progress} /> : null}
      {component === "intro" ? <Intro /> : null}
      {component === "end" ? <End /> : null}
    </Container>
  );
}

export default wizardModal;

const Container = styled.div`
  position: fixed;
  top: ${(props) => props.heightOffset};
  right: ${(props) => props.widthOffset};
  width: 100%;
  height: 100vh;
  transition: top 0.3s, background-color 0.3s ease 0.3s;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.background};

  z-index: 3;

  h3 {
    text-align: center;
    color: white;
  }
  p {
    text-align: center;
    color: ${wizardBlue};
  }
  p.summary {
    color: white;
  }
  .bold {
    font-weight: bold;
  }

  .modal {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    min-width: 400px;
    max-width: 500px;
    min-height: 200px;
    padding: 10px;

    box-shadow: 8px 8px 12px rgba(1, 1, 1, 0.5);
    background-color: white;
    border-radius: 5px;
  }
  .intro {
    padding: 10px 70px;
  }
  .title {
    position: absolute;
    top: -50px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 250px;
    font-size: 20px;
  }

  .questionContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
    height: 100px;
    margin-left: 20px;
    margin-right: 20px;
  }

  .buttons {
    width: 100%;
    max-width: 500px;
    flex-wrap: wrap;
    /* margin-top: 20px; */
    display: flex;
    justify-content: center;
    button {
      height: 50px;
      margin-bottom: 10px;
      padding: 6px 20px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      width: 170px;
    }
    .binary {
      width: 100px;
    }
    /* border: 1px solid red */
  }
  .backNext {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 15px;
    height: 25px;
    /* border: 1px solid red */
  }
  .back {
    text-align: left;
    background-color: transparent;
    color: ${questionColor};
    border-color: transparent;
    padding: 0;
    height: 100%;
    min-width: 0;
    font-weight: unset;
    margin-left: 5px;
  }
  .next {
    height: 100%;
    padding: 0;
    min-width: 80px;
    margin: 0;
  }
  .wizardAnswers {
    margin: 20px 0 10px 0;
    border-radius: 4px;
    /* border: 1px solid red; */
    overflow: hidden;
    scroll-padding: 0;
    /* height: 450px; */
  }
  .result {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 300px;
    height: 35px;
    padding: 3px 15px;
    background-color: ${wizardBlue};
    color: white;
    overflow: hidden;
    font-weight: bold;
    transition: height 0.3s;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  .summaryButtons {
    display: flex;
    justify-content: space-between;
    width: 300px;
    margin: 5px 0 10px 0;
    button {
      margin: 0;
    }
  }
  .mb {
    margin-bottom: 10px;
  }
  .mt {
    margin-top: 10px;
  }
  .summary {
    display: flex;
    align-items: center;
  }
  .skip {
    border: none;
    height: 0;
    overflow: hidden;
    padding: 0;
  }
  .dot {
    height: 3px;
    width: 6px;
    /* border-radius: 50%; */
    background-color: white;
    margin-right: 7px;
  }
  .dep1 {
    padding-left: 15px;
    background-color: ${wizardBlueLight};
    /* border-left: 5px solid ${wizardBlueLight};
      border-right: 5px solid ${wizardBlueLight}; */
  }
  .dep2 {
    padding-left: 30px;
    background-color: ${wizardBlueLight};
    font-weight: unset;
    /* border-left: 5px solid ${wizardBlueLight};
      border-right: 5px solid ${wizardBlueLight}; */
  }
  .yes {
    margin: 0 5px;
    /* background-color: ${navBackground}; */
  }
  .no {
    color: ${wizardBlue};
    border-color: ${wizardBlue};
    /* border-color: rgba(255, 255, 255, 0.2); */
    background-color: rgba(200, 200, 200, 0.5);
    margin: 0 5px;
  }
  button {
    min-width: 100px;
    height: 35px;
    font-weight: bold;
    color: white;
    border-radius: 4px;
    background-color: ${wizardBlue};
    border: 1px solid ${wizardBlue};
    margin: 0 10px;
  }
  .progress {
    position: absolute;
    bottom: -40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    height: 25px;
    width: 100%;
    /* border: 1px solid red; */
    color: ${wizardBlue};
    font-weight: bold;
  }
  .progressBar {
    margin-left: 10px;
    height: 10px;
    border-radius: 15px;
    width: 300px;
    border: 1px solid ${wizardBlue};
    overflow: hidden;
  }
  .bar {
    width: ${(props) => props.progress};
    height: 100%;
    background-color: ${wizardBlue};
  }
`;
