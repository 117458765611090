import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "./ganttRowStyling";
import bin from "../../images/bin-grey.png";
import reorder from "../../images/reorder.png";

import { deleteDeadline, updateDeadline } from "../../store/entities/deadlines";
import { getProjectDates } from "../../store/entities/project";
import Tippy from "@tippy.js/react";
import { handleSendDeadlines } from "../../helpers";

function GanttRowDetails(props) {
  const dispatch = useDispatch();
  const { deadline, provided, lastDeadline } = props;
  const { description, scheduled, deadlineId } = deadline;

  const dateList = getProjectDates(useSelector((state) => state));

  function handleChange(key, value) {
    dispatch(
      updateDeadline({
        deadlineId,
        key,
        value,
      })
    );
    if (key === "scheduled") handleSendDeadlines();
  }

  return (
    <Container>
      <Tippy content="Drag to reorder rows">
        <div {...provided.dragHandleProps} className="hidden drag">
          <img src={reorder} alt="reorder" />
        </div>
      </Tippy>
      <input
        name="description"
        className="field deadlineDescription packBackground"
        value={description}
        type="text"
        onChange={(e) => handleChange("description", e.target.value)}
        onBlur={handleSendDeadlines}
      />

      <select
        name="scheduled"
        className="field"
        value={scheduled}
        onChange={(e) => handleChange("scheduled", parseInt(e.target.value))}
      >
        {dateList.map((date, index) => (
          <option value={index} key={index} className="date">
            {date}
          </option>
        ))}
      </select>
      <div className="hidden delete">
        {lastDeadline ? null : (
          <img
            src={bin}
            alt="delete"
            style={{ cursor: "pointer" }}
            onClick={() => dispatch(deleteDeadline({ deadlineId }))}
          />
        )}
      </div>
    </Container>
  );
}

export default GanttRowDetails;
