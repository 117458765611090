import React from "react";
import { useSelector } from "react-redux";
import { navHeight } from "../helpers";
import styled from "styled-components";
import PrintRiskOptions from "../components/print/PrintRiskOptions";
import PrintRisksPDF from "../export/PrintRisksPDF";

export default function PrintGantt(props) {
  const risks = useSelector((state) => state.entities.risks.data).length;

  // if (!risks) props.history.push("/risks");

  return (
    <Container>
      <PrintRiskOptions />
      <PrintRisksPDF />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: ${navHeight};
  margin: auto;
  padding-bottom: 50px;
  flex-direction: column;
`;
