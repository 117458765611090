import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "../../components/table/TableTabs";
import { getSelectedProject, updateUserSelection } from "../../store/user";

export default function LeaderTabs(props) {
  const dispatch = useDispatch();
  const { partners } = useSelector(getSelectedProject);
  const leader = useSelector((state) => state.user.selectedLeader);
  const pOne = useSelector(
    (state) => state.entities.project.data.pOne.companyName
  );
  const pTwo = useSelector(
    (state) => state.entities.project.data.pTwo.companyName
  );

  const allTabs = [
    { name: "Lead Applicant", key: "lead" },
    { name: pOne ? pOne : "Partner One", key: "pOne" },
    { name: pTwo ? pTwo : "Partner Two", key: "pTwo" },
    { name: "Combined", key: "combined" },
  ];

  // set to leader if combined not relevant
  if (!props.viewCombinedTab) {
    if (leader === "combined") selectLeader("lead");
    allTabs.pop();
  }

  function selectLeader(value) {
    dispatch(updateUserSelection({ key: "selectedLeader", value }));
  }

  if (partners === 0) return null;
  if (partners === 1) return null;
  if (partners === 2) allTabs.splice(2, 1);

  return (
    <Container>
      {allTabs.map((tab, index) => {
        return (
          <div
            className={leader === tab.key ? "tab selectedTab" : "tab"}
            key={index}
            onClick={() => selectLeader(tab.key)}
          >
            <h3>{tab.name}</h3>
          </div>
        );
      })}
    </Container>
  );
}