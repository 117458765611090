import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router";

const AuthRoute = (props) => {
  const { type } = props;
  const { authenticated, admin } = useSelector((state) => state.user);
  // const admin = localStorage.getItem("admin");

  if (type === "guest" && admin && authenticated)
    return <Redirect to="/admin" />;
  if (type === "guest" && authenticated) return <Redirect to="/" />;

  if (type === "admin" && !admin) return <Redirect to="/" />;
  if (type === "private" && !authenticated) return <Redirect to="/login" />;
  return <Route {...props} />;
};

export default AuthRoute;
