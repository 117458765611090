// import { store } from "../../store";
import styled from "styled-components";
import {
  appTop,
  // appTopIfAdmin,
  appWidth,
  fontColorGrey,
  navHeight,
  tabBottomMargin,
  tableContentSideMargin,
  tableInputUnderline,
  // tableMinHeight,
  tableRightWidth,
} from "../../helpers";

export const TableContainer = styled.div`
  position: relative;
  top: ${appTop};
  top: ${(props) => props.tableTop};
  margin: auto;
  max-width: ${appWidth};
  display: flex;
  justify-content: center;
  align-items: center;
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
  overflow: -moz-scrollbars-none;
  .displayArea {
    position: relative;
    margin-bottom: 10px;
    display: flex;
    overflow: hidden;
    border-radius: 6px;
    box-shadow: 8px 8px 12px rgba(1, 1, 1, 0.5);
    -ms-overflow-style: none; /* IE 11 */
    -webkit-scrollbar-width: none; /* Firefox 64 */
    overflow: -moz-scrollbars-none;
    scrollbar-width: none; /* Firefox 64 */
  }
  .content {
    position: relative;
    width: ${tableRightWidth};
    background-color: white;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    max-height: calc(100vh - ${navHeight} - 30px);
    /* border: 1px solid red */
    scrollbar-width: none; /* Firefox 64 */
  }
  .table {
    position: relative;
    flex-grow: 1;
    margin-bottom: ${(props) => props.data.marginBottom};
    transition: margin-bottom 0.3s;
    /* border: 1px solid red */
  }
  .relative {
    position: relative;
  }
  .leaderTabMargin {
    margin-top: ${tabBottomMargin};
  }
  .titles.row {
    /* min-height: 0; */
    /* margin-bottom: 10px; */
  }
  .grabHandle {
    position: absolute;
    width: 18px;
    /* margin: 0px 5px 0px -23px; */
    left: -22px;
    padding-top: 4px;
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    &:active {
      cursor: grabbing;
      cursor: -moz-grabbing;
      cursor: -webkit-grabbing;
    }
  }
  .title {
    position: relative;
    display: flex;
    margin-right: 20px;
    font-weight: bold;
    color: ${(props) => props.data.backgroundColor};
  }
  .title.center {
    justify-content: center;
  }
  .info {
    margin: 0;
    margin-left: 7px;
    width: 16px;
    height: 16px;
  }
  .rows {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    flex-wrap: wrap;
    max-height: ${(props) => props.data.maxHeight};
  }
  .adminContent {
    max-height: calc(100vh - 230px);
    width: 100%;
    overflow-y: scroll;
    flex-wrap: nowrap;
    margin-bottom: 10px;
  }
  .row {
    max-width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    min-height: 35px;
    margin-left: ${tableContentSideMargin};
    &:hover .hidden {
      transition: opacity 0.3s;
      opacity: 1;
    }
  }
  .hidden {
    opacity: 0;
  }
  .column {
    width: calc(50% - ${tableContentSideMargin});
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 15px;
    .field {
      width: 95%;
    }
    .month {
      width: 40px;
    }
    .year {
      width: 55px;
    }
    .length {
      width: 35px;
    }
  }
  .field {
    background-color: transparent;
    margin-right: 20px;
    padding: 5px 0;
    border-radius: 0;
    border-bottom: 2px solid ${tableInputUnderline};
    color: ${fontColorGrey};
    font-size: 16px;
    &:focus {
      border: none;
      border-bottom: 2px solid ${(props) => props.data.backgroundColor};
    }
  }
  .field.display {
    border-color: transparent;
  }
  .field.toggle {
    border: 1px solid ${tableInputUnderline};
    border-radius: 5px;
    display: flex;
    justify-content: center;
  }
  .bold {
    font-weight: bold;
  }
  .total {
    display: flex;
    font-size: 14px;
    font-weight: bold;
    padding: 5px 0;
  }
  .cost {
    justify-content: flex-end;
    text-align: right;
    width: 80px;
    font-weight: bold;
  }
  .bottomLeftCorner {
    position: absolute;
    bottom: 0;
    left: -5px;
    display: flex;
    justify-content: flex-end;
    padding: 20px 30px;
    button {
      border: none;
      color: gray;
      transition: transform 0.3s;
      :hover {
        /* text-decoration: underline; */
        transform: scale(1.08);
      }
    }
  }

  .bottomRightCorner {
    z-index: 8;
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: flex-start;
    padding: 20px 30px;
    button {
      background-color: transparent;
      border: none;
      color: gray;
      transition: transform 0.3s;
      :hover {
        /* text-decoration: underline; */
        transform: scale(1.08);
      }
    }
  }
`;
