import { createSlice } from "@reduxjs/toolkit";
import { leaderList, recentFetch, reducerNotification } from "../../helpers";
import { apiCallBegan } from "../api";
// import { createSelector } from "reselect";

export const initialData = {
  lead: {
    materials: [],
    travel: [],
    capex: [],
  },
  pOne: {
    materials: [],
    travel: [],
    capex: [],
  },
  pTwo: {
    materials: [],
    travel: [],
    capex: [],
  },
};

const initialState = {
  loading: false,
  lastFetch: 0,
  data: initialData,
  error: null,
};

// a list of WPs that costs are assigned to

const slice = createSlice({
  name: "assignments",
  initialState,
  reducers: {
    assignmentDataRequested: (assignment, _) => {
      assignment.loading = true;
    },
    assignmentDataRequestFailed: (assignment, action) => {
      assignment = initialState;
      reducerNotification("error", action.payload);
    },
    assignmentDataReceived: (assignment, action) => {
      // console.log(action.payload);
      assignment.data = action.payload;
      assignment.loading = false;
      assignment.lastFetch = Date.now();
    },

    toggleAssignment: (assignments, action) => {
      const { leader, category, workPackageId } = action.payload;
      if (!assignments.data[leader][category])
        assignments.data[leader][category] = [];
      const index = assignments.data[leader][category].indexOf(workPackageId);
      if (index < 0) {
        assignments.data[leader][category].push(workPackageId);
      } else {
        assignments.data[leader][category].splice(index, 1);
      }
      // const otherIndex = assignments.data[leader][category].indexOf(workPackageId);
      // assignments.data[leader][otherId] = [workPackageId];
    },
    assignAllToCategory: (assignments, action) => {
      const { leader, category, workPackageIds } = action.payload;
      assignments.data[leader][category] = workPackageIds;
    },
    assignNoneToCategory: (assignments, action) => {
      const { leader, category } = action.payload;
      assignments.data[leader][category] = [];
    },
    deletePackAssignments: (assignments, action) => {
      const { workPackageId, otherIds } = action.payload;
      const leaders = ["lead", "pOne", "pTwo"];
      const categories = ["materials", "travel", "capex", ...otherIds];
      leaders.forEach((leader) => {
        categories.forEach((category) => {
          const index = assignments.data[leader][category]
            ? assignments.data[leader][category].indexOf(workPackageId)
            : -1;
          if (index > -1) {
            assignments.data[leader][category].splice(index, 1);
          }
        });
      });
    },
    resetAssignments: (assignments, action) => {
      const { leader } = action.payload;
      assignments.data[leader] = {};
      assignments.data[leader].materials = [];
      assignments.data[leader].travel = [];
      assignments.data[leader].capex = [];
    },
    deleteRedundantAssignments: (assignments, action) => {
      const { redundant } = action.payload;
      redundant.forEach((entry) => {
        const { otherId, workPackageId, leader } = entry;
        if (otherId) {
          delete assignments.data[leader][otherId];
        } else {
          leaderList.forEach((leader) => {
            const categories = Object.keys(assignments.data[leader]);
            categories.forEach((category) => {
              const index =
                assignments.data[leader][category].indexOf(workPackageId);
              assignments.data[leader][category].splice(index, 1);
            });
          });
        }
      });

      // assignments.data[leader] = {};
      // assignments.data[leader].materials = [];
      // assignments.data[leader].travel = [];
      // assignments.data[leader].capex = [];
    },

    apiSuccess: (deadlines, action) => {
      deadlines.loading = false;
      reducerNotification("info", action.payload.message);
    },
    apiFailed: (deadlines, _) => {
      deadlines.loading = false;
      reducerNotification("error", "Assignments failed");
    },
  },
});

export const {
  toggleAssignment,
  assignAllToCategory,
  assignNoneToCategory,
  deletePackAssignments,
  resetAssignments,
  assignmentDataReceived,
  deleteRedundantAssignments,
} = slice.actions;

const {
  assignmentDataRequested,
  assignmentDataRequestFailed,

  apiSuccess,
  apiFailed,
} = slice.actions;
export default slice.reducer;

// Action Creators

const url = "/assignments";

export const loadAssignmentData = () => (dispatch, getState) => {
  const { lastFetch } = getState().entities.assignments;
  // console.log("fetching assignments data...");
  if (recentFetch(lastFetch)) return;

  dispatch(
    apiCallBegan({
      url: url + "/selected",
      method: "get",
      data: null,
      onStart: assignmentDataRequested.type,
      onSuccess: assignmentDataReceived.type,
      onError: assignmentDataRequestFailed.type,
    })
  );
};

export const sendUpdatedAssignments = () => (dispatch, getState) => {
  // console.log(dispatch, getState);
  const data = getState().entities.assignments.data;
  // console.log(data);
  dispatch(
    apiCallBegan({
      url: url + "/selected",
      method: "put",
      data,
      // onStart: sendingUpdatedAssignment.type,
      onSuccess: apiSuccess.type,
      onError: apiFailed.type,
    })
  );
};

export const initiateAssignments = (projectId) =>
  apiCallBegan({
    url: url + "/new",
    method: "post",
    data: {
      projectId,
      data: initialData,
    },
    // onStart: sendingEmptyAssignments.type,
    onSuccess: apiSuccess.type,
    onError: apiFailed.type,
  });

export const cloneAssignments = (projectId) => (dispatch, getState) => {
  const { data } = getState().entities.assignments;
  dispatch(
    apiCallBegan({
      url: url + "/new",
      method: "post",
      data: {
        projectId,
        data,
      },
      // onStart: sendingAssignmenta.type,
      onSuccess: apiSuccess.type,
      onError: apiFailed.type,
    })
  );
};

export const deleteProjectAssignments = (projectId) =>
  apiCallBegan({
    url: url + "/selected",
    method: "delete",
    data: {
      projectId,
    },
    // onStart: sendingEmptyAssignments.type,
    onSuccess: apiSuccess.type,
    onError: apiFailed.type,
  });
