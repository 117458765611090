import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUserSelection } from "../../store/user";
import { getRiskTabs, sortRisks } from "../../store/entities/risks";
import TableTabs from "../table/TableTabs";

export default function RiskTabs() {
  const dispatch = useDispatch();
  const selectedRiskType = useSelector((state) => state.user.selectedRiskType);
  const tabs = useSelector(getRiskTabs);
  function setCategory(value) {
    dispatch(sortRisks());
    dispatch(updateUserSelection({ key: "selectedRiskType", value: "" }));
    setTimeout(() => {
      dispatch(updateUserSelection({ key: "selectedRiskType", value }));
    }, 1);
  }

  const data = {
    tabs,
    selectedTab: selectedRiskType,
    select: (selected) => setCategory(selected),
  };

  return <TableTabs data={data} />;
}
