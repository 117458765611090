import React, { useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { setShowComponent, updateUserSelection } from "../../store/user";
import { navBackground, wizardBlue } from "../../helpers";
import { sendUpdatedDetails, wizardOffered } from "../../store/entities/project";

function wizardOfferModal() {
  const dispatch = useDispatch();
  const { offered } = useSelector(
    (state) => state.entities.project.data.wizard
  );
  const { showComponent, admin } = useSelector((state) => state.user);
  const heightOffset = showComponent === "wizardOffer" ? "0vh" : "-100vh";

  function closeModal() {
    dispatch(sendUpdatedDetails())
    dispatch(updateUserSelection({ key: "showComponent", value: "" }));
  }

  function offerTheWizard() {
    dispatch(wizardOffered());
    dispatch(setShowComponent({ value: "wizardOffer" }));
  }

  function acceptWizard() {
    dispatch(sendUpdatedDetails())
    dispatch(setShowComponent({ value: "" }));
    setTimeout(() => {
      dispatch(setShowComponent({ value: "wizard" }));
    }, 500);
  }

  useEffect(() => {
    if (offered || admin) return;
    setTimeout(() => offerTheWizard(), 1500);
  },[]);

  return (
    <Container id="offerBackground" heightOffset={heightOffset}>
      <div className="initialAsk">
        <h3 className="title">Welcome to the Wizard</h3>
        <p className="bold">
          Would you like to use a quick wizard to customise the options
          available to you throughout the platform?
        </p>
        <p>
          You can access the Wizard again any time using the button at the top
          right of the screen.
        </p>
        <div className="buttons">
          <button onClick={closeModal} className="cancel">
            Maybe later
          </button>
          <button onClick={acceptWizard} className="confirm">
            Launch Wizard
          </button>
        </div>
      </div>
    </Container>
  );
}

export default wizardOfferModal;

const Container = styled.div`
  position: fixed;
  top: ${(props) => props.heightOffset};
  /* right: ${(props) => props.widthOffset}; */
  right: 0;
  width: 100%;
  height: 100vh;
  transition: all 0.3s;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${navBackground};
  /* z-index: 2; */

  p {
    text-align: center;
  }
  .bold {
    font-weight: bold;
  }

  .initialAsk {
    flex-direction: column;
    position: relative;
    height: 240px;
    width: 500px;
    box-shadow: 8px 8px 12px rgba(1, 1, 1, 0.5);

    display: flex;
    justify-content: space-evenly;
    align-items: center;

    background-color: white;
    border: 1px solid black;
    border-radius: 8px;
    padding: 30px 40px;
    /* overflow: hidden; */
    .title {
      position: absolute;
      top: -50px;
      font-size: 20px;
      color: white;
    }
    .buttons {
      margin-top: 20px;
      display: flex;
      justify-content: center;
    }
    .cancel {
      padding: 5px 10px;
      /* background-color: green; */
      margin-right: 10px;
    }
    .confirm {
      padding: 5px 10px;
      /* background-color: red; */
    }
    button {
      width: 150px;
      height: 40px;
      font-weight: bold;
      color: white;
      border: 1px solid ${wizardBlue};
      background-color: ${wizardBlue};
      margin: 0 30px;
    }
  }
`;
