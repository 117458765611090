import Tippy from "@tippy.js/react";
import React from "react";
import { useSelector } from "react-redux";
import { capitaliseFirstLetter, handleSectionStatus } from "../helpers";

function MarkAsComplete(props) {
  const { section } = props;
  const { status, details } = useSelector(
    (state) => state.entities.project.data
  );
  const { productPlatformName } = details;

  function changeStatus() {
    handleSectionStatus(section);
  }

  const Section = capitaliseFirstLetter(section);
  return (
    <>
      {productPlatformName ? (
        <div className="bottomRightCorner">
          <button onClick={changeStatus}>
            {details.notify.length ? (
              <Tippy content="This will send a notification email">
                <h3>
                  {status[section]
                    ? `Edit ${Section}`
                    : `Mark ${Section} as Complete`}
                </h3>
              </Tippy>
            ) : (
              <h3>
                {status[section]
                  ? `Edit ${Section}`
                  : `Mark ${Section} as Complete`}
              </h3>
            )}
          </button>
        </div>
      ) : null}
    </>
  );
}
export default MarkAsComplete;
