import React from "react";
import styled from "styled-components";
import { menuBlueColor, menuBlueColorFade } from "../../helpers";
import Close from "../general/close";

function InfoModal(props) {
  const { heading, p1, p2 } = props;

  return (
    <Container>
      <div className="modal">
        <Close data={{}} />
        <h3>{heading}</h3>
        <p>{p1}</p>
        <p>{p2}</p>
      </div>
    </Container>
  );
}

export default InfoModal;

const Container = styled.div`
  width: 100%;

  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  .modal {
    width: 95%;
    flex-direction: column;
    position: relative;
    height: 150px;

    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;

    /* border: 1px solid red; */

    background-color: ${menuBlueColorFade};
    color: ${menuBlueColor};
    border-radius: 8px;
    padding: 0px 40px;
    overflow: hidden;
  }
`;
