// import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import {
  calculateDepreciation,
  recentFetch,
  reducerNotification,
} from "../../helpers";
import { apiCallBegan } from "../api";

const initialState = {
  loading: false,
  lastFetch: 0,
  data: [],
  lastSent: 0,
  lastEdit: 0,
  error: "",
};

const slice = createSlice({
  name: "capex",
  initialState,
  reducers: {
    capexDataRequested: (capex, _) => {
      capex.loading = true;
    },
    capexDataRequestFailed: (capex, action) => {
      capex = initialState;
      reducerNotification("error", action.payload);
    },
    capexDataReceived: (capex, action) => {
      capex.data = action.payload;
      capex.loading = false;
      capex.lastFetch = Date.now();
    },

    sendingCapex: (capex, _) => {
      capex.loading = true;
    },

    addCapex: (capex, action) => {
      const { newCapex, position } = action.payload;
      capex.data.splice(position, 0, newCapex);
      capex.lastEdit = Date.now();
    },
    deleteCapex: (capex, action) => {
      const index = capex.data.findIndex(
        (capex) => capex.capexId === action.payload.capexId
      );
      capex.data.splice(index, 1);
      capex.lastEdit = Date.now();
    },
    updateCapex: (capex, action) => {
      const { key, value, capexId, projectLength } = action.payload;
      const index = capex.data.findIndex(
        (person) => person.capexId === capexId
      );
      capex.data[index][key] = value;
      const cost = calculateDepreciation({
        depreciation: capex.data[index].depreciation,
        currentValue: capex.data[index].currentValue,
        utilisation: capex.data[index].utilisation,
        projectLength,
      });
      capex.data[index].cost = cost;
      capex.data[index].quantity = 1;
      capex.lastEdit = Date.now();
    },
    reorderCapex: (capex, action) => {
      const originalIndex = capex.data.findIndex(
        (capex) => capex.capexId === action.payload.capex.capexId
      );
      const newIndex = originalIndex + action.payload.movement;
      const [journey] = capex.data.splice(originalIndex, 1);
      capex.data.splice(newIndex, 0, journey);
      capex.lastEdit = Date.now();
    },

    apiSuccess: (capex, action) => {
      capex.loading = false;
      // reducerNotification("info", "Capex saved");
      capex.lastSent = Date.now();
    },
    apiFailed: (capex, _) => {
      capex.loading = false;
      reducerNotification("error", "Capex failed");
    },
  },
});
export default slice.reducer;

export const {
  reorderCapex,
  updateCapex,
  addCapex,
  deleteCapex,
  capexDataReceived,
} = slice.actions;

const {
  capexDataRequested,
  capexDataRequestFailed,

  sendingCapex,

  apiSuccess,
  apiFailed,
} = slice.actions;

// Action Creators

const url = "/capex";

export const loadCapexData = () => (dispatch, getState) => {
  const { lastFetch } = getState().entities.capex;
  // console.log("fetching capex data...");
  if (recentFetch(lastFetch)) return;

  dispatch(
    apiCallBegan({
      url: url + "/selected",
      method: "get",
      data: null,
      onStart: capexDataRequested.type,
      onSuccess: capexDataReceived.type,
      onError: capexDataRequestFailed.type,
    })
  );
};

export const sendUpdatedCapex = () => (dispatch, getState) => {
  const capex = getState().entities.capex;
  const { lastEdit, lastSent, data } = capex;
  if (lastSent >= lastEdit) return;
  dispatch(
    apiCallBegan({
      url: url + "/selected",
      method: "put",
      data,
      // onStart: sendingCapex.type,
      onSuccess: apiSuccess.type,
      onError: apiFailed.type,
    })
  );
};

export const initiateCapex = (projectId) =>
  apiCallBegan({
    url: url + "/new",
    method: "post",
    data: {
      projectId,
      data: [],
    },
    onStart: sendingCapex.type,
    onSuccess: apiSuccess.type,
    onError: apiFailed.type,
  });

export const cloneCapex = (projectId) => (dispatch, getState) => {
  const { data } = getState().entities.capex;
  dispatch(
    apiCallBegan({
      url: url + "/new",
      method: "post",
      data: {
        projectId,
        data,
      },
      // onStart: sendingAllocation.type,
      onSuccess: apiSuccess.type,
      onError: apiFailed.type,
    })
  );
};

export const deleteProjectCapex = (projectId) =>
  apiCallBegan({
    url: url + "/selected",
    method: "delete",
    data: {
      projectId,
    },
    // onStart: sendingEmptyAssignments.type,
    onSuccess: apiSuccess.type,
    onError: apiFailed.type,
  });

// Selectors

export const getCapexCost = createSelector(
  (state) => state.entities.project.data,
  (state) => state.entities.capex.data,
  (project, capex) => {
    // console.log("getCapexCost");
    const { projectLength } = project.details;
    const costs = {
      lead: 0,
      pOne: 0,
      pTwo: 0,
      combined: 0,
      category: "CapEx",
    };
    capex.forEach((capex) => {
      const { depreciation, currentValue, utilisation } = capex;
      const netCost = calculateDepreciation({
        depreciation,
        currentValue,
        utilisation,
        projectLength,
      });
      costs[capex.leader] = costs[capex.leader] + netCost;
      costs.combined = costs.combined + netCost;
    });
    // console.log(costs);
    return costs;
  }
);
