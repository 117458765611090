import styled from "styled-components";
import { wpBackground, wpHighlight } from "../../helpers";

export const Container = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  padding: 5px 30px;
  height: 40px;
  border-bottom: 1px solid lightgrey;
  background-color: white;
  &:hover .hidden {
    opacity: 1;
  }
  &:hover .field {
    background-color: ${wpHighlight};
  }
  .field {
    height: 100%;
    background-color: white;
    transition: background-color 0.3s;
  }
  .hidden {
    transition: opacity 0.3s;
    position: absolute;
    opacity: 0;
  }
  .drag {
    left: 7px;
    top: 10px;
  }
  .delete {
    top: 10px;
    right: 5px;
  }
  img {
    max-height: 18px;
  }
  .resources {
    display: flex;
    align-items: center;
    padding: 0 5px;
    border-radius: 5px;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .bars {
    cursor: pointer;
    padding-right: 5px;
    text-align-last: right;
  }
  .days {
    text-align: right;
    padding-right: 5px;
  }
  .editDays {
    position: relative;
    display: flex;
    align-items: center;
    z-index: 1;
    .accept {
      background-color: white;
      border: 1px solid white;
      position: absolute;
      display: flex;
      align-items: center;
      right: -23px;
      max-height: 25px;
      max-width: 25px;
    }
  }
`;
