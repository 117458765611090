import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { questions } from "../../helpers";
import { wizardToggle } from "../../store/entities/project";

function wizardQuestionMulti() {
  const dispatch = useDispatch();

  const { currentQuestionIndex } = useSelector(
    (state) => state.entities.project.data.wizard
  );
  const { data } = questions[currentQuestionIndex];
  const answer = useSelector(
    (state) =>
      state.entities.project.data.wizard.answers[currentQuestionIndex].result
  );

  function toggleOption(option) {
    dispatch(wizardToggle({ index: currentQuestionIndex, option }));
  }

  return (
    <>
      {data.map((entry, index) => {
        return (
          <button
            key={index}
            onClick={() => toggleOption(entry)}
            className={answer[entry] ? "yes" : "no"}
          >
            {entry}
          </button>
        );
      })}
    </>
  );
}

export default wizardQuestionMulti;
