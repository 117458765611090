import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { navBackground, settingsColor } from "../../helpers";
import add from "../../images/plusButtonPurple.png";

import AccountRow from "./AccountRow";
import Tippy from "@tippy.js/react";
import AdminAccount from "./AdminAccount";
import { setShowComponent } from "../../store/user";
import UserModal from "../modals/userModal";

export default function AccountTable() {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.entities.users.data);
  const { showComponent } = useSelector((state) => state.user);

  function showUserModal() {
    dispatch(setShowComponent({ value: "userModal" }));
  }

  return (
    <Container>
      {showComponent === "userModal" ? <UserModal /> : null}
      <div className="titles">
        <div className="indicatorSection"></div>
        <div className="info tab">
          <h3 className="name">Name / Email</h3>
        </div>
        <div className="optionsContainer">
          <h3>Options</h3>
        </div>
        <h3 className="projects">Projects</h3>
      </div>
      <div className="users">
        {users.map((user, index) => {
          return user.admin ? null : <AccountRow user={user} key={index} />;
        })}
      </div>
      <div className="titles bottomRow">
        <div className="indicatorSection"></div>
        <Tippy content="Add a new user">
          <div className="add tab">
            <img src={add} alt="add" onClick={showUserModal} />
          </div>
        </Tippy>
        <div className="admins">
          {users.map((user, index) => {
            return user.admin ? <AdminAccount user={user} key={index} /> : null;
          })}
        </div>
      </div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 40px;
  box-shadow: 8px 8px 12px rgba(1, 1, 1, 0.5);
  overflow: hidden;
  border-radius: 6px;
  color: ${navBackground};
  background-color: white;

  .titles {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 40px;
  }
  .users {
    width: 100%;
    flex-direction: column;
  }
  .bottomRow {
    position: relative;
    display: flex;
    justify-content: space-between;
    height: 50px;
    .add {
      position: absolute;
      left: 120px;
      max-width: 30px;
      max-height: 30px;
      border-radius: 50%;
      cursor: pointer;
    }
  }
  .admins {
    display: flex;
  }
  .indicatorSection {
    min-width: 120px;
    max-width: 120px;
    height: 100%;
    background-color: ${settingsColor};
  }
  .tab {
    margin-left: 25px;
  }
  .info {
    width: 290px;
    padding-left: 10px;
  }
  .name {
  }
  .optionsContainer {
    margin-right: 20px;
    display: flex;
    justify-content: center;
    width: 80px;
    /* border: 1px solid red; */
  }
  .projects {
    width: 200px;
  }
`;
