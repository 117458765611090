import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Spinner from "../components/spinner";
import {
  fontColorGrey,
  navBackground,
  rev2,
  toastNotification,
} from "../helpers";
import { userLogin, userResetPassword } from "../store/user";

function Login() {
  const dispatch = useDispatch();
  const { projectDataLoading } = useSelector((state) => state.user);
  const [showPassword, setShowPassword] = useState(false);
  const blankForm = {
    email: "",
    password: "",
    rememberMe: false,
  };
  const [loginForm, setLoginForm] = useState(blankForm);
  const [reset, setReset] = useState(false);

  function handleChange(e) {
    setLoginForm({ ...loginForm, [e.target.name]: e.target.value });
  }
  function rememberMe() {
    setLoginForm({ ...loginForm, rememberMe: !loginForm.rememberMe });
  }

  let notification = false;
  function notify() {
    if (!notification)
      toastNotification("error", "Please enter email and password");
    notification = true;
  }
  function submit() {
    if (loginForm.email === "" || loginForm.password === "") return notify();
    setLoginForm(blankForm);
    dispatch(userLogin(loginForm));
  }

  function resetPassword() {
    if (loginForm.email === "") return notify();
    setLoginForm({ ...loginForm, password: "" });
    setReset(false);
    dispatch(userResetPassword(loginForm));
  }

  return (
    <Container id="background" rememberMe={loginForm.rememberMe}>
      {projectDataLoading ? (
        <Spinner />
      ) : (
        <>
          <h2>Wax Grant Portal</h2>
          {/* <h2>Login</h2> */}
          <div className="editWindow">
            <input
              placeholder="Email"
              name="email"
              value={loginForm.email}
              onChange={handleChange}
            />
            {reset ? null : (
              <>
                <div className="passwordContainer">
                  <input
                    name="password"
                    value={loginForm.password}
                    type={showPassword ? null : "password"}
                    placeholder="Password"
                    onChange={handleChange}
                  />
                  <p
                    className="showPassword"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    show
                  </p>
                </div>

                <div className="spevn">
                  <div className="rememberMe" onClick={rememberMe}>
                    <div className="toggle">
                      <div className="indicator" />
                    </div>
                    <p>Remember me</p>
                  </div>
                  <p className="note">
                    (session: {loginForm.rememberMe ? "30 days" : "1 hour"})
                  </p>
                </div>
              </>
            )}

            {reset ? (
              <button id="loginButton" onClick={resetPassword}>
                Reset Password
              </button>
            ) : (
              <button id="loginButton" onClick={submit}>
                Login
              </button>
            )}

            {rev2 ? (
              <button onClick={() => setReset(!reset)} className="forgotten">
                {reset ? "login" : "forgotten password"}
              </button>
            ) : null}
          </div>
        </>
      )}
    </Container>
  );
}

export default Login;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background-color: ${navBackground};
  z-index: 20;

  h2 {
    color: white;
  }

  .editWindow {
    position: relative;
    height: 220px;
    width: 400px;
    padding: 0px 20px 0px 20px;
    margin-top: 30px;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    background-color: white;
    border-radius: 6px;

    .spevn {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    .rememberMe {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;
    }
    .toggle {
      width: 15px;
      height: 15px;
      background-color: white;
      border: 1px solid #d1d1d1;
      padding: 2px;
      border-radius: 5px;
      margin-right: 8px;
    }
    .indicator {
      background-color: ${(props) =>
        props.rememberMe ? navBackground : "white"};
      width: 100%;
      height: 100%;
      border-radius: 3px;
    }
    .passwordContainer {
      position: relative;
      width: 100%;
    }
    .showPassword {
      position: absolute;
      right: 8px;
      top: 8px;
      color: ${fontColorGrey};
      font-size: 12px;
      cursor: pointer;
    }

    input {
      width: 100%;
      border: 1px solid #d1d1d1;
      &:focus {
        border-bottom: 1px solid ${navBackground};
      }
    }

    button {
      padding: 10px;
      font-weight: bold;
      color: white;
      min-width: 100px;
      background-color: ${navBackground};
      border-color: ${navBackground};
    }
    .forgotten {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: absolute;
      color: ${navBackground};
      background-color: white;
      border: none;
      /* border: 1px solid red; */
      padding: none;
      right: 0;
      bottom: 0;
      font-weight: normal;
      font-size: 11px;
      width: auto;
      height: 15px;
    }
  }
`;
