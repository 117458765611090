import React from "react";
import styled from "styled-components";
import OutputSection from "./OutputSection";

export default function OutputPMInfo(props) {
  const { mapObj, edit } = props;

  return (
    <Container>
      <h3 className="title">Information</h3>
      <OutputSection
        objKey={"projectManagement"}
        subject="Project Management"
        mapObj={mapObj}
        edit={edit}
      />
      <OutputSection
        objKey={"reportingLines"}
        subject="Reporting Lines"
        mapObj={mapObj}
        edit={edit}
      />
      <OutputSection
        objKey={"managementTools"}
        subject="Management Tools"
        mapObj={mapObj}
        edit={edit}
      />
    </Container>
  );
}

const Container = styled.div`
  /* margin-top: 15px; */
`;
