import React, { useEffect } from "react";
import styled from "styled-components";
import { dragElement, fontColorGrey } from "../../helpers";

export default function OutputVariables(props) {
  const { variables, color } = props;

  useEffect(() => {
    dragElement(document.getElementById("variables"));
  });

  return (
    <Container color={color} id="variables">
      <div className="title">
        <h6 className="name">Variable</h6>
        <h6>Returns</h6>
      </div>
      <div className="variables">
        {variables.map((variable, index) => {
          return (
            <div key={index} className="variable">
              <p className="bold name">{variable.name}</p>
              <p className="description">{variable.description}</p>
            </div>
          );
        })}
      </div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  z-index: 2;
  position: fixed;
  flex-direction: column;
  background-color: white;
  border: 1px solid ${(props) => props.color};
  padding: 10px 20px;
  border-radius: 8px;
  left: 65%;
  top: 40%;
  cursor: move;

  .title {
    position: fixed;
    display: flex;
    align-items: center;
    height: 30px;
  }
  h6 {
    color: ${(props) => props.color};
  }
  .variables {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 350px;
    overflow: scroll;
  }
  .variable {
    color: ${fontColorGrey};
    display: flex;
    padding-bottom: 10px;
  }
  .name {
    width: 180px;
    font-weight: bold;
  }
  p {
    font-size: 13px;
  }
`;
