import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import Spinner from "../components/spinner";
import GanttChartLeft from "../components/gantt/ganttChartLeft";
import GanttChartRight from "../components/gantt/ganttChartRight";
import { getDeliverables, getMilestones } from "../store/entities/deadlines";
import {
  getWorkedDaysPerMonth,
  getTotalWorkedDays,
  getGeneratedWorkPackages,
} from "../store/entities/tasks";
import {
  appTop,
  appWidth,
  wpMarginBottom,
  loadAll,
  navBackground,
} from "../helpers/";
import MarkedCompleteModal from "../components/modals/markedComplete";
import { getProjectStatus } from "../store/entities/project";

function GanttChart() {
  const workedDays = useSelector(getWorkedDaysPerMonth);
  const totalDays = useSelector(getTotalWorkedDays);
  const workPackages = useSelector(getGeneratedWorkPackages);
  const deliverables = useSelector(getDeliverables);
  const milestones = useSelector(getMilestones);
  const { projectDataLoading } = useSelector((state) => state.user);
  const ganttComplete = useSelector(getProjectStatus).gantt;

  const [chartWidth, setChartWidth] = useState(0);

  const data = {
    workPackages,
    deliverables,
    milestones,
    workedDays,
    totalDays,
  };

  // function toggleComplete() {
  //   dispatch(updateGanttStatus());
  // }

  useEffect(() => {
    loadAll();
    const scheduleElement = document.getElementById("schedule").scrollWidth;
    const detailsElement = document.getElementById("details").scrollWidth;
    setChartWidth(Math.max(500, scheduleElement + detailsElement + 2));
  }, []);

  return projectDataLoading ? (
    <Spinner />
  ) : (
    <PageContainer chartWidth={chartWidth} offset={appTop}>
      {ganttComplete ? <MarkedCompleteModal /> : null}
      <div id="chartArea" className="chartArea">
        <GanttChartLeft data={data} />
        <GanttChartRight data={data} />
      </div>
      <div className="pageBottom"></div>
    </PageContainer>
  );
}
export default GanttChart;

const PageContainer = styled.div`
  position: relative;
  top: ${(props) => props.offset};
  margin: auto;
  padding: 0px 10px 0px 10px;
  /* width: calc(100% + 12px); */
  max-width: ${appWidth};
  /* border: 1px solid red; */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 750px) {
    padding: 0px;
  }
  .pageBottom {
    position: fixed;
    bottom: 0;
    background-color: ${navBackground};
    height: 10px;
    width: 100%;
    z-index: 100;
    /* border: 1px solid red */
  }
  .chartArea {
    position: relative;
    display: flex;
    justify-content: center;
    max-width: ${(props) => props.chartWidth + 10}px;
    /* border: 1px solid red; */
    width: 100%;
  }
  .monthHeaderSpacer {
    height: calc(35px + ${wpMarginBottom});
    @media screen and (max-width: 750px) {
      height: 35px;
      border-bottom: 0;
    }
  }
  .applyShadow {
    box-shadow: 3px 3px 3px rgba(1, 1, 1, 0.5);
  }
  .toggleComplete {
    color: white;
    font-weight: bold;
    border: none;
    margin-top: 10px;
    margin-bottom: 30px;
    z-index: 2;
  }
`;
