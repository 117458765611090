import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Tippy from "@tippy.js/react";
import TeamInfoRow from "./ProjectNotificationRow";
import add from "../../images/plusButtonBlue.png";
import addGrey from "../../images/add-grey.png";
import { Container } from "../team/teamStyling";
import { getSelectedProject } from "../../store/user";
import OutputCopy from "../output/OutputCopy";
import {addNotificationEmail} from '../../store/entities/project'

export default function ProjectNotificationData() {
  const dispatch = useDispatch();
  const { notify } = useSelector(
    (state) => state.entities.project.data.details
  );
  const { partners } = useSelector(getSelectedProject);

  function addPerson() {
    dispatch(addNotificationEmail())
  }

  return (
    <Container>
      {notify ? (
        <div className="rows notifications">
          {notify.map((person, index) => {
            return <TeamInfoRow index={index} key={index} person={person} />;
          })}
          {notify.length >= partners * 2 ? (
            <Tippy content={`Maximum ${partners * 2}`}>
              <button className="addIcon">
                <img src={addGrey} alt="add" />
              </button>
            </Tippy>
          ) : (
            <Tippy content="Add a person to notify">
              <button className="addIcon" onClick={addPerson}>
                <img src={add} alt="add" />
              </button>
            </Tippy>
          )}
        </div>
      ) : (
        <OutputCopy data={{ marginLeft: "20px" }} />
      )}
    </Container>
  );
}
