import React, { useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import {
  sections,
  loadAll,
  navHeight,
  getWPStatus,
  getWPCost,
  handleSendAllocations,
} from "../helpers";
import Section from "../components/homePageSection";
import { getTeamWarnings } from "../store/entities/team";
import { getCostWarnings } from "../store/entities/project";
import WizardOfferModal from "../components/modals/wizardOfferModal";
import {
  deleteAllocation,
  getAllocationsByTaskId,
} from "../store/entities/allocations";
import Spinner from "../components/spinner";
import { getRiskErrors } from "../store/entities/risks";
import ConfirmModal from "../components/modals/confirmModal";
import { deleteRedundantAssignments } from "../store/entities/assignments";

function HomePage() {
  const dispatch = useDispatch();
  const status = useSelector((state) => state.entities.project.data.status);
  const { projectDataLoading, showComponent } = useSelector(
    (state) => state.user
  );

  const project = useSelector(
    (state) => state.entities.project.data.details.productPlatformName
  );

  const warnings = {
    team: useSelector(getTeamWarnings),
    costs: useSelector(getCostWarnings),
    gantt: useSelector(getAllocationsByTaskId).warnings,
    risks: useSelector(getRiskErrors),
  };

  const allocations = useSelector(getAllocationsByTaskId).redundant;
  const allQuestion = "Delete allocation data?";
  const allComment = "Leave this as a reminder to check against spreadsheet.";
  function deleteAllocations() {
    allocations.forEach((allocationId) => {
      dispatch(deleteAllocation({ allocationId }));
    });
    handleSendAllocations();
  }

  const otherAssignments = useSelector(getWPStatus).redundant;
  const packAssignments = useSelector(getWPCost).redundant;
  const redundant = [...otherAssignments, ...packAssignments];
  const assQuestion = "Delete assignment data?";
  const assComment = "Leave this as a reminder to check against spreadsheet.";
  function deleteAssignments() {
    dispatch(deleteRedundantAssignments({ redundant }));
    console.log("uncomment this - rev2 demo");
    // handleSendAssignments();
  }

  useEffect(() => {
    loadAll();
  }, []);

  return projectDataLoading ? (
    <Spinner />
  ) : (
    <Container>
      {showComponent === "redundantAllocations" ? (
        <ConfirmModal
          question={allQuestion}
          comment={allComment}
          confirm={deleteAllocations}
        />
      ) : null}
      {showComponent === "redundantAssignments" ? (
        <ConfirmModal
          question={assQuestion}
          comment={assComment}
          confirm={deleteAssignments}
        />
      ) : null}
      <WizardOfferModal />
      {/* <WizardModal /> */}
      <div className="title">
        <h2>{project ? project : "-"}</h2>
      </div>

      <div className="table shadow">
        {sections.map((title, index) => {
          return (
            <Section
              key={index}
              index={index}
              title={title}
              status={status[title.toLowerCase()]}
              warnings={warnings[title.toLowerCase()]}
            />
          );
        })}
      </div>
    </Container>
  );
}

export default HomePage;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-items: center;
  width: 100%;
  margin: auto;
  /* top: ${navHeight}; */
  color: white;
  top: calc(${navHeight} + (100vh / 10));
  /* border: 1px solid red; */

  .wizardIcon {
    position: fixed;
    display: flex;
    color: white;
    top: 12px;
    right: 12px;
    height: 20px;
    z-index: 100;
    p {
      font-weight: bold;
      font-size: 12px;
    }
    transition: transform 0.3s;
    cursor: pointer;
    &:hover {
      transform: scale(1.08);
    }
  }

  .table {
    position: relative;
    width: 970px;
    height: 160px;
    background-color: white;
    border-radius: 6px;
    padding: 0px 20px 0 20px;
    margin-top: 80px;
    display: flex;
    justify-content: space-evenly;
    box-shadow: 8px 8px 12px rgba(1, 1, 1, 0.5);
  }
  .logout {
    margin-top: 50px;
    font-size: 14px;
    font-weight: bold;
    border: 2px solid white;
    color: white;
    padding: 10px 30px;
  }
`;
