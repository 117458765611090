import React, { useEffect } from "react";
import styled from "styled-components";
import Tippy from "@tippy.js/react";
import { useSelector, useDispatch } from "react-redux";
import RiskTabs from "../components/risks/riskTabs";
import TemplateModal from "../components/modals/templateModal";
import Spinner from "../components/spinner";

import RiskRow from "../components/risks/riskRow";
import {
  appTop,
  appWidth,
  tableMinHeight,
  fontColorGrey,
  loadAll,
  tableRightWidth,
  tableLeftWidth,
  navHeight,
  swellOnLoad,
  handleSendRisks,
  // tableInputUnderline,
} from "../helpers";
import { getMaxRisks } from "../store/entities/setup";
import { addRisk, sortRisks } from "../store/entities/risks";
import { getProjectStatus } from "../store/entities/project";
import { getSelectedProject, setShowComponent } from "../store/user";

import addGreen from "../images/addTeam.png";
// import addBlue from "../images/addMaterials.png";
import addGrey from "../images/add-grey.png";
import MarkedComplete from "../components/modals/markedComplete";
import { risksTitles } from "../components/risks/risksTitles";
import RisksOutput from "../components/risks/RisksOutput";
import MarkAsComplete from "../components/markAsComplete";

function Risks(props) {
  const dispatch = useDispatch();
  const status = useSelector(getProjectStatus).risks;
  const { clientFlavour } = useSelector(getSelectedProject);
  const { showComponent, selectedRiskType, projectDataLoading } = useSelector(
    (state) => state.user
  );
  const { requiresSorting, data } = useSelector(
    (state) => state.entities.risks
  );
  const risks = data.filter((risk) => risk.riskType === selectedRiskType);
  const maxRisks = useSelector(getMaxRisks)[selectedRiskType];
  const maximumReached = risks.length >= maxRisks;

  function handleAddRisk() {
    if (clientFlavour === "Review") {
      dispatch(addRisk({ riskType: selectedRiskType }));
    } else {
      dispatch(setShowComponent({ value: "riskTemplateModal" }));
    }
  }

  function handleSort() {
    dispatch(sortRisks());
    handleSendRisks();
  }

  useEffect(() => {
    const sortButton = document.getElementById("sort");
    if (sortButton) swellOnLoad(sortButton);
  }, [requiresSorting]);

  useEffect(() => {
    loadAll();
  }, []);

  return projectDataLoading ? (
    <Spinner />
  ) : (
    <PageContainer offset={appTop} maximumReached={maximumReached}>
      <div className="displayArea">
        <RiskTabs />
        {selectedRiskType === "output" ? (
          <RisksOutput />
        ) : (
          <div className="risks">
              {status ? <MarkedComplete /> : null}
            {risksTitles}
            <div id="riskList" className="riskList">
              {risks.map((risk, index) => {
                return <RiskRow risk={risk} index={index} key={index} />;
              })}
            </div>
            {risks.length === 0 ? null : <div className="fadeOut" />}
            <div className="buttonRow">
              {maximumReached ? (
                <Tippy content={`Maximum ${maxRisks}`}>
                  <button className="addIcon">
                    <img src={addGrey} alt="add" />
                  </button>
                </Tippy>
              ) : (
                <Tippy content="Add a risk">
                  <button className="addIcon" onClick={handleAddRisk}>
                    <img src={addGreen} alt="add" />
                  </button>
                </Tippy>
              )}
              {risks.length >= 2 && requiresSorting ? (
                <Tippy content="Sort risks by risk level">
                  <button id="sort" onClick={handleSort} className="sort">
                    Sort List
                  </button>
                </Tippy>
              ) : null}
              <MarkAsComplete section="risks" />
            </div>
          </div>
        )}
      </div>
      {showComponent === "riskTemplateModal" ? (
        <TemplateModal riskCount={risks.length} />
      ) : null}
    </PageContainer>
  );
}
export default Risks;

const PageContainer = styled.div`
  position: relative;
  top: ${(props) => props.offset};
  margin: auto;
  max-width: ${appWidth};
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 750px) {
    padding: 0px;
  }
  .displayArea {
    background-color: white;
    width: 100%;
    max-width: calc(${tableRightWidth} + ${tableLeftWidth});
    /* position: relative; */
    margin: 0px;
    display: flex;
    flex-direction: column;
    /* min-height: ${tableMinHeight}; */
    overflow: hidden;
    border-radius: 6px;
    box-shadow: 8px 8px 12px rgba(1, 1, 1, 0.5);
  }
  .riskList {
    position: relative;
    overflow-y: auto;
    scroll-snap-type: y mandatory;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-height: calc(100vh - ${navHeight} - 160px);

    /* ::-webkit-scrollbar:vertical {
      display: block;
    }
    ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #f5f5f5;
    }
    
    ::-webkit-scrollbar {
      background-color: #f5f5f5;
      width: 6px;
    }
    
    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
      background-color: #999;
    } */
  }
  .risks {
    position: relative;
    color: ${fontColorGrey};
    padding-left: 30px;
    min-height: 30px;
    .titles {
      width: calc(100% - 30px);
      display: flex;
      padding: 3px;
    }
    .title {
      position: relative;
      border: none;
      font-weight: bold;
    }
    .tippyImage {
      position: absolute;
      right: -4px;
      top: 7px;
      width: 12px;
      height: 12px;
    }
    .closer {
      right: 3px;
    }
    .field {
      margin-right: 4px;
      padding: 8px 4px;
    }
    .text {
      display: flex;
      flex-basis: 0;
      flex-grow: 1;
    }
    .miti {
      flex-grow: 2;
    }
    .fadeOut {
      position: absolute;
      bottom: 48px;
      display: flex;
      width: calc(100% - 60px);
      height: 50px;
      /* border: 1px solid red; */
      background-image: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.6),
        rgba(255, 255, 255, 1)
      );
    }
    .toggle {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 90px;
    }
    .end {
      margin: 0;
      width: 50px;
    }
  }

  .bottomRightCorner {
    z-index: 100;
    position: absolute;
    bottom: 15px;
    right: 20px;
    display: flex;
    justify-content: flex-start;
    /* padding: 20px 30px; */
    button {
      background-color: transparent;
      border: none;
      color: gray;
      transition: transform 0.3s;
      :hover {
        /* text-decoration: underline; */
        transform: scale(1.08);
      }
    }
  }

  .buttonRow {
    /* border: 1px solid red; */
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 3px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 30px;
    img {
      max-width: 25px;
      max-height: 25px;
    }
    button {
      margin-right: 10px;
    }
    .addIcon {
      display: flex;
      padding: 0;
      border: none;
    }
    .complete {
      /* padding: 0 20px; */
      font-weight: bold;
      border: none;
      color: gray;
      margin: 0;
    }
    .sort {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 5px;
      width: 75px;
      border: none;
      font-weight: bold;
      background-color: rgba(139, 197, 63, 1);
      color: white;
      transition: transform 0.3s;
    }
  }
`;
