import React from "react";
import styled from "styled-components";
import PrintRiskCategories from "../components/print/PrintRiskCategories";
import { useSelector } from "react-redux";
import PrintRiskOptions from "../components/print/PrintRiskOptions";

export default function PrintRisksPDF(props) {
  const risks = useSelector((state) => state.entities.risks.data);
  const { projectName, riskRef } = useSelector(
    (state) => state.entities.project.data.details
  );
  const managerial = risks.filter((r) => r.riskType === "managerial");
  const commercial = risks.filter((r) => r.riskType === "commercial");
  const legal = risks.filter((r) => r.riskType === "legal");
  const environmental = risks.filter((r) => r.riskType === "environmental");
  const technical = risks.filter((r) => r.riskType === "technical");

  const { riskWidth } = useSelector((state) => state.user.gantt);
  const mitigationWidth = 70 - riskWidth;

  return (
    <div>
      <PrintRiskOptions />
      <Container
        id="pdf"
        fontSize={8}
        riskWidth={riskWidth}
        mitigationWidth={mitigationWidth}
      >
        <div className="contents">
          <header>
            <h6>{projectName}</h6>
            <h6>Appendix {riskRef} Risk Management Table</h6>
          </header>
          <div className="tableHeader">
            <div className="type"></div>
            <div className="titles">
              <div className="description">
                <p>Description</p>
              </div>
              <div className="liklihood">
                <p>Liklihood</p>
              </div>
              <div className="severity">
                <p>Severity</p>
              </div>
              <div className="mitigation">
                <p>Mitigation</p>
              </div>
              <div className="liklihood">
                <p>Liklihood</p>
              </div>
              <div className="severity">
                <p>Severity</p>
              </div>
              <div className="owner">
                <p>Owner</p>
              </div>
            </div>
          </div>

          {managerial.length ? (
            <PrintRiskCategories
              docRisks={managerial}
              type="Managerial"
              riskWidth={riskWidth}
              mitigationWidth={mitigationWidth}
            />
          ) : null}
          {technical.length ? (
            <PrintRiskCategories
              docRisks={technical}
              type="Technical"
              riskWidth={riskWidth}
              mitigationWidth={mitigationWidth}
            />
          ) : null}
          {commercial.length ? (
            <PrintRiskCategories
              docRisks={commercial}
              type="Commercial"
              riskWidth={riskWidth}
              mitigationWidth={mitigationWidth}
            />
          ) : null}
          {legal.length ? (
            <PrintRiskCategories
              docRisks={legal}
              type="Legal"
              riskWidth={riskWidth}
              mitigationWidth={mitigationWidth}
            />
          ) : null}
          {environmental.length ? (
            <PrintRiskCategories
              docRisks={environmental}
              type="Environmental"
              riskWidth={riskWidth}
              mitigationWidth={mitigationWidth}
            />
          ) : null}
        </div>
      </Container>
    </div>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  h6 {
    font-size: 9pt;
  }
  .tableHeader {
    padding-bottom: 5px;
    p {
      font-size: 7pt;
      text-align: center;
      font-weight: bold;
    }
    .type {
      width: 27px;
    }
    display: flex;
    .titles {
      display: flex;
      width: 100%;
      .description {
        width: ${(props) => props.riskWidth}%;
      }
      .liklihood {
        padding-right: 2px;
        display: flex;
        justify-content: flex-end;
        width: 6%;
      }
      .severity {
        padding-left: 2px;
        display: flex;
        justify-content: flex-start;
        text-align: left;
        width: 6%;
      }
      .owner {
        min-width: 6%;
      }
      .mitigation {
        width: ${(props) => props.mitigationWidth}%;
      }
    }
  }

  .contents {
    padding: 5px 10px;
    width: 800px;
    background-color: white;
    overflow: hidden;
    height: 1120px;
    /* border: 1px solid red; */
  }

  a {
    display: inline-block;
    color: lightgray;
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 0.6rem 1.6rem;
    margin-bottom: 20px;
  }

  header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
`;
