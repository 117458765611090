import { store } from "../index";
import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import {
  generateRandomId,
  recentFetch,
  reducerNotification,
  toastNotification,
} from "../../helpers";
import { apiCallBegan } from "../api";

function emptyTask() {
  const task = {
    taskId: generateRandomId('task'),
    workPackageId: generateRandomId('pack'),
    workPackageTitle: "Title...",
    description: "",
    days: 1,
    startDep: null,
    endDep: null,
    dayLoading: "front",
    schedule: [
      { barNumber: 1, value: 1 },
      { barNumber: 0, value: 0 },
      { barNumber: 0, value: 0 },
    ],
  };
  return task;
}

const initialState = {
  loading: false,
  lastFetch: 0,
  data: [emptyTask()],
  lastSent: 0,
  lastEdit: 0,
};

const slice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    taskDataRequested: (tasks, _) => {
      tasks.loading = true;
    },
    taskDataRequestFailed: (tasks, action) => {
      tasks = initialState;
      reducerNotification("error", action.payload);
    },
    taskDataReceived: (tasks, action) => {
      tasks.data = action.payload;
      tasks.loading = false;
      tasks.lastFetch = Date.now();
    },

    sendingTask: (tasks, _) => {
      tasks.loading = true;
    },
    taskReceived: (tasks, action) => {
      tasks.loading = false;
    },

    addTask: (tasks, action) => {
      const { lastTaskId, taskTitle, taskId } = action.payload;
      const index = tasks.data.findIndex((task) => task.taskId === lastTaskId);
      const { workPackageId, workPackageTitle, schedule } = tasks.data[index];
      const task = emptyTask();
      task.workPackageId = workPackageId;
      task.workPackageTitle = workPackageTitle;
      if (taskTitle) {
        task.description = taskTitle;
        task.taskId = taskId;
      }
      for (let i = 1; i < schedule.length; i++) {
        task.schedule[i] = { barNumber: 0, value: 0 };
      }
      tasks.data.splice(index + 1, 0, task);
      tasks.lastEdit = Date.now();
    },
    addWorkPackage: (tasks, action) => {
      const { projectLength } = action.payload;
      console.log(projectLength);
      const newTask = emptyTask();
      for (let i = 1; i < projectLength; i++) {
        newTask.schedule[i] = { barNumber: 0, value: 0 };
      }
      tasks.data.push(newTask);
      tasks.lastEdit = Date.now();
    },

    reorderTasks: (tasks, action) => {
      const { taskId, movement } = action.payload;
      const originalIndex = tasks.data.findIndex(
        (task) => task.taskId === taskId
      );
      const newIndex = originalIndex + movement;
      const [movedTask] = tasks.data.splice(originalIndex, 1);
      tasks.data.splice(newIndex, 0, movedTask);
      tasks.lastEdit = Date.now();
    },
    deleteTask: (tasks, action) => {
      const { taskId } = action.payload;
      const index = tasks.data.findIndex((task) => task.taskId === taskId);
      tasks.data.splice(index, 1);
      tasks.lastEdit = Date.now();
    },
    updateTaskKey: (tasks, action) => {
      const { taskId, key, value } = action.payload;
      const index = tasks.data.findIndex((task) => task.taskId === taskId);
      tasks.data[index][key] = value;
      tasks.lastEdit = Date.now();
    },
    // *******************
    resetBars: (tasks, action) => {
      const { taskId, projectLength } = action.payload;
      const index = tasks.data.findIndex((task) => task.taskId === taskId);
      tasks.data[index].schedule[0].barNumber = 1;
      tasks.data[index].schedule[0].value = 1;
      for (let i = 1; i < projectLength; i++) {
        tasks.data[index].schedule[i].barNumber = 0;
        tasks.data[index].schedule[i].value = 0;
      }
      tasks.data[index].days = 1;
      tasks.lastEdit = Date.now();
    },
    // *******************
    updateNumberOfBars: (tasks, action) => {
      const { taskId, newBars } = action.payload;
      const index = tasks.data.findIndex((task) => task.taskId === taskId);
      const length = tasks.data[index].schedule.length;
      let barNumber = 1;
      for (let i = 0; i < length; i = i + 2) {
        // console.log(i);
        if (barNumber <= newBars) {
          tasks.data[index].schedule[i].barNumber = barNumber;
          tasks.data[index].schedule[i].value = 1;
          barNumber++;
        } else {
          tasks.data[index].schedule[i].barNumber = 0;
          tasks.data[index].schedule[i].value = 0;
        }
        // this is the 'next' month, don't go outside the schedule
        if (i < length - 1) {
          tasks.data[index].schedule[i + 1].barNumber = 0;
          tasks.data[index].schedule[i + 1].value = 0;
        }
      }
      tasks.data[index].days = newBars;
      tasks.data[index].bars = newBars;
      tasks.lastEdit = Date.now();
    },
    resizeTaskBar: (tasks, action) => {
      const {
        taskId,
        newDays,
        origStartIndex,
        newStartIndex,
        origEndIndex,
        newEndIndex,
        barNumber,
      } = action.payload;
      const index = tasks.data.findIndex((task) => task.taskId === taskId);
      tasks.data[index].days = newDays; // set days
      // calculate new block positions
      const loopStart = Math.min(origStartIndex, newStartIndex);
      const loopEnd = Math.max(origEndIndex, newEndIndex);
      let workingDay = false;
      let started = false;
      for (let i = loopStart; i <= loopEnd; i++) {
        if (!started && i >= newStartIndex) {
          workingDay = true;
          started = true;
        }
        if (started && i > newEndIndex) {
          workingDay = false;
        }
        if (workingDay) tasks.data[index].schedule[i].barNumber = barNumber;
        else {
          tasks.data[index].schedule[i].barNumber = 0;
          tasks.data[index].schedule[i].value = 0;
        }
      }
      tasks.lastEdit = Date.now();
    },
    spreadWork: (tasks, action) => {
      const { taskId, combinedLength } = action.payload;
      const index = tasks.data.findIndex((task) => task.taskId === taskId);
      const { days } = tasks.data[index];
      const months = Math.floor(days / combinedLength);
      const remainderMonths = days % combinedLength;
      let j = 0;
      for (let i = 0; i < tasks.data[index].schedule.length; i++) {
        if (tasks.data[index].schedule[i].barNumber) {
          if (j < remainderMonths) {
            tasks.data[index].schedule[i].value = months + 1;
            j++;
          } else tasks.data[index].schedule[i].value = months;
        }
      }
      tasks.lastEdit = Date.now();
    },
    moveTaskBar: (tasks, action) => {
      const { taskId, originalIndex, newIndex, blockCount } = action.payload;
      const movement = newIndex - originalIndex;
      const index = tasks.data.findIndex((task) => task.taskId === taskId);
      const bar = tasks.data[index].schedule.splice(originalIndex, blockCount);
      tasks.data[index].schedule.splice(originalIndex + movement, 0, ...bar);
      tasks.lastEdit = Date.now();
    },
    updateBlock: (tasks, action) => {
      const { taskId, blockIndex, value } = action.payload;
      const index = tasks.data.findIndex((task) => task.taskId === taskId);
      tasks.data[index].schedule[blockIndex].value = value;
      // const change = value - tasks.data[index].schedule[blockIndex].value;
      // tasks.data[index].days = tasks.data[index].days + change;
      tasks.data[index].days = tasks.data[index].schedule.reduce(function (
        acc,
        curr
      ) {
        return acc + curr.value;
      },
      0);
      tasks.lastEdit = Date.now();
    },
    increaseSchedule: (tasks, action) => {
      const { oldLength, newLength } = action.payload;
      tasks.data.forEach((task) => {
        for (let i = oldLength; i < newLength; i++) {
          task.schedule[i] = { barNumber: 0, value: 0 };
        }
      });
      tasks.lastEdit = Date.now();
    },
    decreaseSchedule: (tasks, action) => {
      const { newLength } = action.payload;
      tasks.data.forEach((task) => {
        task.schedule = [];
        task.bars = 1;
        task.schedule[0] = { barNumber: 1, value: task.days };
        for (let i = 1; i < newLength; i++) {
          task.schedule[i] = { barNumber: 0, value: 0 };
        }
      });
      tasks.lastEdit = Date.now();
    },

    apiSuccess: (tasks, action) => {
      tasks.loading = false;
      tasks.lastSent = Date.now();
      // reducerNotification("info", "Tasks saved");
    },
    apiFailed: (tasks, action) => {
      tasks.loading = false;
      console.log(action.payload);
      toastNotification("error", action.payload.message);
    },
  },
});

export const {
  taskDataReceived,
  addTask,
  moveTaskBar,
  resizeTaskBar,
  reorderTasks,
  spreadWork,
  deleteTask,
  updateBlock,
  updateTaskKey,
  resetBars,
  updateNumberOfBars,
  addWorkPackage,
  increaseSchedule,
  decreaseSchedule,
} = slice.actions;

const {
  taskDataRequested,
  taskDataRequestFailed,

  sendingTask,
  // sendingTaskFailed,
  // taskReceived,

  apiSuccess,
  apiFailed,
} = slice.actions;

export default slice.reducer;

// Action Creators

const url = "/tasks";

export const loadTasks = () => (dispatch, getState) => {
  const { lastFetch } = getState().entities.tasks;
  // console.log("fetching task data...");
  if (recentFetch(lastFetch)) return;

  dispatch(
    apiCallBegan({
      url: url + "/selected",
      method: "get",
      data: null,
      onStart: taskDataRequested.type,
      onSuccess: taskDataReceived.type,
      onError: taskDataRequestFailed.type,
    })
  );
};

export const sendUpdatedTasks = () => (dispatch, getState) => {
  const tasks = getState().entities.tasks;
  const { lastEdit, lastSent, data } = tasks;
  if (lastSent >= lastEdit) return;
  dispatch(
    apiCallBegan({
      url: url + "/selected",
      method: "put",
      data,
      // onStart: sendingTask.type,
      onSuccess: apiSuccess.type,
      onError: apiFailed.type,
    })
  );
};

export const initiateTask = (data) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: url + "/new",
      method: "post",
      data,
      onStart: sendingTask.type,
      onSuccess: apiSuccess.type,
      onError: apiFailed.type,
    })
  );
};

export const cloneTasks = (projectId) => (dispatch, getState) => {
  const { data } = getState().entities.tasks;
  dispatch(
    apiCallBegan({
      url: url + "/new",
      method: "post",
      data: {
        projectId,
        data,
      },
      // onStart: sendingAllocation.type,
      onSuccess: apiSuccess.type,
      onError: apiFailed.type,
    })
  );
};

export const deleteProjectTasks = (projectId) =>
  apiCallBegan({
    url: url + "/selected",
    method: "delete",
    data: {
      projectId,
    },
    // onStart: sendingEmptyAssignments.type,
    onSuccess: apiSuccess.type,
    onError: apiFailed.type,
  });

// Selectors

export const getWorkPackageTitles = createSelector(
  (state) => state.entities.tasks.data,
  (tasks) => {
    const list = Object.keys(tasks);
    const titles = [
      ...new Set(list.map((taskId) => tasks[taskId].workPackageTitle)),
    ];
    return titles;
  }
);

export const getWorkPackageIds = createSelector(
  (state) => state.entities.tasks.data,
  (tasks) => {
    // console.log("getWorkPackageIds");
    const list = tasks.map((task) => {
      return task.workPackageId;
    });
    // const taskIds = list.filter((id) => id !== "taskOrder");
    // const wpIds = [
    //   ...new Set(list.map((taskId) => tasks[taskId].workPackageId)),
    // ];

    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }

    // usage example:
    // var a = ['a', 1, 'a', 2, '1'];
    // var unique = a.filter(onlyUnique);

    var unique = list.filter(onlyUnique);
    // console.log(unique);
    return unique;
  }
);

export const getTaskIds = createSelector(
  (state) => state.entities.tasks.data,
  (tasks) => {
    const list = tasks.map((task) => {
      return task.taskId;
    });
    return list;
  }
);

export const getLastTaskId = createSelector(
  (state) => state.entities.tasks.data,
  (tasks) => {
    const lastId = tasks.slice(-1)[0].taskId;
    return lastId;
  }
);

export const getCombinedLengthOfBars = createSelector(
  (state) => state.entities.tasks.data,
  (state) => state.entities.project.data,
  (tasks, project) => {
    const { projectLength } = project.details;
    const result = {};
    tasks.forEach((task) => {
      let count = 0;
      for (let i = 0; i < projectLength; i++) {
        if (task.schedule[i].barNumber > 0) count++;
      }
      result[task.taskId] = count;
    });
    return result;
  }
);

export const getNumberOfBars = createSelector(
  (state) => state.entities.tasks.data,
  (state) => state.entities.project.data,
  (tasks, project) => {
    // console.log("getNumberOfBars");
    const { projectLength } = project.details;
    // const taskIds = getTaskIds(store.getState());
    const result = {};
    tasks.forEach((task) => {
      for (let i = projectLength - 1; i >= 0; i--) {
        if (task.schedule[i].barNumber > 0) {
          result[task.taskId] = task.schedule[i].barNumber;
          break;
        }
      }
    });
    return result;
  }
);

export const getWorkedDaysPerMonth = createSelector(
  (state) => state.entities.tasks.data,
  (state) => state.entities.project.data,
  (tasks, project) => {
    // console.log("getWorkedDaysPerMonth");
    const { projectLength } = project.details;
    // const taskIds = getTaskIds(store.getState());

    const daysPerMonth = [];
    for (let i = 0; i < projectLength; i++) {
      let days = 0;
      tasks.forEach((task) => {
        const monthDays = task.schedule[i].value;
        days += monthDays;
        // totalDays += monthDays;
      });
      daysPerMonth.push(days);
    }
    // console.log(daysPerMonth);
    return daysPerMonth;
  }
);

export const getWorkedDaysPerWP = createSelector(
  (state) => state.entities.tasks.data,
  (state) => state.entities.project.data,
  (tasks, project) => {
    const { projectLength } = project.details;

    const result = {};
    const daysPerMonth = [];
    for (let i = 0; i < projectLength; i++) {
      let days = 0;
      tasks.forEach((task) => {
        if (!result[task.workPackageId])
          result[task.workPackageId] = {
            total: 0,
            schedule: new Array(projectLength).fill(0),
          };
        const monthDays = task.schedule[i].value;
        result[task.workPackageId].total += monthDays;
        result[task.workPackageId].schedule[i] += monthDays;
      });
      daysPerMonth.push(days);
    }
    // console.log(daysPerMonth);
    return result;
  }
);

export const getTotalWorkedDays = createSelector(
  (state) => state.entities.tasks.data,
  (state) => state.entities.project.data,
  () => {
    // console.log("getTotalWorkedDays");
    const state = store.getState();
    const workedDays = getWorkedDaysPerMonth(state);
    let total = 0;
    workedDays.forEach((day) => {
      total += day;
    });
    return total;
  }
);

export const getGeneratedWorkPackages = createSelector(
  (state) => state.entities.tasks.data,
  // (state) => state.entities.taskOrder.data,
  (tasks) => {
    // console.log("getGeneratedWorkPackages");
    const groupedTasks = [[]];
    // set initial value equal to the first entry
    let previousPackId = tasks[0].workPackageId;
    let packIndex = 0;

    for (let i = 0; i < tasks.length; i++) {
      const currentTask = tasks[i];
      // console.log(taskOrder);
      const currentPackId = currentTask.workPackageId;
      // if new, increment the index and add an empty array
      if (currentPackId !== previousPackId) {
        packIndex++;
        groupedTasks[packIndex] = [];
      }
      groupedTasks[packIndex].push(currentTask);
      previousPackId = currentPackId;
    }
    return groupedTasks;
  }
);

export function newTask(projectLength, workPackageId, workPackageTitle) {
  const task = emptyTask();
  task.workPackageId = workPackageId;
  task.workPackageTitle = workPackageTitle;
  for (let i = 1; i < projectLength; i++) {
    task.schedule[i] = { barNumber: 0, value: 0 };
  }
  return task;
}

export function newWorkPackage(projectLength) {
  const task = emptyTask();
  for (let i = 1; i < projectLength; i++) {
    task.schedule[i] = { barNumber: 0, value: 0 };
  }
  return task;
}

export function newInitialTask(projectId) {
  const project = {
    projectId,
    data: emptyTask(),
  };
  return project;
}

export const getWorkPackageDays = createSelector(
  (state) => state.entities.tasks.data,
  (_) => {
    const totalDays = [];
    const wPs = getGeneratedWorkPackages(store.getState());
    wPs.forEach((wp) => {
      let packDays = 0;
      wp.forEach((task) => {
        packDays += task.days;
      });
      totalDays.push(packDays);
    });
    return totalDays;
  }
);
