import Tippy from "@tippy.js/react";
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { monthWidth, navBackground, wpMarginBottom } from "../../helpers";
import { getProjectDates } from "../../store/entities/project";
import GanttWPRowSchedule from "./ganttTaskRowSchedule";
// import GanttRowSchedule from "./ganttRowSchedule";

function GanttTaskPackSchedule(props) {
  const { nonWPPrefix, wpNumber } = props;
  const projectLength = useSelector(
    (state) => state.entities.project.data.details.projectLength
  );
  const dateList = useSelector(getProjectDates);

  const scheduleWidth =
    projectLength * parseInt(monthWidth.slice(0, -2)) + "px";

  return (
    <Container scheduleWidth={scheduleWidth}>
      <div className="title">
        {dateList.map((date, i) => {
          return (
            <Tippy key={i} content={date}>
              <div className="month">
                <p>{date[0]}</p>
              </div>
            </Tippy>
          );
        })}
      </div>
      {props.workPackData.map((task, index) => {
        return (
          <GanttWPRowSchedule
            key={index}
            task={task}
            nonWPPrefix={nonWPPrefix}
            taskNumber={index + 1}
            wpNumber={wpNumber}
          />
        );
      })}
      <div className="addButton" />
    </Container>
  );
}

export default GanttTaskPackSchedule;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: ${wpMarginBottom} solid ${navBackground};
  width: ${(props) => props.scheduleWidth};
  @media screen and (max-width: 750px) {
    border-bottom: 0;
  }
  .title {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    /* border: 1px solid red; */
  }
  .month {
    display: flex;
    justify-content: center;
    width: ${monthWidth};
    z-index: 2;
  }
  p {
    font-weight: bold;
    color: rgba(255, 255, 255, 0.3);
  }
  .addButton {
    height: 40px;
  }
`;
