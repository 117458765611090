import { cloneSetup } from "../store/entities/setup";
import { cloneProjectDetails } from "../store/entities/project";
import { cloneTasks } from "../store/entities/tasks";
import { cloneDeadlines } from "../store/entities/deadlines";
import { cloneTeam } from "../store/entities/team";
import { cloneAllocations } from "../store/entities/allocations";
import { cloneMaterials } from "../store/entities/materials";
import { cloneAssignments } from "../store/entities/assignments";
import { cloneCapex } from "../store/entities/capex";
import { cloneTravel } from "../store/entities/travel";
import { cloneRevenue } from "../store/entities/revenue";
import { cloneOther } from "../store/entities/other";
import { cloneRisks } from "../store/entities/risks";
import { generateRandomId } from "./generalHelpers";

export function cloneWholeProject(dispatch) {
  const projectId = generateRandomId('project');
  dispatch(cloneSetup(projectId));
  dispatch(cloneTasks(projectId));
  dispatch(cloneProjectDetails(projectId));
  dispatch(cloneDeadlines(projectId));
  dispatch(cloneTeam(projectId));
  dispatch(cloneAllocations(projectId));
  dispatch(cloneMaterials(projectId));
  dispatch(cloneTravel(projectId));
  dispatch(cloneCapex(projectId));
  dispatch(cloneOther(projectId));
  dispatch(cloneRisks(projectId));
  dispatch(cloneAssignments(projectId));
  dispatch(cloneRevenue(projectId));
}
