import React from "react";
import { useSelector } from "react-redux";
import { Container } from "./costsStyling";
import { getTotalsByLeader, numberToCurrency } from "../../helpers";
import { getSelectedProject } from "../../store/user";
import Tippy from "@tippy.js/react";

function BreakdownInfo() {
  const totals = useSelector(getTotalsByLeader);
  const { partners, percentWarn, percentOver } =
    useSelector(getSelectedProject);
  const { lead, pOne, pTwo, combined } = totals.object.totals;

  function percent(index, value, total, partner = "total") {
    // console.log(index, value);
    if (total && value) {
      const result = Math.round((value / total) * 100);
      let className = "percent grey";
      if (partner === "total") {
        className =
          result > percentOver
            ? "percent over"
            : result > percentWarn
            ? "percent warn"
            : "percent ok";
      }
      if (index > 6 && total && value) return <p className="percent empty" />;
      return (
        <Tippy content={`Percent of ${partner} cost`}>
          <p className={className}>{result}%</p>
        </Tippy>
      );
    }
  }

  // console.log(totals.array);

  const showLead = partners > 1;
  const showpOne = partners > 1;
  const showpTwo = partners > 2;

  return (
    <Container>
      <div className="breakdownTable">
        <div className="row titles leaderTabMargin">
          <p className="title column category"></p>
          {showLead ? <p className="title column center">Lead</p> : null}
          {showpOne ? <p className="title column center">Partner 1</p> : null}
          {showpTwo ? <p className="title column center">Partner 2</p> : null}
          <p className="title column center">Total</p>
        </div>
        <div className="rows">
          {totals.array.map((row, index) => {
            const value = row.combined;
            return value ? (
              <div
                key={index}
                className={index === 7 ? "row highlight" : "row"}
              >
                <p className="field display column category">{row.category}</p>

                {showLead ? (
                  <div className="column border">
                    <p className="field display double">
                      £{row.lead ? numberToCurrency(row.lead) : null}
                    </p>
                    {percent(index, row.lead, lead, "Lead")}
                  </div>
                ) : null}

                {showpOne ? (
                  <div className="column border">
                    <p className="field display double">
                      £{row.pOne ? numberToCurrency(row.pOne) : null}
                    </p>
                    {percent(index, row.pOne, pOne, "Partner 1")}
                  </div>
                ) : null}

                {showpTwo ? (
                  <div className="column border">
                    <p className="field display double">
                      £{row.pTwo ? numberToCurrency(row.pTwo) : null}
                    </p>
                    {percent(index, row.pTwo, pTwo, "Partner 2")}
                  </div>
                ) : null}

                <div className="column">
                  <p className="field display double">
                    £{numberToCurrency(row.combined)}
                  </p>
                  {percent(index, row.combined, combined)}
                </div>
              </div>
            ) : null;
          })}
        </div>
      </div>
    </Container>
  );
}
export default BreakdownInfo;
