import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { navBackground } from "../../helpers";

import { setShowComponent } from "../../store/user";
import UserModal from "../modals/userModal";

export default function AdminAccount(props) {
  const dispatch = useDispatch();
  const { user } = props;
  const { userId, name } = user;
  const { showComponent } = useSelector((state) => state.user);
  const firstName = name.split(" ")[0];
  
  function showModal(value) {
    dispatch(setShowComponent({ value }));
  }
  
  const signedIn = useSelector((state) => state.user.name);
  if (name === 'James Grantham' && signedIn !== 'James Grantham') return null

  return (
    <Container>
      {showComponent === userId + "edit" ? <UserModal user={user} /> : null}
        <button onClick={() => showModal(userId + "edit")} className="contents">
          <p className="admin">{firstName}</p>
        </button>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background-color: white;
  width: 80px;
  margin-right: 25px;
  /* flex-basis: 0; */
  /* flex-grow: 1; */
  /* min-height: 40px; */
  p {
    font-size: 14px;
    color: ${navBackground};
    padding: 0;
    width: 100%;
  }
  .contents {
    display: flex;
    text-align: center;
    border: 1px solid lightgray;
    border-radius: 4px;
    padding-right: 5px;
    padding-left: 5px;
    background-color: white;
    width: 100%;
  }
`;
