import React from "react";
import styled from "styled-components";
import OutputSection from "./OutputSection";
import { menuPurpleColor } from "../../helpers";

export default function EmailProject(props) {
  const { edit, mapObj } = props;

  return (
    <Container>
    <h3>Project marked as complete (all sections)</h3>
    <OutputSection
      source="global"
      objKey="projectCompleteSubject"
      subject="Subject:"
      mapObj={mapObj}
      edit={edit}
      small={true}
    />
    <OutputSection
      source="global"
      objKey="projectCompleteBody"
      subject="Body:"
      mapObj={mapObj}
      edit={edit}
    />
    <h3>Status change (section marked complete)</h3>
    <OutputSection
      source="global"
      objKey="sectionSubject"
      subject="Subject:"
      mapObj={mapObj}
      edit={edit}
      small={true}
    />
    <OutputSection
      source="global"
      objKey="sectionBody"
      subject="Body:"
      mapObj={mapObj}
      edit={edit}
    />
    </Container>
  );
}

const Container = styled.div`
  .heading {
    margin: 10px 0 5px 0;
  }
  .last {
    margin-bottom: 5px;
  }
  h3 {
    color: ${menuPurpleColor};
  }
`;
