import React from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { setShowComponent } from "../../store/user";
import { confirmNo, confirmYes, fontColorGrey } from "../../helpers";

function ConfirmModal(props) {
  const dispatch = useDispatch();

  const { question, comment, confirm } = props;

  function cancel() {
    dispatch(setShowComponent({ value: "" }));
  }
  function ok() {
    confirm();
    dispatch(setShowComponent({ value: "" }));
  }

  return (
    <Container>
      <div className="modal">
        <h3>{question}</h3>
        <p>{comment}</p>
        <div className="modalButtons">
          <button onClick={cancel} className="modalCancel modalButton">
            Cancel
          </button>
          <button onClick={ok} className="modalConfirm modalButton">
            Confirm
          </button>
        </div>
      </div>
    </Container>
  );
}

export default ConfirmModal;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(20, 20, 20, 0.6);
  z-index: 20;

  .modal {
    flex-direction: column;
    position: relative;
    /* height: 220px; */

    display: flex;
    justify-content: space-evenly;
    align-items: center;

    background-color: white;
    border-radius: 8px;
    padding: 30px 80px;
    overflow: hidden;

    h3 , p {
      color: ${fontColorGrey};
    }

    p {
      margin-top: 10px;
    }

    .modalButtons {
      position: relative;
      margin-top: 30px;
      display: flex;
      justify-content: center;
      .modalButton {
        padding: 0;
        width: 120px;
        height: 35px;
        font-weight: bold;
        transition: transform 0.3s;
        color: white;
        :hover {
          transform: scale(1.08);
        }
      }
      .modalCancel {
        border: 2px solid ${confirmNo};
        background-color: ${confirmNo};
        margin-right: 20px;
      }
      .modalConfirm {
        border: 2px solid ${confirmYes};
        background-color: ${confirmYes};
      }
    }
  }
`;
