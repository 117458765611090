import { store } from "../store";
import { deletePersonAllocations } from "../store/entities/allocations";
import { deleteTeamMember } from "../store/entities/team";
import { handleSendAllocations, handleSendTeam } from "./handleSendToServer";

export function handleDeleteTeamMember(personId) {
  if (!personId) return console.log("no id provided");
  store.dispatch(deletePersonAllocations({ personId }));
  store.dispatch(deleteTeamMember({ personId }));
  handleSendAllocations();
  handleSendTeam();
}
