import React from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { updateTeamMember } from "../../store/entities/team";
import {
  teamColor,
  tableInputUnderline,
  fontColorGrey,
  handleSendTeam,
} from "../../helpers";
import { setShowComponent } from "../../store/user";
import Close from "../general/close";

function ProfileModal(props) {
  const dispatch = useDispatch();
  const { person } = props;

  window.addEventListener("keydown", checkCloseModal, false);

  function checkCloseModal(e) {
    // console.log("listening");
    if (e.target.id === "background" || e.key === "Escape" || e.keycode === 27)
      closeModal();
  }

  function closeModal() {
    // console.log('listening');
    handleSendTeam();
    window.removeEventListener("keydown", checkCloseModal);
    dispatch(setShowComponent({ value: "" }));
  }

  function onChangeHandler(e) {
    const key = e.target.name;
    const value = e.target.value;
    dispatch(
      updateTeamMember({
        personId: person.personId,
        key,
        value,
      })
    );
  }

  const data = {};

  return (
    <Container id="background" onClick={checkCloseModal}>
      <div className="editWindow">
        <Close data={data} />
        <h3 className="title">{person.name}</h3>
        <label className="label" htmlFor="url">
          Website
        </label>
        <input
          id={person.website}
          name="website"
          value={person.website || ""}
          onChange={onChangeHandler}
          onBlur={handleSendTeam}
          className="input"
        />
        <label className="label" htmlFor="url">
          LinkedIn
        </label>
        <input
          id={person.linkedIn}
          name="linkedIn"
          value={person.linkedIn || ""}
          onChange={onChangeHandler}
          onBlur={handleSendTeam}
          className="input"
        />
        <label className="label" htmlFor="url">
          Profile
        </label>
        <textarea
          id={person.profile}
          name="profile"
          value={person.profile || ""}
          onChange={onChangeHandler}
          onBlur={handleSendTeam}
          className="input"
        />
      </div>
      {/* <div className="content" /> */}
    </Container>
  );
}

export default ProfileModal;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(20, 20, 20, 0.6);
  z-index: 2;

  .editWindow {
    position: relative;
    min-height: 200px;

    background-color: white;
    border: 1px solid black;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 15px;
    .input {
      min-width: 350px;
      max-width: 350px;
      position: relative;
      padding: 5px;
      margin: 0;
      margin-top: 5px;
      min-height: 30px;
      border-radius: 0;
      padding: 0;
      border-bottom: 2px solid ${tableInputUnderline};
      &:focus {
        border: none;
        border-bottom: 2px solid ${teamColor};
      }
    }
    .label {
      display: flex;
      align-items: center;
      min-height: 30px;
      margin-top: 5px;
      border: none;
      color: ${teamColor};
      min-width: 0px;
      font-weight: bold;
    }
    textarea.input {
      border: 2px solid ${tableInputUnderline};
      height: 200px;
      border-radius: 5px;
      padding: 3px;
      &:focus {
        border: 2px solid ${teamColor};
      }
    }
    h3 {
      color: ${fontColorGrey};
    }
  }
`;
