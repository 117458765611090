import { store } from "./index";
import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import {
  generatePassword,
  printGanttColors,
  randomInteger,
  recentFetch,
  reducerNotification,
  rev2,
} from "../helpers";
import { apiCallBegan } from "./api";
// import Fs from "fs";
// import Path from "path";

const slice = createSlice({
  name: "user",
  initialState: {
    loading: false,
    lastFetch: 0,
    selectedProjectTime: Date.now(),
    projectDataLoading: false,
    lastProjectDataFetch: 0,
    // provided by server
    admin: null,
    authenticated: false,
    selectedProjectId: "abc",
    name: "",
    // app choices
    showComponent: "",
    selectedLeader: "lead",
    resourcesInput: {
      personId: "",
      taskId: "",
      position: 0,
      closeInput: false,
    },
    selectedDetailsOption: "organisation", // organisation before saving
    selectedTeamOption: "staff", // staff before saving
    selectedCostsOption: "labour", // labour before saving
    selectedRevenueOption: "target market", // target market
    selectedRiskType: "managerial", // managerial
    selectedTaskType: "",
    selectedCategory: "",

    dashboard: "projects", // projects before saving
    selectedAdminOption: "details", // details before saving
    selectedOutput: "team", // team before saving
    selectedEmail: "accounts", // accounts before saving
    lastToastMessage: 0,
    top: "100px",
    left: null,
    globalSettings: false,

    gantt: {
      headerHeight: 20,
      titleHeight: 18,
      rowHeight: 18,
      headerSize: 8,
      titleSize: 8,
      textSize: 7,
      resourcesSize: 5,
      descriptionLength: 49,
      resourcesLength: 32,
      textPosition: 0,
      showTaskDays: false,
      showPackDays: false,
      showDelsMils: false,

      barShape: 3,
      header: 0,
      years: 0,
      taskTitle: 0,
      taskBar: 0,
      delTitle: 0,
      delBackground: 0,
      milTitle: 0,
      milBackground: 0,
      descriptionWidth: 250,
      resourcesWidth: 75,
      daysWidth: 35,
      daysPosition: 2,
      showSizing: !rev2,
      riskWidth: 25,
    },
  },
  reducers: {
    userLoginAttempt: (user, _) => {
      user.loading = true;
    },
    userLoginFailed: (user, action) => {
      user.loading = false;
      reducerNotification("error", action.payload);
    },
    userLoginSuccessful: (user, action) => {
      const { admin, message, token, error, name } = action.payload;
      user.loading = false;
      if (error) {
        reducerNotification("error", message);
      } else {
        // user.admin = admin;
        // user.selectedProjectId = projectId;
        user.authenticated = true;

        localStorage.setItem("admin", admin);
        localStorage.setItem("token", token);
        localStorage.setItem("authenticated", true);
        const firstName = name.split(" ")[0];
        reducerNotification("info", `Hello ${firstName}`);
        user.lastFetch = Date.now();
        // if (admin) window.location.href = "/admin";
        // else window.location.href = "/details";
      }
    },

    userDataRequested: (user, _) => {
      user.loading = true;
    },
    userDataRequestFailed: (user, action) => {
      user.loading = false;
      reducerNotification("error", action.payload);
    },
    userDataReceived: (user, action) => {
      const { admin, projectId, message, name, editGlobal, email } =
        action.payload;
      user.loading = false;
      user.lastFetch = Date.now();
      if (message) reducerNotification("error", message);
      if (projectId) {
        user.name = name;
        user.selectedProjectId = projectId;
        user.admin = admin;
        user.editGlobal = editGlobal;
        user.email = email;
        localStorage.setItem("admin", admin);
      } else {
        localStorage.removeItem("admin");
        localStorage.removeItem("token");
        localStorage.removeItem("authenticated");
        setTimeout(() => {
          window.location.href = "/login";
        }, 2500);
      }
    },

    // might not need this...
    userProjectDataRequested: (user, _) => {
      user.projectDataLoading = true;
    },
    userProjectDataReceived: (user, _) => {
      user.projectDataLoading = false;
      user.lastProjectDataFetch = Date.now();
    },
    userProjectDataFailed: (user, _) => {
      user.projectDataLoading = false;
      reducerNotification("error", "Failed to load Project");
    },
    // ------------------------

    userSelectionSent: (user, _) => {
      user.loading = true;
    },
    userSelectionFailed: (user, action) => {
      user.loading = false;
      reducerNotification("error", action.payload);
    },
    userSelectionConfirmed: (user, action) => {
      user.loading = false;
      // console.log(action.payload);
      const { token, project } = action.payload;
      // console.log(project);
      user.selectedProjectId = project;
      user.selectedProjectTime = Date.now();
      localStorage.setItem("token", token);
    },

    setShowComponent: (user, action) => {
      const component = action.payload.value;
      user.showComponent = component;
    },
    updateUserSelection: (user, action) => {
      // console.log(action.payload);
      user[action.payload.key] = action.payload.value;
      if (action.payload.key === "selectedProjectId") {
        user.selectedProjectTime = Date.now();
        localStorage.setItem("selectedProjectId", action.payload.value);
        localStorage.setItem("projectId", action.payload.projectId);
      }
    },
    updatePrintGantt: (user, action) => {
      let { key, value } = action.payload;
      user.gantt[key] = value;
    },
    ganttRandomColors: (user, _) => {
      user.gantt.header = randomInteger(printGanttColors.length);
      user.gantt.years = randomInteger(printGanttColors.length);
      user.gantt.taskTitle = randomInteger(printGanttColors.length);
      user.gantt.taskBar = randomInteger(printGanttColors.length);
      user.gantt.delTitle = randomInteger(printGanttColors.length);
      user.gantt.delBackground = randomInteger(printGanttColors.length);
      user.gantt.milTitle = randomInteger(printGanttColors.length);
      user.gantt.milBackground = randomInteger(printGanttColors.length);
      user.gantt.daysPosition = randomInteger(3);
      user.gantt.barShape = randomInteger(10);
    },

    passwordReset: (_, action) => {
      const { message } = action.payload;
      // user.projectDataLoading = false;
      reducerNotification("info", message);
    },

    // downloadReceived: (user, action) => {
    //   reducerNotification("info", "downloaded");
    //   const data = action.payload;
    //   console.log(data);
    //   const url = window.URL.createObjectURL(new Blob([data]));
    //   const link = document.createElement("a");
    //   link.href = url;
    //   link.setAttribute("download", "test.xlsx"); //or any other extension
    //   document.body.appendChild(link);
    //   link.click();
    // },
    // downloadError: () => {
    //   reducerNotification("error", "Error - Failed to download");
    // },
  },
});

const {
  // downloadReceived,
  // downloadError,
  userDataRequestFailed,
  passwordReset,
  userLoginAttempt,
  userLoginFailed,
  userLoginSuccessful,

  userSelectionSent,
  userSelectionFailed,
  userSelectionConfirmed,
} = slice.actions;

export const {
  // updateResourcesInput,
  // closeResourcesInput,
  // toggleCloseResources,
  userDataReceived,

  userDataRequested,
  updateUserSelection,
  setShowComponent,
  updatePrintGantt,
  ganttRandomColors,

  userProjectDataRequested,
  userProjectDataReceived,
  userProjectDataFailed,
} = slice.actions;
export default slice.reducer;

// USER Action Creators
const userUrl = "/users";

export const userLogin = (data) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: "login",
      method: "post",
      data,
      onStart: userLoginAttempt.type,
      onSuccess: userLoginSuccessful.type,
      onError: userLoginFailed.type,
    })
  );
};

export const userResetPassword = (data) => (dispatch, getState) => {
  data.password = generatePassword;
  dispatch(
    apiCallBegan({
      url: "resetPassword",
      method: "post",
      data,
      // onStart: userLoginAttempt.type,
      onSuccess: passwordReset.type,
      onError: userLoginFailed.type,
    })
  );
};

export const loadUserData = () => (dispatch, getState) => {
  const { lastFetch } = getState().user;
  if (recentFetch(lastFetch)) return;

  dispatch(
    apiCallBegan({
      url: userUrl + "/me",
      method: "get",
      data: null,
      // onStart: userDataRequested.type,
      onSuccess: userDataReceived.type,
      onError: userDataRequestFailed.type,
    })
  );
};

// export const downloadProject = () => (dispatch, _) => {
//   // const { lastFetch } = getState().user;
//   console.log("fetching userData...");
//   // if (recentFetch(lastFetch)) return;

//   dispatch(
//     apiCallBegan({
//       url: "download",
//       method: "get",
//       data: null,
//       responseType: "blob",
//       // onStart: userDataRequested.type,
//       onSuccess: downloadReceived.type,
//       onError: downloadError.type,
//     })
//   );
// };

export const sendSelectedProject = (data) => (dispatch, _) => {
  dispatch(
    apiCallBegan({
      url: userUrl + "/me",
      method: "put",
      data,
      onStart: userSelectionSent.type,
      onSuccess: userSelectionConfirmed.type,
      onError: userSelectionFailed.type,
    })
  );
};

// Selectors

export const getSelectedProject = createSelector(
  (state) => state.user.selectedProjectId,
  (state) => state.entities.revenue.data,
  (state) => state.entities.setup.data,
  (selected) => {
    // console.log("getSelectedProject", projects, selected);
    const projects = store.getState().entities.setup.data;
    const index = projects.findIndex(
      (project) => project.projectId === selected
    );
    // console.log(index);
    const project = projects[index];
    return project;
  }
);

export const getShowComponent = createSelector(
  // returns true if not admin with >1 projects
  (state) => state.user.admin,
  (user) => {
    const { showComponent } = user;
    return showComponent;
  }
);
