import styled from "styled-components";
import { fontColorGrey } from "../helpers";

export const Container = styled.div`
  display: flex;
  justify-content: center;

  h6 {
    font-size: ${(props) => props.data.titleSize}pt;
    color: ${(props) => props.data.fontColor};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: bold;
    margin-top: ${(props) => props.data.textPosition}px; /* this one */
    margin-bottom: -${(props) => props.data.textPosition}px; /* this one */
  }
  .headerSize {
    font-size: ${(props) => props.data.headerSize}pt;
    color: ${(props) => props.data.fontColor};
  }
  p {
    font-size: ${(props) => props.data.textSize}pt; /* this one */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-top: ${(props) => props.data.textPosition}px; /* this one */
    margin-bottom: -${(props) => props.data.textPosition}px; /* this one */
    text-align: start;
    color: black;
  }
  .col {
    flex-direction: column;
  }

  .contents {
    padding: 5px 5px;
    width: 800px;
    height: 1122px;
    background-color: white;
    overflow: hidden;
  }

  header {
    display: flex;
    color: white;
    margin-bottom: 1px;
    .resources.header {
      border-left: 1px solid white;
      justify-content: center;
    }
  }

  .titleBar {
    display: flex;
    color: white;
  }
  .description,
  .deadlineDescription {
    display: flex;
    align-items: center;
    padding-left: 5px;
    text-overflow: ellipsis;
  }
  .resources {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    .data {
      padding: 0 5px;
    }
    .acronyms {
      /* color: red; */
      font-size: ${(props) => props.data.resourcesSize}pt;
      overflow: hidden;
      white-space: initial;
      text-overflow: none;
    }
  }
  .date {
    padding-left: 5px;
  }
  .taskDays {
    display: flex;
    width: 100%;
  }
  .years {
    display: flex;
    align-items: center;
    height: ${(props) => props.data.headerHeight}px;
    width: 100%;
  }
  .year {
    left: 5px;
    position: absolute;
  }
  .newYear {
    border-left: 1px solid white;
    position: relative;
  }
  .months {
    display: flex;
    align-items: center;
    height: ${(props) => props.data.headerHeight}px; /* this one */
    width: 100%;
    background-color: ${(props) => props.data.header};
    margin-top: 1px;
  }

  /* widths */

  .description {
    width: ${(props) => props.data.descriptionWidth}px;
    border-left: ${(props) =>
      props.data.daysPosition === 0 ? "1px solid white" : "none"};
  }
  .resources {
    width: ${(props) => props.data.resourcesWidth}px;
    justify-content: flex-start;
  }
  .descRes {
    padding-left: 5px;
    width: ${(props) =>
      props.data.daysPosition === 0
        ? props.data.descriptionWidth + props.data.resourcesWidth
        : props.data.descriptionWidth + props.data.resourcesWidth}px;
    border-left: ${(props) =>
      props.data.daysPosition === 0 ? "1px solid white" : "none"};
    border-top: 1px solid white;
    border-bottom: 1px solid white;
  }
  .deadlineDescription {
    padding-left: 5px;
    width: ${(props) => props.data.descriptionWidth}px;
  }
  .totalDaysSpacer {
    padding-left: 5px;
    width: ${(props) =>
      props.data.daysPosition < 2
        ? props.data.descriptionWidth +
          props.data.resourcesWidth +
          props.data.daysWidth +
          2
        : props.data.descriptionWidth + props.data.resourcesWidth}px;
  }
  .gantt {
    display: flex;
    position: relative;
    flex-grow: 1;
    flex-basis: 0;
    margin-left: 1px;
  }
  .gantt.title {
    border-top: 1px solid white;
    border-bottom: 1px solid white;
  }

  .days {
    width: ${(props) => props.data.daysWidth}px;
    margin-left: 1px;
  }
  .days.title {
    border-top: 1px solid white;
    border-bottom: 1px solid white;
  }

  /* sub gantt  */

  .monthContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: ${(props) => props.data.monthWidth};
    color: white;
    font-weight: bold;
  }

  /* colours */

  .header {
    background-color: ${(props) => props.data.header};
  }
  .taskMonth {
    background-color: ${(props) => props.data.taskCalender};
  }
  .taskBar {
    background-color: ${(props) => props.data.taskBar};
  }
  .taskTitleColor {
    background-color: ${(props) => props.data.taskTitle};
  }
  .DMonth {
    background-color: ${(props) => props.data.delBackground};
  }
  .DColor {
    background-color: ${(props) => props.data.delTitle};
  }
  .MMonth {
    background-color: ${(props) => props.data.milBackground};
  }
  .MColor {
    background-color: ${(props) => props.data.milTitle};
  }
  .timeline {
    background-color: ${(props) => props.data.years};
  }

  .DMonth,
  .MMonth,
  .taskMonth,
  .block {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: ${(props) => props.data.monthWidth};
    /* font-weight: bold; */
    /* color: white; */
    /* border: 1px solid red; */
    .value {
      font-weight: normal;
      font-size: ${(props) => props.data.taskSize}pt; /* this one */
    }
    p {
      color: ${(props) => props.data.fontColor};
    }
  }
  .fill {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 90%;
    color: white;
    font-weight: bold;
  }
  .pill {
    height: 100%;
    width: 100%;
    min-width: 25px;
  }
  .start {
    border-top-left-radius: ${(props) => props.data.barShape}px;
    border-bottom-left-radius: ${(props) => props.data.barShape}px;
  }
  .end {
    border-top-right-radius: ${(props) => props.data.barShape}px;
    border-bottom-right-radius: ${(props) => props.data.barShape}px;
  }
  .quarter {
    border-right: 1px solid white;
    :last-child {
      border: none;
    }
  }
  .days {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: normal;
  }
  .taskDays {
    background-color: ${(props) => props.data.taskBackground};
    /* color: ${(props) => props.data.header}; */
    color: ${(props) => props.data.header};
    font-weight: normal;
  }
  .block .totalDays {
    /* font-size: 10px; */
    color: ${fontColorGrey};
    font-weight: bold;
  }

  .pack {
    margin-top: 0px;
  }
  .packTotals {
    /* margin-bottom: 10px; */
  }
  .title {
    display: flex;
    align-items: center;
    color: ${(props) => props.data.fontColor};
    font-weight: bold;
    height: ${(props) => props.data.titleHeight}px; /* this one */
    /* padding-left: 5px; */
  }
  .task {
    height: ${(props) => props.data.rowHeight}px; /* this one */
    display: flex;
    .description,
    .deadlineDescription,
    .resources {
      border-bottom: 1px solid rgba(220, 220, 220);
    }
  }
  .schedule {
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    margin: auto;
  }
`;
