import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import {
  getTotalsByLeader,
  handleSendGlobal,
  handleSendProject,
  numberToCurrency,
  replaceWords,
  roundTo,
} from "../../helpers";
import { updateGlobalOutput } from "../../store/entities/global";
import { updateProjectOutput } from "../../store/entities/project";

export default function OutputCostSalaries(props) {
  const dispatch = useDispatch();
  const { edit, category, mapObj } = props;
  const { type, title, key } = category;
  const totalCost = useSelector(getTotalsByLeader).object.totals.combined;
  const cost = useSelector(getTotalsByLeader).object[type].combined;
  const value = numberToCurrency(cost);
  const percent = roundTo((cost / totalCost) * 100, 0);
  const checkedPercent = percent < 1 ? "<1" : percent;
  const costSentence = `£${value} (${checkedPercent}% of project cost).`;
  const { globalSettings } = useSelector((state) => state.user);

  const source = globalSettings ? "global" : "project";
  const sentence = useSelector(
    (state) => state.entities[source].data.outputs[key]
  );
  const replaced = mapObj && sentence ? replaceWords(mapObj, sentence) : "";

  function handleChange(e) {
    const { value } = e.target;
    if (globalSettings) dispatch(updateGlobalOutput({ value, key }));
    else dispatch(updateProjectOutput({ value, key }));
  }
  function sendChanges() {
    if (globalSettings) handleSendGlobal();
    else handleSendProject();
  }

  return cost ? (
    <Container>
      <span className="bold">{title}&nbsp;</span>
      <span>= {costSentence}&nbsp;</span>
      <span>{replaced}</span>

      {edit ? (
        <textarea
          className="textarea"
          onBlur={sendChanges}
          onChange={handleChange}
          value={sentence}
        />
      ) : null}
    </Container>
  ) : null;
}

const Container = styled.div``;
