import React from "react";
import styled from "styled-components";
import { fontColorGrey, tabHeight } from "../../helpers";

export default function TableTabs(props) {
  const { tabs, selectedTab, select } = props.data;

  const selected = selectedTab ? selectedTab.toLowerCase() : "Loading"; // if the data is undefined
  const tabList = selectedTab ? tabs : ["Loading"]; // if the data is undefined

  return (
    <Container>
      {tabList.map((tab, index) => {
        return (
          <div
            key={index}
            onClick={() => select(tab.toLowerCase())}
            className={`tab ${
              tab.toLowerCase() === selected ? "selectedTab" : null
            }`}
          >
            <h3>{tab}</h3>
          </div>
        );
      })}
    </Container>
  );
}

export const Container = styled.div`
  /* height: ${tabHeight}; */
  min-height: ${tabHeight};
  display: flex;
  background-color: rgba(88, 88, 88, 1);
  overflow: hidden;
  width: 100%;
  border-radius: 0 6px 0 0;
  flex-wrap: wrap;
  .tab {
    min-height: ${tabHeight};
    flex-basis: 0;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-left: 2px solid rgba(64, 64, 64, 1);
    :first-child {
      border-left: none;
    }
    :last-child {
      border-right: none;
    }
    border-right: 2px solid rgba(110, 110, 110, 1);
    padding: 0 25px;
    cursor: pointer;
    h3 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .selectedTab {
    background-color: white;
    color: ${fontColorGrey};
    border-color: white;
    border-radius: 6px 6px 0 0;
    :first-child {
      border-radius: 0 6px 0 0;
    }
  }
`;
