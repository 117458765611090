import React from "react";
import { useDispatch } from "react-redux";
import { wizardReset, wizardComponent } from "../../store/entities/project";
import Close from "./close";

function wizardResetModal() {
  const dispatch = useDispatch();

  function startQuestions() {
    dispatch(wizardReset());
    // go to first question
  }
  function showSummary() {
    dispatch(wizardComponent({ value: "summary" }));
  }
  const closeData = {};

  return (
    <div className="modal">
      <Close data={closeData} />
      <h3 className="title">Setup Wizard</h3>
      <div>
        <p className="bold">The Wizard has already been completed,</p>
        <p className="bold">would you like to relaunch the wizard?</p>
        <p>This will reset all previous responses</p>
      </div>
      <div className="buttons mt">
        <button onClick={startQuestions}>Launch Wizard</button>
        <button onClick={showSummary}>View previous responses</button>
      </div>
    </div>
  );
}

export default wizardResetModal;
