import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { wizardAnswer } from "../../store/entities/project";
import Toggle from "./toggle";

function wizardSummaryRow(props) {
  const { question, index, option, optionIndex } = props;
  const dispatch = useDispatch();

  const { answers } = useSelector(
    (state) => state.entities.project.data.wizard
  );

  const { skipped } = answers[index];
  const skip = skipped ? "skip" : "";
  const { dep } = question;
  const dependency = dep ? `dep${dep}` : "";

  const text = option ? option : question.summary;
  const key = index + "dep" + (option ? optionIndex : "");
  const className = `result ${dependency} ${skip}`;
  const result = option ? answers[index].result[option] : answers[index].result;

  function toggleResult() {
    dispatch(wizardAnswer({ index, option, answer: !result }));
  }

  return (
    <div key={key} className={className}>
      {skipped ? null : (
        <>
          <div className="summary">
            {dep === 1 ? <div className="dot" /> : null}
            <p className='summary'>{text}</p>
          </div>
          <Toggle status={result} toggle={toggleResult} />
        </>
      )}
    </div>
  );
}

export default wizardSummaryRow;
