import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  fontColorGrey,
  handleSendGlobal,
  handleSendSetup,
  tableInputUnderline,
} from "../../helpers";
import Close from "../../components/general/close";
import bin from "../../images/bin-grey.png";
import add from "../../images/add-grey.png";
import tick from "../../images/tick-grey.png";
import tickPurple from "../../images/tick-purple.png";
import reorder from "../../images/reorder.png";
import {
  addToGlobalList,
  removeFromGlobalList,
  setGlobalDefault,
  reorderGlobalList,
} from "../../store/entities/global";
import {
  addToSetupList,
  removeFromSetupList,
  updateSetup,
  reorderSetupList,
} from "../../store/entities/setup";
import { getSelectedProject, setShowComponent } from "../../store/user";

function AdminModal(props) {
  const { title, list, defaultOption, listKey, defaultKey, global } =
    props.data;
  const [newValue, setNewValue] = useState("");
  const dispatch = useDispatch();
  const { projectId } = useSelector(getSelectedProject);

  function onChangeHandler(e) {
    const value = e.target.value;
    setNewValue(value);
  }

  function handleClick() {
    const result = newValue.trim();
    if (!result) return;
    if (global) dispatch(addToGlobalList({ key: listKey, value: result }));
    else dispatch(addToSetupList({ key: listKey, value: result, projectId }));
    setNewValue("");
  }

  function setDefault(value) {
    if (global) dispatch(setGlobalDefault({ key: defaultKey, value }));
    else dispatch(updateSetup({ key: defaultKey, value }));
  }

  function removeItem(index) {
    if (global) dispatch(removeFromGlobalList({ key: listKey, index }));
    else dispatch(removeFromSetupList({ key: listKey, index, projectId }));
    if (defaultOption === list[index]) setDefault("");
  }

  function handleMovingRow(result) {
    if (!result.destination || result.destination.index === result.source.index)
      return;
    const newIndex = result.destination.index;
    const originalIndex = result.source.index;
    if (global)
      dispatch(reorderGlobalList({ key: listKey, newIndex, originalIndex }));
    else
      dispatch(
        reorderSetupList({ key: listKey, newIndex, originalIndex, projectId })
      );
  }

  let message = null;
  if (list.length === 0) message = "Must have at least one entry";
  const data = {
    message,
    providedFunction: () => {
      dispatch(setShowComponent({ value: "" }));
      if (global) handleSendGlobal();
      else handleSendSetup();
    },
  };

  return (
    <Container>
      <div className="editWindow">
        <Close data={data} />
        <h3 className="title">{title}</h3>
        <div className="input">
          <input
            id={listKey}
            name={listKey}
            value={newValue}
            onChange={onChangeHandler}
          />
          {newValue === "" ? null : (
            <button onClick={handleClick} className="add image">
              <img src={add} alt="add" />
            </button>
          )}
        </div>
        {defaultKey ? <p className="default">Default</p> : null}
        <DragDropContext onDragEnd={handleMovingRow}>
          <Droppable droppableId="team">
            {(provided) => (
              <div
                className="adminList"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {list.map((value, index) => {
                  return (
                    <Draggable
                      key={index}
                      draggableId={value + index}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          className="MonthContainer packBackground"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <div key={index} className="listRow">
                            {/* <Tippy content="Drag to reorder rows"> */}
                            <div
                              {...provided.dragHandleProps}
                              className="hiddenList grabHandle"
                            >
                              <img src={reorder} alt="reorder" />
                            </div>
                            {/* </Tippy> */}
                            <p>{value}</p>
                            <button
                              onClick={() => removeItem(index)}
                              className="delete image hiddenList"
                            >
                              <img src={bin} alt="delete" />
                            </button>
                            {defaultKey ? (
                              <button
                                onClick={() => setDefault(value)}
                                className={
                                  value === defaultOption ? "" : "hiddenList"
                                }
                              >
                                <div className="updateSetup image">
                                  <img
                                    src={
                                      value === defaultOption
                                        ? tickPurple
                                        : tick
                                    }
                                    alt="accept"
                                  />
                                </div>
                              </button>
                            ) : null}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </Container>
  );
}

export default AdminModal;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(20, 20, 20, 0.6);
  z-index: 11;
  color: ${fontColorGrey};

  .editWindow {
    margin: 30px 0;
    position: relative;
    border-radius: 6px;
    padding: 15px 0px;
    background-color: white;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-height: 610px;

    .adminList {
      display: flex;
      flex-direction: column;
      overflow-x: scroll;
    }

    .title {
      margin: 0 30px;
      height: 30px;
    }
    .input {
      position: relative;
      display: flex;
      align-items: center;
      margin: 10px 0 20px 0;
    }
    input {
      width: 300px;
      position: relative;
      padding: 5px;
      border: 2px solid ${tableInputUnderline};
      margin: 0 30px;
      min-height: 30px;
    }
    .default {
      margin: 0 30px;
      margin-bottom: 15px;
      width: 300px;
      text-align: right;
    }
    .listRow {
      position: relative;
      margin: 0 30px;
      padding-bottom: 10px;
      width: 300px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      /* border: 1px solid red; */
      &:hover .hiddenList {
        opacity: 1;
      }
    }
    .hiddenList {
      transition: opacity 0.3s;
      opacity: 0;
    }
    .image {
      max-height: 20px;
      max-width: 20px;
    }
    .add {
      position: absolute;
      right: 5px;
      /* top: 8px; */
    }
    .delete {
      position: absolute;
      right: -25px;
      max-width: 20px;
      max-height: 20px;
      padding: 0;
    }
    button {
      padding: 0;
    }
  }
`;
