import React, { useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { navBackground, navHeight, rev2 } from "../helpers/settings";
import { updateUserSelection } from "../store/user";
import Projects from "../components/admin/projects";
import Users from "../components/admin/users";
import { loadUsers } from "../store/entities/users";
import { loadAll } from "../helpers";
import SetupTable from "./setupTable";
import Spinner from "../components/spinner";
import ProjectTable from "../components/admin/ProjectTable";
import AccountTable from "../components/admin/AccountTable";

function AdminHome() {
  const dispatch = useDispatch();
  const { dashboard, selectedProjectId, projectDataLoading } = useSelector(
    (state) => state.user
  );

  function select(component) {
    dispatch(updateUserSelection({ key: "dashboard", value: component }));
  }

  useEffect(() => {
    loadAll();
  }, [selectedProjectId]);

  useEffect(() => {
    dispatch(loadUsers());
  }, []);

  const components = {
    projects: rev2 ? <ProjectTable /> : <Projects />,
    accounts: rev2 ? <AccountTable /> : <Users />,
    setup: <SetupTable />,
  };

  return projectDataLoading ? (
    <Spinner />
  ) : (
    <Container>
      <div className="toggle">
        <button
          onClick={() => select("projects")}
          className={`select ${dashboard === "projects" ? "selected" : null}`}
        >
          <h2>Projects</h2>
        </button>
        <button
          onClick={() => select("accounts")}
          className={`select ${dashboard === "accounts" ? "selected" : null}`}
        >
          <h2>Accounts</h2>
        </button>
        <button
          onClick={() => select("setup")}
          className={`select ${dashboard === "setup" ? "selected" : null}`}
        >
          <h2>Setup</h2>
        </button>
      </div>
      {components[dashboard]}
    </Container>
  );
}

export default AdminHome;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: auto;
  top: ${navHeight}; // covers up the project identifier
  background-color: ${navBackground};
  color: white;

  .toggle {
    border-radius: 5px;
    border: 1px solid white;
    overflow: hidden;
  }
  .select {
    width: 250px;
    border-radius: 0px;
    border: none;
    border-right: 1px solid white;
    :last-child {
      border: none
    }
    /* margin: 0 10px; */
    color: white;
    transition: background-color 0.3s;
    :hover {
      background-color: rgba(255, 255, 255, 0.15);
    }
  }

  h2 {
    padding: 10px;
    /* border-radius: 5px; */
    font-size: 20px;
  }

  .selected {
    color: ${navBackground};
    background-color: white;
    border-color: white;
    :hover {
      color: ${navBackground};
      background-color: white;
    }
  }
`;
