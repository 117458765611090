import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllRevenueData,
  handleSendRevenue,
  isNumberKey,
  numberToCurrency,
} from "../../helpers";
import { updateRevenueKey } from "../../store/entities/revenue";
import { setShowComponent, updateUserSelection } from "../../store/user";

function RevenueTotal() {
  const { showComponent } = useSelector((state) => state.user);
  const { profitMargin, taxRate } = useSelector(
    (state) => state.entities.revenue.data
  );
  const {
    corpTax,
    globalShare,
    profit,
    cumulativeProfit,
    revenue,
    roiInnovate,
    roiLead,
    ukShare,
    cumulativeCorpTax,
  } = useSelector(getAllRevenueData);
  const dispatch = useDispatch();

  function showInput(value) {
    const key = "showComponent";
    dispatch(updateUserSelection({ key, value }));
  }

  function closeInput() {
    dispatch(setShowComponent({ value: "" }));
    handleSendRevenue();
  }

  function handleChange(e) {
    const key = e.target.name;
    let value = e.target.value;
    if (value) value = parseInt(value.slice(-2));
    dispatch(updateRevenueKey({ key, value }));
  }

  return (
    <div className="revenueStream revenueTotal relative">
      <div className="revenueRow">
        <p className="field market">Total Revenue</p>
        <p className="field year">£{numberToCurrency(revenue.y1)}</p>
        <p className="field year">£{numberToCurrency(revenue.y2)}</p>
        <p className="field year">£{numberToCurrency(revenue.y3)}</p>
        <p className="field year">£{numberToCurrency(revenue.y4)}</p>
        <p className="field year">£{numberToCurrency(revenue.y5)}</p>
      </div>
      <div className="spacer" />
      <div className="revenueRow">
        <p className="field market">Profit</p>
        <p className="field year">£{numberToCurrency(profit.y1)}</p>
        <p className="field year">£{numberToCurrency(profit.y2)}</p>
        <p className="field year">£{numberToCurrency(profit.y3)}</p>
        <p className="field year">£{numberToCurrency(profit.y4)}</p>
        <p className="field year">£{numberToCurrency(profit.y5)}</p>
        {showComponent === "profitMargin" ? (
          <div className="hiddenInput">
            <input
              id={"profitMargin"}
              name="profitMargin"
              value={profitMargin}
              onChange={handleChange}
              onKeyDown={isNumberKey}
              className="field year"
            />
            <button onClick={closeInput} className="textButton">
              close
            </button>
          </div>
        ) : (
          <button
            onClick={() => showInput("profitMargin")}
            className="textButton marginLeft"
          >{`Profit Margin (${profitMargin}%)`}</button>
        )}
      </div>
      <div className="revenueRow">
        <p className="field market">Cumulative Profit</p>
        <p className="field year">£{numberToCurrency(cumulativeProfit.y1)}</p>
        <p className="field year">£{numberToCurrency(cumulativeProfit.y2)}</p>
        <p className="field year">£{numberToCurrency(cumulativeProfit.y3)}</p>
        <p className="field year">£{numberToCurrency(cumulativeProfit.y4)}</p>
        <p className="field year">£{numberToCurrency(cumulativeProfit.y5)}</p>
      </div>
      <div className="spacer" />
      <div className="revenueRow">
        <p className="field market">Corporation Tax</p>
        <p className="field year">£{numberToCurrency(corpTax.y1)}</p>
        <p className="field year">£{numberToCurrency(corpTax.y2)}</p>
        <p className="field year">£{numberToCurrency(corpTax.y3)}</p>
        <p className="field year">£{numberToCurrency(corpTax.y4)}</p>
        <p className="field year">£{numberToCurrency(corpTax.y5)}</p>
        {showComponent === "taxRate" ? (
          <div className="hiddenInput">
            <input
              id={"taxRate"}
              name="taxRate"
              value={taxRate}
              onChange={handleChange}
              onKeyDown={isNumberKey}
              className="field year"
            />
            <button onClick={closeInput} className="textButton">
              close
            </button>
          </div>
        ) : (
          <button
            onClick={() => showInput("taxRate")}
            className="textButton marginLeft"
          >{`Corp Tax Rate (${taxRate}%)`}</button>
        )}
      </div>
      <div className="revenueRow">
        <p className="field market">Cumulative Corp Tax</p>
        <p className="field year">£{numberToCurrency(cumulativeCorpTax.y1)}</p>
        <p className="field year">£{numberToCurrency(cumulativeCorpTax.y2)}</p>
        <p className="field year">£{numberToCurrency(cumulativeCorpTax.y3)}</p>
        <p className="field year">£{numberToCurrency(cumulativeCorpTax.y4)}</p>
        <p className="field year">£{numberToCurrency(cumulativeCorpTax.y5)}</p>
      </div>
      <div className="spacer" />
      <div className="revenueRow">
        <p className="field market">UK Market Share</p>
        <p className="field year">{ukShare.y1 || "0"}%</p>
        <p className="field year">{ukShare.y2 || "0"}%</p>
        <p className="field year">{ukShare.y3 || "0"}%</p>
        <p className="field year">{ukShare.y4 || "0"}%</p>
        <p className="field year">{ukShare.y5 || "0"}%</p>
      </div>
      <div className="revenueRow">
        <p className="field market">Global Market Share</p>
        <p className="field year">{globalShare.y1 || "0"}%</p>
        <p className="field year">{globalShare.y2 || "0"}%</p>
        <p className="field year">{globalShare.y3 || "0"}%</p>
        <p className="field year">{globalShare.y4 || "0"}%</p>
        <p className="field year">{globalShare.y5 || "0"}%</p>
      </div>
      <div className="spacer" />
      <div className="revenueRow">
        <p className="field market">RoI Innovate UK</p>
        <p className="field year">
          {roiInnovate.y1 === -100 ? "0" : roiInnovate.y1}%
        </p>
        <p className="field year">
          {roiInnovate.y2 === -100 ? "0" : roiInnovate.y2}%
        </p>
        <p className="field year">
          {roiInnovate.y3 === -100 ? "0" : roiInnovate.y3}%
        </p>
        <p className="field year">
          {roiInnovate.y4 === -100 ? "0" : roiInnovate.y4}%
        </p>
        <p className="field year">
          {roiInnovate.y5 === -100 ? "0" : roiInnovate.y5}%
        </p>
      </div>
      <div className="revenueRow">
        <p className="field market">RoI Lead</p>
        <p className="field year" />
        <p className="field year" />
        <p className="field year" />
        <p className="field year" />
        <p className="field year">{roiLead.y5 === -100 ? "0" : roiLead.y5}%</p>
      </div>
    </div>
  );
}
export default RevenueTotal;
