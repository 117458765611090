// import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import {
  clientFlavours,
  recentFetch,
  reducerNotification,
} from "../../helpers";
import { apiCallBegan } from "../api";
import { getSelectedProject } from "../user";

const initialState = {
  loading: false,
  lastFetch: 0,
  lastSent: 0,
  lastEdit: 0,
  data: [
    {
      projectId: "abc",
      productPlatformName: "",
    },
  ],
  error: "",
};

const slice = createSlice({
  name: "setup",
  initialState,
  reducers: {
    addToSetupList: (setup, action) => {
      const { key, value, projectId } = action.payload;
      const index = setup.data.findIndex(
        (project) => project.projectId === projectId
      );
      setup.data[index][key].push(value);
      setup.lastEdit = Date.now();
    },
    removeFromSetupList: (setup, action) => {
      const { key, projectId } = action.payload;
      const index = setup.data.findIndex(
        (project) => project.projectId === projectId
      );
      setup.data[index][key].splice(index, 1);
      setup.lastEdit = Date.now();
    },
    updateSetup: (setup, action) => {
      const { key, value, projectId } = action.payload;
      const index = setup.data.findIndex(
        (project) => project.projectId === projectId
      );
      setup.data[index][key] = value;
      setup.lastEdit = Date.now();
    },
    reorderSetupList: (setup, action) => {
      const { key, newIndex, originalIndex, projectId } = action.payload;
      const index = setup.data.findIndex(
        (project) => project.projectId === projectId
      );
      console.log(index);
      const [entry] = setup.data[index][key].splice(originalIndex, 1);
      setup.data[index][key].splice(newIndex, 0, entry);
    },

    // not needed below in rev2 --------------------
    increasePartners: (setup, action) => {
      const { projectId } = action.payload;
      const index = setup.data.findIndex(
        (project) => project.projectId === projectId
      );

      if (setup.data[index].partners < 3) setup.data[index].partners++;
      setup.lastEdit = Date.now();
      console.log(setup.data[index].partners);
    },
    decreasePartners: (setup, action) => {
      const { projectId } = action.payload;
      const index = setup.data.findIndex(
        (project) => project.projectId === projectId
      );
      if (setup.data[index].partners > 1) setup.data[index].partners--;
      setup.lastEdit = Date.now();
    },
    updateProjectDesc: (setup, action) => {
      const { projectId, desc } = action.payload;
      const index = setup.data.findIndex(
        (project) => project.projectId === projectId
      );
      setup.data[index].projectDesc = desc;
      setup.lastEdit = Date.now();
    },
    toggleClientFlavour: (setup, action) => {
      const { projectId } = action.payload;
      const projectIndex = setup.data.findIndex(
        (project) => project.projectId === projectId
      );
      const flavourIndex = clientFlavours.findIndex(
        (flavour) => flavour === setup.data[projectIndex].clientFlavour
      );

      const newValue = (flavourIndex + 1) % 3;
      setup.data[projectIndex].clientFlavour = clientFlavours[newValue];
      setup.lastEdit = Date.now();
    },
    // not needed above in rev2 --------------------

    updatePartner: (setup, action) => {
      const { projectId, value } = action.payload;
      const index = setup.data.findIndex(
        (project) => project.projectId === projectId
      );
      setup.data[index].partners = value;
      setup.lastEdit = Date.now();
    },

    updateProject: (setup, action) => {
      const { projectId, key, value } = action.payload;
      const index = setup.data.findIndex(
        (project) => project.projectId === projectId
      );
      setup.data[index][key] = value;
      setup.lastEdit = Date.now();
    },

    updateProjectColor: (setup, action) => {
      const { projectId, color } = action.payload;
      const index = setup.data.findIndex(
        (project) => project.projectId === projectId
      );
      setup.data[index].color = color;
      setup.lastEdit = Date.now();
    },

    // INTERNAL - DO NOT EXPORT
    setupDataRequested: (setup, _) => {
      setup.loading = true;
    },
    setupDataRequestFailed: (setup, action) => {
      setup = initialState;
      reducerNotification("error", action.payload);
    },
    setupDataReceived: (setup, action) => {
      setup.data = action.payload;
      setup.loading = false;
      setup.lastFetch = Date.now();
    },

    sendingNewSetup: (setup, action) => {
      setup.loading = true;
    },
    sendingNewSetupFailed: (setup, action) => {
      setup.loading = false;
      reducerNotification("error", action.payload);
    },
    newSetupReceived: (setup, action) => {
      setup.loading = false;
      const { data } = action.payload;
      setup.data.push(data);
    },

    // sendingUpdatedSetup: (setup, _) => {
    //   setup.loading = true;
    // },
    // sendingUpdatedSetupFailed: (setup, action) => {
    //   setup.loading = false;
    //   toastNotification("error", action.payload);
    // },
    // SendingUpdatedSetupSuccess: (setup, action) => {
    //   setup.loading = false;
    //   // setup.data.push(action.payload);
    //   // console.log("splice here");
    // },

    deleteSetup: (setup, action) => {
      const { projectId } = action.payload;
      console.log("deleting:", projectId);
      const index = setup.data.findIndex(
        (project) => project.projectId === projectId
      );
      localStorage.removeItem("selectedProjectId");
      setup.data.splice(index, 1);
    },

    apiSuccess: (setup, action) => {
      setup.loading = false;
      // console.log(action.payload.data);
      // reducerNotification("info", "Setup saved");
      setup.lastSent = Date.now();
    },
    apiFailed: (setup, _) => {
      setup.loading = false;
      reducerNotification("error", "Setup failed");
    },
  },
});
export default slice.reducer;

export const {
  setupDataReceived,
  addToSetupList,
  removeFromSetupList,
  updateSetup,
  toggleClientFlavour,
  reorderSetupList,

  // not needed rev2
  // increasePartners,
  // decreasePartners,
  updatePartner,
  updateProjectDesc,
  // not needed rev2

  updateProjectColor,
  updateProject,
} = slice.actions;

const {
  setupDataRequested,
  setupDataRequestFailed,

  sendingNewSetupFailed,
  newSetupReceived,
  deleteSetup,

  apiSuccess,
  apiFailed,
} = slice.actions;

// Action Creators

const url = "/setup";

export const loadSetupData = () => (dispatch, getState) => {
  const { lastFetch } = getState().entities.setup;
  // console.log("fetching setup data...");
  if (recentFetch(lastFetch)) return;

  dispatch(
    apiCallBegan({
      url: url + "/",
      method: "get",
      data: null,
      onStart: setupDataRequested.type,
      onSuccess: setupDataReceived.type,
      onError: setupDataRequestFailed.type,
    })
  );
};

export const sendNewSetup = (data) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: url + "/new",
      method: "post",
      data,
      // onStart: sendingNewSetup.type,
      onSuccess: newSetupReceived.type,
      onError: sendingNewSetupFailed.type,
    })
  );
};

export const cloneSetup = (projectId) => (dispatch, getState) => {
  const project = getSelectedProject(getState());
  dispatch(
    apiCallBegan({
      url: url + "/new",
      method: "post",
      data: {
        projectId,
        data: { ...project, projectId },
      },
      // onStart: sendingAllocation.type,
      onSuccess: newSetupReceived.type,
      onError: apiFailed.type,
    })
  );
};

export const sendUpdatedSetup = () => (dispatch, getState) => {
  const { selectedProjectId } = getState().user;
  const { lastEdit, lastSent } = getState().entities.setup;
  if (lastSent >= lastEdit) return;
  const index = getState().entities.setup.data.findIndex(
    (project) => project.projectId === selectedProjectId
  );
  const data = getState().entities.setup.data[index];
  // console.log(data);
  dispatch(
    apiCallBegan({
      url: url + "/selected",
      method: "put",
      data,
      // onStart: sendingUpdatedSetup.type,
      onSuccess: apiSuccess.type,
      onError: apiFailed.type,
    })
  );
};

export const deleteProjectSetup = (projectId) =>
  apiCallBegan({
    url: url + "/selected",
    method: "delete",
    data: {
      projectId,
    },
    // onStart: sendingEmptyAssignments.type,
    onSuccess: deleteSetup.type,
    onError: apiFailed.type,
  });

// Selectors

export const getProjectList = createSelector(
  (state) => state.entities.setup.data,
  (projects) => {
    const list = [];
    projects.forEach(({ projectId, projectDesc }, index) => {
      list[index] = { projectId, projectDesc };
    });
    return list;
  }
);

export const getProjectById = createSelector(
  (state) => state.entities.setup.data,
  (projects) => {
    const list = {};
    projects.forEach(({ projectId, projectDesc }) => {
      list[projectId] = projectDesc;
    });
    return list;
  }
);

export const getMaxRisks = createSelector(
  (state) => state.entities.setup.data,
  (state) => state.user.selectedProjectId,
  (projectList, selectedProjectId) => {
    const index = projectList.findIndex(
      (project) => project.projectId === selectedProjectId
    );
    const { maxEnvRisks, maxLegRisks, maxComRisks, maxTechRisks, maxManRisks } =
      projectList[index];

    const maxRisks = {
      environmental: maxEnvRisks,
      legal: maxLegRisks,
      commercial: maxComRisks,
      technical: maxTechRisks,
      managerial: maxManRisks,
    };
    return maxRisks;
  }
);
