import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import add from "../../images/add-white.png";
import UserCard from "./userCard";
import Tippy from "@tippy.js/react";
import UserModal from "../modals/userModal";
import { setShowComponent } from "../../store/user";

function users() {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.entities.users.data);
  const { showComponent } = useSelector((state) => state.user);

  function showUserModal() {
    dispatch(setShowComponent({ value: "userModal" }));
  }

  return (
    <Container>
      {showComponent === "userModal" ? <UserModal /> : null}
      <div className="cards">
        {users.map((user, index) => {
          return user.admin ? <UserCard key={index} user={user} /> : null;
        })}
      </div>
      <div className="cards">
        {users.map((user, index) => {
          return user.admin ? null : <UserCard key={index} user={user} />;
        })}
      </div>
      <Tippy content="Add a user">
        <div className="add">
          <img src={add} alt="add" onClick={showUserModal} />
        </div>
      </Tippy>
    </Container>
  );
}

export default users;

const Container = styled.div`
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
  .cards {
    max-width: 1500px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .add {
    margin-top: 20px;
    max-width: 50px;
    max-height: 50px;
    cursor: pointer;
  }
`;
