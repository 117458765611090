import { deleteTask } from "../store/entities/tasks";
import { deleteTaskAllocations } from "../store/entities/allocations";
import { deletePackAssignments } from "../store/entities/assignments";
import {
  handleSendAllocations,
  handleSendAssignments,
  handleSendTasks,
} from "./handleSendToServer";

export function handleDeleteWorkPack(
  dispatch,
  workPackageId,
  taskIds,
  otherIds
) {
  dispatch(deletePackAssignments({ workPackageId, otherIds }));
  handleSendAssignments();
  taskIds.forEach((taskId) => {
    dispatch(deleteTaskAllocations({ taskId }));
    dispatch(deleteTask({ taskId }));
    handleSendTasks();
  });
  handleSendAllocations();
}
