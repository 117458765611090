// import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { generateRandomId, recentFetch, reducerNotification, rev2 } from "../../helpers";
import { apiCallBegan } from "../api";
import { getSelectedProject } from "../user";
import { store } from "../";

const initialState = {
  loading: false,
  lastFetch: 0,
  data: [],
  lastSent: 0,
  lastEdit: 0,
  requiresSorting: false,
  error: "",
};

const slice = createSlice({
  name: "risks",
  initialState,
  reducers: {
    risksRequested: (risks, _) => {
      risks.loading = true;
    },
    risksRequestFailed: (risks, action) => {
      risks = initialState;
      reducerNotification("error", action.payload);
    },
    risksReceived: (risks, action) => {
      risks.data = action.payload;
      risks.loading = false;
      risks.lastFetch = Date.now();
    },

    addRisk: (risks, action) => {
      const { riskType } = action.payload;
      // console.log(type);
      const newRisk = {
        riskId: generateRandomId('risk'),
        riskType,
        riskDescription: "",
        riskMitigation: "",
        riskPreProbability: 0,
        riskPreConsequence: 0,
        riskPostProbability: 0,
        riskPostConsequence: 0,
        category: null,
        riskValue: 1,
        riskOwner: "",
      };
      risks.data.push(newRisk);
      risks.requiresSorting = true;
      risks.lastEdit = Date.now();
    },
    addFromTemplates: (risks, action) => {
      const { template } = action.payload;
      risks.data.push(template);
      risks.requiresSorting = true;
      risks.lastEdit = Date.now();
    },
    deleteRisk: (risks, action) => {
      const { riskId } = action.payload;
      const index = risks.data.findIndex((risk) => risk.riskId === riskId);
      risks.data.splice(index, 1);
      risks.lastEdit = Date.now();
    },
    updateRiskKeyValue: (risks, action) => {
      const { riskId, key, value } = action.payload;
      const index = risks.data.findIndex((risk) => risk.riskId === riskId);
      risks.data[index][key] = value;
      risks.lastEdit = Date.now();
    },
    // setRiskHeight: (risks, action) => {
    //   const { riskId, value } = action.payload;
    //   const index = risks.data.findIndex((risk) => risk.riskId === riskId);
    //   risks.data[index].height = value;
    // },
    updateProbability: (risks, action) => {
      const { riskId } = action.payload;
      const index = risks.data.findIndex((risk) => risk.riskId === riskId);
      let newValue = (risks.data[index].riskPreProbability + 1) % 6;
      if (!newValue) newValue = 1;
      risks.data[index].riskPreProbability = newValue;
      risks.data[index].riskValue =
        (risks.data[index].riskPreConsequence + 0.1) * newValue;
      risks.requiresSorting = true;
      risks.lastEdit = Date.now();
    },
    updateConsequence: (risks, action) => {
      const { riskId } = action.payload;
      const index = risks.data.findIndex((risk) => risk.riskId === riskId);
      let newValue = (risks.data[index].riskPreConsequence + 1) % 6;
      if (!newValue) newValue = 1;
      risks.data[index].riskPreConsequence = newValue;
      risks.data[index].riskValue =
        risks.data[index].riskPreProbability * (newValue + 0.1);
      risks.requiresSorting = true;
      risks.lastEdit = Date.now();
    },
    updatePostProbability: (risks, action) => {
      const { riskId } = action.payload;
      const index = risks.data.findIndex((risk) => risk.riskId === riskId);
      let newValue = (risks.data[index].riskPostProbability + 1) % 6;
      if (!newValue) newValue = 1;
      risks.data[index].riskPostProbability = newValue;
      risks.requiresSorting = true;
      risks.lastEdit = Date.now();
    },
    updatePostConsequence: (risks, action) => {
      const { riskId } = action.payload;
      const index = risks.data.findIndex((risk) => risk.riskId === riskId);
      let newValue = (risks.data[index].riskPostConsequence + 1) % 6;
      if (!newValue) newValue = 1;
      risks.data[index].riskPostConsequence = newValue;
      risks.requiresSorting = true;
      risks.lastEdit = Date.now();
    },
    updateRiskOwner: (risks, action) => {
      const { riskId, riskOwner } = action.payload;
      const index = risks.data.findIndex((risk) => risk.riskId === riskId);
      risks.data[index].riskOwner = riskOwner;
      risks.lastEdit = Date.now();
    },
    sortRisks: (risks, _) => {
      const calculatedRisks = risks.data.sort((a, b) =>
        a.riskValue > b.riskValue ? -1 : b.riskValue > a.riskValue ? 1 : -1
      );
      risks.data = calculatedRisks;
      risks.requiresSorting = false;
      risks.lastEdit = Date.now();
    },

    apiSuccess: (risks, action) => {
      risks.loading = false;
      // reducerNotification("info", "Risks saved");
      risks.lastSent = Date.now();
    },
    apiFailed: (risks, _) => {
      risks.loading = false;
      reducerNotification("error", "Risks failed");
    },
  },
});
export default slice.reducer;

export const {
  risksReceived,
  reorderRisk,
  addRisk,
  deleteRisk,
  updateRiskKeyValue,
  updateProbability,
  updateConsequence,
  updatePostProbability,
  updatePostConsequence,
  sortRisks,
  addFromTemplates,
  updateRiskOwner,
  // updateAllRisks,
  // setRiskHeight,
} = slice.actions;

const {
  risksRequested,
  risksRequestFailed,

  apiSuccess,
  apiFailed,
} = slice.actions;

// Action Creators
const url = "/risks";

export const loadRisksData = () => (dispatch, getState) => {
  const { lastFetch } = getState().entities.risks;
  // console.log("fetching risks data...");
  if (recentFetch(lastFetch)) return;

  dispatch(
    apiCallBegan({
      url: url + "/selected",
      method: "get",
      data: null,
      onStart: risksRequested.type,
      onSuccess: risksReceived.type,
      onError: risksRequestFailed.type,
    })
  );
};

export const sendUpdatedRisks = () => (dispatch, getState) => {
  // console.log(dispatch, getState);
  const risks = getState().entities.risks;
  const { lastEdit, lastSent, data } = risks;
  if (lastSent >= lastEdit) return;
  // console.log(data);
  dispatch(
    apiCallBegan({
      url: url + "/selected",
      method: "put",
      data,
      // onStart: sendingUpdatedRisks.type,
      onSuccess: apiSuccess.type,
      onError: apiFailed.type,
    })
  );
};

export const initiateRisks = (projectId) =>
  apiCallBegan({
    url: url + "/new",
    method: "post",
    data: {
      projectId,
      data: [],
    },
    // onStart: ...
    onSuccess: apiSuccess.type,
    onError: apiFailed.type,
  });

export const cloneRisks = (projectId) => (dispatch, getState) => {
  const { data } = getState().entities.risks;
  dispatch(
    apiCallBegan({
      url: url + "/new",
      method: "post",
      data: {
        projectId,
        data,
      },
      // onStart: sendingAllocation.type,
      onSuccess: apiSuccess.type,
      onError: apiFailed.type,
    })
  );
};

export const deleteProjectRisks = (projectId) =>
  apiCallBegan({
    url: url + "/selected",
    method: "delete",
    data: {
      projectId,
    },
    // onStart: sendingEmptyAssignments.type,
    onSuccess: apiSuccess.type,
    onError: apiFailed.type,
  });

export const getRiskTabs = createSelector(
  (state) => state.user.admin,
  (state) => state.entities.setup.data,
  (admin) => {
    const {
      useManagerial,
      useCommercial,
      useLegal,
      useTechnical,
      useEnvironmental,
      outputAccess,
    } = getSelectedProject(store.getState());

    const tabs = [];
    if (useManagerial) tabs.push("Managerial");
    if (useTechnical) tabs.push("Technical");
    if (useCommercial) tabs.push("Commercial");
    if (useLegal) tabs.push("Legal");
    if (useEnvironmental) tabs.push("Environmental");
    if (rev2 && (outputAccess || admin)) tabs.push("Output");
    return tabs;
  }
);

export const getRiskErrors = createSelector(
  (state) => state.entities.risks.data,
  (risks) => {
    if (!rev2) return [];
    const check = {
      incomplete: {
        flagged: false,
        message: "Incomplete risk data",
        key: "selectedRiskType",
        value: "",
      },
    };
    const warnings = [];

    risks.forEach((risk) => {
      if (
        (risk.riskDescription === "" ||
          risk.riskMitigation === "" ||
          risk.riskOwner === "" ||
          risk.riskPreProbability === 0 ||
          risk.riskPreConsequence === 0 ||
          risk.riskPostProbability === 0 ||
          risk.riskPostConsequence === 0) &&
        !check.incomplete.flagged
      ) {
        check.incomplete.flagged = true;
        check.incomplete.value = risk.riskType;
        warnings.push(check.incomplete);
      }
    });
    return warnings;
  }
);
