import React from "react";
import { useDispatch } from "react-redux";
import Tippy from "@tippy.js/react";
import "tippy.js/dist/tippy.css";
import { useSelector } from "react-redux";
import { Container } from "./detailsStyling";
import {
  sendUpdatedDetails,
  updateProjectInfo,
} from "../../store/entities/project";
import qMark from "../../images/qMark.png";

function OptionsRows() {
  const dispatch = useDispatch();

  const { ganttRef, competitor, riskRef } = useSelector(
    (state) => state.entities.project.data.details
  );

  const data = {
    maxHeight: "550px",
  };

  function handleChange(e) {
    const key = e.target.name;
    let value = e.target.value;
    value = value.slice(0, 50);
    dispatch(updateProjectInfo({ key, value }));
  }

  function sendChanges() {
    dispatch(sendUpdatedDetails());
  }

  return (
    <Container data={data}>
      <div className="rows">
        <div className="row column">
          <div className="title">
            <p>Gantt Appendix Reference</p>
            <Tippy content="See competition guidance for details">
              <div className="info">
                <img src={qMark} alt="info" />
              </div>
            </Tippy>
          </div>
          <input
            type="text"
            value={ganttRef}
            className="field"
            name="ganttRef"
            onChange={handleChange}
            onBlur={sendChanges}
          />
        </div>
        <div className="row column">
          <div className="title">
            <p>Risk Appendix Reference</p>
            <Tippy content="See competition guidance for details">
              <div className="info">
                <img src={qMark} alt="info" />
              </div>
            </Tippy>
          </div>
          <input
            type="text"
            value={riskRef}
            className="field"
            name="riskRef"
            onChange={handleChange}
            onBlur={sendChanges}
          />
        </div>
        <div className="row column">
          <div className="title">
            <p>Competitors Appendix Reference</p>
            <Tippy content="See competition guidance for details">
              <div className="info">
                <img src={qMark} alt="info" />
              </div>
            </Tippy>
          </div>
          <input
            type="text"
            value={competitor}
            className="field"
            name="competitor"
            onChange={handleChange}
            onBlur={sendChanges}
          />
        </div>
      </div>
    </Container>
  );
}
export default OptionsRows;
