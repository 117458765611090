import React from "react";
import styled from "styled-components";
import OutputSection from "./OutputSection";

export default function OutputPMIntro(props) {
  const { mapObj, edit } = props;

  return (
    <Container>
      <h3 className="title">Overview</h3>
      <OutputSection
        objKey="approach"
        subject="Approach"
        mapObj={mapObj}
        edit={edit}
      />
      <OutputSection
        objKey="objectives"
        subject="Objectives"
        mapObj={mapObj}
        edit={edit}
      />
    </Container>
  );
}

const Container = styled.div``;
