import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import MarkedCompleteModal from "../../components/modals/markedComplete";

import GanttScheduleBackground from "./ganttScheduleBackground";
import GanttWPPackSchedule from "./ganttTaskPackSchedule";
import GanttDMPackSchedule from "./ganttDeadlinePackSchedule";
import {
  taskDeadlineGap,
  monthWidth,
  totalDaysColor,
  schedGapColor,
} from "../../helpers";
import {
  getProjectStatus,
  getGanttWidth,
} from "../../store/entities/project";

function GanttChartRight(props) {
  const { workPackages, deliverables, milestones, workedDays } = props.data;
  const ganttComplete = useSelector(getProjectStatus).gantt;
  const width = useSelector(getGanttWidth);

  return (
    <PageContainer width={width}>
      <div id="schedule" className="editSpace">
        <div className="inner">
          <GanttScheduleBackground />
          <div className="monthHeaderSpacer"></div>
          {workPackages.length
            ? workPackages.map((task, index) => {
                return (
                  <GanttWPPackSchedule
                    key={index}
                    workPackData={task}
                    wpNumber={index + 1}
                  />
                );
              })
            : null}
          <div className="divider">
            {workedDays.map((month, index) => {
              return (
                // <Tippy content="Turnover last financial year">
                <div key={index} className="monthTotalDays">
                  <h3>{month ? month : null}</h3>
                </div>
                // </Tippy>
              );
            })}
          </div>

          <GanttDMPackSchedule workPackData={deliverables} prefix={"D"} />
          <GanttDMPackSchedule workPackData={milestones} prefix={"M"} />
        </div>
      </div>
    </PageContainer>
  );
}
export default GanttChartRight;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-x: auto;
  position: relative;
  padding-left: 5px;
  min-width: ${(props) => props.width};
  min-width: calc(${(props) => props.width} + 10px);

  .editSpace {
    /* width: ${(props) => props.width}; */
    /* width: calc(${(props) => props.width} + 10px); */
  }
  .inner {
    position: relative;
    /* width: calc(${(props) => props.width} + 10px); */
  }

  .divider {
    height: ${taskDeadlineGap};
    display: flex;
    align-items: flex-start;
    border-bottom: 10px solid ${schedGapColor};
    background-color: ${schedGapColor};
    width: ${(props) => props.width};
  }
  .monthTotalDays {
    width: ${monthWidth};
    height: 30px;
    flex-shrink: 0;
    margin-top: 5px;

    display: flex;
    justify-content: center;
    align-items: center;
    color: ${totalDaysColor};
  }
`;
