import React from "react";
import styled from "styled-components";
import { fontColorGrey } from "../../helpers";

export default function OutputTeamCompany(props) {
  const { company, team } = props;
  
  return (
    <Container >
      <h3 className="title">{company} Team:</h3>
      <ul>
        {team.map((person, index) => {
          return (
            <div key={index} className="person">
              <li>
                <span className="bold">
                  {person.name} - {person.role}:{" "}
                </span>
                <span>{person.profile}</span>
              </li>
            </div>
          );
        })}
      </ul>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  color: ${fontColorGrey};
`;
