import React from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { handleSendProject } from "../../helpers";
import bin from "../../images/bin-grey.png";
import {
  removeNotificationEmail,
  editNotificationEmail,
} from "../../store/entities/project";

export default function ProjectNotificationRow(props) {
  const dispatch = useDispatch();
  const { person, index } = props;
  const { project, section, name, email } = person;

  function onChangeHandler(e) {
    const key = e.target.name;
    let value = e.target.value;
    dispatch(editNotificationEmail({ index, key, value }));
  }

  function handleDelete() {
    dispatch(removeNotificationEmail({ index }));
    handleSendProject();
  }

  function handleClick(key, value) {
    dispatch(editNotificationEmail({ index, key, value }));
    handleSendProject();
  }

  // const projectSend, sectionSend = true

  return (
    <div className="row">
      <input
        name="name"
        value={name || ""}
        onChange={onChangeHandler}
        onBlur={handleSendProject}
        className="field name"
        placeholder={"name"}
      />
      <input
        name="email"
        value={email || ""}
        onChange={onChangeHandler}
        onBlur={handleSendProject}
        className="field email"
        placeholder={"email"}
      />
      <div className="toggle">
        <button
          onClick={() => handleClick("section", !section)}
          className={section ? "yes" : "no"}
        >
          {section ? "Yes" : "No"}
        </button>
      </div>
      <div className="toggle">
        <button
          onClick={() => handleClick("project", !project)}
          className={project ? "yes" : "no"}
        >
          {project ? "Yes" : "No"}
        </button>
      </div>
      <div className="hidden deleteIcon">
        <img
          src={bin}
          alt="delete"
          style={{ cursor: "pointer" }}
          onClick={handleDelete}
        />
      </div>
    </div>
  );
}
