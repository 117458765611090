import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { fontColorGrey, menuBlueColor } from "../../helpers";
import { addProjectOutputs } from "../../store/entities/project";

export default function OutputCopy(props) {
  let marginLeft = null;
  if (props) marginLeft = props.data;
  const dispatch = useDispatch();
  const global = useSelector((state) => state.entities.global.data.outputs);
  function copy() {
    dispatch(addProjectOutputs({ global }));
  }

  return (
    <Container marginLeft={marginLeft}>
      <h3>
        This project was created prior to rev2 and does not have the correct
        settings.
      </h3>
      <h3>To copy these settings, click copy.</h3>
      <button className="copy" onClick={copy}>
        Copy
      </button>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  margin-left: ${(props) => props.marginLeft};

  h3 {
    color: ${fontColorGrey};
    margin-bottom: 10px;
  }
  button.copy {
    background-color: ${menuBlueColor};
    border: none;
    color: white;
    font-weight: bold;
    padding: 10px 15px;
    width: 100%;
    margin: 0;
  }
`;
