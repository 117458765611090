import axios from "axios";
// import * as actions from "../api";
// import { getSelectedProject } from "../user";
import { baseURL, toastNotification } from "../../helpers";

import { createAction } from "@reduxjs/toolkit";
const apiCallBegan = createAction("api/callBegan");
// const apiCallSuccess = createAction("api/callSuccess");
// const apiCallFailed = createAction("api/callFailed");

const api =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    if (action.type !== apiCallBegan.type) return next(action);
    const { url, method, data, onStart, onSuccess, onError, responseType } =
      action.payload;
    const token = localStorage.getItem("token");

    if (onStart) dispatch({ type: onStart }); // send action to redux
    // next(action); // shows the action in redux devtools
    // console.log(`fetching... ${url} (${selectedProjectId})`);

    try {
      const response = await axios.request({
        baseURL: baseURL(),
        url,
        method,
        data,
        responseType,
        headers: {
          // "Content-Type": "application/json",
          authorization: token,
          // selectedProjectId,
        },
      });
      // dispatch(apiCallSuccess(response.data));
      if (onSuccess) {
        dispatch({
          type: onSuccess,
          payload: response.data,
        });
      } else {
        console.log(response);
        toastNotification("error", "Data received, no instructions");
      }
    } catch (error) {
      // if server not running
      if (!error.response) {
        toastNotification("error", "Unknown server error");
        console.log(error);
      } else if (error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("admin");
        localStorage.removeItem("authenticated");
        toastNotification("error", error.response.data.message);
        setTimeout(() => {
          window.location.href = "/login";
        }, 2500);
      } else {
        const { data } = error.response;
        // const statusCode = error.response.status;
        // console.log(url, statusCode);
        console.log(error.response);
        if (onError) {
          dispatch({
            type: onError,
            payload: data,
          });
        }
      }
    }
  };

export default api;
