import styled from "styled-components";
import { fontColorGrey, tableContentSideMargin, teamColor } from "../../helpers";

export const Container = styled.div`
  display: flex;
  .name {
    width: 150px;
  }
  .email {
    width: 250px;
  }
  .acronym {
    width: 100px;
  }
  .duplicate {
    color: red;
  }
  .role {
    width: 250px;
  }
  .salary {
    padding-left: 10px;
    width: 90px;
    /* justify-content: flex-end; */
  }
  .dayRate {
    padding-left: 10px;
    width: 90px;
    /* justify-content: flex-end; */
  }
  .location {
    width: 85px;
    /* position: relative; */
  }
  .red {
    color: red;
  }
  .amber {
    color: orange;
  }
  .warning {
    max-width: 20px;
    max-height: 20px;
    margin-left: 20px;
  }
  .dropdown {
    position: absolute;
    /* right: 60px; */
  }
  .deleteIcon {
    width: 18px;
    padding-top: 4px;
    margin-left: 15px;
  }
  .pound {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-width: 90px;
    max-width: 90px;
    margin-left: 0;
    margin-right: 20px;
    /* border: 1px solid red; */
    input {
      width: 80px;
      text-align: right;
      margin: 0;
    }
    p {
      position: absolute;
      color: ${fontColorGrey};
      font-size: 16px;
      padding: 0;
      left: 11px;
    }
  }
  /* .money {
    position: relative;
  }
  .pound {
    position: absolute;
    left: -9px;
    top: 4px;
    color: ${fontColorGrey};
    font-size: 16px;
  } */
  .addIcon {
    height: 35px;
    width: 35px;
    margin-top: 5px;
    margin-left: ${tableContentSideMargin};
    transition: transform 0.3s;
    :hover {
      /* text-decoration: underline; */
      transform: scale(1.2);
    }
  }
  img {
    height: 100%;
    width: 100%;
    margin: auto;
  }
  .profileButton {
    background-color: ${teamColor};
    padding: 5px 10px;
    transition: transform 0.3s;
    :hover {
      /* text-decoration: underline; */
      transform: scale(1.08);
    }
  }
  button {
    color: white;
    border: none;
    padding: 0;
  }
`;
