import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import ConfirmModal from "../modals/confirmModal";
import { settingsColor, navBackground, notifyRemovedClient, menuPurpleColor } from "../../helpers";

import bin from "../../images/bin-grey.png";
import Tippy from "@tippy.js/react";
import {
  deleteUser,
  sendUpdatedUser,
  // sendUserColor,
  sendUserProjects,
  updateUserColor,
  userAddProject,
} from "../../store/entities/users";
import { setShowComponent } from "../../store/user";
import UserModal from "../modals/userModal";
import AccountRowProject from "./AccountRowProject";
import { BsPencil } from "react-icons/bs";
import { projectColors } from "../../helpers";

export default function AccountRow(props) {
  const dispatch = useDispatch();
  const { user } = props;
  const { userId, name, projects, email, color } = user;
  const { showComponent } = useSelector((state) => state.user);
  const allProjects = useSelector((state) => state.entities.setup.data);

  const unAssignedProjects = allProjects.filter(
    (project) => !projects.includes(project.projectId)
  );

  function showModal(value) {
    dispatch(setShowComponent({ value }));
  }

  function handleChange(e) {
    const projectId = e.target.value;
    console.log(projectId);
    dispatch(userAddProject({ userId, projectId }));
    dispatch(sendUserProjects(userId));
  }

  function handleDelete() {
    dispatch(deleteUser({ userId }));
    notifyRemovedClient(user)
  }

  function handleColor(currentColorIndex) {
    let color = (currentColorIndex + 1) % projectColors.length;
    if (!color) color = 0;
    dispatch(updateUserColor({ userId, color }));
  }

  function sendToServer() {
    dispatch(sendUpdatedUser(userId));
  }

  return (
    <Container color={projectColors[color]}>
      {showComponent === userId + "edit" ? <UserModal user={user} /> : null}
      {showComponent === userId + "del" ? (
        <ConfirmModal
          question={`Delete ${name}?`}
          comment="No project data will be deleted"
          confirm={handleDelete}
        />
      ) : null}

      <div className="indicatorSection" />

      <div className="gap">
        <div className="contents">
          <div className="info">
            <p className="name">{name}</p>
            <p className="email">{email}</p>
          </div>

          <div className="optionsContainer">
            <div className="userColor" />
            <div className="options">
              <Tippy content="Delete user">
                <div
                  onClick={() => showModal(userId + "del")}
                  className="icon delete"
                >
                  <img src={bin} alt="delete" />
                </div>
              </Tippy>
              <Tippy content="Edit user">
                <button
                  onClick={() => showModal(userId + "edit")}
                  className="editButton"
                >
                  <BsPencil />
                </button>
              </Tippy>
              <Tippy content="Toggle User Colour">
                <div
                  className="userColor"
                  onClick={() => handleColor(color)}
                  onBlur={sendToServer}
                  contentEditable
                />
              </Tippy>
              <select
                onChange={handleChange}
                className="project"
                name="project"
                id="assign"
                value="blank"
              >
                <option value="blank" disabled>
                  Click here to add a project...
                </option>
                {unAssignedProjects.map((project, index) => {
                  return (
                    <option key={index} value={project.projectId}>
                      {project.projectDesc}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <div className="projects" id={userId}>
            {projects.length ? (
              projects.map((projectId, index) => {
                return (
                  <AccountRowProject
                    key={index}
                    projectId={projectId}
                    userId={userId}
                  />
                );
              })
            ) : (
              <div className="project">
                <p className="red">No project assigned</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background-color: ${settingsColor};
  /* border: 1px solid red; */
  /* min-height: 40px; */
  :hover .delete {
    opacity: 1;
  }
  select,
  p {
    font-size: 14px;
    color: ${navBackground};
    /* max-width: 100%; */
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  select.project {
    padding: 0;
    margin: 3px;
    /* color: rgba(1, 1, 1, 0.4); */
    cursor: pointer;
  }
  .gap {
    height: 100%;
    padding: 5px 25px;
    background-color: ${(props) =>
      props.selected ? "rgb(245, 245, 245)" : "white"};
  }
  .indicatorSection {
    display: flex;
    min-width: 120px;
    max-width: 120px;
    /* border: 1px solid red; */
    background-color: ${settingsColor};
  }
  .contents {
    display: flex;
    align-items: center;
    border: 1px solid lightgray;
    border-radius: 4px;
    background-color: white;
  }
  .info {
    padding: 5px 0;
  }
  .name {
    margin-bottom: 6px;
  }
  .email {
    font-weight: bold;
  }
  .optionsContainer {
    display: flex;
    justify-content: center;
    :hover .options {
      display: flex;
    }
  }
  .editButton {
    display: flex;
    border: none;
    padding: 0;
    cursor: pointer;
  }
  .userColor {
    border-radius: 5px;
    background-color: ${(props) => props.color || "lightgray"};
    height: 20px;
    width: 25px;
    cursor: pointer;
  }
  .options {
    display: none;
    /* display: flex; */
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 240px;
    justify-content: space-evenly;
    align-items: center;
    width: 330px;
    height: 50px;
    border: 1px solid ${menuPurpleColor};
    border-radius: 4px;
    background-color: white;
  }
  .projects {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px 0;
  }
  .project {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 3px;
    &:hover .hidden {
      opacity: 1;
    }
    .red {
      color: red;
    }
    .hidden {
      transition: opacity 0.3s;
      opacity: 0;
    }
  }
  .icon {
    max-width: 18px;
    max-height: 18px;
    padding: 0;
    cursor: pointer;
  }
`;
