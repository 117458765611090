import React from "react";
import { numberToCurrency, roundTo } from "../../helpers";

export default function OutputCostItems(props) {
  const { category } = props;
  const { cost, quantity, description } = props.item;
  let total = numberToCurrency(roundTo(cost * quantity, 0));
  if (category === "other") total = numberToCurrency(cost);
  const str = `£${total} for ${description}. `;
  return <span>{str}</span>;
}
