import React from "react";
import { questions, runWizard } from "../../helpers";
import WizardSummaryRow from "./wizardSummaryRow";
import { useDispatch, useSelector } from "react-redux";
import {
  wizardComplete,
  wizardComponent,
} from "../../store/entities/project";
import { getSelectedProject, setShowComponent } from "../../store/user";
import Close from "./close";

function wizardSummary() {
  const dispatch = useDispatch();
  const { answers, complete, allQuestionsAsked } = useSelector(
    (state) => state.entities.project.data.wizard
  );
  const { partners } = useSelector(getSelectedProject);

  function close() {
    dispatch(setShowComponent({ value: "" }));
    dispatch(wizardComponent({ value: "reset" }));
  }

  function update() {
    dispatch(setShowComponent({ value: "" }));
    setTimeout(() => {
      dispatch(wizardComponent({ value: "reset" }));
      runWizard(dispatch, answers, partners);
      dispatch(wizardComplete());
    }, 500);
  }

  const closeData = {
    providedFunction: close,
  };

  return (
    <div className="modal summary">
      <Close data={closeData} />
      <h3 className="title">Summary of Responses</h3>
      <div className="wizardAnswers">
        {questions.map((question, index) => {
          if (question.data) {
            return question.data.map((option, optionIndex) => {
              return (
                <WizardSummaryRow
                  key={index + "dep" + optionIndex}
                  index={index}
                  question={question}
                  option={option}
                  optionIndex={optionIndex}
                />
              );
            });
          } else {
            return (
              <WizardSummaryRow
                key={index + "dep"}
                index={index}
                question={question}
                option={null}
                optionIndex={null}
              />
            );
          }
        })}
      </div>
      <div className="summaryButtons">
        <div />
        {allQuestionsAsked ? (
          complete ? (
            <button onClick={close}>Close</button>
          ) : (
            <button onClick={update}>Finish</button>
          )
        ) : (
          <button onClick={update}>Apply</button>
        )}
      </div>
    </div>
  );
}

export default wizardSummary;
