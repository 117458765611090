import React from "react";
import { useDispatch } from "react-redux";
import add from "../../images/addTeam.png";
import { addFromTemplates } from "../../store/entities/risks";
import { setShowComponent } from "../../store/user";

function TemplateModalRow(props) {
  const dispatch = useDispatch();
  const { template, riskCount, maxRisks } = props;
  const { riskDescription, riskMitigation } = template;

  function addToRisks() {
    dispatch(addFromTemplates({ template }));
    if (riskCount >= maxRisks - 1) {
      dispatch(setShowComponent({ value: "" }));
    }
  }

  return (
    <div className="template">
      <p className="field text description">{riskDescription}</p>
      <p className="field text mitigation">{riskMitigation}</p>
      <div className="add hidden">
        <img src={add} alt="add" onClick={addToRisks} />
      </div>
    </div>
  );
}

export default TemplateModalRow;
