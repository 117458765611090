import React from "react";
import styled from "styled-components";
import { tabHeight, tableLeftWidth } from "../../helpers";
import Button from "./leftMenuButton";

function LeftMenu(props) {
  const {
    menuList,
    backgroundColor,
    color,
    // status,
    // changeStatus,
    // section,
  } = props.data;

  return (
    <MenuContainer backgroundColor={backgroundColor} color={color}>
      <div className="links">
        <div className="spacer" />
        {menuList.map((option, index) => {
          return <Button option={option} key={index} data={props.data} />;
        })}
        <div className="spacer" />
      </div>
    </MenuContainer>
  );
}
export default LeftMenu;

const MenuContainer = styled.div`
  width: ${tableLeftWidth};
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${(props) => props.backgroundColor};
  .spacer {
    height: ${tabHeight};
  }
  .links {
    width: 100%;
  }
`;
