// import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import {
  generateRandomId,
  recentFetch,
  reducerNotification,
  toastNotification,
} from "../../helpers";
import { apiCallBegan } from "../api";
import { createSelector } from "reselect";

const initialState = {
  loading: false,
  lastFetch: 0,
  lastSent: 0,
  lastEdit: 0,
  data: {
    orgTypes: [],
    orgSizes: [],
    natures: [],
    ipProtections: [],
    projectRoles: [],
    locations: [],
    matchFundingSources: [],
    years: [],
    utilisations: [],
    taskTypes: [],
    riskTemplates: {
      riskData: [],
    },
    roles: {
      roleData: [],
    },
    tasks: {
      taskData: [],
    },
    categories: [],
    outputs: {
      // PM
      approach: "",
      objectives: "",
      ganttAppendix: "",
      projectManagement: "",
      reportingLines: "",
      managementTools: "",

      // Risk
      riskIntro: "",
      riskAppendix: "",
      riskLast: "",
      riskCritical: "", // object?

      // Costs
      costProject: "",
      costRequest: "",
      costLead: "",
      costP1: "",
      costP2: "",
      costInnovate: "",
      costLabour: "",
      costOverhead: "",
      costSubcontract: "",

      newClient: "",
    },
    email: {},
  },
  error: "",
};

const slice = createSlice({
  name: "global",
  initialState,
  reducers: {
    globalDataRequested: (global, _) => {
      global.loading = true;
    },
    globalDataRequestFailed: (global, action) => {
      global = initialState;
      toastNotification("error", action.payload);
    },
    globalDataReceived: (global, action) => {
      global.data = action.payload;
      global.loading = false;
      global.lastFetch = Date.now();
    },

    addToGlobalList: (global, action) => {
      const { key, value } = action.payload;
      global.data[key].push(value);
      global.lastEdit = Date.now();
    },
    removeFromGlobalList: (global, action) => {
      const { key, index } = action.payload;
      global.data[key].splice(index, 1);
      global.lastEdit = Date.now();
    },
    setGlobalDefault: (global, action) => {
      const { key, value } = action.payload;
      global.data[key] = value;
      global.lastEdit = Date.now();
    },
    updateGlobalValue: (global, action) => {
      const { key, value } = action.payload;
      global.data[key] = value;
      global.lastEdit = Date.now();
    },
    reorderGlobalList: (global, action) => {
      const { key, newIndex, originalIndex } = action.payload;
      const [entry] = global.data[key].splice(originalIndex, 1);
      console.log(key, newIndex, originalIndex, entry);
      global.data[key].splice(newIndex, 0, entry);
      global.lastEdit = Date.now();
    },

    // risk templates
    addRiskTemplate: (global, action) => {
      const { riskType } = action.payload;
      const riskId = generateRandomId('risk');
      const template = {
        riskId,
        riskType,
        riskDescription: "Description...",
        riskMitigation: "Mitigation...",
        riskPreProbability: 1,
        riskPreConsequence: 1,
        riskPostProbability: 1,
        riskPostConsequence: 1,
        category: "gen",
        riskValue: 1.1,
      };
      global.data.riskTemplates.riskData.push(template);
      global.lastEdit = Date.now();
    },
    deleteRiskTemplate: (global, action) => {
      const { riskId } = action.payload;
      const index = global.data.riskTemplates.riskData.findIndex(
        (template) => template.riskId === riskId
      );
      global.data.riskTemplates.riskData.splice(index, 1);
      global.lastEdit = Date.now();
    },
    updateRiskTemplate: (global, action) => {
      const { riskId, key, value } = action.payload;
      const index = global.data.riskTemplates.riskData.findIndex(
        (template) => template.riskId === riskId
      );
      global.data.riskTemplates.riskData[index][key] = value;
      if (key !== "height") global.lastEdit = Date.now();
    },
    updateProbability: (global, action) => {
      const { riskId } = action.payload;
      const index = global.data.riskTemplates.riskData.findIndex(
        (risk) => risk.riskId === riskId
      );
      let newValue =
        (global.data.riskTemplates.riskData[index].riskPreProbability + 1) % 6;
      if (!newValue) newValue = 1;
      global.data.riskTemplates.riskData[index].riskPreProbability = newValue;
      global.data.riskTemplates.riskData[index].riskValue =
        (global.data.riskTemplates.riskData[index].riskPreConsequence + 0.1) *
        newValue;
      // global.requiresSorting = true;
      // global.lastEdit = Date.now();
      global.lastEdit = Date.now();
    },
    updateConsequence: (global, action) => {
      const { riskId } = action.payload;
      const index = global.data.riskTemplates.riskData.findIndex(
        (risk) => risk.riskId === riskId
      );
      let newValue =
        (global.data.riskTemplates.riskData[index].riskPreConsequence + 1) % 6;
      if (!newValue) newValue = 1;
      global.data.riskTemplates.riskData[index].riskPreConsequence = newValue;
      global.data.riskTemplates.riskData[index].riskValue =
        global.data.riskTemplates.riskData[index].riskPreProbability *
        (newValue + 0.1);
      // global.requiresSorting = true;
      // global.lastEdit = Date.now();
      global.lastEdit = Date.now();
    },
    updatePostProbability: (global, action) => {
      const { riskId } = action.payload;
      const index = global.data.riskTemplates.riskData.findIndex(
        (risk) => risk.riskId === riskId
      );
      let newValue =
        (global.data.riskTemplates.riskData[index].riskPostProbability + 1) % 6;
      if (!newValue) newValue = 1;
      global.data.riskTemplates.riskData[index].riskPostProbability = newValue;
      // global.requiresSorting = true;
      // global.lastEdit = Date.now();
      global.lastEdit = Date.now();
    },
    updatePostConsequence: (global, action) => {
      const { riskId } = action.payload;
      const index = global.data.riskTemplates.riskData.findIndex(
        (risk) => risk.riskId === riskId
      );
      let newValue =
        (global.data.riskTemplates.riskData[index].riskPostConsequence + 1) % 6;
      if (!newValue) newValue = 1;
      global.data.riskTemplates.riskData[index].riskPostConsequence = newValue;
      // global.requiresSorting = true;
      // global.lastEdit = Date.now();
      global.lastEdit = Date.now();
    },
    runRiskCalcs: (global, _) => {
      global.data.riskTemplates.riskData.forEach((template) => {
        template.riskValue =
          (template.riskPreConsequence + 0.1) * template.riskPreProbability;
      });
      global.lastEdit = Date.now();
    },
    sortTemplates: (global, _) => {
      const calculatedRisks = global.data.riskTemplates.riskData.sort((a, b) =>
        a.riskValue > b.riskValue ? -1 : b.riskValue > a.riskValue ? 1 : -1
      );
      global.data.riskTemplates.riskData = calculatedRisks;
      global.lastEdit = Date.now();
    },

    addTaskTemplate: (global, _) => {
      const taskId = generateRandomId('task');
      global.data.tasks.taskData.push({
        taskId,
        taskTitle: "",
        category: "",
        taskType: "",
      });
      global.lastEdit = Date.now();
    },
    deleteTaskTemplate: (global, action) => {
      const { taskId } = action.payload;
      const index = global.data.tasks.taskData.findIndex(
        (template) => template.taskId === taskId
      );
      global.data.tasks.taskData.splice(index, 1);
      global.lastEdit = Date.now();
    },
    updateTaskTemplate: (global, action) => {
      const { taskId, key, value } = action.payload;
      const index = global.data.tasks.taskData.findIndex(
        (task) => task.taskId === taskId
      );
      global.data.tasks.taskData[index][key] = value;
      if (key !== "height") global.lastEdit = Date.now();
    },
    sortTaskTemplates: (global, _) => {
      const sorted = global.data.tasks.taskData.sort((a, b) =>
        a.taskTitle > b.taskTitle ? -1 : b.taskTitle > a.taskTitle ? 1 : -1
      );
      global.data.tasks.taskData = sorted;
      global.lastEdit = Date.now();
    },

    // roles
    addToGlobalRoles: (global, action) => {
      const { roleTitle } = action.payload;
      const role = {
        roleTitle,
        category: "gen",
      };
      global.data.roles.roleData.push(role);
      global.lastEdit = Date.now();
    },
    removeFromGlobalRoles: (global, action) => {
      const { index } = action.payload;
      global.data.roles.roleData.splice(index, 1);
      global.lastEdit = Date.now();
    },
    updateRoleCategory: (global, action) => {
      const { category, index } = action.payload;
      global.data.roles.roleData[index].category = category;
      global.lastEdit = Date.now();
    },
    reorderGlobalRoles: (global, action) => {
      const { newIndex, originalIndex } = action.payload;
      const [entry] = global.data.roles.roleData.splice(originalIndex, 1);
      global.data.roles.roleData.splice(newIndex, 0, entry);
      global.lastEdit = Date.now();
    },

    updateGlobalOutput: (global, action) => {
      const { value, key } = action.payload;
      global.data.outputs[key] = value;
      global.lastEdit = Date.now();
    },

    apiSuccess: (global, action) => {
      global.loading = false;
      reducerNotification("info", action.payload.message);
      global.lastSent = Date.now();
    },
    apiFailed: (global, _) => {
      global.loading = false;
      reducerNotification("error", "Global failed");
    },
  },
});

export const {
  globalDataRequested,
  globalDataRequestFailed,
  globalDataReceived,
  addToGlobalList,
  removeFromGlobalList,
  setGlobalDefault,
  updateGlobalValue,
  reorderGlobalList,
  updateRiskTemplate,

  updateProbability,
  updateConsequence,
  updatePostProbability,
  updatePostConsequence,
  sortTemplates,
  runRiskCalcs,
  addRiskTemplate,
  deleteRiskTemplate,

  updateTaskTemplate,
  deleteTaskTemplate,
  addTaskTemplate,
  sortTaskTemplates,

  addToGlobalRoles,
  removeFromGlobalRoles,
  updateRoleCategory,
  reorderGlobalRoles,
  updateGlobalOutput,
} = slice.actions;

const { apiSuccess, apiFailed } = slice.actions;

export default slice.reducer;

// Action Creators

const url = "/global";

export const loadGlobalData = () => (dispatch, getState) => {
  const { lastFetch } = getState().entities.global;
  // console.log("fetching global data...");
  if (recentFetch(lastFetch)) return;

  dispatch(
    apiCallBegan({
      url,
      method: "get",
      data: null,
      onStart: globalDataRequested.type,
      onSuccess: globalDataReceived.type,
      onError: globalDataRequestFailed.type,
    })
  );
};

export const sendUpdatedGlobalData = () => (dispatch, getState) => {
  const { lastEdit, lastSent, data } = getState().entities.global;
  const { editGlobal } = getState().user;
  // console.log(lastEdit, lastSent);
  if (!editGlobal) return toastNotification("error", "Changes not being saved");
  if (lastSent >= lastEdit) return;
  dispatch(
    apiCallBegan({
      url: url,
      method: "put",
      data,
      // onStart: sendingDeadlines.type,
      onSuccess: apiSuccess.type,
      onError: apiFailed.type,
    })
  );
};

// export const addUser = (user) =>
//   apiCallBegan({
//     url,
//     method: "post",
//     data: user,
//     onStart: sendNewUser.type,
//     onSuccess: userAdded.type,
//     onError: usersRequestFailed.type,
//   });

// export const deleteUser = (userId) =>
//   apiCallBegan({
//     url,
//     method: "delete",
//     data: userId,
//     onStart: deleteUserRequest.type,
//     onSuccess: userDeleted.type,
//     onError: deleteUserFailed.type,
//   });

// Selectors

export const getGlobalProjectDefaults = createSelector(
  (state) => state.entities.global.data,
  (global) => {
    // console.log("getGlobalProjectDefaults");
    const { natureDefault, ipProtectionDefault } = global;
    const defaults = [
      { key: "nature", value: natureDefault },
      { key: "ipProtection", value: ipProtectionDefault },
    ];
    return defaults;
  }
);

export const getGlobalCompanyDefaults = createSelector(
  (state) => state.entities.global.data,
  (global) => {
    // console.log("getGlobalCompanyDefaults");
    const {
      orgTypeDefault,
      orgSizeDefault,
      fundingLevelDefault,
      matchFundingSourceDefault,
    } = global;
    const defaults = [
      { key: "organisationType", value: orgTypeDefault },
      { key: "organisationSize", value: orgSizeDefault },
      { key: "fundingLevel", value: fundingLevelDefault },
      { key: "matchFundingSource", value: matchFundingSourceDefault },
      { key: "partnerFunding", value: "Yes" },
    ];
    return defaults;
  }
);

export const getAllTemplatesByType = createSelector(
  (state) => state.entities.global.data,
  (global) => {
    const { riskTemplates } = global;
    const separatedTemplates = {
      managerial: riskTemplates.riskData.filter(
        (risk) => risk.riskType === "managerial"
      ),
      commercial: riskTemplates.riskData.filter(
        (risk) => risk.riskType === "commercial"
      ),
      legal: riskTemplates.riskData.filter((risk) => risk.riskType === "legal"),
      technical: riskTemplates.riskData.filter(
        (risk) => risk.riskType === "technical"
      ),
      environmental: riskTemplates.riskData.filter(
        (risk) => risk.riskType === "environmental"
      ),
    };
    return separatedTemplates;
  }
);
