import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addAllocation,
  deleteAllocation,
  getAllocationsByTaskId,
  updateAllocation,
  closeResourcesInput,
  toggleCloseResources,
} from "../../store/entities/allocations";
import styled from "styled-components";
import {
  generateArray,
  handleSendAllocations,
  wpInfoColor,
} from "../../helpers";
import question from "../../images/qMark.png";
import closeIcon from "../../images/close-grey.png";
import Tippy from "@tippy.js/react";

function ResourcesInput(props) {
  const dispatch = useDispatch();
  const { all, taskIds } = props;
  const { resourcesInput } = useSelector((state) => state.entities.allocations);
  const { taskId, personId, position, closeInput } = resourcesInput;
  const allocations = useSelector(getAllocationsByTaskId);

  const margin = 45 * position + "px";
  const options = generateArray(0, 100, 5);

  function sendNumber(value, taskId) {
    const percent = parseInt(value);
    const { allocationId } = allocations[taskId][personId];
    if (allocationId === "new")
      dispatch(
        addAllocation({
          taskId,
          personId,
          value: percent,
        })
      );
    else if (percent === 0) dispatch(deleteAllocation({ allocationId }));
    else dispatch(updateAllocation({ allocationId, value }));
  }

  function handleClick(value) {
    if (all) {
      taskIds.forEach((taskId) => {
        sendNumber(value, taskId);
      });
    } else sendNumber(value, taskId);
    handleSendAllocations();
  }

  function toggleClose() {
    dispatch(toggleCloseResources());
  }

  function close() {
    dispatch(closeResourcesInput());
  }

  return (
    <Container margin={margin} closeInput={closeInput}>
      <div className="questionMark">
        <Tippy content="Enter a specific contribution percentage using the input box and press ok, or quickly select a value from the table">
          <img src={question} alt="info" />
        </Tippy>
      </div>
      <div className="numbers">
        {options.map((number, index) => {
          return (
            <div key={index} className="buttonContainer">
              <button onClick={() => handleClick(number)} key={index}>
                {number}
              </button>
            </div>
          );
        })}
        <div className="close" onClick={close}>
          <img src={closeIcon} alt="close" />
        </div>
      </div>
      <div className="rememberClose">
        <Tippy content="Toggle this to choose whether the input box closes after use">
          <button onClick={toggleClose} />
        </Tippy>
      </div>
    </Container>
  );
}

export default ResourcesInput;

const Container = styled.div`
  display: flex;
  position: relative;
  /* padding-bottom: 10px; */
  border-radius: 6px;
  /* transition: margin 0.3s; */
  margin-left: calc(175px + ${(props) => props.margin});
  /* border: 1px solid red; */
  .close {
    position: absolute;
    bottom: 0;
    right: 5px;
    cursor: pointer;
  }
  .rememberClose {
    position: absolute;
    top: 25px;
    right: -20px;
    max-height: 20px;
    max-width: 20px;
    button {
      height: 18px;
      width: 18px;
      border: 1px solid ${wpInfoColor};
      background-color: ${(props) => (props.closeInput ? wpInfoColor : null)};
    }
  }
  .questionMark {
    position: absolute;
    top: 5px;
    right: -20px;
    max-height: 20px;
    max-width: 20px;
  }
  .numbers {
    display: flex;
    flex-wrap: wrap;
    width: 210px;
    border-radius: 6px;
    padding: 5px;
    background-color: ${wpInfoColor};
    .buttonContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      /* border: 1px solid red; */
      width: 40px;
      height: 30px;
    }
    button {
      width: 35px;
      height: 25px;
      background-color: ${wpInfoColor};
      transition: all 0.3s;
      color: rgb(240, 240, 240);
      :hover {
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
  }
`;
