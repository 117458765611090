import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateUserSelection } from "../store/user";
import LeftMenu from "../components/table/leftMenu";
import { TableContainer } from "../components/table/tableStyling";
import { rev2, settingsColor, settingsFontColor } from "../helpers";
import Project from "../components/setup/projectOptions";
import Costs from "../components/setup/costsOptions";
import Team from "../components/setup/teamOptions";
import Risks from "../components/setup/riskOptions";
import EditRiskTemplates from "../components/admin/EditRiskTemplates";
import EditTaskTemplates from "../components/admin/EditTaskTemplates";
import OutputTable from "../components/output/OutputTable";
import Spinner from "../components/spinner";
import EmailTable from "../components/email/EmailTable";

function SetupTable() {
  const dispatch = useDispatch();
  const status = useSelector(
    (state) => state.entities.project.data.status.setup
  );

  const { selectedAdminOption, projectDataLoading } = useSelector(
    (state) => state.user // check this
  );
  const menuData = {
    section: "Setup",
    status,
    menuList: [
      "Details",
      "Team",
      "Finance",
      "Risks",
      "Risk Templates",
      "Task Templates",
    ],
    selectedOption: selectedAdminOption,
    color: settingsFontColor,
    backgroundColor: settingsColor,
    selectedAdminOption,
    updateOption: function (value) {
      dispatch(updateUserSelection({ key: "selectedAdminOption", value }));
    },
  };
  if (rev2) menuData.menuList.push("Outputs", 'Email');
  const tableTop = "25px";
  const tableHeight = "250px";

  const maxHeight = {
    company: null,
    project: null,
    options: null, // min height applies
  };

  const data = {
    backgroundColor: menuData.backgroundColor,
    maxHeight: maxHeight[selectedAdminOption],
  };

  const table = {
    "risk templates": <EditRiskTemplates />,
    "task templates": <EditTaskTemplates />,
    details: <Project />,
    team: <Team />,
    finance: <Costs />,
    risks: <Risks />,
    outputs: <OutputTable />,
    email: <EmailTable />
  };

  return projectDataLoading ? (
    <Spinner />
  ) : (
    <TableContainer data={data} tableTop={tableTop} tableHeight={tableHeight}>
      <div className="displayArea">
        <LeftMenu data={menuData} />
        <div className="content">
          {/* {status ? <MarkedComplete /> : null} */}
          {table[selectedAdminOption]}
        </div>
      </div>
    </TableContainer>
  );
}
export default SetupTable;
