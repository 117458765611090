import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { capitaliseFirstLetter } from "../../helpers";
import { getProjectDates } from "../../store/entities/project";

export default function OutputDLSummary(props) {
  const { type } = props;
  const list = useSelector((state) => state.entities.deadlines.data).filter(
    (deadline) => deadline.type === type
  );
  const dateList = useSelector(getProjectDates);
  const title = capitaliseFirstLetter(type) + "s";

  return (
    <Container>
      <h3 className="title">{title}</h3>
      <ul>
        {list.map((item, index) => {
          return (
            <div key={index} className="person">
              <li>
                <span className="bold">
                  {title.slice(0, 1)}
                  {index + 1}
                </span>
                <span>
                  {" "}
                  - {item.description} ({dateList[item.scheduled]}).
                </span>
              </li>
            </div>
          );
        })}
      </ul>
    </Container>
  );
}

const Container = styled.div`
`;
