import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import OutputSection from "./OutputSection";
import { getSelectedProject } from "../../store/user";

export default function OutputCostOverview(props) {
  const { mapObj, edit } = props;
  const { partners } = useSelector(getSelectedProject);
  const leadCompany = useSelector(
    (state) => state.entities.project.data.lead.companyAcronym
  );
  const pOneCompany = useSelector(
    (state) => state.entities.project.data.pOne.companyAcronym
  );
  const pTwoCompany = useSelector(
    (state) => state.entities.project.data.pTwo.companyAcronym
  );
  return (
    <Container>
      <h3 className='title' >Overview</h3>
      <OutputSection
        objKey="costProject"
        subject="Project Cost"
        mapObj={mapObj}
        edit={edit}
      />
      {partners === 1 ? (
        <OutputSection
          objKey="costRequestP1"
          subject="Financial Request"
          mapObj={mapObj}
          edit={edit}
        />
      ) : null}
      {partners > 1 ? (
        <div className="section">
          <p className="bold heading">Cost Distribution and Grant Requested:</p>
          <ul className="companies">
            <li>
              <OutputSection
                objKey="costRequestP1"
                subject={leadCompany}
                mapObj={mapObj}
                edit={edit}
              />
            </li>
            <li>
              <OutputSection
                objKey="costRequestP2"
                subject={pOneCompany}
                mapObj={mapObj}
                edit={edit}
              />
            </li>
            {partners > 2 ? (
              <li className="last">
                <OutputSection
                  objKey="costRequestP3"
                  subject={pTwoCompany}
                  mapObj={mapObj}
                  edit={edit}
                />
              </li>
            ) : null}
          </ul>
        </div>
      ) : null}

      <OutputSection
        objKey="costInnovate"
        subject="Innovate UK Funding Required"
        mapObj={mapObj}
        edit={edit}
      />
    </Container>
  );
}

const Container = styled.div`
  .heading {
    margin: 10px 0 5px 0;
  }
  .last {
    margin-bottom: 5px;
  }
`;
