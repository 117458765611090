import React from "react";
import styled from "styled-components";
import Tippy from "@tippy.js/react";
import { useSelector, useDispatch } from "react-redux";
import { navBackground } from "../../helpers/settings";
import bin from "../../images/bin-grey.png";
import key from "../../images/key.png";
import {
  deleteUser,
  sendUserProjects,
  userChangeProject,
} from "../../store/entities/users";
import { setShowComponent } from "../../store/user";
import ConfirmModal from "../modals/confirmModal";
import UserModal from "../modals/userModal";

function userCard(props) {
  const { user } = props;
  const { name, userId, email, admin, projects } = user;
  const dispatch = useDispatch();
  const { showComponent } = useSelector((state) => state.user);
  const allProjects = useSelector((state) => state.entities.setup.data);

  function handleDelete() {
    dispatch(deleteUser({ userId }));
  }

  function showModal(value) {
    dispatch(setShowComponent({ value }));
  }

  function handleChange(e) {
    const projectId = e.target.value;
    console.log(projectId);
    dispatch(userChangeProject({ userId, projectId }));
    dispatch(sendUserProjects(userId));
  }

  return (
    <Container admin={admin}>
      {showComponent === userId + "edit" ? <UserModal user={user} /> : null}
      {showComponent === userId + "del" ? (
        <ConfirmModal
          question={`Delete ${name}?`}
          comment="No project data will be deleted"
          confirm={handleDelete}
        />
      ) : null}
      <div className="person">
        <h3>{name}</h3>
        {admin ? (
          <p>Admin</p>
        ) : (
          <Tippy content="Delete user">
            <div className="delete hidden">
              <img
                src={bin}
                alt="delete"
                onClick={() => showModal(userId + "del")}
              />
            </div>
          </Tippy>
        )}
      </div>
      <div className="details">
        <p>{email}</p>
        <Tippy content="Edit user">
          <div className="reset hidden">
            <img
              src={key}
              alt="reset"
              onClick={() => showModal(userId + "edit")}
            />
          </div>
        </Tippy>
      </div>
      {admin ? null : (
        <div className="details list">
          <p className="title">Project:</p>
          <select
            onChange={handleChange}
            className="project"
            name="project"
            id="assign"
            value={projects[0]}
          >
            <option>Click here to choose project...</option>
            {allProjects.map((project, index) => {
              return (
                <option key={index} value={project.projectId}>
                  {project.projectDesc}
                </option>
              );
            })}
          </select>
        </div>
      )}
    </Container>
  );
}

export default userCard;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: ${(props) => (props.admin ? "290px" : "400px")};
  background-color: ${(props) => (props.admin ? "#b1b1b1" : "white")};
  margin: 15px;
  padding: ${(props) => (props.admin ? "10px 15px" : "20px")};
  box-shadow: 8px 8px 12px rgba(1, 1, 1, 0.5);
  border-radius: 6px;
  overflow: hidden;
  &:hover .hidden {
    opacity: 1;
  }
  .hidden {
    transition: opacity 0.3s;
    opacity: 0;
  }

  .person {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      font-weight: bold;
    }
  }
  .details {
    /* border: 1px solid red; */
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    /* background-color: white; */
    .title {
      margin-top: 10px;
      font-weight: bold;
    }
    .none {
      color: red;
      font-weight: bold;
    }
    p {
      margin-bottom: 5px;
    }
    /* border: 1px solid red; */
  }
  .list {
    flex-direction: column;
  }
  .project {
    display: flex;
    justify-content: space-between;
  }
  select.project {
    padding: 0;
  }
  .delete {
    max-width: 20px;
    max-height: 20px;
    cursor: pointer;
  }
  .reset {
    max-width: 20px;
    max-height: 20px;
    padding: 2px;
    cursor: pointer;
  }
  p {
    color: ${navBackground};
  }
  h3 {
    font-size: 16px;
    font-weight: 600;
    color: ${navBackground};
  }
`;
