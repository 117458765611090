import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleSendSetup } from "../../helpers";
import { updateSetup } from "../../store/entities/setup";
import { getSelectedProject, updateUserSelection } from "../../store/user";
import Global from "./globalIndicator";

function OptionsToggle(props) {
  const dispatch = useDispatch();
  const { title, field, value, multiple, global, classNames = "" } = props;
  const { projectId } = useSelector(getSelectedProject);

  const className = "field value toggle " + classNames;
  let content = value ? "Yes" : "No";
  if (field === "editTemplates") content = "Edit";

  function handleClick() {
    if (field === "editTemplates") {
      dispatch(
        updateUserSelection({ key: "showComponent", value: "editTemplates" })
      );
      return;
    }
    dispatch(updateSetup({ projectId, key: field, value: !value }));
    handleSendSetup();
  }

  const row = (
    <div className="row">
      {global ? <Global /> : null}
      <p className="field display description">{title}</p>
      <button id={field} className={className} onClick={handleClick}>
        {content}
      </button>
    </div>
  );

  const button = (
    <button id={field} className={className} onClick={handleClick}>
      {value ? "Yes" : "No"}
    </button>
  );

  if (multiple) return button;
  else return row;
}
export default OptionsToggle;
