import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { navBackground, sendNewProject } from "../../helpers";
import add from "../../images/add-white.png";

import Card from "./projectCard";
import Tippy from "@tippy.js/react";

function projects() {
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.entities.setup.data);
  function newProject() {
    sendNewProject(dispatch);
  }

  return (
    <Container>
      <div className="cards">
        {projects.map((project, index) => {
          return <Card project={project} key={index} />;
        })}
      </div>
      <Tippy content="Add a new project">
        <div className="add">
          <img src={add} alt="add" onClick={newProject} />
        </div>
      </Tippy>
    </Container>
  );
}
export default projects;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  width: 100%;
  /* border: 1px solid red; */
  .cards {
    margin-top: 15px;
    width: 90%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  h3,
  p {
    color: ${navBackground};
  }
  .add {
    margin-top: 20px;
    max-width: 50px;
    max-height: 50px;
    border-radius: 50%;
    box-shadow: 4px 4px 12px rgba(1, 1, 1, 0.5);
    cursor: pointer;
  }
`;
