import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleSendRevenue, isNumberKey, shortCurrency } from "../../helpers";
import {
  updateMarket,
  deleteMarket,
  getMarketData,
} from "../../store/entities/revenue";
import bin from "../../images/bin-grey.png";
import tick from "../../images/white-tick.png";
import dropdown2 from "../../images/dropArrow2.png";
import TargetMarketModal from "../modals/targetMarketModal";
import { getSelectedProject, setShowComponent } from "../../store/user";

function TargetMarketRow(props) {
  let { name, start, growth } = props.market;
  const { index } = props;
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const market = useSelector(getMarketData)[name];
  const { showComponent } = state.user;
  const modalName = "modal" + name;
  const { marketOptions } = useSelector(getSelectedProject);

  const [temp, setTemp] = useState(growth);

  function onChangeHandler(e) {
    const key = e.target.name;
    let value = e.target.value;
    if (key === "growth") {
      if (e.target.value) {
        setTemp(value);
      } else value = 0;
    } else {
      if (key === "start") {
        if (e.target.value) {
          value = parseInt(value.slice(-12));
        } else value = 0;
      }
      dispatch(updateMarket({ index, key, value }));
    }
  }

  function showModal(value) {
    dispatch(setShowComponent({ value }));
  }

  function closeModal() {
    if (growth !== temp) {
      const value = parseFloat(temp);
      dispatch(updateMarket({ index, key: "growth", value }));
    }
    dispatch(setShowComponent({ value: "" }));
    handleSendRevenue();
  }

  return (
    <div className="row">
      {showComponent === modalName ? (
        <TargetMarketModal name={name} index={index} />
      ) : null}
      <div className="relative">
        {name === "UK Market" || name === "Global" ? (
          <p className="field market display">{name}</p>
        ) : (
          <>
            <select
              id={index + "name"}
              name="name"
              value={name}
              onChange={onChangeHandler}
              className="field market"
            >
              <option hidden value="">
                Select option
              </option>
              {marketOptions.map((market, index) => {
                return (
                  <option key={index} value={market}>
                    {market}
                  </option>
                );
              })}
            </select>
            <div className="dropdown">
              <img src={dropdown2} alt="option" />
            </div>
          </>
        )}
      </div>

      {showComponent === `marketValue${index}` ? (
        <div className="setMarket">
          <input
            autoFocus
            id={name + "year"}
            name="start"
            value={start || ""}
            onKeyDown={isNumberKey}
            onChange={onChangeHandler}
            className="field year"
          />
          <div className="accept">
            <button className="textButton" onClick={closeModal}>
              <img src={tick} alt="accept" />
            </button>
          </div>
        </div>
      ) : (
        <p
          onClick={() => showModal(`marketValue${index}`)}
          className="field display year edit"
        >
          {shortCurrency(market.y1)}
        </p>
      )}

      <p className="field display year">{shortCurrency(market.y2)}</p>
      <p className="field display year">{shortCurrency(market.y3)}</p>
      <p className="field display year">{shortCurrency(market.y4)}</p>
      <p className="field display year">{shortCurrency(market.y5)}</p>

      {showComponent === `cagr${index}` ? (
        <div className="setMarket">
          <input
            autoFocus
            id={index + "growth"}
            name="growth"
            value={temp || 0}
            onChange={onChangeHandler}
            onBlur={handleSendRevenue}
            className="field year cagr"
          />
          <div className="accept">
            <button className="textButton" onClick={closeModal}>
              <img src={tick} alt="accept" />
            </button>
          </div>
        </div>
      ) : (
        <div className="cagr">
          <p
            onClick={() => showModal(`cagr${index}`)}
            className="field year value"
          >
            {growth}
          </p>
          <p className="percent">%</p>
        </div>
      )}

      {/* <div className="cagr">
        <input
          id={index + "growth"}
          name="growth"
          value={growth || 0}
          onChange={onChangeHandler}
          onBlur={handleSendRevenue}
          className="field year cagr"
        />
        <p className="percent">%</p>
      </div> */}

      {showComponent === `cagr${index}` ? null : (
        <button onClick={() => showModal(modalName)} className="textButton">
          Source
        </button>
      )}

      {index === 0 || name === "Global" ? null : (
        <div className="hidden deleteIcon">
          <img
            src={bin}
            alt="delete"
            style={{ cursor: "pointer" }}
            onClick={() => dispatch(deleteMarket({ index }))}
          />
        </div>
      )}
    </div>
  );
}
export default TargetMarketRow;
