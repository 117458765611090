import React from "react";
import Tippy from "@tippy.js/react";
import question from "../../images/qMark.png";

export const risksTitles = <div className="titles">
  <div className="field text title">
    <p>Description</p>
  </div>
  <div className="field toggle title">
    <p>Likelihood</p>
    <Tippy content="Pre-Mitigation">
      <div className="tippyImage">
        <img src={question} alt="info" />
      </div>
    </Tippy>
  </div>
  <div className="field toggle title">
    <p>Severity</p>
    <Tippy content="Pre-Mitigation">
      <div className="tippyImage closer">
        <img src={question} alt="info" />
      </div>
    </Tippy>
  </div>
  <div className="field text title miti">
    <p>Mitigation</p>
  </div>
  <div className="field toggle title">
    <p>Likelihood</p>
    <Tippy content="Post-Mitigation">
      <div className="tippyImage">
        <img src={question} alt="info" />
      </div>
    </Tippy>
  </div>
  <div className="field toggle title">
    <p>Severity</p>
    <Tippy content="Post-Mitigation">
      <div className="tippyImage closer">
        <img src={question} alt="info" />
      </div>
    </Tippy>
  </div>
  <p className="field toggle input title end">Owner</p>
</div>;
