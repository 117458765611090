import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "../api";
import {
  recentFetch,
  reducerNotification,
  rev2,
  toastNotification,
} from "../../helpers";

const slice = createSlice({
  name: "users",
  initialState: {
    loading: false,
    lastFetch: 0,
    data: [],
    error: "",
  },
  reducers: {
    usersRequested: (users, _) => {
      users.loading = true;
    },
    usersRequestFailed: (users, action) => {
      users.loading = false;
      reducerNotification("error", action.payload);
    },
    usersReceived: (users, action) => {
      users.data = action.payload;
      users.loading = false;
      users.lastFetch = Date.now();
    },

    sendNewUser: (users, _) => {
      users.loading = true;
    },
    userSendingFailed: (users, action) => {
      users.loading = false;
      toastNotification("error", action.payload);
    },
    userAdded: (users, action) => {
      users.loading = false;
      users.data.push(action.payload.data);
      // reducerNotification("info", "User saved");
    },

    updateUser: (users, action) => {
      const { user } = action.payload;
      const { userId } = user;
      const index = users.data.findIndex((user) => user.userId === userId);
      users.data.splice(index, 1, user);
    },

    userAddProject: (users, action) => {
      const { userId, projectId } = action.payload;
      const index = users.data.findIndex((user) => user.userId === userId);
      users.data[index].projects.push(projectId);
      users.data[index].projectId = projectId;
    },

    userChangeProject: (users, action) => {
      const { userId, projectId } = action.payload;
      const index = users.data.findIndex((user) => user.userId === userId);
      users.data[index].projects = [projectId];
      users.data[index].projectId = projectId;
    },

    userRemoveProject: (users, action) => {
      const { userId, projectId } = action.payload;
      const userIndex = users.data.findIndex((user) => user.userId === userId);
      const projectIndex = users.data[userIndex].projects.indexOf(projectId);
      if (projectIndex !== -1) {
        users.data[userIndex].projects.splice(projectIndex, 1);
      }
      if (users.data[userIndex].projects.length > 0)
        users.data[userIndex].projectId = users.data[userIndex].projects[0];
      else users.data[userIndex].projectId = "";
    },

    userUpdated: (users, action) => {
      users.loading = false;
      const user = action.payload.data;
      const { userId } = user;
      const index = users.data.findIndex((user) => user.userId === userId);
      users.data.splice(index, 1, user);
      // reducerNotification("info", "User saved");
    },

    updateUserColor: (users, action) => {
      const { userId, color } = action.payload;
      const index = users.data.findIndex((user) => user.userId === userId);
      users.data[index].color = color;
      users.lastEdit = Date.now();
    },

    deleteUserRequest: (users, action) => {
      users.loading = true;
    },
    userDeleted: (users, action) => {
      const { userId } = action.payload;
      const index = users.data.findIndex((user) => user.userId === userId);
      users.data.splice(index, 1);
    },

    apiFailed: (users, action) => {
      users.loading = false;
      reducerNotification("error", action.payload.message);
    },
    emailSuccess: (users, action) => {
      users.loading = false;
      reducerNotification("info", action.payload.message);
    },
    emailFailed: (users, action) => {
      users.loading = false;
      reducerNotification(
        "error",
        "Server email system failed. Contact WAX administration"
      );
    },
  },
});
export default slice.reducer;

export const {
  usersRequested,
  usersReceived,
  usersRequestFailed,
  userAdded,
  apiFailed,
  sendNewUser,
  updateUser,
  userAddProject,
  userRemoveProject,
  deleteUserRequest,
  userDeleted,
  updateUserColor,
  userChangeProject, // remove on rev2
} = slice.actions;

const { userUpdated, emailSuccess, emailFailed } = slice.actions;

// Action Creators
const url = "/users";

export const loadUsers = () => (dispatch, getState) => {
  const { lastFetch } = getState().entities.users;
  // console.log("fetching users...");
  if (recentFetch(lastFetch)) return;

  dispatch(
    apiCallBegan({
      url,
      method: "get",
      data: null,
      onStart: usersRequested.type,
      onSuccess: usersReceived.type,
      onError: usersRequestFailed.type,
    })
  );
};

export const addNewUser = (data) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url,
      method: "post",
      data,
      onStart: sendNewUser.type,
      onSuccess: userAdded.type,
      onError: apiFailed.type,
    })
  );
};

export const sendUpdatedUser = (userId) => (dispatch, getState) => {
  const { data } = getState().entities.users;
  const index = data.findIndex((user) => user.userId === userId);
  const user = data[index];

  dispatch(
    apiCallBegan({
      url,
      method: "put",
      data: user,
      // onStart: sendNewUser.type,
      onSuccess: userUpdated.type,
      onError: apiFailed.type,
    })
  );
};

// export const sendUserColor = (userId) => (dispatch, getState) => {
//   const { data } = getState().entities.users;
//   const index = data.findIndex((user) => user.userId === userId);
//   const { color } = data[index];

//   dispatch(
//     apiCallBegan({
//       url: url + "/color",
//       method: "put",
//       data: { color, userId },
//       // onStart: sendNewUser.type,
//       onSuccess: userUpdated.type,
//       onError: apiFailed.type,
//     })
//   );
// };

export const sendUserProjects = (userId) => (dispatch, getState) => {
  const { data } = getState().entities.users;
  const index = data.findIndex((user) => user.userId === userId);
  const { projects, projectId } = data[index];

  dispatch(
    apiCallBegan({
      url: url + "/projects",
      method: "put",
      data: { projects, userId, projectId },
      // onStart: sendNewUser.type,
      onSuccess: userUpdated.type,
      onError: apiFailed.type,
    })
  );
};

export const deleteUser = (userId) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url,
      method: "delete",
      data: userId,
      onStart: deleteUserRequest.type,
      onSuccess: userDeleted.type,
      onError: apiFailed.type,
    })
  );
};

export const sendEmail =
  ({ email, subject, content }) =>
  (dispatch, getState) => {
    if (!rev2) return;
    dispatch(
      apiCallBegan({
        url: "/email",
        method: "post",
        data: { email, subject, content },
        // onStart: deleteUserRequest.type,
        onSuccess: emailSuccess.type,
        onError: emailFailed.type,
      })
    );
  };

// Selectors

export const getUsersByEmail = createSelector(
  (state) => state.entities.users,
  (users) => {
    // console.log("getUsersByEmail");
    const result = {
      none: "none",
    };
    users.data.forEach(({ userId, projects, name, email }) => {
      result[email] = {};
      result[email].userId = userId;
      result[email].projects = projects;
      result[email].name = name;
    });
    return result;
  }
);

export const getUsersByProjectId = createSelector(
  (state) => state.entities.users.data,
  (state) => state.entities.setup.data,
  (users, projectList) => {
    const result = {};
    projectList.forEach(({ projectId }) => {
      result[projectId] = [];
    });
    users.forEach(({ projects, admin, userId, name }) => {
      if (!admin) {
        projects.forEach((projectId) => {
          if (result[projectId]) result[projectId].push(userId);
          else console.log(name, "has a deleted project");
        });
      }
    });
    return result;
  }
);

export const getUsersById = createSelector(
  (state) => state.entities.users.data,
  (users) => {
    const result = {};
    users.forEach(({ name, userId }) => {
      result[userId] = name;
    });
    return result;
  }
);
