import React from "react";
import styled from "styled-components";
import OutputSection from "./OutputSection";
import { menuPurpleColor } from "../../helpers";

export default function EmailAccounts(props) {
  const { edit, mapObj } = props;

  return (
    <Container>
      <h3>New Account</h3>
      <OutputSection
        source="global"
        objKey="newClientSubject"
        subject="Subject:"
        mapObj={mapObj}
        edit={edit}
        small={true}
      />
      <OutputSection
        source="global"
        objKey="newClientBody"
        subject="Body:"
        mapObj={mapObj}
        edit={edit}
      />
      <h3>Reset Password</h3>
      <OutputSection
        source="global"
        objKey="resetPasswordSubject"
        subject="Subject:"
        mapObj={mapObj}
        edit={edit}
        small={true}
      />
      <OutputSection
        source="global"
        objKey="resetPasswordBody"
        subject="Body:"
        mapObj={mapObj}
        edit={edit}
      />
      <h3>Removed Account</h3>
      <OutputSection
        source="global"
        objKey="removedClientSubject"
        subject="Subject:"
        mapObj={mapObj}
        edit={edit}
        small={true}
      />
      <OutputSection
        source="global"
        objKey="removedClientBody"
        subject="Body:"
        mapObj={mapObj}
        edit={edit}
      />
    </Container>
  );
}

const Container = styled.div`
  .heading {
    margin: 10px 0 5px 0;
  }
  .last {
    margin-bottom: 5px;
  }
  h3 {
    color: ${menuPurpleColor};
  }
`;
