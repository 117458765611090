import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Tippy from "@tippy.js/react";
import "tippy.js/dist/tippy.css";
// import { BiDotsHorizontalRounded } from "react-icons/bi";
import {
  generateArray,
  handleSendTasks,
  isNumberKey,
  rev2,
  toastNotification,
} from "../../helpers";
// import EditModal from "../modals/ganttEditModal";
import ResourcesModal from "../modals/ganttResourcesModal";
import {
  updateTaskKey,
  updateNumberOfBars,
  spreadWork,
  getNumberOfBars,
  getCombinedLengthOfBars,
} from "../../store/entities/tasks";
import tick from "../../images/tick-grey.png";
import bin from "../../images/bin-grey.png";
import warningImg from "../../images/warning.png";
import reorder from "../../images/reorder.png";
import { Container } from "./ganttRowStyling";
import { updateUserSelection } from "../../store/user";
import { getAllocationsByTaskId } from "../../store/entities/allocations";
import { handleDeleteTask } from "../../helpers/handleDeleteTask";

function GanttTaskRowInfo(props) {
  const dispatch = useDispatch();
  const { task, provided, packData } = props;
  const { description, days, taskId, bars } = task;
  const showComponent = useSelector((state) => state.user.showComponent);
  const resources = useSelector(getAllocationsByTaskId);
  const numberOfBars = useSelector(getNumberOfBars)[taskId];
  const combinedLength = useSelector(getCombinedLengthOfBars)[taskId];
  const teamLength = useSelector((state) => state.entities.team.data.length);
  const { completion } = useSelector(getAllocationsByTaskId)[taskId];
  const warning = completion === 100 || completion === 0 ? true : false;
  const { projectLength } = useSelector(
    (state) => state.entities.project.data.details
  );
  const barLimit = Math.ceil(projectLength / 2);
  const barOptions = generateArray(1, barLimit, 1);

  const buttonContent =
    !warning && rev2 ? (
      <Tippy content="Incomplete. Click to edit">
        <img src={warningImg} alt="warning" />
      </Tippy>
    ) : (
      resources[taskId].people
    );

  const [newDays, setNewDays] = useState(days);

  function handleDescriptionChange(value) {
    dispatch(
      updateTaskKey({
        taskId,
        key: "description",
        value,
      })
    );
  }

  function handleDayChange(e) {
    if (e.target.value) {
      const lastThreeNumbers = e.target.value.slice(-3);
      setNewDays(parseInt(lastThreeNumbers));
    } else {
      setNewDays(1);
    }
  }

  function handleBars(e) {
    const newBars = e.target.value;
    if (newBars) {
      dispatch(updateNumberOfBars({ taskId, newBars: parseInt(newBars) }));
      toastNotification("info", "Days reset due to changing bars");
    }
    handleSendTasks();
  }

  function acceptNewDays() {
    updateUser("showComponent", "");
    // console.log(newDays);
    if (newDays >= numberOfBars) {
      if (newDays !== days) {
        dispatch(updateTaskKey({ taskId, key: "days", value: newDays }));
        if (newDays < combinedLength) {
          toastNotification("info", "Bar lengths reset due to too few days");
          dispatch(updateNumberOfBars({ taskId, newBars: numberOfBars }));
          dispatch(spreadWork({ taskId, combinedLength: numberOfBars }));
        } else dispatch(spreadWork({ taskId, combinedLength }));
        handleSendTasks();
      }
    } else toastNotification("info", "Days cannot be less than number of bars");
  }

  function updateUser(key, value) {
    dispatch(updateUserSelection({ key, value }));
  }

  function setResources() {
    if (teamLength === 0)
      return toastNotification("info", "Add team members to project");
    updateUser("showComponent", taskId + "resources");
  }

  function deleteTask() {
    handleDeleteTask(dispatch, taskId);
  }

  useEffect(() => {
    setNewDays(days);
  }, [days]);

  return (
    <Container>
      {showComponent === taskId + "resources" ? (
        <ResourcesModal packData={packData} />
      ) : null}

      <Tippy content="Drag to reorder tasks">
        <div {...provided.dragHandleProps} className="hidden drag">
          <img src={reorder} alt="reorder" />
        </div>
      </Tippy>

      <input
        className="field description wider packBackground"
        value={description}
        type="text"
        onChange={(e) => handleDescriptionChange(e.target.value)}
        onBlur={handleSendTasks}
      />

      <button onClick={setResources} className="resources field packBackground">
        {buttonContent}
      </button>
      <select
        name="bars"
        className="bars field packBackground"
        onChange={handleBars}
        value={bars}
      >
        {barOptions.map((bar, index) => {
          return (
            <option key={index} value={bar}>
              {bar}
            </option>
          );
        })}
      </select>

      {showComponent === taskId + "days" ? (
        <div className="editDays">
          <input
            autoFocus
            className="days field packBackground"
            type="text"
            value={newDays}
            onKeyDown={(e) => isNumberKey(e)}
            onChange={(e) => handleDayChange(e)}
          />
          {/* <button className="accept"> */}
          <button onClick={acceptNewDays} className="accept">
            <img id="accept" src={tick} alt="accept" />
          </button>
        </div>
      ) : (
        <button
          className="days field packBackground"
          onClick={() => updateUser("showComponent", taskId + "days")}
        >
          {days}
        </button>
      )}

      <div className="delete hidden">
        {packData.length === 1 ? null : (
          <button className="">
            <img src={bin} alt="delete" onClick={deleteTask} />
          </button>
        )}
      </div>
    </Container>
  );
}

export default GanttTaskRowInfo;
