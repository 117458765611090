import { store } from "../store";
import {
  sendUpdatedDetails,
  updateAvailableTemplates,
} from "../store/entities/project";
import { sendUpdatedSetup, updateProject } from "../store/entities/setup";

export function handleClientFlavour(clientFlavour) {
  const { selectedProjectId } = store.getState().user;
  const allTemplates =
    store.getState().entities.global.data.riskTemplates.riskData;
  const allRoles = store.getState().entities.global.data.roles.roleData;
  const allTasks = store.getState().entities.global.data.tasks.taskData;

  let templates = [];
  let roles = [];
  let tasks = [];

  if (clientFlavour === "Review Plus") {
    const count = 5;
    for (let i = 0; i < allTemplates.length; i++) {
      if (allTemplates[i].category === "gen")
        templates.push(allTemplates[i]);
      if (templates.length === count) break;
    }
    for (let i = 0; i < allRoles.length; i++) {
      if (allRoles[i].category === "gen") roles.push(allRoles[i]);
      if (roles.length === count) break;
    }
    for (let i = 0; i < allTasks.length; i++) {
      if (allTasks[i].category === "gen") tasks.push(allTasks[i]);
      if (tasks.length === count) break;
    }
  }

  if (clientFlavour === "Full Write") {
    templates = allTemplates;
    roles = allRoles;
    tasks = allTasks;
  }

  store.dispatch(
    updateProject({
      projectId: selectedProjectId,
      key: "clientFlavour",
      value: clientFlavour,
    })
  );
  store.dispatch(updateAvailableTemplates({ templates, roles, tasks }));
  store.dispatch(sendUpdatedDetails());
  store.dispatch(sendUpdatedSetup());
}
