import React from "react";
import styled from "styled-components";
import OutputSection from "./OutputSection";

export default function OutputRisksManagement(props) {
  const { mapObj, edit } = props;

  return (
    <Container>
      <h3 className='title' >Overview</h3>
      <OutputSection
        objKey="riskIntro"
        subject="Risk Management"
        mapObj={mapObj}
        edit={edit}
      />
    </Container>
  );
}

const Container = styled.div``;
