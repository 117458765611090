import React from "react";
import { useSelector } from "react-redux";
import { getWorkPackageIds } from "../../store/entities/tasks";
import styled from "styled-components";
import AssignmentTotal from "./assignmentTotal";

export default function (props) {
  const workPackageIds = useSelector(getWorkPackageIds);
  const { heading } = props;

  return (
    <Data>
      <div className="title totalCost">
        <h3>{heading}</h3>
      </div>
      {workPackageIds.map((packId, index) => {
        return <AssignmentTotal key={index} packId={packId} />;
      })}
    </Data>
  );
}

const Data = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .wpData {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
  }
`;
