import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { fontColorGrey, menuBlueColor, settingsColor } from "../../helpers";
import { getSelectedProject, updateUserSelection } from "../../store/user";

export default function OutputChangeSource() {
  const dispatch = useDispatch();
  const { globalSettings, admin } = useSelector((state) => state.user);
  const { projectDesc } = useSelector(getSelectedProject);

  function toggle() {
    dispatch(
      updateUserSelection({
        key: "globalSettings",
        value: !globalSettings,
      })
    );
  }

  return admin ? (
    <Container onClick={toggle}>
      <h3 className="text">You are editing the&nbsp;</h3>
      {globalSettings ? (
        <h3 className="globalColor">Global</h3>
      ) : (
        <h3 className="projectColor">{projectDesc}</h3>
      )}
      <h3 className="text">&nbsp;output. (click to change)</h3>
    </Container>
  ) : null;
}

const Container = styled.div`
  position: sticky;
  display: flex;
  cursor: pointer;

  .text {
    font-weight: initial;
    color: ${fontColorGrey};
  }
  .projectColor {
    color: ${menuBlueColor};
  }
  .globalColor {
    color: ${settingsColor};
  }
`;
