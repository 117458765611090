import React from "react";
import { useSelector } from "react-redux";
import { getAllocationsByTaskId } from "../../store/entities/allocations";
import GanttResourcesButton from "./ganttResourcesButton";
import styled from "styled-components";
import { tableInputUnderline, wpInfoColor } from "../../helpers";
import GanttResourcesInput from "./ganttResourcesInput";
import Tippy from "@tippy.js/react";

function ResourcesRow(props) {
  const { allPeople, task, updateResourceAllocation } = props;
  const resources = useSelector(getAllocationsByTaskId);
  const { taskId } = useSelector((state) => state.entities.allocations.resourcesInput);
  const selected = taskId === task.taskId;

  const completion = resources[task.taskId].completion;
  const complete =
    completion === 100
      ? "ok total"
      : completion < 100
      ? "under total"
      : "over total";

  return (
    <Container selected={selected}>
      <div className="modalRow">
        <Tippy content={task.description}>
          <p className="description">{task.description}</p>
        </Tippy>
        {allPeople.map((person, index) => {
          return (
            <GanttResourcesButton
              updateResourceAllocation={updateResourceAllocation}
              person={person}
              key={index}
              task={task}
              index={index}
            />
          );
        })}
        <p className={complete}>{completion}%</p>
      </div>
      <div className="inputSpace">
        {taskId === task.taskId ? (
          <GanttResourcesInput
            task={task}
            updateResourceAllocation={updateResourceAllocation}
          />
        ) : null}
      </div>
    </Container>
  );
}

export default ResourcesRow;

export const Container = styled.div`
  .modalRow {
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 20px;
    /* border: 1px solid red; */
  }
  .description {
    width: 200px;
    min-width: 200px;
    max-width: 200px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .assignAll {
    color: red;
    border-top: 1px solid ${tableInputUnderline};
    /* border: 1px solid red; */
    margin-top: 7px;
    min-height: 55px;
    /* padding-top: 25px; */
    /* margin-bottom: 20px; */

    button {
      border: none;
      background-color: ${wpInfoColor};
      color: white;
      margin-left: 20px;
      padding: 5px 8px;
      font-size: 10px;
    }
  }
  .bold {
    color: ${wpInfoColor};
    font-weight: bold;
  }
  .increment {
    transition: height 0.3s;
    height: ${(props) => (props.showOptions ? "40px" : "0px")};
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    /* border: 1px solid red; */
    margin-top: -5px;
    margin-bottom: 5px;
  }
  .title {
    height: 50px;
    margin-bottom: 10px;
    background-color: ${wpInfoColor};
    color: white;
    padding: 0 20px;
    border-radius: 6px 6px 0px 0px;
  }
  .person {
    width: 35px;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }
  .select {
    border: 1px solid lightgray;
    cursor: pointer;
    :focus {
      border-color: ${wpInfoColor};
    }
  }
  .total {
    width: 50px;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    font-weight: 600;
  }
  .under {
    color: orange;
  }
  .ok {
    color: green;
  }
  .over {
    color: red;
  }
  img {
    max-width: 100%;
    max-height: 100%;
  }
  .inputSpace {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: rgb(240, 240, 240);
    height: ${(props) => (props.selected ? "160px" : 0)};
    overflow: hidden;
    transition: height 0.3s;
  }
`;
