import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import {
  addAllocation,
  deleteAllocation,
  getAllocationsByTaskId,
  updateAllocation,
  updateResourcesInput,
} from "../../store/entities/allocations";
import { handleSendAllocations, isNumberKey, wpInfoColor } from "../../helpers";

function ResourcesButton(props) {
  const dispatch = useDispatch();
  const { person, task, index, all, taskIds } = props;
  const resources = getAllocationsByTaskId(useSelector((state) => state));
  const value = all ? 0 : resources[task.taskId][person.personId].percent;
  const { resourcesInput } = useSelector((state) => state.entities.allocations);
  const { personId, taskId } = resourcesInput;
  const selectedPerson = personId === person.personId;
  const selectedInput = selectedPerson && taskId === task.taskId;
  const allocations = useSelector(getAllocationsByTaskId);

  function showInput() {
    dispatch(
      updateResourcesInput({
        personId: person.personId,
        taskId: all ? "all" : task.taskId,
        position: index,
      })
    );
  }

  const [newValue, setNewValue] = useState(value);
  function handleChange(e) {
    let value = e.target.value;
    console.log(value);
    if (!value) value = 0;
    else if (value !== "100") value = parseInt(value.slice(-2));
    if (value === "") value = 0;
    else value = parseInt(value);
    setNewValue(value);
    // dispatch(updateResourcesValue({ value }));
  }

  function handleUpdate(taskId) {
    const { allocationId } = allocations[taskId][person.personId];
    if (newValue === 0 || newValue === "")
      dispatch(deleteAllocation({ allocationId }));
    else if (allocationId === "new")
      dispatch(
        addAllocation({
          taskId,
          personId: person.personId,
          value: newValue,
        })
      );
    else dispatch(updateAllocation({ allocationId, value: newValue }));
  }

  function handleClick() {
    if (all) {
      taskIds.forEach((taskId) => {
        handleUpdate(taskId);
      });
    } else if (!value || value === newValue) return;
    else handleUpdate(task.taskId);
    handleSendAllocations();
  }

  useEffect(() => {
    setNewValue(value);
  }, [value, person.personId]);

  return (
    <Container selectedPerson={selectedPerson} selectedInput={selectedInput}>
      {selectedInput ? (
        <div className="input">
          <input
            onChange={handleChange}
            onKeyDown={isNumberKey}
            value={newValue}
            className="select person"
            autoFocus
            name="resourcesInput"
            id="resourcesInput"
          />
          <button onClick={handleClick}>OK</button>
        </div>
      ) : (
        <button
          onClick={showInput}
          className={`person select ${value ? "fill" : null}`}
        >
          {value || ""}
        </button>
      )}
    </Container>
  );
}

export default ResourcesButton;

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-left: 5px;
  align-items: center;
  background-color: ${(props) =>
    props.selectedPerson ? "rgb(240, 240, 240)" : null};
  height: 40px;
  background-color: ${(props) => (props.selectedInput ? wpInfoColor : null)};
  border-radius: ${(props) => (props.selectedInput ? "6px 6px 0px 0px" : 0)};
  width: ${(props) => (props.selectedPerson ? "90px" : "45px")};
  .input {
    display: flex;
    align-items: center;
    button {
      margin-left: 5px;
      height: 25px;
      width: 35px;
      color: white;
      background-color: ${wpInfoColor};
    }
  }
  button.select,
  input.select {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    margin: 0;
    border-radius: 6px;
    background-color: rgb(225, 225, 225);
    border-color: rgb(225, 225, 225);
  }
  button.select {
    /* background-color: white; */
  }
  input.select {
    background-color: white;
    border: 1px solid ${wpInfoColor};
    width: 40px;
  }
  .select.fill {
    background-color: lightblue;
    border-color: lightblue;
  }
`;
