import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getWPStatus, handleSendAssignments } from "../../helpers";
import {
  assignAllToCategory,
  assignNoneToCategory,
} from "../../store/entities/assignments";
import { getWorkPackageIds } from "../../store/entities/tasks";
import AssignmentColumnButton from "./assignmentButton";
import Tippy from "@tippy.js/react";
import warning from "../../images/warning.png";
import { ColumnStyling } from "./assignmentColumnStyling";

export default function (props) {
  const dispatch = useDispatch();
  const { category } = props;
  const cat = category.toLowerCase();
  const workPackageIds = useSelector(getWorkPackageIds);
  const { selectedLeader } = useSelector((state) => state.user);
  // const assignments = useSelector((state) => state.entities.assignments.data);
  const hasCosts = useSelector(getWPStatus)[selectedLeader].has[cat];
  const status = useSelector(getWPStatus)[selectedLeader].unassigned[cat];

  function assignAll() {
    dispatch(
      assignAllToCategory({
        leader: selectedLeader,
        category: cat,
        workPackageIds,
      })
    );
    handleSendAssignments();
  }

  function resetCategory() {
    dispatch(
      assignNoneToCategory({
        leader: selectedLeader,
        category: cat,
      })
    );
    handleSendAssignments();
  }

  return hasCosts ? (
    <ColumnStyling>
      <div className="title">
        <h3>{category}</h3>
        {status ? (
          <Tippy content="Cost must be assigned to at least one work package">
            <div className="warning">
              <img src={warning} alt="warning" />
            </div>
          </Tippy>
        ) : null}
      </div>
      {workPackageIds.map((workPackageId, index) => {
        return (
          <AssignmentColumnButton
            key={index}
            category={cat}
            workPackageId={workPackageId}
          />
        );
      })}
      <Tippy content={`Remove ${category} costs from all work packages`}>
        <button onClick={resetCategory} className="applyAll hiddenButton">
          None
        </button>
      </Tippy>
      <Tippy content={`Assign ${category} costs to all work packages`}>
        <button onClick={assignAll} className="applyAll hiddenButton">
          All
        </button>
      </Tippy>
    </ColumnStyling>
  ) : null;
}
