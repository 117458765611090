import React from "react";
import { Container } from "../team/teamStyling";
import question from "../../images/qMark.png";
import Tippy from "@tippy.js/react";

export default function ProjectNotificationTitles() {
  return (
    <Container>
      <div className="row titles leaderTabMargin">
        <div className="title name">
          <p>Name</p>
        </div>
        <div className="title email">
          <p>Email</p>
        </div>
        <div className="title toggle">
          <p>Section</p>
          <Tippy content="Receive an email when a section status changes">
            <div className="icon">
              <img src={question} alt="info" />
            </div>
          </Tippy>
        </div>
        <div className="title toggle">
          <p>Project</p>
          <Tippy content="Receive an email when all sections are complete">
            <div className="icon">
              <img src={question} alt="info" />
            </div>
          </Tippy>
        </div>
        <div className="delete"></div>
      </div>
    </Container>
  );
}
