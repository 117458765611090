import React from "react";
import { useSelector } from "react-redux";

export default function PrintGanttBars(props) {
  const { showTaskDays } = useSelector((state) => state.user.gantt);
  const bars = [];

  let barStarted = false;
  const end = props.schedule.length - 1;

  props.schedule.forEach((month, index) => {
    const entry = { ...month, className: "fill taskBar" };
    if (month.barNumber && !barStarted) {
      entry.className = entry.className + " start";
      barStarted = true;
    }
    if (!month.barNumber && barStarted) {
      barStarted = false;
      if (index > 0)
        bars[index - 1].className = bars[index - 1].className + " end";
    }
    if (index === end) entry.className = entry.className + " end";
    bars.push(entry);
  });

  return (
    <div className="schedule">
      {bars.map((month, index) => {
        return (
          <div key={index} className="block">
            {month.barNumber ? (
              <div className={month.className}>
                {showTaskDays ? <p className="value">{month.value}</p> : null}
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
}
