import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { menuBlueColor, navBackground } from "../helpers";
import ProjectRow from "./ProjectRow";

export default function UserProjectTable() {
  const projects = useSelector((state) => state.entities.setup.data);

  return (
    <Container>
      <div className="titles">
        <div className="indicatorSection"/>
        <div className="selectProject">
          <h3>Select Project</h3>
        </div>
      </div>
      <div className="projects">
        {projects.map((project, index) => {
          return <ProjectRow project={project} key={index} />;
        })}
      </div>
      <div className="titles bottomRow">
        <div className="indicatorSection"></div>
      </div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  box-shadow: 8px 8px 12px rgba(1, 1, 1, 0.5);
  overflow: hidden;
  border-radius: 6px;
  color: ${navBackground};
  background-color: white;

  .titles {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 50px;
  }
  .selectProject {
    padding-left: 25px;
  }
  .projects {
    width: 100%;
    flex-direction: column;
  }
  .bottomRow {
    height: 20px;
  }
  .indicatorSection {
    min-width: 120px;
    max-width: 120px;
    height: 100%;
    background-color: ${menuBlueColor};
    /* margin-right: 25px; */
  }
  .description {
    width: 150px;
    padding-left: 5px;
  }
`;
