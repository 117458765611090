import styled from "styled-components";
import { fontColorGrey, questionColor, settingsColor } from "../../helpers";

export const Container = styled.div`
  display: flex;
  /* width: 100%; */
  /* border: 1px solid red; */

  /* risks table - project identifier */
  h2.project {
    padding: 0;
    margin-top: 10px;
  }
  .project {
    font-size: 20px;
    color: ${fontColorGrey};
  }
  .description {
    width: 300px;
  }
  .value {
    width: 100px;
    text-align: right;
    justify-content: flex-end;
  }
  .dropDown {
    width: 40px;
    text-align-last: center;
    /* border: 1px solid lightgray; */
    /* border-radius: 5px; */
    :focus {
      /* border: 1px solid ${settingsColor}; */
    }
    margin-right: 0;
  }
  .dropArrow {
    /* border: 1px solid red; */
    max-height: 100%;
    max-width: 15px;
    padding-bottom: 2px;
  }
  .double {
    width: 200px;
    justify-content: flex-start;
    text-align: left;
  }
  .triple {
    width: 420px;
    justify-content: flex-start;
    text-align: left;
  }
  .text {
    width: 340px;
    text-align: right;
    justify-content: flex-end;
  }
  .showButton {
    font-weight: bold;
  }
  .list {
    margin: 10px 20px;
    width: 330px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #f4f4f4;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .alignLeft {
    text-align: left;
    justify-content: flex-start;
  }
  .left {
    display: flex;
    align-items: center;
  }
  .symbol {
    color: ${fontColorGrey};
    margin-left: -20px;
  }
  button {
    font-size: 12px;
    background-color: white;
    color: ${questionColor};
    border: none;
  }
  .imageButton {
    margin-right: 10px;
    background-color: transparent;
    max-height: 30px;
    max-width: 30px;
    min-width: 30px;
  }
  .select {
    text-align: right;
    justify-content: flex-end;
  }
  .field.highlight {
    background-color: ${settingsColor};
    border-color: ${settingsColor};
    color: white;
    font-weight: bold;
  }
`;
