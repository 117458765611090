import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import OutputRisksDisplay from "./OutputRisksInfo";
import OutputVariables from "./OutputVariables";
import { getSelectedProject, setShowComponent } from "../../store/user";
import {
  fontColorGrey,
  getSwapDetails,
  menuBlueColor,
  settingsColor,
} from "../../helpers";
import OutputRisksManagement from "./OutputRisksManagement";
import OutputRiskList from "./OutputRiskList";
import OutputChangeSource from "./OutputChangeSource";
import OutputCopy from "./OutputCopy";

export default function OutputRisks(props) {
  const dispatch = useDispatch();
  // source is "global" or "project"
  const { location } = props;
  const { outputEdit } = useSelector(getSelectedProject);
  const { showComponent, globalSettings, admin } = useSelector(
    (state) => state.user
  );

  const { outputs } = useSelector((state) => state.entities.project.data);

  const { variables, mapObj } = useSelector(getSwapDetails);
  const edit = showComponent === "outputRisks";

  const frontColor = location === "admin" ? settingsColor : menuBlueColor;
  const editColor = globalSettings ? settingsColor : menuBlueColor;
  const color = edit ? editColor : frontColor;

  function showEdit() {
    const value = edit ? "" : "outputRisks";
    dispatch(setShowComponent({ value }));
  }

  return (
    <Container color={color}>
      {outputs ? (
        <>
          {edit && admin ? <OutputChangeSource /> : null}
          <OutputRisksManagement mapObj={mapObj} edit={edit} />
          <OutputRiskList />
          <OutputRisksDisplay mapObj={mapObj} edit={edit} />
          {outputEdit || admin ? (
            <button className="edit" onClick={showEdit}>
              {edit ? "Done" : "Edit"}
            </button>
          ) : null}
          {edit ? (
            <OutputVariables variables={variables} color={color} />
          ) : null}
        </>
      ) : (
        <OutputCopy />
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  color: ${fontColorGrey};

  h3.title {
    margin-top: 10px;
    margin-bottom: 5px;
    color: ${(props) => props.color};
  }
  p,
  span {
    height: 20px;
  }
  li {
    margin-left: 20px;
    list-style-type: disc;
  }
  textarea.textarea {
    width: 100%;
    height: 80px;
    border-color: lightgray;
    color: ${(props) => props.color};
    &:focus {
      border-color: ${(props) => props.color};
    }
  }

  button.edit {
    position: absolute;
    right: 20px;
    background-color: ${(props) => props.color};
    color: white;
    font-weight: bold;
    border: none;
    width: 80px;
    height: 25px;
    padding: 0;
  }
`;
