// import axios from "axios";
import { store } from "../index";
import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
// import { projectData } from "../../data";
import moment from "moment";
import { getAllocationsByTaskId, getTeamDaysAndCosts } from "./allocations";
import {
  recentFetch,
  questions,
  reducerNotification,
  roundTo,
  getWPStatus,
  leaderList,
  monthWidth,
  clientFlavours,
  rev2,
  getWPCost,
} from "../../helpers";
import { apiCallBegan } from "../api";

export const initialData = {
  status: {
    // details: {},
    // team: {},
    // gantt: {},
    // costs: {},
    // revenue: {},
    // risks: {},
  },
  details: {
    projectLength: 3,
    startYear: 2022,
    startMonth: "Jan",
    ganttWidth: "120px",
    notify: [],
  },
  lead: {},
  pOne: {},
  pTwo: {},
  wizard: {
    offered: true,
    allQuestionsAsked: false,
    currentQuestionIndex: 0,
    component: "intro",
    complete: false,
    progress: 0,
    answers: {},
  },
  templates: [],
  roles: [],
  tasks: [],
  settings: {
    resourcesIncrement: 5,
  },
};

const initialState = {
  loading: false,
  lastFetch: 0,
  projectDefaultsApplied: false,
  companyDefaultsApplied: false,
  data: initialData,
  lastSent: 0,
  lastEdit: 0,
  error: "",
};

const slice = createSlice({
  name: "project",
  initialState,
  reducers: {
    projectRequested: (project, _) => {
      project.loading = true;
    },
    projectRequestFailed: (project, action) => {
      project = initialState;
      reducerNotification("error", action.payload);
    },
    projectReceived: (project, action) => {
      project.data = action.payload;
      project.loading = false;
      project.lastFetch = Date.now();
    },

    updateSectionStatus: (project, action) => {
      const { section } = action.payload;
      console.log(section);
      project.data.status[section] = !project.data.status[section];
      project.lastEdit = Date.now();
    },
    updateGanttStatus: (project) => {
      project.data.status.gantt = !project.data.status.gantt;
      project.lastEdit = Date.now();
    },
    updateRevenueStatus: (project) => {
      project.data.status.revenue = !project.data.status.revenue;
      project.lastEdit = Date.now();
    },
    updateRiskStatus: (project) => {
      project.data.status.risks = !project.data.status.risks;
      project.lastEdit = Date.now();
    },
    updateSetupStatus: (project) => {
      project.data.status.setup = !project.data.status.setup;
      project.lastEdit = Date.now();
    },
    updateProjectInfo: (project, action) => {
      project.data.details[action.payload.key] = action.payload.value;
      project.projectDefaultsApplied = false;
      // innerFunction();
      project.lastEdit = Date.now();
    },
    updateProjectLength: (project, action) => {
      const length = action.payload.value;
      const width = monthWidth.slice(0, 2) * length + "px";
      project.data.details.projectLength = length;
      project.data.details.ganttWidth = width;
      project.lastEdit = Date.now();
    },
    updateLeaderInfo: (project, action) => {
      const { leader, key, value } = action.payload;
      project.data[leader][key] = value;
      project.companyDefaultsApplied = false;
      project.lastEdit = Date.now();
    },
    applyProjectDefaults: (project, action) => {
      const { defaults } = action.payload;
      defaults.forEach((row) => {
        const { key, value } = row;
        project.data.details[key] = value;
      });
      project.projectDefaultsApplied = true;
      reducerNotification("info", "Project defaults applied");
      project.lastEdit = Date.now();
    },
    setCompanyDefaults: (project, action) => {
      const { defaults, leader } = action.payload;
      console.log(defaults, leader);
      defaults.forEach((row) => {
        const { key, value } = row;
        project.data[leader][key] = value;
      });
      project.companyDefaultsApplied = true;
      reducerNotification("info", "Company defaults applied");
      project.lastEdit = Date.now();
    },
    updateResourcesIncrement: (project, action) => {
      const { increment } = action.payload;
      project.data.settings.resourcesIncrement = increment;
      project.lastEdit = Date.now();
    },

    updateProjectTemplates: (project, action) => {
      const { clientFlavour, templates, roles, tasks } = action.payload;
      const currentIndex = clientFlavours.findIndex(
        (flavour) => flavour === clientFlavour
      );
      const newIndex = (currentIndex + 1) % 3;
      const newFlavour = clientFlavours[newIndex];

      if (newFlavour === "Review") {
        project.data.templates = [];
        project.data.roles = [];
        project.data.tasks = [];
      }

      if (newFlavour === "Review Plus") {
        const count = 5;
        const sampleTemplates = [];
        const sampleRoles = [];
        const sampleTasks = [];
        for (let i = 0; i < templates.length; i++) {
          if (templates[i].category === "gen")
            sampleTemplates.push(templates[i]);
          if (sampleTemplates.length === count) break;
        }
        for (let i = 0; i < roles.length; i++) {
          if (roles[i].category === "gen") sampleRoles.push(roles[i]);
          if (sampleRoles.length === count) break;
        }
        for (let i = 0; i < roles.length; i++) {
          if (tasks[i].category === "gen") sampleTasks.push(tasks[i]);
          if (sampleTasks.length === count) break;
        }
        project.data.templates = sampleTemplates;
        project.data.roles = sampleRoles;
        project.data.tasks = sampleTasks;
      }

      if (newFlavour === "Full Write") {
        project.data.templates = templates;
        project.data.roles = roles;
        project.data.tasks = tasks;
      }
      project.lastEdit = Date.now();
    },

    updateAvailableTemplates: (project, action) => {
      const { templates, roles, tasks } = action.payload;
      console.log(templates, roles, tasks);
      project.data.templates = templates;
      project.data.roles = roles;
      project.data.tasks = tasks;
      project.lastEdit = Date.now();
    },

    wizardAnswer: (project, action) => {
      const { index, answer, option } = action.payload;
      const { skip } = questions[index];
      const { wizard } = project.data;

      // if no answer object, build one
      if (!wizard.answers[0]) {
        const answerObject = {};
        questions.forEach((question, index) => {
          answerObject[index] = {};
          if (question.data) {
            answerObject[index].result = {};
            question.data.forEach((entry) => {
              answerObject[index].result[entry] = false;
            });
          } else {
            answerObject[index].result = false;
          }
        });
        wizard.answers = answerObject;
      }

      // question is answered, therefore set skipped to false
      wizard.answers[index].skipped = false;
      wizard.complete = false;
      wizard.allQuestionsAsked = false;

      // if it is the last question but not on the summary
      if (index === questions.length - 1 && wizard.component === "questions") {
        wizard.component = "end";
        wizard.allQuestionsAsked = true;
      }

      // if multi choice toggle options
      if (option) {
        wizard.answers[index].result[option] =
          !wizard.answers[index].result[option];
        // otherwise answer the question either yes or no
      } else wizard.answers[index].result = answer;

      // if answer is truthy
      if (answer) {
        // increment answer not including the skip
        const nextQuestion =
          (wizard.currentQuestionIndex + 1) % questions.length;
        wizard.currentQuestionIndex = nextQuestion;
        wizard.progress =
          roundTo((nextQuestion / questions.length) * 100, 0) + "%";
        console.log(nextQuestion, questions.length, wizard.progress);
        // get dependency level of question
        const dep = questions[index].dep;
        // loop through all dependant question that follow (using skip count)
        for (let i = index + 1; i <= index + skip; i++) {
          const currentDep = questions[i].dep;
          const directDep = dep + 1 === currentDep;
          // if this question is a direct dependant skip is false
          if (directDep) wizard.answers[i].skipped = false;
          // if this is dependant on another question, skip is true
          else wizard.answers[i].skipped = true;
        }

        // if falsy, set the subsequent dependant questions to false
      } else {
        const nextQuestion =
          (wizard.currentQuestionIndex + 1 + skip) % questions.length;
        wizard.currentQuestionIndex = nextQuestion;
        wizard.progress =
          roundTo((nextQuestion / questions.length) * 100, 0) + "%";
        console.log(nextQuestion, questions.length, wizard.progress);
        for (let i = index + 1; i <= index + skip; i++) {
          const multi = questions[i].data;
          // set all option to false
          if (multi)
            Object.values(multi).forEach((option) => {
              wizard.answers[i].result[option] = false;
            });
          // otherwise set the answer to false
          else wizard.answers[i].result = false;
          wizard.answers[i].skipped = true;
        }
      }
      project.lastEdit = Date.now();
    },

    wizardToggle: (project, action) => {
      const { index, option } = action.payload;
      project.data.wizard.answers[index].result[option] =
        !project.data.wizard.answers[index].result[option];
      project.data.wizard.answers[index].skipped = false;
      project.lastEdit = Date.now();
    },
    wizardNext: (project, _) => {
      if (
        project.data.wizard.currentQuestionIndex === questions.length - 1 &&
        project.data.wizard.component === "questions"
      ) {
        project.data.wizard.component = "end";
        project.data.wizard.allQuestionsAsked = true;
      }
      project.data.wizard.currentQuestionIndex =
        (project.data.wizard.currentQuestionIndex + 1) % questions.length;
      project.lastEdit = Date.now();
    },
    wizardPrevious: (project, _) => {
      let index = project.data.wizard.currentQuestionIndex - 1;
      const answers = project.data.wizard.answers;
      for (let i = index; i >= 0; i--) {
        const answered = !answers[i].skipped;
        if (answered) {
          index = i;
          break;
        }
      }
      project.data.wizard.currentQuestionIndex = index;
      project.lastEdit = Date.now();
    },
    wizardReset: (project, _) => {
      project.data.wizard.allQuestionsAsked = false;
      project.data.wizard.currentQuestionIndex = 0;
      project.data.wizard.component = "questions";
      project.data.wizard.complete = false;
      project.data.wizard.answers = {};
      project.data.wizard.progress = "0%";
      // project.data.wizard.offered = false;
      project.lastEdit = Date.now();
    },
    wizardComplete: (project, _) => {
      project.data.wizard.complete = true;
      project.data.wizard.allQuestionsAsked = true;
      project.data.wizard.component = "reset";
      project.lastEdit = Date.now();
    },
    wizardComponent: (project, action) => {
      const { value } = action.payload;
      project.data.wizard.component = value;
      project.lastEdit = Date.now();
    },
    wizardOffered: (project, _) => {
      project.data.wizard.offered = true;
      project.lastEdit = Date.now();
    },

    wizardClearPrevious: (project, _) => {
      project.data.templates = [];
      project.data.roles = [];
      project.data.tasks = [];
      project.lastEdit = Date.now();
    },
    wizardAddOptions: (project, action) => {
      const { tempResult, taskResult, roleResult } = action.payload;
      project.data.templates = project.data.templates.concat(tempResult);
      project.data.tasks = project.data.tasks.concat(taskResult);
      project.data.roles = project.data.roles.concat(roleResult);
      project.lastEdit = Date.now();
    },

    addNotificationEmail: (project, action) => {
      project.data.details.notify.push({});
      project.lastEdit = Date.now();
    },
    removeNotificationEmail: (project, action) => {
      const { index } = action.payload;
      project.data.details.notify.splice(index, 1);
      project.lastEdit = Date.now();
    },
    editNotificationEmail: (project, action) => {
      const { index, key, value } = action.payload;
      project.data.details.notify[index][key] = value;
      project.lastEdit = Date.now();
    },

    addProjectOutputs: (project, action) => {
      const { global } = action.payload;
      project.data.outputs = global;
      project.data.details.notify = [];
      project.lastEdit = Date.now();
    },
    updateProjectOutput: (project, action) => {
      const { value, key } = action.payload;
      project.data.outputs[key] = value;
      project.lastEdit = Date.now();
    },

    apiSuccess: (project, action) => {
      project.loading = false;
      project.lastSent = Date.now();
      reducerNotification("info", "Project saved");
    },
    apiFailed: (project, action) => {
      project.loading = false;
      console.log(action.payload);
      reducerNotification("error", "Project failed");
    },
  },
});
export default slice.reducer;

const {
  projectRequested,
  projectRequestFailed,

  apiSuccess,
  apiFailed,
} = slice.actions;

export const {
  projectReceived,

  updateProjectInfo,
  updateLeaderInfo,
  applyProjectDefaults,
  setCompanyDefaults,

  updateSectionStatus,
  updateGanttStatus,
  updateRevenueStatus,
  updateSetupStatus,
  updateRiskStatus,
  updateResourcesIncrement,
  updateProjectLength,
  updateProjectTemplates, // not needed rev2
  updateAvailableTemplates,

  wizardAnswer,
  wizardToggle,
  wizardNext,
  wizardPrevious,
  wizardReset,
  wizardComplete,
  wizardComponent,
  wizardClearPrevious,
  wizardAddOptions,
  wizardOffered,

  addNotificationEmail,
  removeNotificationEmail,
  editNotificationEmail,

  updateProjectOutput,
  addProjectOutputs,
} = slice.actions;

// Action Creators
const url = "/projects";

export const loadProjectData = () => (dispatch, getState) => {
  const { lastFetch } = getState().entities.project;
  // console.log("fetching project...");
  if (recentFetch(lastFetch)) return;

  dispatch(
    apiCallBegan({
      url: url + "/selected",
      method: "get",
      data: null,
      onStart: projectRequested.type,
      onSuccess: projectReceived.type,
      onError: projectRequestFailed.type,
    })
  );
};

export const sendUpdatedDetails = () => (dispatch, getState) => {
  const project = getState().entities.project;
  const { lastEdit, lastSent, data } = project;
  if (lastSent >= lastEdit) return;
  // console.log(data);
  dispatch(
    apiCallBegan({
      url: url + "/selected",
      method: "put",
      data,
      // onStart: sendingUpdatedDetails.type,
      onSuccess: apiSuccess.type,
      onError: apiFailed.type,
    })
  );
};

export const initiateProject = (data) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: url + "/new",
      method: "post",
      data,
      // onStart: sendingNewProject.type,
      onSuccess: apiSuccess.type,
      onError: apiFailed.type,
    })
  );
};

export const cloneProjectDetails = (projectId) => (dispatch, getState) => {
  const { data } = getState().entities.project;
  dispatch(
    apiCallBegan({
      url: url + "/new",
      method: "post",
      data: {
        projectId,
        data,
      },
      // onStart: sendingAllocation.type,
      onSuccess: apiSuccess.type,
      onError: apiFailed.type,
    })
  );
};

export const deleteProjectDetails = (projectId) =>
  apiCallBegan({
    url: url + "/selected",
    method: "delete",
    data: {
      projectId,
    },
    // onStart: sendingEmptyAssignments.type,
    onSuccess: apiSuccess.type,
    onError: apiFailed.type,
  });

// Selectors

export const getProjectDates = createSelector(
  (state) => state.entities.project,
  (project) => {
    const { projectLength, startYear, startMonth } = project.data.details;
    const month = startMonth; // hard coded !!!!!
    const year = startYear; // hard coded !!!!!
    const projectStart = moment(`${month} ${year}`, "MMM YYYY");
    const dateArray = () => {
      const years = [];
      const dateStart = projectStart;
      for (let i = 0; i < projectLength; i++) {
        years.push(dateStart.format("MMM YYYY"));
        dateStart.add(1, "month");
      }
      return years;
    };
    return dateArray();
  }
);

export const getProjectEnd = createSelector(
  (state) => state.entities.project,
  (project) => {
    const { projectLength, startYear, startMonth } = project.data.details;
    const projectStart = moment(`${startMonth} ${startYear}`, "MMM YYYY");
    const projectEnd = moment(projectStart).add(projectLength, "M");
    // console.log(projectEnd.format("MMM YYYY"));
    return projectEnd;
  }
);

export const getProjectStatus = createSelector(
  (state) => state.entities.project.data.status,
  (status) => {
    return status;
  }
);

export const getProjectLength = createSelector(
  (state) => state.entities.project,
  (project) => {
    const { projectLength } = project.data.details;
    return projectLength;
  }
);

export const getGanttWidth = createSelector(
  (state) => state.entities.project,
  (project) => {
    const { ganttWidth } = project.data.details;
    return ganttWidth;
  }
);

export const getWorkingDaysPerMonth = createSelector(
  (state) => state.entities.project.data,
  (data) => {
    // console.log("getWorkingDaysPerMonth");
    function workedDays(leader) {
      const { bankHolidays, annualLeave } = data[leader];
      return (260 - bankHolidays - annualLeave) / 12;
    }
    const companyDays = {
      lead: workedDays("lead"),
      pOne: workedDays("pOne"),
      pTwo: workedDays("pTwo"),
    };
    return companyDays;
  }
);

export const getOverheads = createSelector(
  (state) => state.entities,
  (entities) => {
    const totalDays = getTeamDaysAndCosts(store.getState());

    function totalCost(leader) {
      return entities.project.data[leader].overheadRate;
    }
    function overhead(leader) {
      return totalDays[leader].staff.cost;
    }

    const overheads = {
      lead: Math.round((totalCost("lead") * overhead("lead")) / 100),
      pOne: Math.round((totalCost("pOne") * overhead("pOne")) / 100),
      pTwo: Math.round((totalCost("pTwo") * overhead("pTwo")) / 100),
      combined: 0,
      category: "Overhead",
    };
    overheads.combined = overheads.lead + overheads.pOne + overheads.pTwo;
    return overheads;
  }
);

export const getFundingLevel = createSelector(
  (state) => state.entities.project.data,
  (data) => {
    const grants = {
      lead: data.lead.fundingLevel,
      pOne: data.pOne.fundingLevel,
      pTwo: data.pTwo.fundingLevel,
      combined: 0,
      category: "Grant",
    };
    grants.combined = grants.lead + grants.pOne + grants.pTwo;
    return grants;
  }
);

export const getSelectedCompany = createSelector(
  (state) => state.entities.project.data,
  (state) => state.user.selectedLeader,
  (project, leader) => {
    if (leader === "combined") return false;
    const company = project[leader].companyName;
    return company;
  }
);

export const getCostWarnings = createSelector(
  (state) => state.entities.tasks.data,
  (state) => state.entities.capex.data,
  (state) => state.entities.materials.data,
  (state) => state.entities.travel.data,
  (state) => state.entities.other.data,
  (state) => state.entities.allocations.data,
  (state) => state.entities.assignments.data,
  (_) => {
    const warnings = [];
    const state = store.getState();
    const { admin } = state.user;
    const wpStatus = getWPStatus(state); // catches unassigned costs
    const personAllocations = getAllocationsByTaskId(state).redundant; // catches deleted people
    const otherAssignments = getWPStatus(state).redundant; // catches deleted other costs
    const packAssignments = getWPCost(state).redundant; // catches deleted work packages

    let check = {
      flagged: false,
      setting: false,
      message: "Unassigned cost",
      key: "selectedCostsOption",
      value: "assignment",
    };
    const redundantAllocation = {
      message:
        "Task or team member deleted, allocated data still present. Only admin can see this.",
      key: "showComponent",
      value: "redundantAllocations",
      link: "/",
    };
    const redundantAssignment = {
      message:
        "Costs deleted, assigned work package data still present. Only admin can see this.",
      key: "showComponent",
      value: "redundantAssignments",
      link: "/",
    };

    // unassigned cost
    leaderList.forEach((leader) => {
      const costs = Object.keys(wpStatus[leader].unassigned);
      costs.forEach((cost) => {
        // console.log(wpStatus[leader].unassigned[cost]);
        if (wpStatus[leader].unassigned[cost] && !check.flagged) {
          check.flagged = true;
          warnings.push(check);
        }
      });
    });

    // allocations - allocating a percentage of a task to a person
    if (personAllocations.length && admin && rev2)
      warnings.push(redundantAllocation);

    // assignments - assigning a cost to a work package (checked)
    if ((otherAssignments.length || packAssignments.length) && admin && rev2) {
      warnings.push(redundantAssignment);
    }
    return warnings;
  }
);

export const getProjectTemplatesByType = createSelector(
  (state) => state.entities.project.data.templates,
  (templates) => {
    const separatedTemplates = {
      managerial: templates.filter((risk) => risk.riskType === "managerial"),
      commercial: templates.filter((risk) => risk.riskType === "commercial"),
      legal: templates.filter((risk) => risk.riskType === "legal"),
      technical: templates.filter((risk) => risk.riskType === "technical"),
      environmental: templates.filter(
        (risk) => risk.riskType === "environmental"
      ),
    };
    return separatedTemplates;
  }
);
