import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  updateProbability,
  updateConsequence,
  updateRiskKeyValue,
  deleteRisk,
  updatePostConsequence,
  updatePostProbability,
  updateRiskOwner,
} from "../../store/entities/risks";
import bin from "../../images/bin-grey.png";
import {
  fontColorGrey,
  handleSendRisks,
  ranking,
  riskLevelHighColor,
  riskLevelLowColor,
  riskLevelMediumColor,
  riskLevelVeryHighColor,
  riskLevelVeryLowColor,
} from "../../helpers";
import { getAcronyms } from "../../store/entities/team";

function RiskRow(props) {
  const dispatch = useDispatch();
  // const { risks, requiresSorting } = useSelector(
  //   (state) => state.entities.risks
  // );
  const { risk } = props;
  const {
    riskId,
    riskPreProbability,
    riskPreConsequence,
    riskPostProbability,
    riskPostConsequence,
    riskDescription,
    riskMitigation,
    riskOwner,
    // height,
  } = risk;
  const acronyms = useSelector(getAcronyms);
  const { maxDescription, maxMitigation } = useSelector(
    (state) => state.entities.setup.data
  );

  function toggleProbability(riskId) {
    dispatch(updateProbability({ riskId }));
  }

  function toggleConsequence(riskId) {
    dispatch(updateConsequence({ riskId }));
  }

  function togglePostProbability(riskId) {
    dispatch(updatePostProbability({ riskId }));
  }

  function togglePostConsequence(riskId) {
    dispatch(updatePostConsequence({ riskId }));
  }

  function deleteEntry(riskId) {
    dispatch(deleteRisk({ riskId }));
    handleSendRisks();
  }

  function editRisk(e) {
    const { name, value } = e.target;
    dispatch(updateRiskKeyValue({ riskId, key: name, value }));
  }

  function handleRiskOwner(e) {
    const riskOwner = e.target.value;
    dispatch(updateRiskOwner({ riskId, riskOwner }));
    handleSendRisks();
  }

  const [tempHeight, setTempHeight] = useState(50);
  function getMaxHeight() {
    const measuredHeight =
      Math.max(
        document.getElementById(`${riskId}-des`).scrollHeight,
        document.getElementById(`${riskId}-mit`).scrollHeight
      ) + 2;
    setTempHeight(measuredHeight);
    // dispatch(setRiskHeight({ riskId, value: measuredHeight }));
  }

  useEffect(() => {
    getMaxHeight();
  }, [riskDescription, riskMitigation]);

  return (
    <PageContainer>
      <textarea
        id={riskId + "-des"}
        type="text"
        onChange={editRisk}
        onBlur={handleSendRisks}
        // onBlur={sendChanges}
        className="field text input"
        name="riskDescription"
        value={riskDescription}
        style={{ height: tempHeight }}
        maxLength={maxDescription}
      />
      <button
        className={`field toggle input ${ranking[riskPreProbability]}`}
        onClick={() => toggleProbability(riskId)}
        onBlur={handleSendRisks}
      >
        {ranking[riskPreProbability]}
      </button>
      <button
        className={`field toggle input ${ranking[riskPreConsequence]}`}
        onClick={() => toggleConsequence(riskId)}
        onBlur={handleSendRisks}
      >
        {ranking[riskPreConsequence]}
      </button>
      <textarea
        id={riskId + "-mit"}
        type="text"
        onChange={editRisk}
        onBlur={handleSendRisks}
        // onBlur={sendChanges}
        className="field text input miti"
        name="riskMitigation"
        value={riskMitigation}
        style={{ height: tempHeight }}
        maxLength={maxMitigation}
      />
      <button
        name={riskPostProbability}
        className={`field toggle input ${ranking[riskPostProbability]}`}
        onClick={() => togglePostProbability(riskId)}
        onBlur={handleSendRisks}
      >
        {ranking[riskPostProbability]}
      </button>
      <button
        className={`field toggle input ${ranking[riskPostConsequence]}`}
        onClick={() => togglePostConsequence(riskId)}
        onBlur={handleSendRisks}
      >
        {ranking[riskPostConsequence]}
      </button>
      <select
        className="field toggle input end"
        onChange={handleRiskOwner}
        value={riskOwner}
      >
        <option hidden>...</option>
        {acronyms.map((acronym, index) => {
          return (
            <option key={index} value={acronym}>
              {acronym}
            </option>
          );
        })}
      </select>
      <div className="delete hidden">
        <img onClick={() => deleteEntry(riskId)} src={bin} alt="delete" />
      </div>
    </PageContainer>
  );
}
export default RiskRow;

const PageContainer = styled.div`
  position: relative;
  scroll-snap-align: start;
  display: flex;
  border-radius: 6px;
  padding: 3px;
  /* min-height: 40px; */
  width: calc(100% - 30px);
  /* border: 1px solid red; */
  transition: opacity 0.3s;
  transition: background-color 0.3s;
  :last-child {
    margin-bottom: 30px;
  }
  &:hover {
    background-color: rgba(230, 230, 230);
    opacity: 1;
    .delete {
      opacity: 1;
    }
  }

  .input {
    border: 1px solid #e1e1e1;
    background-color: white;
    border-radius: 5px;
  }
  textarea.text {
    padding-top: 15px;
    resize: none;
    &:focus {
      border-color: dodgerblue;
    }
    .text {
      height: auto;
    }
  }
  .Very.High {
    background-color: ${riskLevelVeryHighColor};
  }
  .High {
    background-color: ${riskLevelHighColor};
    color: white;
  }
  .Medium {
    background-color: ${riskLevelMediumColor};
    color: ${fontColorGrey};
  }
  .Low {
    background-color: ${riskLevelLowColor};
    color: ${fontColorGrey};
  }
  .Very.Low {
    background-color: ${riskLevelVeryLowColor};
    color: ${fontColorGrey};
  }
  button {
    font-weight: bold;
    color: ${fontColorGrey};
  }
  select {
    text-align: center;
    text-align-last: center;
    /* webkit*/
  }
  .delete {
    /* border: 1px solid red; */
    position: absolute;
    display: flex;
    align-items: center;
    right: -24px;
    top: auto;
    bottom: auto;
    margin: auto;
    height: calc(100% - 6px);
    max-width: 20px;
    /* max-height: 20px; */
    opacity: 0;
    transition: opacity 0.3s;
    /* border: 1px solid red; */
  }
  img {
    cursor: pointer;
  }
`;
