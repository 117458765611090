// import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { recentFetch, reducerNotification } from "../../helpers";
import { apiCallBegan } from "../api";

const slice = createSlice({
  name: "travel",
  initialState: {
    loading: false,
    lastFetch: 0,
    data: [],
    lastSent: 0,
    lastEdit: 0,
    error: "",
  },
  reducers: {
    travelDataRequested: (travel, _) => {
      travel.loading = true;
    },
    travelDataRequestFailed: (travel, action) => {
      travel.loading = false;
      reducerNotification("error", action.payload);
    },
    travelDataReceived: (travel, action) => {
      travel.data = action.payload;
      travel.loading = false;
      travel.lastFetch = Date.now();
    },

    sendingTravel: (travel, _) => {
      travel.loading = true;
    },

    addTravel: (travel, action) => {
      const { newTravel, position } = action.payload;
      travel.data.splice(position, 0, newTravel);
      travel.lastEdit = Date.now();
    },
    deleteTravel: (travel, action) => {
      const index = travel.data.findIndex(
        (travel) => travel.travelId === action.payload.travelId
      );
      travel.data.splice(index, 1);
      travel.lastEdit = Date.now();
    },
    updateTravel: (travel, action) => {
      const { key, value, travelId } = action.payload;
      const index = travel.data.findIndex(
        (person) => person.travelId === travelId
      );
      travel.data[index][key] = value;
      travel.lastEdit = Date.now();
    },
    reorderTravel: (travel, action) => {
      const originalIndex = travel.data.findIndex(
        (travel) => travel.travelId === action.payload.travel.travelId
      );
      const newIndex = originalIndex + action.payload.movement;
      const [journey] = travel.data.splice(originalIndex, 1);
      travel.data.splice(newIndex, 0, journey);
      travel.lastEdit = Date.now();
    },

    apiSuccess: (travel, action) => {
      travel.loading = false;
      travel.lastSent = Date.now();
      // reducerNotification("info", "Travel saved");
    },
    apiFailed: (travel, _) => {
      travel.loading = false;
      reducerNotification("error", "Travel failed");
    },
  },
});

export const {
  travelDataReceived,
  reorderTravel,
  updateTravel,
  addTravel,
  deleteTravel,
} = slice.actions;

const {
  travelDataRequested,
  travelDataRequestFailed,

  sendingTravel,

  apiSuccess,
  apiFailed,
} = slice.actions;

export default slice.reducer;

// Action Creators

const url = "/travel";

export const loadTravelData = () => (dispatch, getState) => {
  const { lastFetch } = getState().entities.travel;
  // console.log("fetching travel data...");
  if (recentFetch(lastFetch)) return;

  dispatch(
    apiCallBegan({
      url: url + "/selected",
      method: "get",
      data: null,
      onStart: travelDataRequested.type,
      onSuccess: travelDataReceived.type,
      onError: travelDataRequestFailed.type,
    })
  );
};

export const initiateTravel = (projectId) =>
  apiCallBegan({
    url: url + "/new",
    method: "post",
    data: {
      projectId,
      data: [],
    },
    onStart: sendingTravel.type,
    onSuccess: apiSuccess.type,
    onError: apiFailed.type,
  });

  export const cloneTravel = (projectId) => (dispatch, getState) => {
    const { data } = getState().entities.travel;
    dispatch(
      apiCallBegan({
        url: url + "/new",
        method: "post",
        data: {
          projectId,
          data,
        },
        // onStart: sendingAllocation.type,
        onSuccess: apiSuccess.type,
        onError: apiFailed.type,
      })
    );
  };

export const sendUpdatedTravel = () => (dispatch, getState) => {
  const travel = getState().entities.travel;
  const { lastEdit, lastSent, data } = travel;
  if (lastSent >= lastEdit) return;
  dispatch(
    apiCallBegan({
      url: url + "/selected",
      method: "put",
      data,
      // onStart: sendingTravel.type,
      onSuccess: apiSuccess.type,
      onError: apiFailed.type,
    })
  );
};

export const deleteProjectTravel = (projectId) =>
  apiCallBegan({
    url: url + "/selected",
    method: "delete",
    data: {
      projectId,
    },
    // onStart: sendingEmptyAssignments.type,
    onSuccess: apiSuccess.type,
    onError: apiFailed.type,
  });

// Selectors

export const getTravelCost = createSelector(
  (state) => state.entities.travel,
  (travel) => {
    // console.log("getTravelCost");
    const costs = {
      lead: 0,
      pOne: 0,
      pTwo: 0,
      combined: 0,
      category: "Travel",
    };
    travel.data.forEach((travel) => {
      const travelCost = travel.cost * travel.quantity;
      costs[travel.leader] = costs[travel.leader] + travelCost;
      costs.combined = costs.combined + travelCost;
    });
    return costs;
  }
);
