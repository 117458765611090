import React from "react";
import styled from "styled-components";
import { menuBlueColor } from "../../helpers";
import OutputCosts from "../output/OutputCosts";

export default function CostOutput() {
  return (
    <Container>
      <div className="contents">
        <OutputCosts location="project" color={menuBlueColor} />
      </div>
    </Container>
  );
}

const Container = styled.div`
  padding: 20px;
  h3 {
    margin-bottom: 10px;
  }
  button {
    background-color: ${menuBlueColor};
    border: none;
    color: white;
    font-weight: bold;
    padding: 10px 15px;
  }
  .contents {
    max-height: 600px;
    overflow: scroll;
  }
`;
