import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import OutputVariables from "./OutputVariables";
import { getSelectedProject, setShowComponent } from "../../store/user";
import {
  fontColorGrey,
  getSwapCompany,
  getSwapCosts,
  getSwapDetails,
  menuBlueColor,
  settingsColor,
} from "../../helpers";
import OutputCostOverview from "./OutputCostOverview";
import OutputCostWorkPackages from "./OutputCostWorkPackages";
import OutputCostBreakdown from "./OutputCostBreakdown";
import OutputCostValue from "./OutputCostValue";
import OutputChangeSource from "./OutputChangeSource";
import OutputCopy from "./OutputCopy";

export default function OutputCosts(props) {
  const { location } = props;
  const dispatch = useDispatch();
  const { outputs } = useSelector((state) => state.entities.project.data);
  const { showComponent, globalSettings, admin } = useSelector(
    (state) => state.user
  );
  const edit = showComponent === "outputCosts";
  const { outputEdit } = useSelector(getSelectedProject);

  const frontColor = location === "admin" ? settingsColor : menuBlueColor;
  const editColor = globalSettings ? settingsColor : menuBlueColor;
  const color = edit ? editColor : frontColor;

  function showEdit() {
    const value = edit ? "" : "outputCosts";
    dispatch(setShowComponent({ value }));
  }

  const costsSwap = useSelector(getSwapCosts);
  const companySwap = useSelector(getSwapCompany);
  const detailsSwap = useSelector(getSwapDetails);

  const mapObj = {
    ...detailsSwap.mapObj,
    ...companySwap.mapObj,
    ...costsSwap.mapObj,
  };
  const variables = [
    ...detailsSwap.variables,
    ...companySwap.variables,
    ...costsSwap.variables,
  ];

  return (
    <Container color={color}>
      {outputs ? (
        <>
          {showComponent === "outputCosts" ? <OutputChangeSource /> : null}
          <OutputCostOverview mapObj={mapObj} edit={edit} />
          <OutputCostWorkPackages mapObj={mapObj} />
          <OutputCostBreakdown mapObj={mapObj} edit={edit} />
          <OutputCostValue mapObj={mapObj} />
          {outputEdit || admin ? (
            <button className="edit" onClick={showEdit}>
              {edit ? "Done" : "Edit"}
            </button>
          ) : null}
          {edit ? (
            <OutputVariables variables={variables} color={color} />
          ) : null}
        </>
      ) : (
        <OutputCopy />
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  color: ${fontColorGrey};

  h3.title {
    margin-top: 10px;
    margin-bottom: 5px;
    color: ${(props) => props.color};
  }
  p,
  span {
    height: 20px;
  }
  li {
    margin-left: 20px;
    list-style-type: disc;
  }
  textarea.textarea {
    width: 100%;
    height: 80px;
    border-color: lightgray;
    color: ${(props) => props.color};
    &:focus {
      border-color: ${(props) => props.color};
    }
  }

  button.edit {
    position: absolute;
    right: 20px;
    background-color: ${(props) => props.color};
    color: white;
    font-weight: bold;
    border: none;
    width: 80px;
    height: 25px;
    padding: 0;
  }
`;
