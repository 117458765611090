import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container } from "./costsStyling";
import { getTotalsByLeader } from "../../helpers";

import AssignmentColumnCosts from "./assignmentColumnCosts";
import AssignmentColumnPack from "./assignmentColumnPack";
import AssignmentColumnCost from "./assignmentColumnTotal";
import AssignmentColumnOther from "./assignmentColumnOther";
import InfoModal from "../modals/infoModal";
import { setShowComponent } from "../../store/user";

function AssignmentInfo() {
  const dispatch = useDispatch();
  const { selectedLeader, showComponent } = useSelector((state) => state.user);
  const others = useSelector(getTotalsByLeader).other[selectedLeader];

  const heading = "How to assign costs to work packages";
  const p1 =
    "To assign a cost to a work package simply hover over the relevant cost column and click the button within the relevant work package row to toggle it to ‘YES’. To remove that cost from the work package simply click the button again to toggle it to ‘NO’";
  const p2 =
    "To quickly assign a particular cost to all work packages click the ‘All’  button that appears at the bottom of each cost column when you hover over it. To remove a cost from all work packages just click the ‘None’ button.";

  function showInfo() {
    dispatch(setShowComponent({ value: "info" }));
  }

  return (
    <Container>
      <div className="newAssignmentTable">
        <AssignmentColumnPack />
        <AssignmentColumnCosts category="Capex" />
        <AssignmentColumnCosts category="Materials" />
        <AssignmentColumnCosts category="Travel" />
        {others.map((other, index) => {
          return (
            <AssignmentColumnOther
              key={index}
              category="Other"
              other={other}
              index={index}
            />
          );
        })}
        <AssignmentColumnCost heading="Total Cost" />
      </div>
      {showComponent === "info" ? (
        <InfoModal heading={heading} p1={p1} p2={p2} />
      ) : (
        <div className="bottomMiddle">
          <button onClick={showInfo}>
            Need help with assigning costs? Click here
          </button>
        </div>
      )}
    </Container>
  );
}
export default AssignmentInfo;
