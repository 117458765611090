import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import OutputPMInfo from "./OutputPMInfo";
import OutputVariables from "./OutputVariables";
import {
  fontColorGrey,
  getSwapDetails,
  getSwapWPShare,
  settingsColor,
  menuBlueColor,
} from "../../helpers";
import { getSelectedProject, setShowComponent } from "../../store/user";
import OutputPMIntro from "./OutputPMIntro";
import OutputPMWorkPacks from "./OutputPMWorkPacks";
import OutputDLSummary from "./OutputDLSummary";
import OutputChangeSource from "./OutputChangeSource";
import OutputCopy from "./OutputCopy";

export default function OutputPM(props) {
  const dispatch = useDispatch();
  // source is "global" or "project"
  const { location } = props;
  const { outputEdit } = useSelector(getSelectedProject);
  const { outputs } = useSelector((state) => state.entities.project.data);
  const { showComponent, globalSettings, admin } = useSelector(
    (state) => state.user
  );
  const edit = showComponent === "outputPM";
  const frontColor = location === "admin" ? settingsColor : menuBlueColor;
  const editColor = globalSettings ? settingsColor : menuBlueColor;
  const color = edit ? editColor : frontColor;

  function showEdit() {
    const value = edit ? "" : "outputPM";
    dispatch(setShowComponent({ value }));
  }

  const wpSwap = useSelector(getSwapWPShare);
  const detailsSwap = useSelector(getSwapDetails);
  const mapObj = { ...detailsSwap.mapObj, ...wpSwap.mapObj };
  const variables = [...detailsSwap.variables, ...wpSwap.variables];

  return (
    <Container color={color}>
      {outputs ? (
        <>
          {edit && admin ? <OutputChangeSource /> : null}
          <OutputPMIntro mapObj={mapObj} edit={edit} />
          <OutputPMWorkPacks mapObj={mapObj} edit={edit} />
          <OutputDLSummary type="deliverable" />
          <OutputDLSummary type="milestone" />
          <OutputPMInfo mapObj={mapObj} edit={edit} />
          {outputEdit || admin ? (
            <button className="edit" onClick={showEdit}>
              {edit ? "Done" : "Edit"}
            </button>
          ) : null}
          {edit ? (
            <OutputVariables variables={variables} color={color} />
          ) : null}
        </>
      ) : (
        <OutputCopy />
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  color: ${fontColorGrey};

  h3.title {
    margin-top: 10px;
    margin-bottom: 5px;
    color: ${(props) => props.color};
  }
  p,
  span {
    height: 20px;
  }
  li {
    margin-left: 20px;
    list-style-type: disc;
  }
  textarea.textarea {
    width: 100%;
    height: 80px;
    border-color: lightgray;
    color: ${(props) => props.color};
    &:focus {
      border-color: ${(props) => props.color};
    }
  }
  h3 {
    margin-bottom: 10px;
  }
  button {
    background-color: ${menuBlueColor};
    border: none;
    color: white;
    font-weight: bold;
    padding: 10px 15px;
  }
  button.edit {
    position: absolute;
    right: 20px;
    background-color: ${(props) => props.color};
    color: white;
    font-weight: bold;
    border: none;
    width: 80px;
    height: 25px;
    padding: 0;
  }
`;
