import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateUserSelection } from "../store/user";
import LeftMenu from "../components/table/leftMenu";
import { TableContainer } from "../components/table/tableStyling";
import MarkedComplete from "../components/modals/markedComplete";
import { revenueColor, revenueFontColor, loadAll } from "../helpers"; // check this
import TargetMarket from "../components/revenue/targetMarketInfo"; // check this
import RevenueInfo from "../components/revenue/revenueInfo"; // check this
import Spinner from "../components/spinner";
import MarkAsComplete from "../components/markAsComplete";

function Revenue() {
  const dispatch = useDispatch();
  const status = useSelector(
    (state) => state.entities.project.data.status.revenue // check this
  );
  const { selectedRevenueOption, projectDataLoading } = useSelector(
    (state) => state.user
  ); // check this
  const menuList = ["Target Market", "Sales"]; // check this
  // const menuList = ["Target Market", "Sales", "R&D", "Details"]; // check this

  const menuData = {
    menuList,
    selectedOption: selectedRevenueOption,
    color: revenueFontColor, // check this
    backgroundColor: revenueColor, // check this
    updateOption: function (value) {
      dispatch(updateUserSelection({ key: "selectedRevenueOption", value })); // check this
    },
  };

  const data = {
    backgroundColor: menuData.backgroundColor,
    maxHeight: null,
  };

  function content() {
    if (selectedRevenueOption === "target market") return <TargetMarket />;
    if (selectedRevenueOption === "sales") return <RevenueInfo />;
  }

  useEffect(() => {
    loadAll();
  }, []);

  return projectDataLoading ? (
    <Spinner />
  ) : (
    <TableContainer data={data}>
      <div className="displayArea">
        <LeftMenu data={menuData} />
        <div className="content">
          <MarkAsComplete section="revenue" />
          <div>
            {status ? <MarkedComplete /> : null}
            {content()}
          </div>
        </div>
      </div>
    </TableContainer>
  );
}
export default Revenue;
