import React from "react";
import styled from "styled-components";
import { questionColor, wizardBlue } from "../../helpers";

function Toggle(props) {
  const { status, toggle } = props;

  return (
    <Container onClick={() => toggle()} status={status}>
      <div className="slider">
        <div className="indicator" />
      </div>
    </Container>
  );
}
export default Toggle;

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  .slider {
    position: relative;
    display: flex;
    align-items: center;
    /* border: 1px solid ${questionColor}; */
    border: 1px solid ${wizardBlue};
    background-color: white;
    height: 17px;
    width: 30px;
    border-radius: 10px;
  }
  .indicator {
    position: absolute;
    transition: left 0.3s, background-color 0.3s;
    left: ${(props) => (props.status ? "14px" : "3px")};
    height: 11px;
    width: 11px;
    background-color: ${(props) => (props.status ? wizardBlue : "darkgrey")};
    border-radius: 100%;
  }
  /* border-radius: 50%; */
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
  }
`;
