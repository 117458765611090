import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import Tippy from "@tippy.js/react";
import "tippy.js/dist/tippy.css";
import {
  updateTaskKey,
  reorderTasks,
  addTask,
} from "../../store/entities/tasks";
import GanttTaskRowInfo from "./ganttTaskRowInfo";
import tick from "../../images/tick-white.png";
import add from "../../images/addTask.png";
import addGray from "../../images/add-grey.png";
import bin from "../../images/bin-grey.png";
import { Container } from "./ganttPackStyling";
import {
  getSelectedProject,
  setShowComponent,
  updateUserSelection,
} from "../../store/user";
import TasksTemplateModal from "../../components/modals/tasksTemplateModal";
import ConfirmModal from "../modals/confirmModal";
import { getOtherIds } from "../../store/entities/other";
import { handleDeleteWorkPack } from "../../helpers/handleDeleteWorkPack";
import { getUnusedTaskTemplates, handleSendTasks } from "../../helpers";

function GanttPackWork(props) {
  const dispatch = useDispatch();
  const { title, index, packData, workPackageCount } = props;
  const { workPackageId } = packData[0];
  const wpNumber = index + 1;
  const { showComponent } = useSelector((state) => state.user);
  const { maxTasksPerPackage } = useSelector(getSelectedProject);
  const [lastTaskOfPack] = packData.slice(-1);
  const { taskId } = lastTaskOfPack;
  const taskCount = packData.length;
  const templates = useSelector(getUnusedTaskTemplates);

  const [newTitle, setNewTitle] = useState(title);

  function calculateDays() {
    let days = 0;
    packData.forEach((task) => {
      days += task.days;
    });
    return days;
  }

  function handleAddTask() {
    if (templates.length)
      dispatch(setShowComponent({ value: "taskTemplates" + wpNumber }));
    else dispatch(addTask({ lastTaskId: taskId }));
  }

  function handleMovingRow(result) {
    if (!result.destination || result.destination.index === result.source.index)
      return;
    const movement = result.destination.index - result.source.index;
    const taskId = packData[result.source.index].taskId;
    dispatch(reorderTasks({ taskId, movement }));
    handleSendTasks();
  }

  function handleEditTitle(value) {
    if (value === "Deliverables" || title === "Milestones") return;
    setNewTitle(value);
  }

  function showEditTitle() {
    dispatch(
      updateUserSelection({
        key: "showComponent",
        value: "workPackage" + index,
      })
    );
  }

  function acceptEditedTitle() {
    if (title !== newTitle)
      packData.forEach(({ taskId }) => {
        dispatch(
          updateTaskKey({
            taskId,
            key: "workPackageTitle",
            value: newTitle,
          })
        );
        handleSendTasks();
      });
    dispatch(updateUserSelection({ key: "showComponent", value: "" }));
  }

  // show confirm modal
  function confirmDelete() {
    dispatch(
      updateUserSelection({
        key: "showComponent",
        value: workPackageId,
      })
    );
  }

  // function to confirm
  const { selectedLeader } = useSelector((state) => state.user);
  const otherIds = useSelector(getOtherIds)[selectedLeader];
  function deleteWorkPack() {
    const taskList = [...new Set(packData.map((task) => task.taskId))];
    handleDeleteWorkPack(dispatch, workPackageId, taskList, otherIds);
  }

  return (
    <Container titleBarColor={props.titleBarColor} className="applyShadow">
      {/* {edit ? <EditModal setEdit={setEdit} /> : null} */}
      {showComponent === workPackageId ? (
        <ConfirmModal
          question={`Delete entire work package?`}
          comment="All associated data will also be deleted"
          confirm={deleteWorkPack}
        />
      ) : null}
      <div className="titleBar">
        {showComponent === "workPackage" + index ? (
          <div className="titleInput">
            <input
              autofocus
              className="title description"
              type="text"
              value={newTitle}
              onChange={(e) => handleEditTitle(e.target.value)}
            />
            <button className="imgButton" onClick={acceptEditedTitle}>
              <img src={tick} alt="accept" />
            </button>
          </div>
        ) : (
          <>
            <h3 className="title description" onClick={showEditTitle}>
              {`WP${wpNumber} - ${title ? title : "New Work Package"}`}
            </h3>
            <h3 className="resources">Resources</h3>
            <h3 className="days">Bars</h3>
            <h3 className="days">Days</h3>
          </>
        )}
      </div>
      <DragDropContext onDragEnd={handleMovingRow}>
        <Droppable droppableId={`workPackageNumber${index + 1}`}>
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {packData.map((task, index) => {
                return (
                  <Draggable
                    key={task.taskId}
                    draggableId={task.taskId}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        className="MonthContainer packBackground"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                      >
                        <GanttTaskRowInfo
                          packData={packData}
                          // taskPackTitles={taskPackTitles}
                          provided={provided}
                          key={index}
                          task={task}
                        />
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <div className="bottom">
        <div className="actions">
          {taskCount >= maxTasksPerPackage ? (
            <Tippy
              content={`Maximum ${maxTasksPerPackage} tasks per work package`}
            >
              <button className="imgButton">
                <img src={addGray} alt="add" />
              </button>
            </Tippy>
          ) : (
            <Tippy content={`Add a task`}>
              <button onClick={handleAddTask} className="imgButton">
                <img src={add} alt="add" />
              </button>
            </Tippy>
          )}
          {workPackageCount < 2 ? null : (
            <Tippy content="Delete work package">
              <button onClick={confirmDelete} className="imgButton delete">
                <img src={bin} alt="delete" />
              </button>
            </Tippy>
          )}
        </div>
        <div>
          <p className="days">{calculateDays()}</p>
        </div>
      </div>

      {showComponent === "taskTemplates" + wpNumber ? (
        <TasksTemplateModal
          lastTaskOfPack={lastTaskOfPack}
          taskCount={taskCount}
          maxTasksPerPackage={maxTasksPerPackage}
        />
      ) : null}
    </Container>
  );
}
export default GanttPackWork;
