import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { sendSelectedProject } from "../store/user";
import { highlight, menuBlueColor, menuBlueHighlightColor } from "../helpers";
import { projectColors } from "../helpers";

export default function ProjectRow(props) {
  const dispatch = useDispatch();
  const { project } = props;
  const { projectId, color, productPlatformName, clientFlavour } = project;
  const { selectedProjectId } = useSelector((state) => state.user);

  const selected = projectId === selectedProjectId;

  function selectProject() {
    dispatch(sendSelectedProject({ projectId }));
  }

  return (
    <Container color={projectColors[color]} selected={selected}>
      {selected ? null : (
        <div onClick={selectProject} className="selectProject"></div>
      )}

      <div className="indicatorSection">
        <div className="indicator"><h3>{selected ? "Selected" : ""}</h3></div>
      </div>

      <div className="gap">
        <div className="project">
          <p className="description">{productPlatformName}</p>
          <p>{clientFlavour}</p>
        </div>
      </div>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background-color: white;
  background-color: ${menuBlueColor};
  .selectProject {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    padding-right: 3px;
    align-items: center;
    width: 100%;
    height: 100%;
    /* border: 1px solid red; */
    transition: background-color 0.3s;
    cursor: pointer;
    :hover {
      background-color: rgba(200, 200, 200, 0.2);
    }
  }
  .gap {
    width: 100%;
    height: 100%;
    padding: 6px 25px;
    background-color: white;
    /* background-color: ${(props) =>
      props.selected ? "rgb(245, 245, 245)" : "white"}; */
  }
  .project {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid lightgray;
    width: 100%;
    min-height: 40px;
    border-radius: 4px;
    height: 100%;
    padding: 5px 10px 5px 5px;
    background-color: white;
  }
  .hidden {
    opacity: 0;
  }
  .indicatorSection {
    height: 100%;
  }
  .indicator {
    width: 100%;
    height: 40px;
    background-color: ${(props) => (props.selected ? highlight : null)};
    display: flex;
    justify-content: center;
    align-items: center;
    h3 {
      color: ${menuBlueHighlightColor};
      font-size: 14px;
    }
  }
`;
