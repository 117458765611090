import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getSelectedProject, setShowComponent } from "../../store/user";
import styled from "styled-components";
import { costsFontColor, numberToCurrency } from "../../helpers";
import { getTotalsByCategory, overviewModalHeight } from "../../helpers";
import Close from "../general/close";

function OverviewModal() {
  const dispatch = useDispatch();
  const { showComponent, selectedCostsOption } = useSelector(
    (state) => state.user
  );
  const { partners } = useSelector(getSelectedProject);
  const breakdown = selectedCostsOption === "breakdown";
  const overview = showComponent === "overview";
  const height = overview ? overviewModalHeight : 0;
  const offset = "0px";

  const { leaders, percents, grants, matchFunding } =
    useSelector(getTotalsByCategory);

  function showOverview() {
    dispatch(setShowComponent({ value: "overview" }));
  }

  const display = {
    0: partners > 1,
    1: partners > 1,
    2: partners > 2,
    3: true,
  };

  const closeData = {
    key: "showTaskEditModal",
  };

  return (
    <Container offset={offset} height={height}>
      {breakdown ? null : (
        <>
          {overview ? (
            <>
              <div className="close">
                <Close data={closeData} />
              </div>
              <div className="modalRow top">
                <h3 className="category">Overview</h3>
                <h3 className={`heading ${display[0] ? "" : "hide"}`}>
                  Partner 1
                </h3>
                <h3 className={`heading ${display[1] ? "" : "hide"}`}>
                  Partner 2
                </h3>
                <h3 className={`heading ${display[2] ? "" : "hide"}`}>
                  Partner 3
                </h3>
                <h3 className={`heading ${display[3] ? "" : "hide"}`}>Total</h3>
              </div>

              <div className="modalRow">
                <p className="category">Project Cost</p>
                {leaders.map((value, index) => (
                  <p
                    key={index}
                    className={`value ${display[index] ? "" : "hide"}`}
                  >
                    {numberToCurrency(value)}
                  </p>
                ))}
              </div>
              <div className="modalRow">
                <p className="category">Percentage share</p>
                {percents.map((value, index) => (
                  <p
                    key={index}
                    className={`value ${display[index] ? "" : "hide"}`}
                  >
                    {value}
                  </p>
                ))}
              </div>
              <div className="modalRow">
                <p className="category">IUK Grant</p>
                {grants.map((value, index) => (
                  <p
                    key={index}
                    className={`value ${display[index] ? "" : "hide"}`}
                  >
                    {numberToCurrency(value)}
                  </p>
                ))}
              </div>
              <div className="modalRow">
                <p className="category">Match Funding</p>
                {matchFunding.map((value, index) => (
                  <p
                    key={index}
                    className={`value ${display[index] ? "" : "hide"}`}
                  >
                    {numberToCurrency(value)}
                  </p>
                ))}
              </div>
            </>
          ) : (
            <div className="aboveLeft">
              <button onClick={showOverview}>
                <h3>Show Overview</h3>
              </button>
            </div>
          )}
        </>
      )}
    </Container>
  );
}

export default OverviewModal;

const Container = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: ${(props) => props.height};
  /* bottom: ${(props) => props.offset}; */
  /* bottom: 0; */
  transition: bottom 0.3s;
  background-color: rgba(230, 230, 230, 1);
  z-index: 10;
  .hide {
    display: none;
  }
  /* border: 1px solid red; */
  .aboveLeft {
    position: absolute;
    padding: 19px 30px;
    top: -66px;
    left: 0;
    button {
      border: none;
      color: gray;
      transition: transform 0.3s;
      :hover {
        /* text-decoration: underline; */
        transform: scale(1.08);
      }
    }
  }
  .close {
    position: absolute;
    top: 15px;
    right: 15px;
  }
  .modalRow {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 7px 80px 7px 30px;
    /* padding: 7px 80px;/ */
  }
  .category {
    font-weight: bold;
    width: 150px;
  }
  .value,
  .heading {
    width: 150px;
    text-align: right;
  }
  .top {
    color: ${costsFontColor};
  }
`;
