import React from "react";
import { useSelector } from "react-redux";
import { navHeight } from "../helpers";
import styled from "styled-components";
import PrintGanttPDF from "../export/PrintGanttPDF";
import PrintGanttOptions from "../export/PrintGanttOptions";

export default function PrintGantt(props) {
  const { projectName } = useSelector(
    (state) => state.entities.project.data.details
  );

  if (!projectName) props.history.push("/gantt");

  return (
    <Container>
      <PrintGanttOptions />
      <PrintGanttPDF />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: ${navHeight};
  margin: auto;
  padding-bottom: 50px;
  flex-direction: column;
`;
