import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { navBackground } from "../../helpers";

import bin from "../../images/bin-grey.png";
import warn from "../../images/warning.png";
import Tippy from "@tippy.js/react";
import {
  sendUserProjects,
  userRemoveProject,
} from "../../store/entities/users";
import { getProjectById } from "../../store/entities/setup";

export default function AccountRowProject(props) {
  const { projectId, userId } = props;
  const dispatch = useDispatch();

  const projectDesc = useSelector(getProjectById)[projectId];
  const content = "This project has been deleted";

  function removeProject() {
    dispatch(userRemoveProject({ userId, projectId }));
    dispatch(sendUserProjects(userId));
  }

  return (
    <Container>
      {projectDesc ? (
        <p>{projectDesc}</p>
      ) : (
        <Tippy content={content}>
          <div className="icon padding">
            <img src={warn} alt="warning" />
          </div>
        </Tippy>
      )}
      <Tippy content="Remove project">
        <div onClick={removeProject} className="icon hidden">
          <img src={bin} alt="delete" />
        </div>
      </Tippy>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background-color: white;
  justify-content: space-between;
  max-width: 100%;
  margin: 3px;
  :hover .hidden {
    opacity: 1;
  }
  select,
  p {
    font-size: 14px;
    color: ${navBackground};
    padding: 0;
    width: 100%;
  }
  .hidden {
    transition: opacity 0.3s;
    opacity: 0;
  }
`;
