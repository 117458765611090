import React from "react";
import { useSelector } from "react-redux";

export default function PrintGanttDeadlines(props) {
  const { scheduled, type, number, showDelsMils } = props;
  const { projectLength } = useSelector(
    (state) => state.entities.project.data.details
  );

  const schedule = new Array(projectLength).fill("");

  return (
    <div className="schedule">
      {schedule.map((_, index) => {
        return (
          <div
            key={index}
            className={`${type}Month ${
              (index + 1) % 3 === 0 ? "quarter" : null
            } `}
          >
            <div className="pill">
              {index === scheduled ? (
                <div className={`fill ${type}Color start end`}>
                  {showDelsMils ? (
                    <p>
                      {type}
                      {number}
                    </p>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        );
      })}
    </div>
  );
}
