import React from "react";
import styled from "styled-components";
// import { toast } from "react-toastify";
import { toastNotification } from "../../helpers";

// toast.configure();

function MarkedComplete() {
  let alerted = false;
  function alert() {
    if (!alerted) toastNotification("info", "Marked as complete, click edit");
  }
  return <Container onClick={alert} />;
}
export default MarkedComplete;

const Container = styled.div`
  position: absolute;
  background-color: rgba(1, 1, 1, 0);
  width: 100%;
  height: 100%;
  z-index: 1;
`;