// import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { recentFetch, reducerNotification } from "../../helpers";
import { apiCallBegan } from "../api";

const initialState = {
  loading: false,
  lastFetch: 0,
  data: [],
  lastSent: 0,
  lastEdit: 0,
  error: "",
};

const slice = createSlice({
  name: "other",
  initialState,
  reducers: {
    otherDataRequested: (other, _) => {
      other.loading = true;
    },
    otherDataRequestFailed: (other, action) => {
      other = initialState;
      reducerNotification("error", action.payload);
    },
    otherDataReceived: (other, action) => {
      // console.log(action.payload);
      other.data = action.payload;
      other.loading = false;
      other.lastFetch = Date.now();
    },

    addOther: (other, action) => {
      const { newOther, position } = action.payload;
      other.data.splice(position, 0, newOther);
      other.lastEdit = Date.now();
    },
    deleteOther: (other, action) => {
      const index = other.data.findIndex(
        (other) => other.otherId === action.payload.otherId
      );
      other.data.splice(index, 1);
      other.lastEdit = Date.now();
    },
    updateOther: (other, action) => {
      const { key, value, otherId } = action.payload;
      const index = other.data.findIndex(
        (person) => person.otherId === otherId
      );
      other.data[index][key] = value;
      other.lastEdit = Date.now();
    },
    reorderOther: (other, action) => {
      const originalIndex = other.data.findIndex(
        (other) => other.otherId === action.payload.other.otherId
      );
      const newIndex = originalIndex + action.payload.movement;
      const [journey] = other.data.splice(originalIndex, 1);
      other.data.splice(newIndex, 0, journey);
      other.lastEdit = Date.now();
    },

    apiSuccess: (other, action) => {
      other.loading = false;
      // reducerNotification("info", "Other saved");
      other.lastSent = Date.now();
    },
    apiFailed: (other, _) => {
      other.loading = false;
      reducerNotification("error", "Other failed");
    },
  },
});
export default slice.reducer;

export const {
  otherDataReceived,
  reorderOther,
  updateOther,
  addOther,
  deleteOther,
} = slice.actions;

const {
  otherDataRequested,
  otherDataRequestFailed,

  apiSuccess,
  apiFailed,
} = slice.actions;

// Action Creators

const url = "/other";

export const loadOtherData = () => (dispatch, getState) => {
  const { lastFetch } = getState().entities.other;
  // console.log("fetching other data...");
  if (recentFetch(lastFetch)) return;

  dispatch(
    apiCallBegan({
      url: url + "/selected",
      method: "get",
      data: null,
      onStart: otherDataRequested.type,
      onSuccess: otherDataReceived.type,
      onError: otherDataRequestFailed.type,
    })
  );
};

export const sendUpdatedOther = () => (dispatch, getState) => {
  const other = getState().entities.other;
  const { lastEdit, lastSent, data } = other;
  if (lastSent >= lastEdit) return;
  dispatch(
    apiCallBegan({
      url: url + "/selected",
      method: "put",
      data,
      // onStart: sendingOther.type,
      onSuccess: apiSuccess.type,
      onError: apiFailed.type,
    })
  );
};

export const initiateOther = (projectId) =>
  apiCallBegan({
    url: url + "/new",
    method: "post",
    data: {
      projectId,
      data: [],
    },
    // onStart: sendingOther.type,
    onSuccess: apiSuccess.type,
    onError: apiFailed.type,
  });

  export const cloneOther = (projectId) => (dispatch, getState) => {
    const { data } = getState().entities.other;
    dispatch(
      apiCallBegan({
        url: url + "/new",
        method: "post",
        data: {
          projectId,
          data,
        },
        // onStart: sendingAllocation.type,
        onSuccess: apiSuccess.type,
        onError: apiFailed.type,
      })
    );
  };

export const deleteProjectOthers = (projectId) =>
  apiCallBegan({
    url: url + "/selected",
    method: "delete",
    data: {
      projectId,
    },
    // onStart: sendingEmptyAssignments.type,
    onSuccess: apiSuccess.type,
    onError: apiFailed.type,
  });

// Selectors

export const getOtherCost = createSelector(
  (state) => state.entities.other,
  (other) => {
    // console.log("getOtherCost");
    const costs = {
      lead: 0,
      pOne: 0,
      pTwo: 0,
      combined: 0,
      category: "Other",
      breakdown: {
        lead: [],
        pOne: [],
        pTwo: [],
      },
    };
    other.data.forEach((other) => {
      const { cost, leader, description, otherId } = other;
      costs[leader] = costs[leader] + cost;
      costs.combined = costs.combined + cost;
      costs.breakdown[leader].push({ description, cost, otherId });
    });
    return costs;
  }
);

export const getOtherIds = createSelector(
  (state) => state.entities.other,
  (other) => {
    // console.log("getOtherIds");
    const ids = {
      lead: [],
      pOne: [],
      pTwo: [],
    };
    other.data.forEach((other) => {
      const { leader, otherId } = other;
      ids[leader].push(otherId);
    });
    // console.log(ids);
    return ids;
  }
);
