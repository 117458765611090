import { store } from "../store";
import { sendEmail } from "../store/entities/users";
import { replaceWords } from "./generalHelpers";

function handleDispatch(data) {
  const { user, providedSubject, providedBody, section, status, changedBy } =
    data;

  const { productPlatformName } =
    store.getState().entities.project.data.details;
  const { gantt, details, team, costs, revenue, setup, risks } =
    store.getState().entities.project.data.status;

  if (!user.name || !user.email) return

  const mapObj = {
    "{fullName}": user.name,
    "{firstName}": user.name.split(" ")[0],
    "{password}": user.password,
    "{userEmail}": user.email,
    "{productPlatformName}": productPlatformName,
    "{section}": section,
    "{status}": status,
    "{changedBy}": changedBy,
    "{gantt}": gantt ? "Complete" : "In progress",
    "{details}": details ? "Complete" : "In progress",
    "{team}": team ? "Complete" : "In progress",
    "{costs}": costs ? "Complete" : "In progress",
    "{revenue}": revenue ? "Complete" : "In progress",
    "{setup}": setup ? "Complete" : "In progress",
    "{risks}": risks ? "Complete" : "In progress",
    // these need copying into the email setup in the admin section
  };
  const email = user.email;
  const content = providedBody
    ? replaceWords(mapObj, providedBody)
    : providedBody;
  const subject = providedSubject
    ? replaceWords(mapObj, providedSubject)
    : providedSubject;
  store.dispatch(sendEmail({ email, subject, content }));
}

export const emailVariables = [
  { name: "{fullName}", description: "Full name of recipient" },
  { name: "{firstName}", description: "First name of recipient" },
  { name: "{password}", description: "Recipients password" },
  { name: "{userEmail}", description: "Recipients email" },
  { name: "{productPlatformName}", description: "Product or Platform Name" },
  { name: "{section}", description: "Project section" },
  { name: "{status}", description: "Updated section status" },
  { name: "{changedBy}", description: "Name of editor" },
  { name: "{gantt}", description: "Gantt status" },
  { name: "{details}", description: "Details status" },
  { name: "{team}", description: "Team status" },
  { name: "{costs}", description: "Costs status" },
  { name: "{revenue}", description: "Revenue status" },
  { name: "{setup}", description: "Setup status" },
  { name: "{risks}", description: "Risks status" },
];

export function notifyNewClient(user) {
  const { newClientSubject, newClientBody } =
    store.getState().entities.global.data.outputs;
  const data = {
    providedSubject: newClientSubject,
    providedBody: newClientBody,
    user,
  };
  handleDispatch(data);
}

export function notifyResetPassword(user) {
  const { resetPasswordSubject, resetPasswordBody } =
    store.getState().entities.global.data.outputs;
  const data = {
    providedSubject: resetPasswordSubject,
    providedBody: resetPasswordBody,
    user,
  };
  handleDispatch(data);
}

export function notifyRemovedClient(user) {
  const { removedClientSubject, removedClientBody } =
    store.getState().entities.global.data.outputs;
  const data = {
    providedSubject: removedClientSubject,
    providedBody: removedClientBody,
    user,
  };
  handleDispatch(data);
}

export function notifySectionStatus(info) {
  const { sectionSubject, sectionBody } =
    store.getState().entities.global.data.outputs;
  const { productPlatformName, notify } =
    store.getState().entities.project.data.details;
  const { name } = store.getState().user;

  notify.forEach((user) => {
    const data = {
      ...info, // { section, status }
      providedSubject: sectionSubject,
      providedBody: sectionBody,
      changedBy: name,
      productPlatformName,
      user,
    };
    if (user.section) handleDispatch(data);
  });
  console.log(info);
}

export function notifyProjectStatus() {
  const { projectCompleteSubject, projectCompleteBody } =
    store.getState().entities.global.data.outputs;
  const { productPlatformName, notify } =
    store.getState().entities.project.data.details;
  const { name } = store.getState().user;

  notify.forEach((user) => {
    const data = {
      providedSubject: projectCompleteSubject,
      providedBody: projectCompleteBody,
      changedBy: name,
      productPlatformName,
      user,
    };
    if (user.project) handleDispatch(data);
  });
}

// export function basicEmail(info) {
//   const { notify, providedSubject, providedBody } = info;
//   notify.forEach((user) => {
//     const data = { user, providedSubject, providedBody };
//     handleDispatch(data);
//   });
// }
