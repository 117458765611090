import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  teamColor,
  tableInputUnderline,
  fontColorGrey,
  handleSendRevenue,
} from "../../helpers";
import { updateMarket } from "../../store/entities/revenue";
import Close from "../general/close";
import { setShowComponent } from "../../store/user";

function TargetMarketModal(props) {
  const dispatch = useDispatch();
  const { index } = props;

  const { website, description } = useSelector(
    (state) => state.entities.revenue.data.markets[index]
  );

  function onChangeHandler(e) {
    const key = e.target.name;
    let value = e.target.value;
    if (key === "start" || key === "growth") {
      if (e.target.value) {
        value = parseInt(value.slice(-7));
      } else value = 0;
    }
    dispatch(updateMarket({ index, key, value }));
  }

  function sendChanges() {
    handleSendRevenue();
    dispatch(setShowComponent({ value: "" }));
  }

  const data = {
    providedFunction: sendChanges,
  };

  return (
    <Container id="background">
      <div className="editWindow">
        <Close data={data} />
        <h3 className="title">Target Market Source Information</h3>
        <label className="label" htmlFor="url">
          Website
        </label>
        <input
          // id={website}
          name="website"
          value={website || ""}
          onChange={onChangeHandler}
          // onBlur={sendChanges}
          className="input"
        />
        <label className="label" htmlFor="url">
          Description
        </label>
        <textarea
          // id="comment"
          name="description"
          value={description || ""}
          onChange={onChangeHandler}
          // onBlur={sendChanges}
          className="input"
        />
      </div>
      {/* <div className="content" /> */}
    </Container>
  );
}

export default TargetMarketModal;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(20, 20, 20, 0.6);
  z-index: 2;

  .editWindow {
    position: relative;
    min-height: 200px;

    background-color: white;
    border: 1px solid black;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 15px;
    /* z-index: 10; */
    .title {
      justify-content: flex-start;
    }
    .input {
      min-width: 350px;
      max-width: 350px;
      position: relative;
      padding: 5px;
      margin: 0;
      margin-top: 5px;
      min-height: 30px;
      border-radius: 0;
      padding: 0;
      border-bottom: 2px solid ${tableInputUnderline};
      &:focus {
        border: none;
        border-bottom: 2px solid ${teamColor};
      }
    }
    .label {
      display: flex;
      align-items: center;
      min-height: 30px;
      margin-top: 5px;
      border: none;
      color: ${teamColor};
      min-width: 0px;
      font-weight: bold;
    }
    textarea.input {
      border: 2px solid ${tableInputUnderline};
      height: 200px;
      border-radius: 5px;
      padding: 3px;
      &:focus {
        border: 2px solid ${teamColor};
      }
    }
    h3 {
      color: ${fontColorGrey};
    }
  }
`;
