import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Tippy from "@tippy.js/react";
import "tippy.js/dist/tippy.css";
import { handleSendOther, isNumberKey } from "../../helpers";
import bin from "../../images/bin-grey.png";
import reorder from "../../images/reorder.png";
import { deleteOther, updateOther } from "../../store/entities/other";
import { toggleAssignment } from "../../store/entities/assignments";

function OtherRow(props) {
  const dispatch = useDispatch();
  const { other, provided, index, leader } = props;
  const { cost, otherId, description } = other;
  const { selectedLeader } = useSelector((state) => state.user);

  function onChangeHandler(e) {
    const key = e.target.name;
    let value = e.target.value;
    if (key === "cost" || key === "quantity") {
      if (e.target.value) {
        value = parseInt(value.slice(-5));
      } else value = 0;
    }
    dispatch(
      updateOther({
        otherId,
        key,
        value,
      })
    );
  }

  function handleDeleteOther() {
    dispatch(deleteOther({ otherId }));
    dispatch(toggleAssignment({ leader }));
    handleSendOther();
  }

  return (
    <div className="row">
      {selectedLeader === "combined" ? null : (
        <Tippy content="Drag to reorder rows">
          <div {...provided.dragHandleProps} className="hidden grabHandle">
            <img src={reorder} alt="reorder" />
          </div>
        </Tippy>
      )}

      <input
        id={index + "description"}
        name="description"
        value={description}
        onChange={onChangeHandler}
        onBlur={handleSendOther}
        className="field materialsDescription"
      />

      <div className="pound">
        <p>£</p>
        <input
          type="text"
          id={index + "cost"}
          name="cost"
          value={cost}
          onKeyDown={isNumberKey}
          onChange={onChangeHandler}
          onBlur={handleSendOther}
          className="field materialsCost"
        />
      </div>

      {/* <input
        type="text"
        id={index + "cost"}
        name="cost"
        value={cost}
        onKeyDown={isNumberKey}
        onChange={onChangeHandler}
        onBlur={handleSendOther}
        className="field materialsCost"
      /> */}
      <div className="hidden deleteIcon">
        {selectedLeader === "combined" ? null : (
          <img
            src={bin}
            alt="delete"
            style={{ cursor: "pointer" }}
            onClick={handleDeleteOther}
          />
        )}
      </div>
    </div>
  );
}
export default OtherRow;
