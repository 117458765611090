// import { debounce } from "lodash";
import { toastNotification } from "./config";
import html2pdf from "html2pdf.js";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

export function isNumberKey(e) {
  const charCode = e.which ? e.which : e.keyCode;
  // if (charCode > 31 && (charCode < 48 || charCode > 57)) e.preventDefault();
  if (
    (charCode < 48 &&
      !(charCode === 37 || charCode === 39 || charCode === 8)) ||
    charCode > 57
  )
    e.preventDefault();
}

export function leadingZero(number) {
  let zeroNumber = number.toString();
  zeroNumber = number < 10 ? 0 + zeroNumber : zeroNumber;
  return zeroNumber;
}

export function nextIndexOfGroup(group, array) {
  const [lastEntry] = group.slice(-1);
  const lastEntryIndex = array.indexOf(lastEntry);
  const position = lastEntryIndex + 1;
  console.log(lastEntry);
  console.log(position);
  return position;
}

export function roundTo(number, decimalPlaces) {
  const roundBy = Math.pow(10, decimalPlaces);
  const result = Math.round(number * roundBy) / roundBy;
  return result;
}

export function numberToCurrency(number) {
  if (number) {
    const rounded = Math.round(number);
    let formattedCost = rounded.toString();
    let result = "";
    let counter = 0;
    for (let i = formattedCost.length - 1; i >= 0; i--) {
      const character = formattedCost.charAt(i);
      if (counter > 0 && counter % 3 === 0) {
        result = "," + result;
      }
      result = character + result;
      counter++;
    }
    // result = "£" + result;
    return result;
  } else return 0;
}

export function calculateDepreciation(data) {
  const { depreciation, currentValue, utilisation, projectLength } = data;
  const residual =
    depreciation === 0
      ? 0
      : Math.round((1 - projectLength / depreciation) * currentValue);
  const netCost = Math.round((currentValue - residual) * utilisation) / 100;
  return netCost;
}

export function removeTrailingZeros(number) {
  const index = number.length;
  let remove = 0;
  for (let i = index; i > index - 6; i--) {
    const char = number.charAt(i);
    if (char === "0") remove++;
    else if (char === ".") {
      remove++;
      break;
    } else if (Number.isInteger(parseInt(char))) break;
  }
  let result = number;
  if (remove) result = number.slice(0, -remove);
  return result;
}

export function shortCurrency(number) {
  const rounded = numberToCurrency(number);
  let result = 0;
  if (number >= 1000000000000) {
    const keep = rounded.toString().length - 12;
    result = rounded.slice(0, keep) + "bn";
  } else if (number >= 1000000000) {
    result = rounded.slice(0, 5).replace(",", ".");
    result = removeTrailingZeros(result);
    result = result + "bn";
  } else if (number >= 1000000) {
    result = rounded.slice(0, 5).replace(",", ".");
    result = removeTrailingZeros(result);
    result = result + "m";
  } else {
    result = rounded;
  }
  result = "£" + result;
  return result;
}

export function generateArray(start = 1, end = 1, increment = 1) {
  const length = [];
  for (let i = start; i <= end; i = i + increment) {
    length.push(i);
  }
  return length;
}

export function swellOnLoad(element) {
  setTimeout(() => {
    element.classList.add("swellOnLoad");
  }, 250);
  setTimeout(() => {
    element.classList.remove("swellOnLoad");
  }, 750);
}

export function capitaliseFirstLetter(string) {
  if (string) return string.charAt(0).toUpperCase() + string.slice(1);
}

export function randomInteger(number) {
  return Math.floor(Math.random() * number);
}

export function downloadAsJson(exportData, fileName) {
  const dataStr =
    "data:text/json;charset=utf-8," +
    encodeURIComponent(JSON.stringify(exportData));
  const node = document.createElement("a");
  node.setAttribute("href", dataStr);
  node.setAttribute("download", fileName + ".json");
  document.body.appendChild(node); // required for firefox
  node.click();
  node.remove();
}

export function logout() {
  toastNotification("info", "Successfully logged out");
  localStorage.removeItem("token");
  localStorage.removeItem("authenticated");
  localStorage.removeItem("admin");
  setTimeout(() => {
    window.location.href = "/login";
  }, 2500);
}

export function replaceWords(mapObj, sentence) {
  let string = sentence;
  var re = new RegExp(Object.keys(mapObj).join("|"), "gi");
  string = string.replace(re, function (matched) {
    return mapObj[matched];
  });
  return string;
}

export function generatePDF(data) {
  const { filename, elementId } = data;
  console.log();
  const element = document.getElementById(elementId);
  const options = {
    margin: 0,
    filename,
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: { scale: 4 },
    jsPDF: { unit: "in", format: "A4", orientation: "portrait" },
  };
  const doc = html2pdf().set(options).from(element);
  doc.save();
}

let timerId;
export function deBounce(callback, wait = 3000) {
  clearTimeout(timerId);
  timerId = setTimeout(callback, wait);
}

export function generateRandomId(prefix) {
  const time = moment(Date.now()).toString().slice(4, 25).toLowerCase();
  const unique = uuidv4().slice(0, 8);
  const id = prefix
    ? prefix.toLowerCase() + " " + time + unique
    : time + unique;
  return id;
}

export function generatePassword() {
  return uuidv4().slice(0, 8);
}
