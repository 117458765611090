import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { getSelectedProject } from "../../store/user";
import { fontColorGrey, handleSendSetup, settingsColor } from "../../helpers";
import { updateProject } from "../../store/entities/setup";
import OutputCopy from "./OutputCopy";

export default function OutputOptions() {
  const dispatch = useDispatch();
  const { outputs } = useSelector((state) => state.entities.project.data);
  const { projectDesc, outputAccess, outputEdit, projectId } =
    useSelector(getSelectedProject);

  function output(key, value) {
    dispatch(updateProject({ projectId, key, value }));
  }

  return (
    <Container>
      {outputs ? (
        <div className="section">
          <h2 className="title project">{projectDesc}</h2>
          <h3 className="title">Permissions</h3>
          <div className="row">
            <p className="desc">
              Allow clients on this project to access outputs
            </p>
            <button
              onBlur={handleSendSetup}
              onClick={() => output("outputAccess", !outputAccess)}
            >
              {outputAccess ? "Yes" : "No"}
            </button>
          </div>
          <div className="row">
            <p className="desc">
              Allow clients on this project to edit their outputs
            </p>
            <button
              onBlur={handleSendSetup}
              onClick={() => output("outputEdit", !outputEdit)}
            >
              {outputEdit ? "Yes" : "No"}
            </button>
          </div>
        </div>
      ) : (
        <OutputCopy />
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  color: ${fontColorGrey};
  .row {
    margin-left: 0;
    margin-bottom: 0;
  }
  .desc {
    /* width: 350px; */
  }
  button {
    background-color: ${settingsColor};
    border: none;
    font-weight: bold;
    color: white;
    padding: 5px;
    width: 70px;
    margin-left: 10px;
  }

  h2.title {
    font-size: 20px;
    color: ${fontColorGrey};
    padding-left: 0;
  }
  h3.title {
    margin-top: 10px;
    margin-bottom: 5px;
    color: ${(props) => props.color};
  }
`;
