import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { updateUserSelection } from "../../store/user";
import TableTabs from "../table/TableTabs";
import OutputCosts from "./OutputCosts";
import OutputTeam from "./OutputTeam";
import OutputPM from "./OutputPM";
import OutputRisks from "./OutputRisks";
import OutputOptions from "./OutputOptions";

export default function OutputTable() {
  const dispatch = useDispatch();
  const { selectedOutput } = useSelector((state) => state.user);

  const tabs = ["Team", "Project Management", "Risks", "Costs", "Options"];
  // tabs
  const data = {
    // tabs: ["Team", ''],
    tabs,
    selectedTab: selectedOutput,
    select: (selected) => {
      dispatch(
        updateUserSelection({
          key: "selectedOutput",
          value: selected,
        })
      );
    },
  };

  const contents = {
    team: <OutputTeam />,
    risks: <OutputRisks location="admin" />,
    "project management": <OutputPM location="admin" />,
    costs: <OutputCosts location="admin" />,
    options: <OutputOptions />,
  };

  return (
    <Container>
      <TableTabs data={data} />
      <div className="table">
        <div className="contents">{contents[selectedOutput]}</div>
      </div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  overflow: hidden;
  .table {
    padding: 20px 30px;
  }
  .contents {
    max-height: calc(100vh - 275px);
    overflow: scroll;
  }
`;
