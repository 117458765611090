import React from "react";
import { runWizard } from "../../helpers";
import {
  wizardComplete,
  wizardComponent,
  wizardReset,
} from "../../store/entities/project";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedProject, setShowComponent } from "../../store/user";
import Close from "./close";

function wizardEndModal() {
  const dispatch = useDispatch();
  let partners;
  const project = useSelector(getSelectedProject);
  if (project) partners = project.partners;

  const { answers } = useSelector(
    (state) => state.entities.project.data.wizard
  );

  function summary() {
    dispatch(wizardComponent({ value: "summary" }));
  }
  function finish() {
    dispatch(setShowComponent({ value: "" }));
    setTimeout(() => {
      runWizard(dispatch, answers, partners);
      dispatch(wizardComplete());
    }, 500);
  }

  function close() {
    dispatch(setShowComponent({ value: "" }));
    dispatch(wizardReset());
  }

  const closeData = {
    providedFunction: close,
  };

  return (
    <div className="modal">
      <Close data={closeData} />
      <h3 className="title">Setup Wizard</h3>
      <div>
        <p className="bold">The wizard is now complete</p>
        {/* <p>You can view a summary, or simply apply the settings.</p> */}
      </div>
      <div className="buttons mt">
        <button onClick={summary}>View Responses</button>
        <button onClick={finish}>Close Wizard</button>
      </div>
    </div>
  );
}

export default wizardEndModal;
