import { toastNotification } from "./config";
import { store } from "../store";
import {
  sendUpdatedDetails,
  wizardAddOptions,
  wizardClearPrevious,
} from "../store/entities/project";

export const questions = [
  {
    // index 0
    question: "Will your project use subcontractors?", // first line, bold
    line2: "", // second line, bold
    note: "", // optional line not bold
    summary: "Subcontractors", // this is shown on the summary
    skip: 0, // if false, skip this many questions
    dep: 0, // this question is dependant on how many questions?
    action: (answer) => {
      if (answer) addToProject("sub");
    },
  },
  {
    // index 1
    question: "Will your project involve software development?",
    line2: "",
    note: "",
    summary: "Software",
    skip: 4,
    dep: 0,
    action: (answer) => {
      if (answer) addToProject("sw");
    },
  },
  {
    // index 2
    question: "Will your project involve data usage?",
    line2: "",
    note: "(collection, storage, analysis etc)",
    summary: "Data Usage",
    skip: 0,
    dep: 1,
    action: (answer) => {
      if (answer) addToProject("dat");
    },
  },
  {
    // index 3
    question: "Will your project develop or utilise",
    line2: "artificial intelligence?",
    note: "",
    summary: "Artificial Intelligence",
    skip: 1,
    dep: 1,
    action: (answer) => {
      if (answer) addToProject("ai");
    },
  },
  {
    // index 4
    question: "Select all that apply to your project",
    line2: "",
    note: "",
    summary: "multi...",
    data: ["Natural Language Processing", "Computer Vision"],
    skip: 0,
    dep: 2,
    action: (answer) => {
      if (answer["Natural language processing"]) addToProject("nlp");
      if (answer["Computer vision"]) addToProject("vis");
    },
  },
  {
    // index 5
    question:
      "Will your project develop or utilise ",
    line2: "distributed ledger technology, such as blockchain?",
    note: "",
    summary: "Distributed Ledger Technology",
    skip: 0,
    dep: 1,
    action: (answer) => {
      if (answer) addToProject("dlt");
    },
  },
  {
    // index 6
    question: "Will your project develop a user interface?",
    line2: "",
    note: "",
    summary: "User Interface",
    skip: 0,
    dep: 0,
    action: (answer) => {
      if (answer) addToProject("ui");
    },
  },
  {
    // index 7
    question: "Do you expect COVID to impact the project?",
    line2: "",
    note: "",
    summary: "COVID",
    skip: 0,
    dep: 0,
    action: (answer) => {
      if (answer) addToProject("cov");
    },
  },
  {
    // index 8
    question: "Does your project develop any hardware?",
    line2: "",
    note: "",
    summary: "Manufacturing",
    skip: 0,
    dep: 0,
    action: (answer) => {
      if (answer) addToProject("man");
    },
  },
  {
    // index 9
    question: "Will your project develop technologies",
    line2: "aimed at any of these sectors?",
    note: "Select all that apply",
    // data: ["Medical", "Manufacturing", "Finance"],
    data: ["Medical", "Finance"],
    skip: 0,
    dep: 0,
    action: (answer) => {
      if (answer.Medical) addToProject("med");
      // if (answer.Manufacturing) addToProject("man");
      if (answer.Finance) addToProject("fin");
    },
  },
];

export const questionCount = questions.length

function addToProject(type) {
  const { roles, tasks, riskTemplates } = store.getState().entities.global.data;
  const tempResult = riskTemplates.riskData.filter(
    (temp) => temp.category === type
  );
  const roleResult = roles.roleData.filter((role) => role.category === type);
  const taskResult = tasks.taskData.filter((task) => task.category === type);
  store.dispatch(wizardAddOptions({ taskResult, tempResult, roleResult }));
}

export function runWizard() {
  store.dispatch(wizardClearPrevious());

  const state = store.getState();
  const { answers } = state.entities.project.data.wizard;
  const { selectedProjectId } = state.user;
  const projects = state.entities.setup.data;
  const index = store
    .getState()
    .entities.setup.data.findIndex(
      (project) => project.projectId === selectedProjectId
    );
  const { partners } = projects[index];

  addToProject("gen");
  if (partners > 1) addToProject("col");

  questions.forEach((question, index) => {
    question.action(answers[index].result);
  });

  store.dispatch(sendUpdatedDetails())

  toastNotification(
    "info",
    "Project updated. Customised options now available."
  );
}
