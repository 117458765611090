import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { toggleAssignment } from "../../store/entities/assignments";
import styled from "styled-components";
import { handleSendAssignments, selectedNo, selectedYes } from "../../helpers";

export default function (props) {
  const dispatch = useDispatch();
  const { category, workPackageId, other } = props;
  const { selectedLeader } = useSelector((state) => state.user);
  const cat = category.toLowerCase();
  const assignedData = useSelector((state) => state.entities.assignments.data)[
    selectedLeader
  ][cat];

  let assigned = false;
  if (assignedData) assigned = assignedData.includes(workPackageId);

  function toggleAssign() {
    let catKey = cat;
    if (other) catKey = other.otherId;
    dispatch(
      toggleAssignment({
        leader: selectedLeader,
        category: catKey,
        workPackageId,
      })
    );
    handleSendAssignments();
  }

  return (
    <ToggleButton className="assignmentRow">
      <button
        onClick={toggleAssign}
        className={`toggle ${assigned ? "yes" : "no"}`}
      >
        {assigned ? "Yes" : "No"}
      </button>
    </ToggleButton>
  );
}

const ToggleButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 10px;
  /* border: 1px solid red; */
  .toggle {
    width: 60px;
    height: 25px;
    transition: transform 0.3s;
    :hover {
      transform: scale(1.08);
    }
  }
  .yes {
    background-color: ${selectedYes};
  }
  .no {
    background-color: ${selectedNo};
  }
`;
