import React from "react";
// import { newTaskFromTemplate } from "../../helpers";
import { useDispatch } from "react-redux";
import add from "../../images/plusButtonBlue.png";
import { addTask } from "../../store/entities/tasks";
import { setShowComponent } from "../../store/user";

function TaskRow(props) {
  const dispatch = useDispatch();
  const {
    index,
    lastTaskOfPack,
    maxTasksPerPackage,
    taskCount,
    filteredTemplates,
  } = props;
  const { taskTitle, taskId } = props.template;

  function handleAddTemplate(e) {
    dispatch(addTask({ lastTaskId: lastTaskOfPack.taskId, taskTitle, taskId }));
    if (taskCount >= maxTasksPerPackage - 1 || filteredTemplates.length === 1) {
      dispatch(setShowComponent({ value: "" }));
    }
  }

  return (
    <div className="template">
      <div className="contents">
        <p>{taskTitle}</p>
      </div>
      <div className="add hidden" id={`button${index}`}>
        <img src={add} alt="add" onClick={handleAddTemplate} />
      </div>
    </div>
  );
}

export default TaskRow;
