import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  dragElement,
  generatePDF,
  menuBlueColor,
  navBackground,
  navHeight,
  rev2,
} from "../helpers";
import plus from "../images/plus.png";
import minus from "../images/minus.png";
import question from "../images/qMark.png";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { ganttRandomColors, updatePrintGantt } from "../store/user";
import Tippy from "@tippy.js/react";
import PrintGanttColor from "./PrintGanttColor";

export default function PrintGanttOptions() {
  const {
    headerHeight,
    titleHeight,
    rowHeight,
    headerSize,
    titleSize,
    textSize,
    resourcesSize,
    descriptionLength,
    resourcesLength,
    textPosition,
    showTaskDays,
    showPackDays,
    showDelsMils,

    descriptionWidth,
    resourcesWidth,
    daysWidth,
    daysPosition,
    showSizing,
    barShape,

    top,
    left,
  } = useSelector((state) => state.user.gantt);
  const { projectName } = useSelector(
    (state) => state.entities.project.data.details
  );

  const dispatch = useDispatch();

  function adjust(key, value) {
    dispatch(updatePrintGantt({ key, value }));
  }
  function toggle(key, value) {
    dispatch(updatePrintGantt({ key, value }));
  }
  function colors() {
    dispatch(ganttRandomColors());
  }
  function moveDays() {
    const key = "daysPosition";
    const value = (daysPosition + 1) % 3;
    dispatch(updatePrintGantt({ key, value }));
  }
  function adjustBar() {
    const key = "barShape";
    const value = (barShape + 2) % 10;
    dispatch(updatePrintGantt({ key, value }));
  }

  function save() {
    const filename = projectName + " Gantt Chart";
    const elementId = "pdf";
    generatePDF({ filename, elementId });
  }

  const history = useHistory();
  function close() {
    history.push("gantt");
  }

  useEffect(() => {
    dragElement(document.getElementById("printOptions"));
  });

  return (
    <Container id="printOptions" left={left} top={top}>
      {/* <Container id="printOptions" onClick={dragElement} left={left} top={top}> */}
      <div className="info">
        <Tippy content="For optimum results use Google Chrome">
          <img src={question} alt="info" />
        </Tippy>
      </div>
      <button onClick={close}>Close Preview</button>
      <div className="open">
        <h3 id="">Theme</h3>
        {rev2 ? (
          <button className="random" onClick={colors}>
            Random
          </button>
        ) : null}
      </div>
      <div className="option">
        <p>Header</p>
        <PrintGanttColor objKey="header" />
      </div>
      <div className="option">
        <p>Timeline</p>
        <PrintGanttColor objKey="years" />
      </div>
      <div className="option">
        <p>Work package Title</p>
        <PrintGanttColor objKey="taskTitle" />
      </div>
      <div className="option">
        <p>Gantt Bar</p>
        <PrintGanttColor objKey="taskBar" />
      </div>
      <div className="option">
        <p>Deliverables</p>
        <PrintGanttColor objKey="delTitle" />
      </div>
      <div className="option">
        <p>Milestones</p>
        <PrintGanttColor objKey="milTitle" />
      </div>
      {rev2 ? (
        <>
          <div className="option">
            <p>Days Position</p>
            <div onClick={moveDays} className="toggle" />
          </div>
          <div className="option">
            <p>Gantt bar corners</p>
            <div onClick={adjustBar} className="toggle" />
          </div>
          <div className="option">
            <p>Show Pack Days</p>
            <div
              onClick={() => toggle("showPackDays", !showPackDays)}
              className={`toggle ${showPackDays ? "toggleOn" : ""}`}
            />
          </div>
          <div className="option">
            <p>Show Task Days</p>
            <div
              onClick={() => toggle("showTaskDays", !showTaskDays)}
              className={`toggle ${showTaskDays ? "toggleOn" : ""}`}
            />
          </div>
          <div className="option">
            <p>Show Deadline Index</p>
            <div
              onClick={() => toggle("showDelsMils", !showDelsMils)}
              className={`toggle ${showDelsMils ? "toggleOn" : ""}`}
            />
          </div>
        </>
      ) : null}

      <div className="open">
        <h3 id="">Sizing</h3>
        {rev2 ? (
          <button
            className="random"
            onClick={() => adjust("showSizing", !showSizing)}
          >
            {showSizing ? "hide" : "show"}
          </button>
        ) : null}
      </div>

      <div className={`sizing ${showSizing ? "showSizing" : ""}`}>
        <div className="option">
          <p>Header Height</p>
          <div className="adjust">
            <div
              onClick={() => adjust("headerHeight", headerHeight - 1)}
              className="icon"
            >
              <img src={minus} alt="adjust" />
            </div>
            <div className="value">
              <p>{headerHeight}</p>
            </div>
            <div
              onClick={() => adjust("headerHeight", headerHeight + 1)}
              className="icon"
            >
              <img src={plus} alt="adjust" />
            </div>
          </div>
        </div>
        <div className="option">
          <p>Title Height</p>
          <div className="adjust">
            <div
              onClick={() => adjust("titleHeight", titleHeight - 1)}
              className="icon"
            >
              <img src={minus} alt="adjust" />
            </div>
            <div className="value">
              <p>{titleHeight}</p>
            </div>
            <div
              onClick={() => adjust("titleHeight", titleHeight + 1)}
              className="icon"
            >
              <img src={plus} alt="adjust" />
            </div>
          </div>
        </div>
        <div className="option">
          <p>Row Height</p>
          <div className="adjust">
            <div
              onClick={() => adjust("rowHeight", rowHeight - 1)}
              className="icon"
            >
              <img src={minus} alt="adjust" />
            </div>
            <div className="value">
              <p>{rowHeight}</p>
            </div>
            <div
              onClick={() => adjust("rowHeight", rowHeight + 1)}
              className="icon"
            >
              <img src={plus} alt="adjust" />
            </div>
          </div>
        </div>
        {rev2 ? (
          <>
            <div className="option">
              <p>Description width</p>
              <div className="adjust">
                <div
                  onClick={() =>
                    adjust("descriptionWidth", descriptionWidth - 1)
                  }
                  className="icon"
                >
                  <img src={minus} alt="adjust" />
                </div>
                <div className="value">
                  <p>{descriptionWidth}</p>
                </div>
                <div
                  onClick={() =>
                    adjust("descriptionWidth", descriptionWidth + 1)
                  }
                  className="icon"
                >
                  <img src={plus} alt="adjust" />
                </div>
              </div>
            </div>
            <div className="option">
              <p>Resources width</p>
              <div className="adjust">
                <div
                  onClick={() => adjust("resourcesWidth", resourcesWidth - 1)}
                  className="icon"
                >
                  <img src={minus} alt="adjust" />
                </div>
                <div className="value">
                  <p>{resourcesWidth}</p>
                </div>
                <div
                  onClick={() => adjust("resourcesWidth", resourcesWidth + 1)}
                  className="icon"
                >
                  <img src={plus} alt="adjust" />
                </div>
              </div>
            </div>
            <div className="option">
              <p>Days width</p>
              <div className="adjust">
                <div
                  onClick={() => adjust("daysWidth", daysWidth - 1)}
                  className="icon"
                >
                  <img src={minus} alt="adjust" />
                </div>
                <div className="value">
                  <p>{daysWidth}</p>
                </div>
                <div
                  onClick={() => adjust("daysWidth", daysWidth + 1)}
                  className="icon"
                >
                  <img src={plus} alt="adjust" />
                </div>
              </div>
            </div>
          </>
        ) : null}
        <div className="option">
          <p>Header Font Size</p>
          <div className="adjust">
            <div
              onClick={() => adjust("headerSize", headerSize - 1)}
              className="icon"
            >
              <img src={minus} alt="adjust" />
            </div>
            <div className="value">
              <p>{headerSize}</p>
            </div>
            <div
              onClick={() => adjust("headerSize", headerSize + 1)}
              className="icon"
            >
              <img src={plus} alt="adjust" />
            </div>
          </div>
        </div>
        <div className="option">
          <p>Pack Title Font Size</p>
          <div className="adjust">
            <div
              onClick={() => adjust("titleSize", titleSize - 1)}
              className="icon"
            >
              <img src={minus} alt="adjust" />
            </div>
            <div className="value">
              <p>{titleSize}</p>
            </div>
            <div
              onClick={() => adjust("titleSize", titleSize + 1)}
              className="icon"
            >
              <img src={plus} alt="adjust" />
            </div>
          </div>
        </div>
        <div className="option">
          <p>Row Font Size</p>
          <div className="adjust">
            <div
              onClick={() => adjust("textSize", textSize - 1)}
              className="icon"
            >
              <img src={minus} alt="adjust" />
            </div>
            <div className="value">
              <p>{textSize}</p>
            </div>
            <div
              onClick={() => adjust("textSize", textSize + 1)}
              className="icon"
            >
              <img src={plus} alt="adjust" />
            </div>
          </div>
        </div>
        <div className="option">
          <p>Resource Font Size</p>
          <div className="adjust">
            <div
              onClick={() => adjust("resourcesSize", resourcesSize - 1)}
              className="icon"
            >
              <img src={minus} alt="adjust" />
            </div>
            <div className="value">
              <p>{resourcesSize}</p>
            </div>
            <div
              onClick={() => adjust("resourcesSize", resourcesSize + 1)}
              className="icon"
            >
              <img src={plus} alt="adjust" />
            </div>
          </div>
        </div>
        <div className="option">
          <p>Description Characters</p>
          <div className="adjust">
            <div
              onClick={() => adjust("descriptionLength", descriptionLength - 1)}
              className="icon"
            >
              <img src={minus} alt="adjust" />
            </div>
            <div className="value">
              <p>{descriptionLength}</p>
            </div>
            <div
              onClick={() => adjust("descriptionLength", descriptionLength + 1)}
              className="icon"
            >
              <img src={plus} alt="adjust" />
            </div>
          </div>
        </div>
        <div className="option">
          <p>Resources Characters</p>
          <div className="adjust">
            <div
              onClick={() => adjust("resourcesLength", resourcesLength - 1)}
              className="icon"
            >
              <img src={minus} alt="adjust" />
            </div>
            <div className="value">
              <p>{resourcesLength}</p>
            </div>
            <div
              onClick={() => adjust("resourcesLength", resourcesLength + 1)}
              className="icon"
            >
              <img src={plus} alt="adjust" />
            </div>
          </div>
        </div>
        <div className="option">
          <p>Vertical Text Position</p>
          <div className="adjust">
            <div
              onClick={() => adjust("textPosition", textPosition - 1)}
              className="icon"
            >
              <img src={minus} alt="adjust" />
            </div>
            <div className="value">
              <p>{textPosition}</p>
            </div>
            <div
              onClick={() => adjust("textPosition", textPosition + 1)}
              className="icon"
            >
              <img src={plus} alt="adjust" />
            </div>
          </div>
        </div>
      </div>
      <button onClick={save}>Download Gantt PDF</button>
    </Container>
  );
}

const Container = styled.div`
  z-index: 1;
  position: absolute;
  top: calc(${navHeight} + 20px);
  left: 3%;
  background-color: rgba(255, 255, 255, 0.95);
  color: ${navBackground};
  display: flex;
  flex-direction: column;

  padding: 10px;
  border-radius: 8px;
  overflow: hidden;
  width: 270px;

  padding-top: 20px;

  cursor: move;

  h3 {
    margin: 10px 0;
    min-width: 60px;
  }
  button {
    background-color: ${menuBlueColor};
    color: white;
    font-weight: bold;
    border: none;
    padding: 8px;
    transition: transform 0.3s;
    :hover {
      transform: scale(1.05);
    }
    margin-top: 10px;
  }
  .open {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
  }
  .random {
    position: absolute;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    font-size: 12px;
    width: 70px;
    padding: 5px;
  }
  .sizing {
    height: 0px;
    overflow: hidden;
    transition: height 0.3s;
    margin-bottom: 10px;
  }
  .showSizing {
    height: 325px;
  }
  .info {
    position: absolute;
    top: 3px;
    right: 3px;
    max-height: 20px;
    max-width: 20px;
    cursor: initial;
  }
  .adjust {
    display: flex;
    align-items: center;
  }
  .value {
    display: flex;
    justify-content: center;
    width: 35px;
  }
  .icon {
    max-height: 18px;
    max-width: 18px;
    cursor: pointer;
    transition: transform 0.3s;
    :hover {
      transform: scale(1.3);
    }
  }
  .options {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    height: 25px;
  }
  .label {
    width: 180px;
  }
  .toggle {
    width: 15px;
    height: 15px;
    border: 1px solid ${menuBlueColor};
    border-radius: 3px;
    /* margin-right: 5px; */
    cursor: pointer;
    transition: transform 0.3s;
    :hover {
      transform: scale(1.3);
    }
  }
  .toggleOn {
    background-color: ${menuBlueColor};
  }
`;
