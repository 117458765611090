// import axios from "axios";
import { store } from "../index";
import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
// import { allocationData } from "../../data";
import { getWorkPackageIds } from "./tasks";
import { getDayRateById } from "./team";
import {
  generateRandomId,
  recentFetch,
  reducerNotification,
} from "../../helpers";
import { apiCallBegan } from "../api";

export const initialData = [];
const initialState = {
  loading: false,
  lastFetch: 0,
  data: [],
  lastSent: 0,
  lastEdit: 0,
  error: "",
  resourcesInput: {
    personId: "",
    taskId: "",
    position: 0,
    closeInput: false,
  },
};
const slice = createSlice({
  name: "allocations",
  initialState,
  reducers: {
    allocationDataRequested: (allocation, _) => {
      allocation.loading = true;
    },
    allocationDataRequestFailed: (allocation, action) => {
      allocation = initialState;
      reducerNotification("error", action.payload);
    },
    allocationDataReceived: (allocation, action) => {
      allocation.data = action.payload;
      allocation.loading = false;
      allocation.lastFetch = Date.now();
    },

    sendingAllocation: (allocation, _) => {
      allocation.loading = true;
    },

    sendingNewAllocation: (taskOrder, _) => {
      taskOrder.loading = true;
    },
    sendingNewAllocationFailed: (taskOrder, action) => {
      taskOrder.loading = false;
      reducerNotification("error", action.payload);
    },

    addAllocation: (allocations, action) => {
      const { taskId, personId, value } = action.payload;
      const allocation = {
        allocationId: generateRandomId('allocation'),
        taskId,
        personId,
        percent: value,
      };
      allocations.data.push(allocation);
      allocations.lastEdit = Date.now();
      if (allocations.resourcesInput.closeInput) {
        allocations.resourcesInput.personId = "";
        allocations.resourcesInput.taskId = "";
      }
    },
    updateAllocation: (allocations, action) => {
      const { allocationId, value } = action.payload;
      const index = allocations.data.findIndex(
        (allocation) => allocation.allocationId === allocationId
      );
      allocations.data[index].percent = value;
      allocations.lastEdit = Date.now();
      if (allocations.resourcesInput.closeInput) {
        allocations.resourcesInput.personId = "";
        allocations.resourcesInput.taskId = "";
      }
    },
    deleteAllocation: (allocations, action) => {
      const { allocationId } = action.payload;
      const index = allocations.data.findIndex(
        (allocation) => allocation.allocationId === allocationId
      );
      allocations.data.splice(index, 1);
      allocations.lastEdit = Date.now();
      if (allocations.resourcesInput.closeInput) {
        allocations.resourcesInput.personId = "";
        allocations.resourcesInput.taskId = "";
      }
    },
    deleteTaskAllocations: (allocations, action) => {
      const { taskId } = action.payload;
      const filtered = allocations.data.filter(
        (allocation) => allocation.taskId !== taskId
      );
      allocations.data = filtered;
      allocations.lastEdit = Date.now();
    },
    deletePersonAllocations: (allocations, action) => {
      const { personId } = action.payload;
      const filtered = allocations.data.filter(
        (allocation) => allocation.personId !== personId
      );
      allocations.data = filtered;
      allocations.lastEdit = Date.now();
    },

    updateResourcesInput: (allocations, action) => {
      const { personId, taskId, position } = action.payload;
      allocations.resourcesInput.personId = personId;
      allocations.resourcesInput.taskId = taskId;
      allocations.resourcesInput.position = position;
    },
    closeResourcesInput: (allocations, _) => {
      allocations.resourcesInput.personId = "";
      allocations.resourcesInput.taskId = "";
    },
    toggleCloseResources: (allocations, _) => {
      allocations.resourcesInput.closeInput =
        !allocations.resourcesInput.closeInput;
    },

    apiSuccess: (allocations, action) => {
      allocations.loading = false;
      allocations.lastSent = Date.now();
      // reducerNotification("info", "Allocation saved");
    },
    apiFailed: (allocation, _) => {
      allocation.loading = false;
      reducerNotification("error", "Allocation Failed");
    },
  },
});

export const {
  sendingNewAllocation,
  sendingNewAllocationFailed,
  allocationDataReceived,

  updateResourcesInput,
  closeResourcesInput,
  toggleCloseResources,

  addAllocation,
  updateAllocation,
  deleteAllocation,
  deleteTaskAllocations,
  deletePersonAllocations,
} = slice.actions;

const {
  allocationDataRequested,
  allocationDataRequestFailed,

  apiSuccess,
  apiFailed,
} = slice.actions;
export default slice.reducer;

// Action Creators

const url = "/allocations";

export const loadAllocationData = () => (dispatch, getState) => {
  const { lastFetch } = getState().entities.allocations;
  // console.log("fetching allocation data...");
  if (recentFetch(lastFetch)) return;

  dispatch(
    apiCallBegan({
      url: url + "/selected",
      method: "get",
      data: null,
      onStart: allocationDataRequested.type,
      onSuccess: allocationDataReceived.type,
      onError: allocationDataRequestFailed.type,
    })
  );
};

export const sendUpdatedAllocations = () => (dispatch, getState) => {
  const allocations = getState().entities.allocations;
  const { lastEdit, lastSent, data } = allocations;
  if (lastSent >= lastEdit) return;
  dispatch(
    apiCallBegan({
      url: url + "/selected",
      method: "put",
      data,
      // onStart: sendingAllocation.type,
      onSuccess: apiSuccess.type,
      onError: apiFailed.type,
    })
  );
};

export const initiateAllocation = (projectId) =>
  apiCallBegan({
    url: url + "/new",
    method: "post",
    data: {
      projectId,
      data: [],
    },
    // onStart: sendingAllocation.type,
    onSuccess: apiSuccess.type,
    onError: apiFailed.type,
  });

export const cloneAllocations = (projectId) => (dispatch, getState) => {
  const { data } = getState().entities.allocations;
  dispatch(
    apiCallBegan({
      url: url + "/new",
      method: "post",
      data: {
        projectId,
        data,
      },
      // onStart: sendingAllocation.type,
      onSuccess: apiSuccess.type,
      onError: apiFailed.type,
    })
  );
};

export const deleteProjectAllocations = (projectId) =>
  apiCallBegan({
    url: url + "/selected",
    method: "delete",
    data: {
      projectId,
    },
    // onStart: sendingEmptyAssignments.type,
    onSuccess: apiSuccess.type,
    onError: apiFailed.type,
  });

// Selectors

export const getAllocationsByTaskId = createSelector(
  (state) => state.entities.team.data,
  (state) => state.entities.tasks.data,
  (state) => state.entities.allocations.data,
  (team, tasks, allocations) => {
    if (team === []) return;
    const initialsById = {};
    team.forEach((person) => {
      initialsById[person.personId] = person.acronym;
    });

    // create a dictionary for saving allocated days
    const resources = {
      warnings: false,
      redundant: [],
    };
    // list each task
    tasks.forEach((task) => {
      const peopleKeys = {};
      // list each person within each task
      // initiate with 0
      team.forEach((person) => {
        peopleKeys[person.personId] = {
          allocationId: "new",
          percent: 0,
        };
      });
      resources[task.taskId] = {
        completion: 0,
        people: "",
        ...peopleKeys,
      };
    });

    // console.log(resources);
    // loop through all allocations and assign
    for (let i = 0; i < allocations.length; i++) {
      let taskId = allocations[i].taskId;
      let personId = allocations[i].personId;
      let acronym = initialsById[personId];

      if (!resources[taskId] || !resources[taskId][personId]) {
        resources.redundant.push(allocations[i].allocationId);
      } else {
        if (resources[taskId]["people"].length > 1) {
          resources[taskId]["people"] =
            resources[taskId]["people"] + ", " + acronym;
        } else {
          resources[taskId]["people"] = resources[taskId]["people"] + acronym;
        }
        resources[taskId]["completion"] =
          resources[taskId]["completion"] + allocations[i].percent;

        resources[taskId][personId].percent = allocations[i].percent;
        resources[taskId][personId].acronym = acronym;
        resources[taskId][personId].allocationId = allocations[i].allocationId;
      }
    }

    // check all are completed
    tasks.forEach(({ taskId }) => {
      let check = {
        unassigned: {
          flagged: true,
          setting: false,
          message: "Unassigned resources",
        },
      };
      if (resources[taskId].completion !== 100) {
        resources.warnings = [check.unassigned];
        return; // once a warning, don't need more
      }
    });
    return resources;
  }
);

export const getTeamDaysAndCosts = createSelector(
  (state) => state.entities.tasks.data,
  (state) => state.entities.team.data,
  (state) => state.entities.allocations.data,
  (tasks, team, _) => {
    const resources = getAllocationsByTaskId(store.getState());

    // construct object to populate
    const peoplesDays = {
      combined: {
        cost: 0,
        days: 0,
        staff: { cost: 0, days: 0 },
        subcontract: { cost: 0, days: 0 },
      },
      lead: {
        cost: 0,
        days: 0,
        staff: { cost: 0, days: 0 },
        subcontract: { cost: 0, days: 0 },
      },
      pOne: {
        cost: 0,
        days: 0,
        staff: { cost: 0, days: 0 },
        subcontract: { cost: 0, days: 0 },
      },
      pTwo: {
        cost: 0,
        days: 0,
        staff: { cost: 0, days: 0 },
        subcontract: { cost: 0, days: 0 },
      },
    };

    // get the values for each team member
    team.forEach((person) => {
      const { personId } = person;
      const rate = getDayRateById(store.getState())[personId];
      peoplesDays[personId] = { days: 0, cost: 0 };
      tasks.forEach((task) => {
        const taskDays = task.days;
        let percentage = 0;
        if (resources[task.taskId][personId].percent) {
          percentage = resources[task.taskId][personId].percent;
          const days = (taskDays * percentage) / 100;
          const cost = days * rate;
          peoplesDays[personId].days = peoplesDays[personId].days + days;
          peoplesDays[personId].cost = peoplesDays[personId].cost + cost;
        }
      });
    });
    // round the team members days and costs
    team.forEach((person) => {
      const { personId, leader, employment } = person;
      const roundedCost = Math.round(peoplesDays[personId].cost);
      const roundedDays = Math.round(peoplesDays[personId].days);

      // team member values
      peoplesDays[personId].days = roundedDays;
      peoplesDays[personId].cost = roundedCost;
      // calculate combined days
      peoplesDays.combined.cost = peoplesDays.combined.cost + roundedCost;
      peoplesDays.combined.days = peoplesDays.combined.days + roundedDays;
      peoplesDays.combined[employment].cost =
        peoplesDays.combined[employment].cost + roundedCost;
      peoplesDays.combined[employment].days =
        peoplesDays.combined[employment].days + roundedDays;
      // calculated days per leader
      peoplesDays[leader].days = peoplesDays[leader].days + roundedDays;
      peoplesDays[leader].cost = peoplesDays[leader].cost + roundedCost;
      peoplesDays[leader][employment].cost =
        peoplesDays[leader][employment].cost + roundedCost;
      peoplesDays[leader][employment].days =
        peoplesDays[leader][employment].days + roundedDays;
    });
    return peoplesDays;
  }
);

export const getWorkPackageLabourCost = createSelector(
  (state) => state.entities.tasks.data,
  (state) => state.entities.allocations.data,
  (tasks, allocations) => {
    // console.log("getWorkPackageLabourCost");
    // const allTasks = entities.tasks.data;
    const wpIds = getWorkPackageIds(store.getState());
    const dayRateById = getDayRateById(store.getState());

    const workPackageCost = {};
    wpIds.forEach((task) => {
      workPackageCost[task] = 0;
    });

    allocations.forEach((allocation) => {
      const { percent, personId, taskId } = allocation;
      const index = tasks.findIndex((task) => task.taskId === taskId);
      let days = 0,
        cost = 0,
        workPackage = "";
      if (tasks[index]) {
        days = (tasks[index].days * percent) / 100;
        cost = Math.round(days * dayRateById[personId]);
        if (cost) workPackage = tasks[index].workPackageId;
      }
      workPackageCost[workPackage] = workPackageCost[workPackage] + cost;
    });
    // console.log(workPackageCost);
    return workPackageCost;
  }
);