import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Tippy from "@tippy.js/react";
import "tippy.js/dist/tippy.css";
import {
  updateProjectInfo,
  applyProjectDefaults,
  sendUpdatedDetails,
} from "../../store/entities/project";
import qMark from "../../images/qMark.png";
import { Container } from "./detailsStyling";
import dropdown2 from "../../images/dropArrow2.png";
import {
  generateArray,
  handleProjectLength,
  handleSendRevenue,
  months,
} from "../../helpers";
import { getGlobalProjectDefaults } from "../../store/entities/global";
import { getSelectedProject, setShowComponent } from "../../store/user";
import ConfirmModal from "../modals/confirmModal";
import { updateRevenueDefault } from "../../store/entities/revenue";

function ProjectRows() {
  const dispatch = useDispatch();

  // const { selectedLeader } = useSelector((state) => state.user);
  const { maxProjectLength } = useSelector(getSelectedProject);
  const defaults = useSelector(getGlobalProjectDefaults);
  const projectLengths = generateArray(3, maxProjectLength, 1);
  const { ipProtections, natures, years } = useSelector(
    (state) => state.entities.global.data
  );
  const { showComponent } = useSelector((state) => state.user);
  // const status = useSelector(
  //   (state) => state.entities.project.data.status.details[selectedLeader]
  // );

  const {
    productPlatformName,
    applicationNumber,
    nature,
    ipProtection,
    projectName,
    projectLength,
    startMonth,
    startYear,
    projectManager,
    software,
    funding,
  } = useSelector((state) => state.entities.project.data.details);
  const { projectDefaultsApplied } = useSelector(
    (state) => state.entities.project
  );

  // console.log(status);

  function onchangeHandler(e) {
    const key = e.target.name;
    let value = e.target.value;
    value = value.slice(0, 50);
    console.log(key);
    dispatch(updateProjectInfo({ key, value }));
    if (
      key === "startMonth" ||
      key === "startYear"
      // key === "projectLength"
    ) {
      const data = {
        projectLength,
        startMonth,
        startYear,
      };
      data[key] = value;
      dispatch(updateRevenueDefault(data));
      handleSendRevenue();
    }
  }

  const [tempLength, setTempLength] = useState(0);
  function changeProjectLength(e) {
    const newLength = parseInt(e.target.value);
    const oldLength = parseInt(projectLength);
    if (newLength < oldLength) {
      setTempLength(newLength);
      dispatch(setShowComponent({ value: "projectLength" }));
    } else handleProjectLength(newLength);
    const data = {
      projectLength: newLength,
      startMonth,
      startYear,
    };
    dispatch(updateRevenueDefault(data));
  }

  function applyDefaults() {
    dispatch(applyProjectDefaults({ defaults }));
    sendChanges();
  }

  function sendChanges() {
    dispatch(sendUpdatedDetails());
  }

  const data = {
    maxHeight: "350px",
  };

  return (
    <Container data={data}>
      {showComponent === "projectLength" ? (
        <ConfirmModal
          question={`Reduce project length to ${tempLength} months?`}
          comment="All bars in the Gantt Chart will be reset"
          confirm={() => handleProjectLength(tempLength)}
        />
      ) : null}
      <div className="rows">
        <div className="bottomLeftCorner">
          {projectDefaultsApplied ? null : (
            <button onClick={applyDefaults}>
              <h3>Apply defaults</h3>
            </button>
          )}
        </div>

        <div className="row column">
          <div className="title">
            <p>Product / Platform Name</p>
          </div>
          <input
            type="text"
            value={productPlatformName || ""}
            className="field"
            name="productPlatformName"
            onChange={onchangeHandler}
            onBlur={sendChanges}
          />
        </div>

        <div className="row column">
          <div className="title">
            <p>Applicant Number</p>
            <Tippy content="Applicant Number from the funders grant platform">
              <div className="info">
                <img src={qMark} alt="info" />
              </div>
            </Tippy>
          </div>
          <input
            type="text"
            value={applicationNumber || ""}
            className="field"
            name="applicationNumber"
            onChange={onchangeHandler}
            onBlur={sendChanges}
          />
        </div>

        <div className="row column relative">
          <div className="title">
            <p>Nature of Product or Service</p>
          </div>
          <select
            value={nature}
            className="field"
            name="nature"
            onChange={onchangeHandler}
            onBlur={sendChanges}
          >
            {natures.map((nature, index) => {
              return (
                <option key={index} value={nature}>
                  {nature}
                </option>
              );
            })}
          </select>
          <div className="dropdown">
            <img src={dropdown2} alt="option" />
          </div>
        </div>

        <div className="row column">
          <div className="title">
            <p>IP Protection Strategy</p>
          </div>
          <select
            value={ipProtection}
            className="field"
            name="ipProtection"
            onChange={onchangeHandler}
            onBlur={sendChanges}
          >
            {ipProtections.map((protection, index) => {
              return (
                <option key={index} value={protection}>
                  {protection}
                </option>
              );
            })}
          </select>
          <div className="dropdown">
            <img src={dropdown2} alt="option" />
          </div>
        </div>

        <div className="row column">
          <div className="title">
            <p>Project Name</p>
          </div>
          <input
            type="text"
            value={projectName || ""}
            className="field"
            name="projectName"
            onChange={onchangeHandler}
            onBlur={sendChanges}
          />
        </div>

        <div className="row column">
          <div className="title">
            <p>Project Start</p>
          </div>
          <div className="projectStart">
            <Tippy placement="top-start" content="Month project starts">
              <select
                className="field month"
                name="startMonth"
                value={startMonth}
                onChange={onchangeHandler}
                onBlur={sendChanges}
              >
                {months.map((month, index) => {
                  return (
                    <option key={index} value={month}>
                      {month}
                    </option>
                  );
                })}
              </select>
            </Tippy>
            <Tippy content="Year project starts">
              <select
                className="field year"
                name="startYear"
                value={startYear}
                onChange={onchangeHandler}
                onBlur={sendChanges}
              >
                {years.map((year, index) => {
                  return (
                    <option key={index} value={year}>
                      {year}
                    </option>
                  );
                })}
              </select>
            </Tippy>
          </div>
        </div>

        <div className="row column">
          <div className="title">
            <p>Project Length (months)</p>
            <Tippy content="Check funder's website for guidance on project lengths">
              <div className="info">
                <img src={qMark} alt="info" />
              </div>
            </Tippy>
          </div>
          <div className="projectStart">
            {/* <Tippy content="Project length (months). Changing this will affect the Gantt Chart data"> */}
            <select
              className="field length"
              name="projectLength"
              value={projectLength}
              onChange={changeProjectLength}
              // onBlur={sendChanges}
            >
              {projectLengths.map((month, index) => {
                return (
                  <option key={index} value={month}>
                    {month}
                  </option>
                );
              })}
            </select>
            {/* </Tippy> */}
          </div>
        </div>

        <div className="row column">
          <div className="title">
            <p>Project Manager</p>
          </div>
          <input
            type="text"
            value={projectManager || ""}
            className="field"
            name="projectManager"
            onChange={onchangeHandler}
            onBlur={sendChanges}
          />
        </div>

        <div className="row column">
          <div className="title">
            <p>Management Software</p>
          </div>
          <input
            type="text"
            value={software || ""}
            className="field"
            name="software"
            onChange={onchangeHandler}
            onBlur={sendChanges}
          />
        </div>

        <div className="row column">
          <div className="title">
            <p>Commercialisation Funding Required</p>
          </div>
          <input
            type="boolean"
            value={funding || ""}
            className="field"
            name="funding"
            onChange={onchangeHandler}
            onBlur={sendChanges}
          />
        </div>
      </div>
    </Container>
  );
}
export default ProjectRows;
