import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { getSelectedProject } from "../../store/user";
import { fontColorGrey } from "../../helpers";
import { getTeamByLeader } from "../../store/entities/team";
import OutputTeamCompany from "./OutputTeamCompany";
import OutputCopy from "./OutputCopy";

export default function OutputTeam(props) {
  const { color } = props;
  const { partners } = useSelector(getSelectedProject);
  const { outputs } = useSelector((state) => state.entities.project.data);
  const leadCompany = useSelector(
    (state) => state.entities.project.data.lead.companyAcronym
  );
  const pOneCompany = useSelector(
    (state) => state.entities.project.data.pOne.companyAcronym
  );
  const pTwoCompany = useSelector(
    (state) => state.entities.project.data.pTwo.companyAcronym
  );
  const teams = useSelector(getTeamByLeader);

  return (
    <Container color={color}>
      {outputs ? (
        <>
          <OutputTeamCompany company={leadCompany} team={teams.lead} />
          {partners > 1 ? (
            <OutputTeamCompany company={pOneCompany} team={teams.pOne} />
          ) : null}
          {partners > 2 ? (
            <OutputTeamCompany company={pTwoCompany} team={teams.pTwo} />
          ) : null}
          <OutputTeamCompany company="Subcontract" team={teams.subcontract} />
        </>
      ) : (
        <OutputCopy />
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  color: ${fontColorGrey};

  h3.title {
    margin-top: 10px;
    margin-bottom: 5px;
    color: ${(props) => props.color};
  }
  p,
  span {
    height: 20px;
  }
  li {
    margin-left: 20px;
    list-style-type: disc;
  }
  .bold {
    font-weight: bold;
  }
`;
