import React from "react";
import styled from "styled-components";
import { menuBlueColor } from "../../helpers";
import OutputPM from "../output/OutputPM";

export default function DetailsOutput() {

  return (
    <Container>
      <div className="contents">
        <OutputPM source="project" color={menuBlueColor} />
      </div>
    </Container>
  );
}

const Container = styled.div`
  padding: 20px;
  h3 {
    margin-bottom: 10px;
  }
  .contents {
    max-height: 600px;
    overflow: scroll;
  }
`;
