import { store } from "../store";
import { setupDataReceived } from "../store/entities/setup";
import { taskDataReceived } from "../store/entities/tasks";
import { allocationDataReceived } from "../store/entities/allocations";
import { projectReceived } from "../store/entities/project";
import { deadlineDataReceived } from "../store/entities/deadlines";
import { teamReceived } from "../store/entities/team";
import { travelDataReceived } from "../store/entities/travel";
import { capexDataReceived } from "../store/entities/capex";
import { materialsDataReceived } from "../store/entities/materials";
import { otherDataReceived } from "../store/entities/other";
import { assignmentDataReceived } from "../store/entities/assignments";
import { revenueDataReceived } from "../store/entities/revenue";
import {
  // loadUserData,
  sendSelectedProject,
  userDataReceived,
  // userProjectDataFailed,
  userProjectDataReceived,
  userProjectDataRequested,
} from "../store/user";
import { globalDataReceived } from "../store/entities/global";
import { risksReceived } from "../store/entities/risks";
import { baseURL } from "../helpers";
import axios from "axios";
import { recentFetch } from "./config";

const urls = [
  "/allocations/selected",
  "/assignments/selected",
  "/capex/selected",
  "/deadlines/selected",
  "/global",
  "/materials/selected",
  "/other/selected",
  "/projects/selected",
  "/revenue/selected",
  "/risks/selected",
  "/setup",
  "/tasks/selected",
  "/team/selected",
  "/travel/selected",
  "/users/me",
];

export async function loadAll() {
  const { lastProjectDataFetch } = store.getState().user;
  if (recentFetch(lastProjectDataFetch)) return;

  const token = localStorage.getItem("token");

  const fetch = (endpoint) =>
    axios.request({
      baseURL: baseURL(),
      url: endpoint,
      method: "get",
      data: null,
      headers: {
        "Content-Type": "application/json",
        authorization: token,
      },
    });
  const promiseArray = urls.map(fetch);

  store.dispatch(userProjectDataRequested());
  Promise.all(promiseArray)
    .then((results) => {
      store.dispatch(setupDataReceived(results[10].data));
      store.dispatch(deadlineDataReceived(results[3].data));
      store.dispatch(taskDataReceived(results[11].data));
      store.dispatch(capexDataReceived(results[2].data));
      store.dispatch(globalDataReceived(results[4].data));
      store.dispatch(materialsDataReceived(results[5].data));
      store.dispatch(otherDataReceived(results[6].data));
      store.dispatch(projectReceived(results[7].data));
      store.dispatch(revenueDataReceived(results[8].data));
      store.dispatch(risksReceived(results[9].data));
      store.dispatch(teamReceived(results[12].data));
      store.dispatch(travelDataReceived(results[13].data));
      store.dispatch(userDataReceived(results[14].data));
      store.dispatch(allocationDataReceived(results[0].data));
      store.dispatch(assignmentDataReceived(results[1].data));
      store.dispatch(userProjectDataReceived());
    })
    .catch(() => {
      // store.dispatch(userProjectDataFailed());
      const projectId = store.getState().user.selectedProjectId;
      console.log('Failed to load',projectId);
      store.dispatch(sendSelectedProject({ projectId }));
      window.location.reload()
      // notifications handled by api.js
    });
}
