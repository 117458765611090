import React from "react";
import { useDispatch, useSelector } from "react-redux";
import addGrey from "../../images/add-grey.png";
import add from "../../images/plusButtonBlue.png";
import Tippy from "@tippy.js/react";
import TargetMarketRow from "./targetMarketRow";
import { Container } from "./revenueStyling";
import Titles from "./targetMarketTitles";
import { addMarket } from "../../store/entities/revenue";
import { getSelectedProject } from "../../store/user";

function TargetMarketInfo() {
  const dispatch = useDispatch();
  const { markets } = useSelector((state) => state.entities.revenue.data);
  const { maxMarkets } = useSelector(getSelectedProject);

  return (
    <Container>
      <Titles />
      <div className="rows">
        {markets.map((market, index) => {
          return <TargetMarketRow market={market} index={index} key={index} />;
        })}
        {markets.length >= maxMarkets + 1? (
          <Tippy content={`Maximum ${maxMarkets} markets`}>
            <button className="addIcon">
              <img src={addGrey} alt="add" />
            </button>
          </Tippy>
        ) : (
          <Tippy content="Add a market">
            <button className="addIcon" onClick={() => dispatch(addMarket())}>
              <img src={add} alt="add" />
            </button>
          </Tippy>
        )}
      </div>
    </Container>
  );
}
export default TargetMarketInfo;
