import React from "react";
import styled from "styled-components";
import {
  handleSendGlobal,
  handleSendProject,
  replaceWords,
} from "../../helpers";
import { useSelector, useDispatch } from "react-redux";
import { updateGlobalOutput } from "../../store/entities/global";
import { updateProjectOutput } from "../../store/entities/project";

export default function OutputSection(props) {
  const dispatch = useDispatch();
  const { objKey, subject, mapObj, source, edit, small } = props;

  const value = useSelector(
    (state) => state.entities[source].data.outputs[objKey]
  );

  const replaced = mapObj && value ? replaceWords(mapObj, value) : value;

  function handleChange(e) {
    const { value } = e.target;
    if (source === "global")
      dispatch(updateGlobalOutput({ value, key: objKey }));
    else dispatch(updateProjectOutput({ value, key: objKey }));
  }
  function sendChanges() {
    if (source === "global") handleSendGlobal();
    else handleSendProject();
  }

  return (
    <Container>
      <div>
        {subject ? <span className="bold">{subject}&nbsp;</span> : null}
        <span>{replaced}</span>
      </div>
      {edit ? (
        small ? (
          <input
            className="textarea"
            onBlur={sendChanges}
            onChange={handleChange}
            value={value || ""}
          />
        ) : (
          <textarea
            className="textarea"
            onBlur={sendChanges}
            onChange={handleChange}
            value={value || ""}
          />
        )
      ) : null}
    </Container>
  );
}

const Container = styled.div``;
