import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getWPStatus, handleSendAssignments } from "../../helpers";
import {
  assignAllToCategory,
  assignNoneToCategory,
} from "../../store/entities/assignments";
import { getWorkPackageIds } from "../../store/entities/tasks";
import AssignmentColumnButton from "./assignmentButton";
import Tippy from "@tippy.js/react";
import warning from "../../images/warning.png";
import { ColumnStyling } from "./assignmentColumnStyling";

export default function (props) {
  const dispatch = useDispatch();
  const { other, index } = props;
  const category = other.otherId;
  const workPackageIds = useSelector(getWorkPackageIds);
  const { selectedLeader } = useSelector((state) => state.user);
  // const assignments = useSelector((state) => state.entities.assignments.data);
  const hasCosts = useSelector(getWPStatus)[selectedLeader].has[other.otherId];
  const status =
    useSelector(getWPStatus)[selectedLeader].unassigned[other.otherId];

  // let assignedCount = 0;
  // if (assignments[selectedLeader][category])
  //   assignedCount = assignments[selectedLeader][category].length;
  // const all = assignedCount === workPackageIds.length;

  function assignAll() {
    dispatch(
      assignAllToCategory({
        leader: selectedLeader,
        category,
        workPackageIds,
      })
    );
    handleSendAssignments();
  }

  function resetCategory() {
    dispatch(
      assignNoneToCategory({
        leader: selectedLeader,
        category,
      })
    );
    handleSendAssignments();
  }

  return hasCosts ? (
    <ColumnStyling>
      <div className="title">
        <Tippy content={other.description}>
          <h3>Other {index + 1}</h3>
        </Tippy>
        {status ? (
          <Tippy content="Cost must be assigned to at least one work package">
            <div className="warning">
              <img src={warning} alt="warning" />
            </div>
          </Tippy>
        ) : null}
      </div>
      {workPackageIds.map((workPackageId, index) => {
        return (
          <AssignmentColumnButton
            key={index}
            category={category}
            workPackageId={workPackageId}
          />
        );
      })}
      {/* {all ? ( */}
      <Tippy
        content={`Remove ${other.description} cost from all work packages`}
      >
        <button onClick={resetCategory} className="applyAll hiddenButton">
          None
        </button>
      </Tippy>
      {/* ) : ( */}
      <Tippy content={`Assign ${other.description} cost to all work packages`}>
        <button onClick={assignAll} className="applyAll hiddenButton">
          All
        </button>
      </Tippy>
      {/* )} */}
    </ColumnStyling>
  ) : null;
}
