import { store } from "../store";
import { rev2 } from "./settings";

export function newProject(projectId) {
  const { outputs } = store.getState().entities.global.data;
  const newSettings = {
    projectId,
    data: {
      projectId,
      status: {
        gantt: false,
        details: false,
        team: false,
        costs: false,
        revenue: false,
        risks: false,
      },
      details: {
        productPlatformName: "",
        applicationNumber: "",
        nature: "",
        ipProtection: "",
        projectName: "",
        projectLength: 3,
        startMonth: "Jan",
        startYear: 2021,
        projectManager: "",
        software: "",
        matchFunding: "",
        ganttRef: "",
        riskRef: "",
        competitor: "",
        notify: [],
      },
      lead: {
        companyName: "",
        companyAcronym: "",
        organisationType: "",
        organisationSize: "",
        postcode: "",
        turnover: 0,
        lastFinancialMonth: "",
        lastFinancialYear: "",
        lawyer: "",
        bankHolidays: 3,
        annualLeave: 27,
        numEmployees: 0,
        overheadRate: 10,
        partnerFunding: "Yes",
        fundingLevel: 0,
        matchFundingSource: "",
        investorName: "",

        detailsComplete: false,
        teamComplete: false,
        ganttComplete: false,
        costsComplete: false,
        revenueComplete: false,
        risksComplete: false,
      },
      pOne: {
        companyName: "",
        companyAcronym: "",
        organisationType: "",
        organisationSize: "",
        postcode: "",
        turnover: 0,
        lastFinancialMonth: "",
        lastFinancialYear: "",
        lawyer: "",
        bankHolidays: 3,
        annualLeave: 27,
        numEmployees: 0,
        overheadRate: 10,
        partnerFunding: "Yes",
        fundingLevel: 0,
        matchFundingSource: "",
        investorName: "",

        detailsComplete: false,
        teamComplete: false,
        ganttComplete: false,
        costsComplete: false,
        revenueComplete: false,
        risksComplete: false,
      },
      pTwo: {
        companyName: "",
        companyAcronym: "",
        organisationType: "",
        organisationSize: "",
        postcode: "",
        turnover: 0,
        lastFinancialMonth: "",
        lastFinancialYear: "",
        lawyer: "",
        bankHolidays: 3,
        annualLeave: 27,
        numEmployees: 0,
        overheadRate: 10,
        partnerFunding: "Yes",
        fundingLevel: 0,
        matchFundingSource: "",
        investorName: "",

        detailsComplete: false,
        teamComplete: false,
        ganttComplete: false,
        costsComplete: false,
        revenueComplete: false,
        risksComplete: false,
      },
      wizard: {
        currentQuestionIndex: 0,
        allQuestionsAsked: false,
        complete: false,
        component: "questions",
        progress: "0%",
        answers: {},
      },
      settings: {
        resourcesIncrement: 5,
      },
      templates: [],
      roles: [],
      tasks: [],
    },
  };
  if (rev2) newSettings.data.outputs = outputs;
  return newSettings;
}
