import React from "react";
import { useDispatch } from "react-redux";
import { wizardComponent } from "../../store/entities/project";
import Close from "./close";

function wizardIntroModal() {
  const dispatch = useDispatch();

  function next() {
    dispatch(wizardComponent({ value: "questions" }));
  }
  const closeData = {};

  return (
    <div className="modal intro">
      <Close data={closeData} />
      <div>
        <h3 className="title">Setup Wizard</h3>
        <p className="bold">
          Use this quick wizard to customise the options available to you
          throughout the platform
        </p>
      </div>
      <div className="buttons mt">
        <div />
        <button onClick={next}>Launch Wizard</button>
        <div />
      </div>
    </div>
  );
}

export default wizardIntroModal;
