import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import {
  capitaliseFirstLetter,
  getTotalsByLeader,
  numberToCurrency,
  roundTo,
} from "../../helpers";
import OutputCostItems from "./OutputCostItems";

export default function OutputCostCategory(props) {
  const { key, title } = props.category;
  const items = useSelector((state) => state.entities[key].data);
  const { combined, percent } =
    useSelector(getTotalsByLeader).object[key + "Cost"];

  const checkedPercent = percent < 1 ? "<1" : percent;
  const str = `= £${numberToCurrency(
    roundTo(combined, 0)
  )} (${checkedPercent}% of project costs).`;

  return combined ? (
    <Container>
      <span className="bold">{title}&nbsp;</span>
      <span>{capitaliseFirstLetter(str)} </span>
      {items.map((item, index) => {
        return <OutputCostItems item={item} key={index} category={key} />;
      })}
    </Container>
  ) : null;
}

const Container = styled.div`
`;
