import React from "react";
import { useSelector } from "react-redux";
// import { Container } from "./revenueStyling";
// import moment from "moment";
import { getProjectEnd } from "../../store/entities/project";

function TargetMarketTitles() {
  const revenueStart = useSelector(
    (state) => state.entities.revenue.data.revenueStart
  );

  const projectEnd = useSelector(getProjectEnd);
  let defaultStart = parseInt(projectEnd.format("y"));
  const endMonth = parseInt(projectEnd.format("M"));
  if (endMonth > 6) defaultStart = defaultStart + 1;

  return (
    // <Container>
      <div className="row titles leaderTabMargin sticky">
        <div className="title market" />
        <div className="title year">
          <p>Y1&nbsp;</p>
          <p className="subtle">{revenueStart || defaultStart}</p>
        </div>
        <div className="title year">
          <p>Y2&nbsp;</p>
          <p className="subtle">{revenueStart + 1 || defaultStart + 1}</p>
        </div>
        <div className="title year">
          <p>Y3&nbsp;</p>
          <p className="subtle">{revenueStart + 2 || defaultStart + 2}</p>
        </div>
        <div className="title year">
          <p>Y4&nbsp;</p>
          <p className="subtle">{revenueStart + 3 || defaultStart + 3}</p>
        </div>
        <div className="title year">
          <p>Y5&nbsp;</p>
          <p className="subtle">{revenueStart + 4 || defaultStart + 4}</p>
        </div>
      </div>
    // </Container>
  );
}
export default TargetMarketTitles;
