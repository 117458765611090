import React from "react";
import Tippy from "@tippy.js/react";
import { useSelector, useDispatch } from "react-redux";
import { updateRevenueKey } from "../../store/entities/revenue";
import dropdown2 from "../../images/dropArrow2.png";
import question from "../../images/qMark.png";
import { handleSendRevenue } from "../../helpers";

function TargetMarketTitles() {
  const dispatch = useDispatch();
  const projectStart = parseInt(
    useSelector((state) => state.entities.project.data.details.startYear)
  );
  const { revenueStart, defaultStart } = useSelector(
    (state) => state.entities.revenue.data
  );

  function changeYear(e) {
    const key = "revenueStart";
    const value = parseInt(e.target.value);
    dispatch(updateRevenueKey({ key, value }));
    handleSendRevenue();
  }

  return (
    // <Container>
    <div className="row titles leaderTabMargin sticky">
      <div className="title market relative"></div>
      <div className="title year relative">
        <Tippy content={`Default start: ${defaultStart}`}>
          <p>Y1&nbsp;</p>
        </Tippy>
        <select
          // selected={projectStart}
          value={revenueStart || ""}
          className="title subtle right"
          onChange={changeYear}
        >
          {/* <option selected disabled>
            select...
          </option> */}
          <option value={revenueStart - 3}>{revenueStart - 3}</option>
          <option value={revenueStart - 2}>{revenueStart - 2}</option>
          <option value={revenueStart - 1}>{revenueStart - 1}</option>
          <option value={revenueStart}>{revenueStart}</option>
          <option value={revenueStart + 1}>{revenueStart + 1}</option>
          <option value={revenueStart + 2}>{revenueStart + 2}</option>
          <option value={revenueStart + 3}>{revenueStart + 3}</option>
          <option value={revenueStart + 4}>{revenueStart + 4}</option>
        </select>
        <div className="dropdown">
          <Tippy content="Click the year to adjust. Based on project end, the default is xxx">
            <img src={dropdown2} alt="option" />
          </Tippy>
        </div>
      </div>
      <div className="title year">
        <p>Y2&nbsp;</p>
        <p className="subtle">{revenueStart + 1 || projectStart + 1}</p>
      </div>
      <div className="title year">
        <p>Y3&nbsp;</p>
        <p className="subtle">{revenueStart + 2 || projectStart + 2}</p>
      </div>
      <div className="title year">
        <p>Y4&nbsp;</p>
        <p className="subtle">{revenueStart + 3 || projectStart + 3}</p>
      </div>
      <div className="title year">
        <p>Y5&nbsp;</p>
        <p className="subtle">{revenueStart + 4 || projectStart + 4}</p>
      </div>
      <div className="title year cagr">
        <p className="subtle">CAGR</p>
        <Tippy content="Compound Annual Growth Rate">
          <div className="info">
            <img src={question} alt="add" />
          </div>
        </Tippy>
      </div>

      <div className="delete"></div>
    </div>
    // </Container>
  );
}
export default TargetMarketTitles;
