import { store } from "../store";
import { getAllocationsByTaskId } from "../store/entities/allocations";
import {
  getCostWarnings,
  sendUpdatedDetails,
  updateSectionStatus,
} from "../store/entities/project";
import { getRiskErrors } from "../store/entities/risks";
import { getTeamWarnings } from "../store/entities/team";
import { toastNotification } from "./config";
import { capitaliseFirstLetter, deBounce } from "./generalHelpers";
import { notifyProjectStatus, notifySectionStatus } from "./handleEmails";
import { handleSendProject } from "./handleSendToServer";
import { rev2 } from "./settings";

export function handleSectionStatus(section) {
  const { status } = store.getState().entities.project.data;
  const check = { ...status };
  check[section] = !check[section];
  const projectComplete = Object.values(check).every((item) => item === true);

  const warnings = {
    details: [],
    team: getTeamWarnings(store.getState()),
    gantt: getAllocationsByTaskId(store.getState()).warnings,
    revenue: [],
    costs: getCostWarnings(store.getState()),
    risks: getRiskErrors(store.getState()),
  };

  function updateSection() {
    const info = {
      section: capitaliseFirstLetter(section),
      status: status[section] ? "in progress" : "complete",
    };
    console.log(`section ${section} ${info.status}`);
    notifySectionStatus(info);
    store.dispatch(sendUpdatedDetails());
  }

  function updateProject() {
    console.log(`project complete`);
    notifyProjectStatus();
    store.dispatch(sendUpdatedDetails());
  }

  if (warnings[section].length && !status[section] && rev2) {
    toastNotification("error", "Fix errors before marking complete");
  } else if (rev2) {
    store.dispatch(updateSectionStatus({ section }));
    if (projectComplete) {
      deBounce(updateProject);
    } else {
      deBounce(updateSection);
    }
  } else {
    store.dispatch(updateSectionStatus({ section }));
    handleSendProject();
  }
}
