import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import AuthRoute from "./components/AuthRoute";
import GanttChart from "./pages/ganttChart";
import UserProjects from "./pages/UserProjects";
import Details from "./pages/detailsTable";
import HomePage from "./pages/homePage";
import NoResult from "./pages/noResult";
import Team from "./pages/teamTable";
import Costs from "./pages/costsTable";
import Revenue from "./pages/revenueTable";
import Setup from "./pages/setupTable";
import Risks from "./pages/risks";
import Dashboard from "./pages/adminDashboard";
import Login from "./pages/login";
import NoProjects from "./pages/noProjects";
// import Loading from "./pages/loading";
import { store } from "./store";
import PrintRisks from "./pages/PrintRisks";
import PrintGantt from "./pages/PrintGantt";

function auth(Component, props) {
  const authenticated = localStorage.getItem("authenticated");
  const { projectDataLoading } = store.getState().user;
  if (projectDataLoading) return <Details {...props} />;
  if (authenticated) return <Component {...props} />;
  props.history.push("/login");
}

function admin(Component, props) {
  const admin = localStorage.getItem("admin");
  if (admin) return <Component {...props} />;
  return <Redirect to="/" />;
}

const Router = () => (
  <Switch>
    <AuthRoute path="/login" type="guest">
      <Login />
    </AuthRoute>

    <Route path="/admin" render={(props) => admin(Dashboard, props)} />
    <Route path="/setup" render={(props) => admin(Setup, props)} />

    <Route exact path="/" render={(props) => auth(HomePage, props)} />
    <Route path="/projects" render={(props) => auth(UserProjects, props)} />
    <Route path="/gantt" render={(props) => auth(GanttChart, props)} />
    <Route path="/details" render={(props) => auth(Details, props)} />
    <Route path="/team" render={(props) => auth(Team, props)} />
    <Route path="/costs" render={(props) => auth(Costs, props)} />
    <Route path="/revenue" render={(props) => auth(Revenue, props)} />
    <Route path="/risks" render={(props) => auth(Risks, props)} />

    <Route path="/printRisks" render={(props) => auth(PrintRisks, props)} />
    <Route path="/printGantt" render={(props) => auth(PrintGantt, props)} />

    <Route path="/empty" render={(props) => auth(NoProjects, props)} />
    <Route path="/" component={NoResult} />
  </Switch>
);
export default Router;
