import React from "react";
import warning from "../../images/warning.png";
import { useSelector, useDispatch } from "react-redux";
import Tippy from "@tippy.js/react";
import "tippy.js/dist/tippy.css";
import { getUtilisations } from "../../store/entities/team";
import { getTeamDaysAndCosts } from "../../store/entities/allocations";
import { updateUserSelection } from "../../store/user";
import UtilisationModal from "../modals/utilisationModal";
import { numberToCurrency, roundTo } from "../../helpers";

function LabourRow(props) {
  const dispatch = useDispatch();
  const { personId, name, role } = props.person;
  const index = props.index;
  const state = useSelector((state) => state);
  const daysById = getTeamDaysAndCosts(state);
  const utilisation = getUtilisations(state)[personId].overutilised;
  const { showComponent, selectedLeader } = state.user;

  const { days, cost } = daysById[personId];
  const formattedCost = numberToCurrency(cost);
  const modalId = "utilisationModel" + selectedLeader + index;

  function showDetails() {
    dispatch(
      updateUserSelection({
        key: "showComponent",
        value: modalId,
      })
    );
  }

  return (
    <div className="row">
      {showComponent === modalId ? <UtilisationModal /> : null}
      <p className="field display labourNameRole">{`${name} (${role})`}</p>
      <p className="field display labourCost">£{formattedCost}</p>
      {/* <p className="field display labourCost">£{oldCost}</p> */}
      <p className="field display labourDays">{roundTo(days, 0)}</p>
      {utilisation ? (
        <Tippy content="Click here for details">
          <button onClick={showDetails} className="warningButton">
            <div className="warningIcon">
              <img src={warning} alt="warning" />
            </div>
            <span className="field display labourOverutilised">
              Overutilised
            </span>
          </button>
        </Tippy>
      ) : null}
    </div>
  );
}

export default LabourRow;
