import React from "react";
import "tippy.js/dist/tippy.css";
import { Container } from "./detailsStyling";

import ProjectNotificationTitles from "./ProjectNotificationTitles";
import ProjectNotificationData from "./ProjectNotificationData";

export default function ProjectNotifications() {
  const data = {
    maxHeight: "550px",
  };

  return (
    <Container data={data}>
      <ProjectNotificationTitles />
      <ProjectNotificationData />
    </Container>
  );
}