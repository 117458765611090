import styled from "styled-components";
import { menuBlueColor, menuBlueColorFade } from "../../helpers";

export const ColumnStyling = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 6px;
  padding-bottom: 10px;
  transition: background-color 0.3s;
  :hover {
    background-color: ${menuBlueColorFade};
    .hiddenButton {
      opacity: 1;
    }
  }
  /* border: 1px solid red; */
  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0;
    padding: 0;
    /* border: 1px solid red; */
    height: 50px;
    width: 100%;
  }
  .warning {
    position: absolute;
    bottom: -6px;
    max-height: 20px;
    max-width: 20px;
  }
  .applyAll {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    padding: 3px;
    width: 50px;
    background-color: ${menuBlueColor};
  }
  .hiddenButton {
    transition: all 0.3s;
    opacity: 0;
    :hover {
      transform: scale(1.08);
    }
  }
`;
