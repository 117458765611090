import { store } from "../store";
import { updateProjectLength } from "../store/entities/project";
import { increaseSchedule, decreaseSchedule } from "../store/entities/tasks";
import { resetDeadlineDate } from "../store/entities/deadlines";
import { updateRevenueDefault } from "../store/entities/revenue";
import {
  handleSendDeadlines,
  handleSendProject,
  handleSendRevenue,
  handleSendTasks,
} from "./handleSendToServer";

function increase(newLength, oldLength) {
  store.dispatch(
    updateProjectLength({
      key: "projectLength",
      value: newLength,
    })
  );
  store.dispatch(increaseSchedule({ newLength, oldLength }));
}

function reduce(newLength) {
  store.dispatch(
    updateProjectLength({
      key: "projectLength",
      value: newLength,
    })
  );
  store.dispatch(decreaseSchedule({ newLength }));
  store.dispatch(resetDeadlineDate());
}

export function handleProjectLength(value) {
  const { projectLength, startMonth, startYear } =
    store.getState().entities.project.data.details;
  const newLength = parseInt(value);
  const oldLength = parseInt(projectLength);

  if (newLength > oldLength) increase(newLength, oldLength);
  if (newLength < oldLength) reduce(newLength);

  const data = {
    projectLength: newLength,
    startMonth,
    startYear,
  };

  store.dispatch(updateRevenueDefault(data));
  handleSendTasks();
  handleSendDeadlines();
  handleSendProject();
  handleSendRevenue();
}
