import React, { useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import Close from "../general/close";
import TaskRow from "./tasksTemplateModalRow";
import Tabs from "../gantt/taskTemplateTabs";
import { dragElement, getUnusedTaskTemplates, wpInfoColor } from "../../helpers";
import { getSelectedProject, setShowComponent } from "../../store/user";
import { addTask } from "../../store/entities/tasks";

function TaskTemplateModal(props) {
  const dispatch = useDispatch();
  const { top, left } = useSelector((state) => state.user);
  const { clientFlavour } = useSelector(getSelectedProject);
  const { lastTaskOfPack, taskCount, maxTasksPerPackage } = props;

  const filteredTemplates = useSelector(getUnusedTaskTemplates)

  function handleAddBlankTask() {
    dispatch(addTask({ lastTaskId: lastTaskOfPack.taskId }));
    if (taskCount >= maxTasksPerPackage - 1) {
      dispatch(setShowComponent({ value: "" }));
    }
  }

  const data = {
    message: "",
  };

  useEffect(() => {
    dragElement(document.getElementById("tasksModal"));
  });

  return (
    <Container id="background" onClick={dragElement} left={left} top={top}>
      <div id="tasksModal" className="editWindow">
        {clientFlavour === "Full Write" ? <Tabs /> : null}
        <Close data={data} />
        <div className="mainContent">
          <div className="title">
            <h3>Add a task from the list below or</h3>
            <button onClick={handleAddBlankTask}>add a blank row</button>
          </div>
          <div className="templateList">
            {filteredTemplates.map((template, index) => {
              return (
                <TaskRow
                  key={index}
                  index={index}
                  template={template}
                  lastTaskOfPack={lastTaskOfPack}
                  maxTasksPerPackage={maxTasksPerPackage}
                  taskCount={taskCount}
                  filteredTemplates={filteredTemplates}
                />
              );
            })}
          </div>
        </div>
      </div>
    </Container>
  );
}

export default TaskTemplateModal;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: flex-start;

  z-index: 2;

  .editWindow {
    position: absolute;
    top: ${(props) => props.top};
    left: ${(props) => props.left};
    display: flex;
    height: 300px;
    /* min-height: 300px; */
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 8px 8px 12px rgba(1, 1, 1, 0.5);
    cursor: move;
  }
  .mainContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    /* padding: 2px 10px 10px 10px; */
    padding-bottom: 10px;
    width: 450px;
  }
  .title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    color: black;
    width: 100%;
    padding: 10px 33px;
    button {
      display: flex;
      align-items: center;
      padding: 8px;
      background-color: ${wpInfoColor};
      color: white;
      margin-left: 5px;
      height: 30px;
      transition: transform 0.3s;
      :hover {
        transform: scale(1.04);
      }
    }
  }
  .templateList {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
  }
  .template {
    /* border: 1px solid red; */
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0px 5px 5px 30px;
    &:hover .hidden {
      opacity: 1;
    }
  }
  .contents {
    border-radius: 5px;
    width: calc(100% - 25px);
    border: 1px solid #e1e1e1;
    background-color: rgb(240, 240, 240);
    padding: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 40px;
  }
  .add {
    display: flex;
    height: 100%;
    align-items: center;
    width: 25px;
    height: 25px;
    margin-left: 3px;
    transition: opacity 0.3s;
    cursor: pointer;
  }
  .hidden {
    opacity: 0;
  }
`;
