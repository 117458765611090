import React from "react";
import { printGanttColors } from "../helpers";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { updatePrintGantt } from "../store/user";

export default function PrintGanttColor(props) {
  const { objKey } = props;
  const color = useSelector((state) => state.user.gantt)[objKey];
  const dispatch = useDispatch();

  function adjust(value) {
    dispatch(updatePrintGantt({ key: objKey, value }));
  }

  return (
    <Container style={{ backgroundColor: printGanttColors[color].solid }}>
      <div className="colors">
        {printGanttColors.map((_, index) => {
          return (
            <div
              onClick={() => adjust(index)}
              key={index}
              className={`color ${color === index ? "selected" : ""}`}
              style={{
                backgroundColor: printGanttColors[index].solid,
                borderColor:
                  color === index ? printGanttColors[index].solid : "white",
                transform: color === index ? "none" : null,
              }}
            />
          );
        })}
      </div>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  height: 15px;
  width: 15px;
  border-radius: 5px;
  margin-left: 5px;
  cursor: pointer;
  :hover .colors {
    opacity: 1;
  }
  .colors {
    transition: opacity 0.3s;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    width: 254px;
    background-color: white;
    /* border: 1px solid red; */
  }
  .color {
    position: relative;
    height: 18px;
    width: 18px;
    border-radius: 5px;
    margin-left: 5px;
    cursor: pointer;
    border: 3px solid transparent;
    transition: transform 0.3s;
    :hover {
      transform: scale(1.5);
    }
  }
`;
