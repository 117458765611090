import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { updateUserSelection } from "../../store/user";
import {
  // fontColorGrey,
  highlight,
  tabHeight,
  wpHighlightColor,
  wpInfoColor,
} from "../../helpers";
import arrow from "../../images/dropArrow1.png";

function TaskTemplateTabs() {
  const dispatch = useDispatch();
  const selectedTaskType = useSelector((state) => state.user.selectedTaskType);
  const { taskTypes } = useSelector((state) => state.entities.global.data);

  function setCategory(value) {
    dispatch(updateUserSelection({ key: "selectedTaskType", value }));
  }

  const [atTop, setAtTop] = useState(0);
  const [atBottom, setAtBottom] = useState(1);

  console.log(atTop);

  function checkPosition(e) {
    const top = 0;
    const bottom = e.scrollHeight - e.clientHeight;
    const position = e.scrollTop;
    if (position < top + 10) setAtTop(0);
    else setAtTop(1);
    if (position > bottom - 10) setAtBottom(0);
    else setAtBottom(1);
    console.log(position, bottom);
  }

  useEffect(() => {
    const element = document.getElementById("buttons");
    element.onscroll = function () {
      checkPosition(element);
    };
  }, []);

  return (
    <PageContainer atTop={atTop} atBottom={atBottom}>
      <div className="spacer">
        <div className="arrow topArrow">
          <img src={arrow} alt="more" />
        </div>
      </div>
      <div className="buttons" id="buttons">
        {taskTypes.map((tab, index) => (
          <button
            className={`tab ${selectedTaskType === tab ? "selected" : null}`}
            key={index}
            onClick={() => setCategory(tab)}
          >
            <h3>{tab}</h3>
          </button>
        ))}
      </div>
      <div className="spacer">
        <div className="arrow bottomArrow">
          <img src={arrow} alt="more" />
        </div>
      </div>
    </PageContainer>
  );
}
export default TaskTemplateTabs;

const PageContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 6px 0 0 0;
  background-color: ${wpInfoColor};
  /* min-height: 200px; */
  .spacer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: ${tabHeight};
    transform: rotate();
  }
  .arrow {
    max-width: 20px;
    max-height: 20px;
    transition: opacity 0.3s;
  }
  .topArrow {
    opacity: ${(props) => props.atTop};
    -moz-transform: scaleY(-1);
    -o-transform: scaleY(-1);
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
    filter: FlipV;
    -ms-filter: "FlipV";
  }
  .bottomArrow {
    opacity: ${(props) => props.atBottom};
  }
  .buttons {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    height: 100%;
  }
  .tab {
    min-height: ${tabHeight};
    width: 180px;
    /* height: 40px; */
    padding: 15px 10px;
    /* margin-bottom: 10px; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: white;
    background-color: ${wpInfoColor};
    border: none;
    border-radius: 0;
    cursor: pointer;
    /* color: ${wpInfoColor}; */
    h3 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .selected {
    background-color: ${highlight};
    color: ${wpHighlightColor};
  }
`;
