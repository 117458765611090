import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Tippy from "@tippy.js/react";
import "tippy.js/dist/tippy.css";
import { updateTeamMember } from "../../store/entities/team";
import { handleSendTeam, isNumberKey } from "../../helpers";
import bin from "../../images/bin-grey.png";
import reorder from "../../images/reorder.png";
import arrow from "../../images/dropArrow2.png";
import warning from "../../images/warning.png";
import ProfileModal from "../modals/teamProfileModal";
import ConfirmModal from "../modals/confirmModal";

import { getSelectedProject, setShowComponent } from "../../store/user";
import { handleDeleteTeamMember, capitaliseFirstLetter } from "../../helpers";

function TeamRow(props) {
  const dispatch = useDispatch();
  const { showComponent, selectedTeamOption } = useSelector(
    (state) => state.user
  );
  const { locations } = useSelector((state) => state.entities.global.data);
  const { roles } = useSelector((state) => state.entities.project.data);
  const { person, employmentType, provided, acronyms, index } = props;
  const { amberSalary, redSalary, amberDayRate, redDayRate } =
    useSelector(getSelectedProject);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const team = capitaliseFirstLetter(selectedTeamOption);
  const placeholder = `${team} Member ${index + 1}`;

  const salaryFlag =
    person.salary > redSalary
      ? "red"
      : person.salary > amberSalary
      ? "amber"
      : null;

  const dayRateFlag =
    person.dayRate > redDayRate
      ? "red"
      : person.dayRate > amberDayRate
      ? "amber"
      : null;

  function onChangeHandler(e) {
    const key = e.target.name;
    let value = e.target.value;
    if (key === "salary" || key === "dayRate") {
      if (e.target.value) {
        value = parseInt(value.slice(-6));
      } else value = 0;
    }
    dispatch(
      updateTeamMember({
        personId: person.personId,
        key,
        value,
      })
    );
  }

  function deletePerson() {
    handleDeleteTeamMember(person.personId);
  }

  function handleProfile() {
    dispatch(setShowComponent({ value: "profile" + index }));
  }

  return (
    <div className="row">
      {showComponent === "profile" + index ? (
        <ProfileModal person={person} />
      ) : null}
      {confirmDelete ? (
        <ConfirmModal
          question={`Delete ${person.name}?`}
          comment="All associated data will be deleted"
          confirm={deletePerson}
        />
      ) : null}
      <Tippy content="Drag to reorder rows">
        <div {...provided.dragHandleProps} className="hidden grabHandle">
          <img src={reorder} alt="reorder" />
        </div>
      </Tippy>
      <input
        id={person.personId + "name"}
        name="name"
        value={person.name}
        onChange={onChangeHandler}
        onBlur={handleSendTeam}
        className="field name"
        placeholder={placeholder}
      />
      <input
        id={person.personId + "acronym"}
        name="acronym"
        value={person.acronym}
        onChange={onChangeHandler}
        onBlur={handleSendTeam}
        className={
          acronyms[person.acronym] > 1
            ? "field acronym duplicate"
            : "field acronym"
        }
      />
      <input
        type="text"
        id={person.personId + "role"}
        name="role"
        value={person.role}
        onChange={onChangeHandler}
        onBlur={handleSendTeam}
        className="field role"
        list={`${person.personId}roleList`}
      />
      <datalist id={`${person.personId}roleList`}>
        {roles.map(({ roleTitle }, index) => {
          return (
            <option key={index} value={roleTitle}>
              {roleTitle}
            </option>
          );
        })}
      </datalist>

      {employmentType === "staff" ? (
        <>
          <div className="pound">
            <p>£</p>
            <input
              id={person.personId + "salary"}
              name="salary"
              value={person.salary}
              onKeyDown={(e) => isNumberKey(e)}
              onChange={onChangeHandler}
              onBlur={handleSendTeam}
              className={"field salary " + salaryFlag}
            />
          </div>
        </>
      ) : (
        // <div className="money">
        //   <p className="pound">£</p>
        //   <input
        //     id={person.personId + "salary"}
        //     name="salary"
        //     value={person.salary}
        //     onKeyDown={(e) => isNumberKey(e)}
        //     onChange={onChangeHandler}
        //     onBlur={handleSendTeam}
        //     className="field salary"
        //   />
        // </div>
        <>
          <div className="pound">
            <p>£</p>
            <input
              id={person.personId + "dayRate"}
              name="dayRate"
              value={person.dayRate}
              onKeyDown={(e) => isNumberKey(e)}
              onChange={onChangeHandler}
              onBlur={handleSendTeam}
              className={"field dayRate " + dayRateFlag}
            />
          </div>

          <div className="relative">
            <select
              selected="UK"
              className={`field location ${
                person.location === "Other" ? "red" : null
              }`}
              id={person.personId + "location"}
              name="location"
              value={person.location}
              onChange={onChangeHandler}
              onBlur={handleSendTeam}
            >
              {locations.map((location, index) => {
                return (
                  <option key={index} value={location}>
                    {location}
                  </option>
                );
              })}
            </select>
            <div className="dropdown">
              <img src={arrow} alt="option" />
            </div>
          </div>
        </>
      )}
      <button onClick={handleProfile} className="profileButton">
        Profile
      </button>
      {salaryFlag === "red" ? (
        <Tippy
          content={`The salary entered is above the £${redSalary} threshold`}
        >
          <div className="warning">
            <img src={warning} alt="warning" />
          </div>
        </Tippy>
      ) : null}
      {dayRateFlag === "red" ? (
        <Tippy
          content={`The day rate entered is above the £${redDayRate} threshold`}
        >
          <div className="warning">
            <img src={warning} alt="warning" />
          </div>
        </Tippy>
      ) : null}
      {person.location === "Other" ? (
        <Tippy content={`Must be within the UK`}>
          <div className="warning">
            <img src={warning} alt="warning" />
          </div>
        </Tippy>
      ) : null}
      <div className="hidden deleteIcon">
        <img
          src={bin}
          alt="delete"
          style={{ cursor: "pointer" }}
          onClick={() => setConfirmDelete(true)}
        />
      </div>
    </div>
  );
}
export default TeamRow;
