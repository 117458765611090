import React from "react";
import { useDispatch, useSelector } from "react-redux";
// import { questions } from "../../helpers";
import { wizardAnswer } from "../../store/entities/project";
// import { updateUserSelection } from "../../store/user";

function wizardQuestionSingle() {
  const dispatch = useDispatch();
  const { currentQuestionIndex } = useSelector(
    (state) => state.entities.project.data.wizard
  );
  // const {skip} = questions[currentQuestionIndex]

  function handleAnswer(answer) {
    dispatch(
      wizardAnswer({
        // questions,
        answer,
        index: currentQuestionIndex,
        option: null,
      })
    );
  }

  return (
    <>
      <button className="binary" onClick={() => handleAnswer(true)}>
        Yes
      </button>
      <button className="binary" onClick={() => handleAnswer(false)}>
        No
      </button>
    </>
  );
}

export default wizardQuestionSingle;
