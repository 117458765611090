import React from "react";
import { useSelector } from "react-redux";
import GanttResourcesInput from "./ganttResourcesInput";
import GanttResourcesButton from "./ganttResourcesButton";
import { Container } from "./ganttResourcesModalRow";

function ResourcesRowAssAll(props) {
  const { allPeople, task, taskIds } = props;
  const { taskId } = useSelector(
    (state) => state.entities.allocations.resourcesInput
  );
  const selected = taskId === task.taskId;

  return (
    <Container selected={selected}>
      <div className="modalRow">
        <p className="description bold">Assign to all tasks</p>
        {allPeople.map((person, index) => {
          return (
            <GanttResourcesButton
              person={person}
              key={index}
              task={task}
              index={index}
              taskIds={taskIds}
              all={true}
            />
          );
        })}
      </div>
      <div className="inputSpace">
        {taskId === "all" ? (
          <GanttResourcesInput task={task} taskIds={taskIds} all={true} />
        ) : null}
      </div>
    </Container>
  );
}

export default ResourcesRowAssAll;
