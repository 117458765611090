// import axios from "axios";
import { store } from "../index";
import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { recentFetch, roundTo, reducerNotification } from "../../helpers";
import { apiCallBegan } from "../api";
import moment from "moment";

const data = {
  markets: [
    { name: "UK Market", start: 0, growth: 0 },
    { name: "Global", start: 0, growth: 0 },
  ],
  streams: [{}],
  total: [],
  revenueStart: 0,
  defaultStart: 0,
};

const initialState = {
  loading: false,
  lastFetch: 0,
  error: "",
  data,
  lastSent: 0,
  lastEdit: 0,
}

const slice = createSlice({
  name: "revenue",
  initialState,
  reducers: {
    revenueDataRequested: (revenue, _) => {
      revenue.loading = true;
    },
    revenueDataRequestFailed: (revenue, action) => {
      revenue = initialState;
      reducerNotification("error", action.payload);
    },
    revenueDataReceived: (revenue, action) => {
      revenue.data = action.payload;
      revenue.loading = false;
      revenue.lastFetch = Date.now();
    },

    sendingEmptyRevenue: (revenue, _) => {
      revenue.loading = true;
    },

    updateMarket: (revenue, action) => {
      const { index, key, value } = action.payload;
      revenue.data.markets[index][key] = value;
      revenue.lastEdit = Date.now();
    },
    addMarket: (revenue) => {
      const index = revenue.data.markets.length - 1;
      revenue.data.markets.splice(index, 0, {});
      revenue.lastEdit = Date.now();
    },
    deleteMarket: (revenue, action) => {
      const { index } = action.payload;
      revenue.data.markets.splice(index, 1);
      revenue.lastEdit = Date.now();
    },
    updateRevenueKey: (revenue, action) => {
      const { key, value } = action.payload;
      revenue.data[key] = value;
      revenue.lastEdit = Date.now();
    },
    updateProfitMargin: (revenue, action) => {
      revenue.data.profitMargin = action.payload.profitMargin;
      revenue.lastEdit = Date.now();
    },
    updateCorpTax: (revenue, action) => {
      revenue.data.corpTax = action.payload.corpTax;
      revenue.lastEdit = Date.now();
    },
    updateRevenueDefault: (revenue, action) => {
      const { projectLength, startYear, startMonth } = action.payload;
      console.log(projectLength, parseInt(startYear), startMonth);
      const projectStart = moment(`${startMonth} ${startYear}`, "MMM YYYY");
      let projectEnd = moment(projectStart).add(projectLength, "M").add(1, "y");
      const endMonth = parseInt(projectEnd.format("M"));

      let defaultStart = parseInt(projectEnd.format("y"));
      if (endMonth > 5) defaultStart = defaultStart + 1;
      revenue.data.revenueStart = defaultStart;
      revenue.data.defaultStart = defaultStart;
      revenue.lastEdit = Date.now();
    },
    updateStreamYear: (revenue, action) => {
      const { streamIndex, key, year, value } = action.payload;
      if (!revenue.data.streams[streamIndex][key])
        revenue.data.streams[streamIndex][key] = {};
      revenue.data.streams[streamIndex][key][year] = value;
      revenue.lastEdit = Date.now();
    },
    updateStreamName: (revenue, action) => {
      const { streamIndex, key, value } = action.payload;
      revenue.data.streams[streamIndex][key] = value;
      revenue.lastEdit = Date.now();
    },
    updateStreamUnit: (revenue, action) => {
      const { streamIndex, value, markets } = action.payload;
      console.log(streamIndex, value, markets);
      markets.forEach((market) => {
        if (!revenue.data.streams[streamIndex][market])
          revenue.data.streams[streamIndex][market] = {};
        revenue.data.streams[streamIndex][market].unitRevenue = value;
      });
      revenue.lastEdit = Date.now();
    },
    addStream: (revenue) => {
      revenue.data.streams.push({});
      revenue.lastEdit = Date.now();
    },
    deleteStream: (revenue, action) => {
      const { index } = action.payload;
      revenue.data.streams.splice(index, 1);
      revenue.lastEdit = Date.now();
    },

    apiSuccess: (revenue, action) => {
      revenue.loading = false;
      // reducerNotification("info", "Revenue saved");
      revenue.lastSent = Date.now();
    },
    apiFailed: (revenue, _) => {
      revenue.loading = false;
      reducerNotification("error", "Revenue failed");
    },
  },
});
export default slice.reducer;

export const {
  revenueDataReceived,
  updateMarket,
  addMarket,
  deleteMarket,
  updateRevenueKey,
  updateStreamYear,
  updateStreamName,
  updateStreamUnit,
  updateRevenueDefault,
  addStream,
  deleteStream,
} = slice.actions;

const {
  revenueDataRequested,
  revenueDataRequestFailed,

  sendingEmptyRevenue,

  apiSuccess,
  apiFailed,
} = slice.actions;

// Action Creators

const url = "/revenue";

export const loadRevenueData = () => (dispatch, getState) => {
  const { lastFetch } = getState().entities.revenue;
  // console.log("fetching assignments data...");
  if (recentFetch(lastFetch)) return;

  dispatch(
    apiCallBegan({
      url: url + "/selected",
      method: "get",
      data: null,
      onStart: revenueDataRequested.type,
      onSuccess: revenueDataReceived.type,
      onError: revenueDataRequestFailed.type,
    })
  );
};

export const sendUpdatedRevenue = () => (dispatch, getState) => {
  // console.log(dispatch, getState);
  const revenue = getState().entities.revenue;
  const { lastEdit, lastSent, data } = revenue;
  if (lastSent >= lastEdit) return console.log("not sending");;
  // console.log(data);
  dispatch(
    apiCallBegan({
      url: url + "/selected",
      method: "put",
      data,
      // onStart: sendingUpdate.type,
      onSuccess: apiSuccess.type,
      onError: apiFailed.type,
    })
  );
};

export const initiateRevenue = (projectId) =>
  apiCallBegan({
    url: url + "/new",
    method: "post",
    data: {
      projectId,
      data,
    },
    onStart: sendingEmptyRevenue.type,
    onSuccess: apiSuccess.type,
    onError: apiFailed.type,
  });

  export const cloneRevenue = (projectId) => (dispatch, getState) => {
    const { data } = getState().entities.revenue;
    dispatch(
      apiCallBegan({
        url: url + "/new",
        method: "post",
        data: {
          projectId,
          data,
        },
        // onStart: sendingAllocation.type,
        onSuccess: apiSuccess.type,
        onError: apiFailed.type,
      })
    );
  };

  export const deleteProjectRevenue = (projectId) =>
    apiCallBegan({
      url: url + "/selected",
      method: "delete",
      data: {
        projectId,
      },
      // onStart: sendingEmptyAssignments.type,
      onSuccess: apiSuccess.type,
      onError: apiFailed.type,
    });

// Selectors

export const getMarketList = createSelector(
  (state) => state.entities.revenue.data.markets,
  (markets) => {
    // console.log("getMarketList");
    const result = [];
    markets.forEach((market) => {
      if (market.name === "Global") return;
      result.push(market.name);
    });
    return result;
  }
);

export const getMarketData = createSelector(
  (state) => state.entities.revenue.data.markets,
  (markets) => {
    // console.log("getMarketData");
    const result = {};
    markets.forEach((market) => {
      let { name, start, growth } = market;
      const values = name && start;
      growth = growth ? growth : 0;
      const y1 = start;
      const y2 = values ? roundTo(y1 + (y1 * growth) / 100, 0) : null;
      const y3 = values ? roundTo(y2 + (y2 * growth) / 100, 0) : null;
      const y4 = values ? roundTo(y3 + (y3 * growth) / 100, 0) : null;
      const y5 = values ? roundTo(y4 + (y4 * growth) / 100, 0) : null;
      result[name] = { y1, y2, y3, y4, y5 };
    });
    return result;
  }
);

export const getStreamTotals = createSelector(
  (state) => state.entities.revenue.data.streams,
  (streams) => {
    // console.log("getStreamTotals");
    const markets = getMarketList(store.getState());
    const years = ["y1", "y2", "y3", "y4", "y5"];
    const result = [];

    console.log(streams);

    for (let i = 0; i < streams.length; i++) {
      // const { unitRevenue } = streams[i];
      const summary = {};

      years.forEach((year) => {
        let sales = 0;
        let revenue = 0;
        markets.forEach((market) => {
          const checkMarket = streams[i][market] && streams[i][market][year];
          const value = checkMarket ? streams[i][market][year] : 0;
          const unitRevenue = checkMarket ? streams[i][market].unitRevenue : 0;
          sales = sales + value;
          revenue = revenue + value * unitRevenue;
        });
        const data = { sales, revenue };
        summary[year] = data;
      });
      result.push(summary);
    }
    console.log(result);
    return result;
  }
);

export const getUKRevenue = createSelector(
  (state) => state.entities.revenue,
  (revenue) => {
    // console.log("getUKRevenue");
    const years = ["y1", "y2", "y3", "y4", "y5"];
    const result = {};
    years.forEach((year) => {
      let value = 0;
      let unitRevenue = 0;
      revenue.data.streams.forEach((stream) => {
        let quantity = 0;
        if (stream["UK Market"] && stream["UK Market"][year]) {
          unitRevenue = stream["UK Market"].unitRevenue || 0;
          quantity = stream["UK Market"][year];
        }
        const combined = quantity * unitRevenue;
        value = value + combined;
      });
      result[year] = value;
    });

    return result;
  }
);

export const getAnnualRevenue = createSelector(
  (state) => state.entities.revenue,
  () => {
    // console.log("getAnnualRevenue");
    const streams = getStreamTotals(store.getState());
    const years = ["y1", "y2", "y3", "y4", "y5"];
    const result = {};
    years.forEach((year) => {
      let total = 0;
      streams.forEach((stream) => {
        const count = stream[year].revenue || 0;
        total = total + count;
      });
      result[year] = total;
    });
    return result;
  }
);
