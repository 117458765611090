import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { getWorkPackageIds } from "../../store/entities/tasks";
import OutputSection from "./OutputSection";
import OutputWPSummary from "./OutputWPSummary";

export default function OutputPMWorkPacks(props) {
  const { mapObj, edit } = props;
  const packIds = useSelector(getWorkPackageIds);

  return (
    <Container>
      <h3 className="title">Work Packages</h3>
      <ul>
        {packIds.map((id, index) => {
          return <OutputWPSummary key={index} index={index} id={id} />;
        })}
      </ul>
      <OutputSection
        objKey="ganttAppendix"
        mapObj={mapObj}
        edit={edit}
      />
    </Container>
  );
}

const Container = styled.div`
ul {
  margin-bottom: 5px;
}`;
