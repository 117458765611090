import React from "react";
import styled from "styled-components";
import { wpInfoColor } from "../../helpers";
import { useSelector } from "react-redux";
import Tippy from "@tippy.js/react";

export default function ResourcesModalTitle(props) {
  const { name, role, leader, employment, personId, acronym } = props.person;
  const selected = useSelector(
    (state) => state.entities.allocations.resourcesInput.personId
  );

  const partner = {
    lead: "Lead",
    pOne: "P2",
    pTwo: "P3",
  };

  const { companyAcronym } = useSelector(
    (state) => state.entities.project.data[leader]
  );
  const ref = companyAcronym || partner[leader];

  const content = `${name} - ${role} (${ref})`;
  const className = `personTitle ${personId === selected ? "selected" : ""}`;

  return (
    <Container className={className}>
      <Tippy content={content}>
        <h3>{acronym}</h3>
      </Tippy>
      <Tippy content={employment}>
        <div className={`employment ${employment}`} />
      </Tippy>
    </Container>
  );
}

const Container = styled.div`
  .bold {
    color: ${wpInfoColor};
    font-weight: bold;
  }
  .title {
    min-height: 50px;
    margin-bottom: 10px;
    background-color: ${wpInfoColor};
    color: white;
    padding: 0 20px;
    border-radius: 6px 6px 0px 0px;
  }
  .personTitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 3px;
    align-items: center;
    width: 45px;
    height: 30px;
  }
  .employment {
    width: 6px;
    height: 6px;
    border-radius: 100%;
  }
  .staff {
    background-color: lightgreen;
  }
  .subcontract {
    background-color: orange;
  }
  .selected {
    background-color: rgb(240, 240, 240);
    width: 90px;
    color: ${wpInfoColor};
  }
  .person {
    text-align: center;
    /* display: flex; */
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }
  .select {
    border: 1px solid lightgray;
    cursor: pointer;
    :focus {
      border-color: ${wpInfoColor};
    }
  }
  .total {
    width: 50px;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    font-weight: 600;
  }
  .under {
    color: orange;
  }
  .ok {
    color: green;
  }
  .over {
    color: red;
  }
  img {
    max-width: 100%;
    max-height: 100%;
  }
`;
