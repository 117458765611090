import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getSelectedProject, updateUserSelection } from "../../store/user";
import OptionsInput from "./optionsInput";
import { Container } from "./optionsStyling";
// import Global from "./globalIndicator";
import AdminModal from "../modals/adminModal";
import AdminModalRoles from "../modals/adminModalRoles";
import TableTabs from "../table/TableTabs";

// import MaterialsRow from "./optionsRow";

function TeamOptions() {
  const dispatch = useDispatch();
  const {
    maxTeamMembers,
    maxSubcontract,
    amberSalary,
    redSalary,
    amberDayRate,
    redDayRate,
    projectDesc,
  } = useSelector(getSelectedProject);

  const { roles, projectRoleDefault, locations, locationDefault } = useSelector(
    (state) => state.entities.global.data
  );
  const projectRoles = roles.roleData;
  const { showComponent, globalSettings } = useSelector((state) => state.user);

  function showModal(field) {
    const value = showComponent === field ? "" : field;
    dispatch(updateUserSelection({ key: "showComponent", value }));
  }

  const modalData = {
    projectRoles: {
      title: "Project Roles",
      // global: true,
      list: projectRoles,
      defaultOption: projectRoleDefault,
      listKey: "projectRoles",
      // defaultKey: "projectRoleDefault",
    },
    locations: {
      title: "Subcontractor Locations",
      global: true,
      list: locations,
      defaultOption: locationDefault,
      listKey: "locations",
      defaultKey: "locationDefault",
    },
  };

  // tabs
  const data = {
    tabs: [projectDesc, "Global"],
    selectedTab: globalSettings ? "global" : projectDesc,
    select: (selected) => {
      let value;
      if (selected === "global") value = true;
      else value = false;
      dispatch(
        updateUserSelection({
          key: "globalSettings",
          value,
        })
      );
    },
  };

  return (
    <Container>
      {showComponent === "projectRoles" ? (
        <AdminModalRoles data={modalData.projectRoles} />
      ) : null}
      {showComponent === "locations" ? (
        <AdminModal data={modalData.locations} />
      ) : null}
      <div className="rows adminContent">
        <TableTabs data={data} />

        {globalSettings ? (
          <>
            <div className="row titles leaderTabMargin">
              <h3 className="title description">Team Options</h3>
            </div>

            <div className="row">
              {/* {global ? <Global /> : null} */}
              <p className="field display description">Project Roles</p>
              <button
                className="showModal value"
                onClick={() => showModal("projectRoles")}
              >
                Show options
              </button>
            </div>

            <div className="row">
              {/* {global ? <Global /> : null} */}
              <p className="field display description">
                Subcontractor Locations
              </p>
              <button
                className="showModal value"
                onClick={() => showModal("locations")}
              >
                Show options
              </button>
            </div>
          </>
        ) : (
          <>
            {" "}
            <div className="row titles leaderTabMargin">
              <h3 className="title description">Team Constraints</h3>
              <h3 className="title value">Value</h3>
            </div>
            <OptionsInput
              title="Max Staff per Partner"
              field={"maxTeamMembers"}
              value={maxTeamMembers}
            />
            <OptionsInput
              title="Max Subcontractors per Partner"
              field={"maxSubcontract"}
              value={maxSubcontract}
            />
            <div className="row titles leaderTabMargin">
              <h3 className="title description">Team Thresholds</h3>
              <h3 className="title value">Amber</h3>
              <h3 className="title value">Red</h3>
            </div>
            <div className="row">
              <p className="field display description">Staff Salary</p>
              <OptionsInput
                multiple={true}
                field={"amberSalary"}
                value={amberSalary}
                characters={5}
              />
              <OptionsInput
                multiple={true}
                field={"redSalary"}
                value={redSalary}
                characters={5}
              />
            </div>
            <div className="row">
              <p className="field display description">Subcontract Day Rate</p>
              <OptionsInput
                multiple={true}
                field={"amberDayRate"}
                value={amberDayRate}
                characters={4}
              />
              <OptionsInput
                multiple={true}
                field={"redDayRate"}
                value={redDayRate}
                characters={4}
              />
            </div>
          </>
        )}
      </div>
      {/* </div> */}
    </Container>
  );
}
export default TeamOptions;
