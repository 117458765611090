import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Tippy from "@tippy.js/react";
import "tippy.js/dist/tippy.css";
import { handleSendCapex, isNumberKey, numberToCurrency } from "../../helpers";
import bin from "../../images/bin-grey.png";
import reorder from "../../images/reorder.png";
import { deleteCapex, updateCapex } from "../../store/entities/capex";

function CapexRow(props) {
  const dispatch = useDispatch();
  const { capex, provided, index } = props;
  const {
    capexId,
    condition,
    description,
    depreciation,
    currentValue,
    utilisation,
  } = capex;
  const { projectLength } = useSelector(
    (state) => state.entities.project.data.details
  );
  const { utilisations } = useSelector((state) => state.entities.global.data);
  const residualValue =
    depreciation === 0
      ? 0
      : Math.round((1 - projectLength / depreciation) * currentValue);
  const netCost =
    Math.round((currentValue - residualValue) * utilisation) / 100;
  const formattedCost = numberToCurrency(netCost);

  function onChangeHandler(e) {
    const key = e.target.name;
    let value = e.target.value;
    if (key === "depreciation" || key === "currentValue") {
      if (e.target.value) {
        value = parseInt(value.slice(-6));
      } else value = 0;
    }
    dispatch(
      updateCapex({
        capexId,
        key,
        value,
        projectLength,
      })
    );
  }

  function handleDeleteCapex() {
    dispatch(deleteCapex({ capexId }));
    handleSendCapex();
  }

  return (
    <div className="row">
      <Tippy content="Drag to reorder rows">
        <div {...provided.dragHandleProps} className="hidden grabHandle">
          <img src={reorder} alt="reorder" />
        </div>
      </Tippy>
      <input
        id={index + "description"}
        name="description"
        value={description}
        onChange={onChangeHandler}
        onBlur={handleSendCapex}
        className="field materialsDescription"
      />
      <select
        id={index + "condition"}
        name="condition"
        value={condition}
        onChange={onChangeHandler}
        onBlur={handleSendCapex}
        className="field small left"
      >
        <option value="New">New</option>
        <option value="Existing">Existing</option>
      </select>
      <input
        type="text"
        id={index + "depreciation"}
        name="depreciation"
        value={depreciation}
        onKeyDown={isNumberKey}
        onChange={onChangeHandler}
        onBlur={handleSendCapex}
        className="field small depreciation"
      />
      <div className="pound">
        <p>£</p>
        <input
          type="text"
          id={index + "currentValue"}
          name="currentValue"
          value={currentValue}
          onKeyDown={isNumberKey}
          onChange={onChangeHandler}
          onBlur={handleSendCapex}
          className="field "
        />
      </div>
      <p className="field display small">£{Math.max(0, residualValue)}</p>
      <div className="symbol">
        <select
          id={index + "utilisation"}
          name="utilisation"
          value={utilisation}
          onKeyDown={isNumberKey}
          onChange={onChangeHandler}
          onBlur={handleSendCapex}
          className="field small"
        >
          {utilisations.map((value, index) => {
            return (
              <option key={index} value={value}>
                {value}
              </option>
            );
          })}
        </select>
        <p className="percent">%</p>
      </div>
      <p className="field display small">
        £{formattedCost ? formattedCost : 0}
      </p>
      <div className="hidden deleteIcon">
        <img
          src={bin}
          alt="delete"
          style={{ cursor: "pointer" }}
          onClick={handleDeleteCapex}
        />
      </div>
    </div>
  );
}
export default CapexRow;
