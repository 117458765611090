// import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { recentFetch, reducerNotification } from "../../helpers";
import { apiCallBegan } from "../api";

const initialState = {
  loading: false,
  lastFetch: 0,
  data: [],
  lastSent: 0,
  lastEdit: 0,
  error: "",
};

const slice = createSlice({
  name: "materials",
  initialState,
  reducers: {
    materialsDataRequested: (materials, _) => {
      materials.loading = true;
    },
    materialsDataRequestFailed: (materials, action) => {
      materials = initialState;
      reducerNotification("error", action.payload);
    },
    materialsDataReceived: (materials, action) => {
      materials.data = action.payload;
      materials.loading = false;
      materials.lastFetch = Date.now();
    },

    sendingMaterials: (materials, _) => {
      materials.loading = true;
    },

    addMaterial: (materials, action) => {
      const { newMaterial, position } = action.payload;
      materials.data.splice(position, 0, newMaterial);
      materials.lastEdit = Date.now();
    },
    deleteMaterial: (materials, action) => {
      const index = materials.data.findIndex(
        (material) => material.materialId === action.payload.materialId
      );
      materials.data.splice(index, 1);
      materials.lastEdit = Date.now();
    },
    updateMaterial: (materials, action) => {
      const { key, value, materialId } = action.payload;
      const index = materials.data.findIndex(
        (person) => person.materialId === materialId
      );
      materials.data[index][key] = value;
      materials.lastEdit = Date.now();
    },
    reorderMaterials: (materials, action) => {
      const originalIndex = materials.data.findIndex(
        (material) => material.materialId === action.payload.material.materialId
      );
      const newIndex = originalIndex + action.payload.movement;
      const [material] = materials.data.splice(originalIndex, 1);
      materials.data.splice(newIndex, 0, material);
      materials.lastEdit = Date.now();
    },

    apiSuccess: (materials, action) => {
      materials.loading = false;
      // reducerNotification("info", "Materials saved");
      materials.lastSent = Date.now();
    },
    apiFailed: (deadlines, _) => {
      deadlines.loading = false;
      reducerNotification("error", "Materials failed");
    },
  },
});

export const {
  materialsDataRequested,
  materialsDataRequestFailed,
  materialsDataReceived,

  reorderMaterials,
  updateMaterial,
  addMaterial,
  deleteMaterial,
} = slice.actions;

const {
  sendingMaterials,

  apiSuccess,
  apiFailed,
} = slice.actions;
export default slice.reducer;

// Action Creators

const url = "/materials";

export const loadMaterialsData = () => (dispatch, getState) => {
  const { lastFetch } = getState().entities.materials;
  // console.log("fetching materials data...");
  if (recentFetch(lastFetch)) return;

  dispatch(
    apiCallBegan({
      url: url + "/selected",
      method: "get",
      data: null,
      onStart: materialsDataRequested.type,
      onSuccess: materialsDataReceived.type,
      onError: materialsDataRequestFailed.type,
    })
  );
};

export const sendUpdatedMaterials = () => (dispatch, getState) => {
  const materials = getState().entities.materials;
  const { lastEdit, lastSent, data } = materials;
  if (lastSent >= lastEdit) return;
  dispatch(
    apiCallBegan({
      url: url + "/selected",
      method: "put",
      data,
      // onStart: sendingMaterials.type,
      onSuccess: apiSuccess.type,
      onError: apiFailed.type,
    })
  );
};

export const initiateMaterials = (projectId) =>
  apiCallBegan({
    url: url + "/new",
    method: "post",
    data: {
      projectId,
      data: [],
    },
    onStart: sendingMaterials.type,
    onSuccess: apiSuccess.type,
    onError: apiFailed.type,
  });

  export const cloneMaterials = (projectId) => (dispatch, getState) => {
    const { data } = getState().entities.materials;
    dispatch(
      apiCallBegan({
        url: url + "/new",
        method: "post",
        data: {
          projectId,
          data,
        },
        // onStart: sendingAllocation.type,
        onSuccess: apiSuccess.type,
        onError: apiFailed.type,
      })
    );
  };

export const deleteProjectMaterials = (projectId) =>
  apiCallBegan({
    url: url + "/selected",
    method: "delete",
    data: {
      projectId,
    },
    // onStart: sendingEmptyAssignments.type,
    onSuccess: apiSuccess.type,
    onError: apiFailed.type,
  });

// Selectors

export const getMaterialsCost = createSelector(
  (state) => state.entities.materials.data,
  (materials) => {
    // console.log("getMaterialsCost");
    const costs = {
      lead: 0,
      pOne: 0,
      pTwo: 0,
      combined: 0,
      category: "Materials",
    };
    materials.forEach((material) => {
      const materialCost = material.cost * material.quantity;
      costs[material.leader] = costs[material.leader] + materialCost;
      costs.combined = costs.combined + materialCost;
    });
    return costs;
  }
);
