import React from "react";
// import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { printGanttColors } from "../helpers";
import {
  getGeneratedWorkPackages,
  getWorkedDaysPerMonth,
  getWorkedDaysPerWP,
} from "../store/entities/tasks";
import PrintGanttBars from "../components/print/PrintGanttBars";
import PrintGanttDeadlines from "../components/print/PrintGanttDeadlines";
import { getDeliverables, getMilestones } from "../store/entities/deadlines";
import { getProjectDates } from "../store/entities/project";
import { getAllocationsByTaskId } from "../store/entities/allocations";
import { Container } from "./printGanttStyling";
// import { ganttRandomColors } from "../store/user";

export default function PrintGanttPDF() {
  // const dispatch = useDispatch();
  const packs = useSelector(getGeneratedWorkPackages);
  const deliverables = useSelector(getDeliverables);
  const milestones = useSelector(getMilestones);
  const dates = useSelector(getProjectDates);
  const resources = useSelector(getAllocationsByTaskId);
  const workedDays = useSelector(getWorkedDaysPerMonth);
  const packDays = useSelector(getWorkedDaysPerWP);
  const { projectLength } = useSelector(
    (state) => state.entities.project.data.details
  );
  
  const {
    headerHeight,
    titleHeight,
    rowHeight,
    headerSize,
    textSize,
    titleSize,
    resourcesSize,
    descriptionLength,
    resourcesLength,
    textPosition,
    showPackDays,
    showDelsMils,

    header,
    years,
    taskTitle,
    taskBar,
    delTitle,
    milTitle,
    daysPosition,
    descriptionWidth,
    resourcesWidth,
    daysWidth,
    barShape,
  } = useSelector((state) => state.user.gantt);

  const monthWidth = (1 / projectLength) * 100 + "%";

  let currentYear = 0;
  const calendar = [];
  dates.forEach((date) => {
    const year = date.slice(-4);
    if (year === currentYear) calendar.push("");
    else {
      calendar.push(year);
      currentYear = year;
    }
  });

  const data = {
    header: printGanttColors[header].solid,
    years: printGanttColors[years].solid,
    taskTitle: printGanttColors[taskTitle].solid,
    taskBackground: printGanttColors[taskTitle].fade,
    taskCalender: printGanttColors[taskBar].fade,
    taskBar: printGanttColors[taskBar].solid,
    delTitle: printGanttColors[delTitle].solid,
    delBackground: printGanttColors[delTitle].fade,
    milTitle: printGanttColors[milTitle].solid,
    milBackground: printGanttColors[milTitle].fade,
    fontColor: printGanttColors[milTitle].fontColor,

    monthWidth,

    headerHeight,
    titleHeight,
    rowHeight,
    headerSize,
    textSize,
    titleSize,
    resourcesSize,
    textPosition,
    descriptionWidth,
    resourcesWidth,
    daysWidth,
    daysPosition,
    barShape,
  };

  return (
    <Container id="pdf" data={data}>
      <div className="contents">
        <header>
          {daysPosition === 0 ? (
            <div className="days header">
              <h6 className="title headerSize">Days</h6>
            </div>
          ) : null}
          <div className="description header">
            <h6 className="title headerSize">Description</h6>
          </div>
          <div className="resources header">
            <h6 className="title headerSize">Resources</h6>
          </div>
          {daysPosition === 1 ? (
            <div className="days header">
              <h6 className="title headerSize">Days</h6>
            </div>
          ) : null}
          <div className="gantt col">
            <div className="years timeline">
              {calendar.map((year, index) => {
                return (
                  <div
                    key={index}
                    className={`block ${year && index ? "newYear" : ""}`}
                  >
                    <h6 className="year displayYear headerSize">{year}</h6>
                  </div>
                );
              })}
            </div>
            <div className="months">
              {dates.map((month, index) => {
                return (
                  <div key={index} className="block timeline">
                    <h6 className="title headerSize">{month.slice(0, 1)}</h6>
                  </div>
                );
              })}
            </div>
          </div>
          {daysPosition === 2 ? (
            <div className="days header">
              <h6 className="title headerSize">Days</h6>
            </div>
          ) : null}
        </header>

        {packs.map((pack, index) => {
          return (
            <div key={index} className="pack">
              <div className="titleBar">
                {daysPosition === 0 ? (
                  <div className="days taskTitleColor title">
                    <h6>{packDays[pack[0].workPackageId].total}</h6>
                  </div>
                ) : null}
                <div className=" title descRes taskTitleColor">
                  <h6>
                    WP{index + 1} - {pack[0].workPackageTitle}
                  </h6>
                </div>
                {daysPosition === 1 ? (
                  <div className="days taskTitleColor title">
                    <h6>{packDays[pack[0].workPackageId].total}</h6>
                  </div>
                ) : null}
                <div className="gantt title">
                  {packDays[pack[0].workPackageId].schedule.map(
                    (days, index) => {
                      return (
                        <div
                          key={index}
                          className={`block taskTitleColor ${
                            (index + 1) % 3 === 0 ? "quarter" : null
                          }`}
                        >
                          <h6>{showPackDays && days ? days : ""}</h6>
                        </div>
                      );
                    }
                  )}
                </div>
                {daysPosition === 2 ? (
                  <div className="days taskTitleColor title">
                    <h6>{packDays[pack[0].workPackageId].total}</h6>
                  </div>
                ) : null}
              </div>
              {pack.map((task, index) => {
                return (
                  <div key={index} className="task">
                    {daysPosition === 0 ? (
                      <div className="days taskDays">
                        <p>{task.days ? task.days : "-"}</p>
                      </div>
                    ) : null}
                    <div className="description">
                      <p>
                        {task.description.length < descriptionLength
                          ? task.description
                          : task.description
                              .slice(0, descriptionLength)
                              .concat("...")}
                      </p>
                    </div>
                    <div className="resources">
                      <p className="data acronyms">
                        {resources[task.taskId].people.length < resourcesLength
                          ? resources[task.taskId].people
                          : resources[task.taskId].people
                              .slice(0, resourcesLength)
                              .concat("...")}
                      </p>
                    </div>
                    {daysPosition === 1 ? (
                      <div className="days taskDays">
                        <p>{task.days ? task.days : "-"}</p>
                      </div>
                    ) : null}
                    <div className="gantt">
                      <div className="schedule">
                        {task.schedule.map((_, index) => {
                          return (
                            <div
                              key={index}
                              className={`taskMonth ${
                                (index + 1) % 3 === 0 ? "quarter" : null
                              }`}
                            ></div>
                          );
                        })}
                      </div>
                      <PrintGanttBars schedule={task.schedule} />
                    </div>
                    {daysPosition === 2 ? (
                      <div className="days taskDays">
                        <p>{task.days ? task.days : "-"}</p>
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </div>
          );
        })}

        <div className="pack packTotals">
          <div className="titleBar">
            <div className=" title totalDaysSpacer " />
            <div className="gantt">
              {workedDays.map((day, index) => {
                return (
                  <div key={index} className={`block`}>
                    <p className="totalDays">{day ? day : "-"}</p>
                  </div>
                );
              })}
            </div>
            <div className="days" />
          </div>
        </div>

        <div className="title pack">
          {daysPosition === 0 ? <div className="days" /> : null}
          <div className="deadlineDescription title DColor">
            <h6 className="">Deliverables</h6>
          </div>
          <div className="resources title DColor">
            <h6 className="date">Date</h6>
          </div>
        </div>
        {deliverables.map((entry, index) => {
          return (
            <div key={index} className="task">
              {daysPosition === 0 ? <div className="days" /> : null}
              <div className="deadlineDescription">
                <p>{entry.description}</p>
              </div>
              <div className="resources">
                <p className="date">{dates[entry.scheduled]}</p>
              </div>
              {daysPosition === 1 ? <div className="days" /> : null}
              <div className="gantt">
                <PrintGanttDeadlines
                  scheduled={entry.scheduled}
                  type="D"
                  number={index + 1}
                  showDelsMils={showDelsMils}
                />
              </div>
              {daysPosition === 2 ? <div className="days" /> : null}
            </div>
          );
        })}
        <div className="title pack">
          {daysPosition === 0 ? <div className="days" /> : null}
          <div className="deadlineDescription title MColor">
            <h6 className="">Milestones</h6>
          </div>
          <div className="resources title MColor">
            <h6 className="date">Date</h6>
          </div>
        </div>
        {milestones.map((entry, index) => {
          return (
            <div key={index} className="task">
              {daysPosition === 0 ? <div className="days" /> : null}
              <div className="deadlineDescription">
                <p>{entry.description}</p>
              </div>
              <div className="resources">
                <p className="date">{dates[entry.scheduled]}</p>
              </div>
              {daysPosition === 1 ? <div className="days" /> : null}
              <div className="gantt">
                <PrintGanttDeadlines
                  scheduled={entry.scheduled}
                  type="M"
                  number={index + 1}
                  showDelsMils={showDelsMils}
                />
              </div>
              {daysPosition === 2 ? <div className="days" /> : null}
            </div>
          );
        })}
      </div>
    </Container>
  );
}
