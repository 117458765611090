import React from "react";
import styled from "styled-components";
import {
  ranking,
  riskLevelHighColor,
  riskLevelLowColor,
  riskLevelMediumColor,
  riskLevelVeryHighColor,
  riskLevelVeryLowColor,
  manRiskColor,
  comRiskColor,
  techRiskColor,
  legRiskColor,
  envRiskColor,
} from "../../helpers";

export default function PrintRiskCategories(props) {
  const { type, docRisks, riskWidth, mitigationWidth } = props;
  console.log(riskWidth, mitigationWidth);

  return (
    <Container
      color={props.color}
      fontSize={8}
      riskWidth={riskWidth}
      mitigationWidth={mitigationWidth}
    >
      <div className={"type " + type} id={type.toLocaleLowerCase()}>
        <p className="vertical">{type}</p>
      </div>
      <div className="risks">
        {docRisks.map((risk, index) => (
          <div key={index} className="risk">
            <p className="description text">{risk.riskDescription}</p>
            <p className={`flag ${ranking[risk.riskPreProbability]}`}>
              {ranking[risk.riskPreProbability]}
            </p>
            <p className={`flag ${ranking[risk.riskPreConsequence]}`}>
              {ranking[risk.riskPreConsequence]}
            </p>
            <p className="mitigation text">{risk.riskMitigation}</p>
            <p className={`flag ${ranking[risk.riskPostProbability]}`}>
              {ranking[risk.riskPreProbability]}
            </p>
            <p className={`flag ${ranking[risk.riskPostConsequence]}`}>
              {ranking[risk.riskPreConsequence]}
            </p>
            <p className="flag owner">{risk.riskOwner}</p>
          </div>
        ))}
      </div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  /* flex-direction: column; */
  /* margin-bottom: 20px; */
  text-align: left;
  border: 2px solid black;
  margin-bottom: -2px;

  p {
    font-size: ${(props) => props.fontSize}pt;
  }

  .type {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 90px;
    border-right: 1px solid black;
    background-color: ${(props) => props.color};
  }

  .vertical {
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
    white-space: nowrap;
    width: 25px;
    height: 25px;
    font-weight: bold;
    /* border: 1px solid black; */
  }
  .Managerial {
    background-color: ${manRiskColor};
  }
  .Commercial {
    background-color: ${comRiskColor};
  }
  .Technical {
    background-color: ${techRiskColor};
  }
  .Legal {
    background-color: ${legRiskColor};
  }
  .Environmental {
    background-color: ${envRiskColor};
  }

  .risks {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
  }
  .risk {
    /* border: 1px solid black; */
    display: flex;
    height: 100%;
    width: 100%;
    border-bottom: 1px solid black;
    /* background-color: black; */
    :last-child {
      border-bottom: none;
    }
    .text {
      display: flex;
      align-items: center;
      padding: 2px 5px;
      /* border: 1px solid black; */
    }
    .description {
      width: ${(props) => props.riskWidth}%;
      border-right: 1px solid black;
    }
    .mitigation {
      width: ${(props) => props.mitigationWidth}%;
      border-right: 1px solid black;
    }
    .flag {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      border-right: 1px solid black;
      width: 6%;
      :last-child {
        border-right: none;
      }
    }
    .Very.High {
      background-color: ${riskLevelVeryHighColor};
      color: white;
    }
    .High {
      background-color: ${riskLevelHighColor};
      color: white;
    }
    .Medium {
      background-color: ${riskLevelMediumColor};
    }
    .Low {
      background-color: ${riskLevelLowColor};
    }
    .Very.Low {
      background-color: ${riskLevelVeryLowColor};
    }
    .owner {
      text-align: center;
    }
  }
  /* } */
`;
