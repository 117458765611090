import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { getWPCost, numberToCurrency } from "../../helpers";
import {
  getWorkPackageDays,
  getWorkPackageTitles,
} from "../../store/entities/tasks";
import { getWorkPackageLabourCost } from "../../store/entities/allocations";

export default function OutputWPSummary(props) {
  const { index, id } = props;
  const titles = useSelector(getWorkPackageTitles);
  const labourCosts = useSelector(getWorkPackageLabourCost);
  const additionalCosts = useSelector(getWPCost);
  const days = useSelector(getWorkPackageDays);

  function cost(packId) {
    const labour = labourCosts[packId];
    const additional = additionalCosts[packId].combined;
    const total = labour + additional;
    const formattedCost = numberToCurrency(total);
    return formattedCost;
  }

  return (
    <Container>
      <li>
      <span className="bold">WP{index + 1}&nbsp;</span>
      <span> - {titles[index]}.&nbsp;</span>
      <span className="bold"> Days:&nbsp;</span>
      <span> {days[index]}.&nbsp;</span>
      <span className="bold"> Cost:&nbsp;</span>
      <span> £{cost(id)}.</span>
      </li>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
`;
