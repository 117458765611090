import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { navBackground, settingsColor } from "../../helpers";
import add from "../../images/plusButtonPurple.png";

import ProjectRow from "./ProjectRow";
import { sendNewProject } from "../../helpers";
import Tippy from "@tippy.js/react";

export default function ProjectTable() {
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.entities.setup.data);

  function newProject() {
    sendNewProject(dispatch);
  }

  return (
    <Container>
      <div className="titles">
        <div className="indicatorSection"></div>
        <h3 className="description tab">Description</h3>
        <h3 className="partners">Partners</h3>
        <h3 className="flavour">Flavour</h3>
        <h3 className="optionsContainer">Options</h3>
        <h3 className="access">Access</h3>
      </div>
      <div className="projects">
        {projects.map((project, index) => {
          return <ProjectRow project={project} key={index} />;
        })}
      </div>
      <div className="titles bottomRow">
        <div className="indicatorSection"></div>
        <Tippy content="Add a new project">
          <div className="add tab">
            <img src={add} alt="add" onClick={newProject} />
          </div>
        </Tippy>
      </div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 40px;
  box-shadow: 8px 8px 12px rgba(1, 1, 1, 0.5);
  overflow: hidden;
  border-radius: 6px;
  color: ${navBackground};
  background-color: white;

  div {
    /* border: 1px solid red; */
  }

  .titles {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 40px;
  }
  .projects {
    width: 100%;
    flex-direction: column;
  }
  .bottomRow {
    height: 50px;
    .add {
      max-width: 30px;
      max-height: 30px;
      border-radius: 50%;
      cursor: pointer;
    }
  }
  .indicatorSection {
    min-width: 120px;
    max-width: 120px;
    height: 100%;
    background-color: ${settingsColor};
    /* margin-right: 25px; */
  }
  .tab {
    margin-left: 25px;
  }
  .color {
    width: 20px;
  }
  .description {
    width: 250px;
    padding-left: 5px;
  }
  .partners {
    display: flex;
    justify-content: center;
    /* border: 1px solid red; */
    width: 70px;
    margin-right: 15px;
  }
  .flavour {
    width: 85px;
    margin: 0 20px;
    /* border: 1px solid red; */
  }
  .optionsContainer {
    display: flex;
    justify-content: center;
    width: 70px;
    /* border: 1px solid red; */
  }
  .access {
    width: 150px;
    margin-left: 25px;
  }
`;
