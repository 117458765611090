import { store } from "../store";
import { createSelector } from "reselect";
import { getProjectTemplatesByType } from "../store/entities/project";

export const getUnusedRiskTemplates = createSelector(
  (state) => state.entities.risks.data,
  (state) => state.entities.project.data.templates,
  (state) => state.user.selectedRiskType,
  (risks, _, selectedRiskType) => {
    const state = store.getState();
    const templatesByType = getProjectTemplatesByType(state)[selectedRiskType];
    const usedTemplateIds = [];
    risks.forEach((risk) => {
      if (risk.riskId) usedTemplateIds.push(risk.riskId);
    });
    const unusedTemplates = templatesByType.filter(
      (risk) => !usedTemplateIds.includes(risk.riskId)
    );
    return unusedTemplates;
  }
);

export const getUnusedTaskTemplates = createSelector(
  (state) => state.entities.tasks.data,
  (state) => state.entities.project.data.tasks,
  (usedTasks, templates) => {
    const usedTemplateIds = [];
    usedTasks.forEach((task) => {
      if (task.taskId) usedTemplateIds.push(task.taskId);
    });

    const unusedTemplates = templates.filter(
      (task) => !usedTemplateIds.includes(task.taskId)
    );
    return unusedTemplates;
  }
);
