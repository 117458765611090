import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { generateArray, numberToCurrency } from "../../helpers";
import { getTeamDaysAndCosts } from "../../store/entities/allocations";
import {
  getOverheads,
  sendUpdatedDetails,
  updateLeaderInfo,
} from "../../store/entities/project";
import { Container } from "./costsStyling";
import dropArrow2 from "../../images/dropArrow2.png";
import Tippy from "@tippy.js/react";
import qMark from "../../images/qMark.png";

function OverheadInfo() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const leader = state.user.selectedLeader;
  const { overheadRate } = state.entities.project.data[leader];
  const { overheadRateMin, overheadRateMax, overheadRateInc } =
    state.entities.global.data;
  const formattedValue = numberToCurrency(useSelector(getOverheads)[leader]);

  console.log(useSelector(getOverheads))

  const rateOptions = generateArray(
    overheadRateMin,
    overheadRateMax,
    overheadRateInc
  );

  function onChangeHandler(e) {
    const key = e.target.name;
    const value = e.target.value;
    dispatch(
      updateLeaderInfo({
        leader,
        key,
        value,
      })
    );
    dispatch(sendUpdatedDetails());
  }

  return (
    <Container>
      <div className="overheads">
        <div className="row titles leaderTabMargin">
          <div className="title overheadWidth">
            <p className="">Overhead (%)</p>
            <Tippy content="Selecting an overhead rate of 0% or 20% means you do not have to provide supporting overhead evidence to the funder. See funder’s guidance for details">
              <div className="info">
                <img src={qMark} alt="add" />
              </div>
            </Tippy>
          </div>
          <p className="title labourCost">Cost</p>
          {/* <p className="title labourDays">Days</p> */}
        </div>
        <div className="rows">
          <div className="row">
            <select
              value={overheadRate}
              name="overheadRate"
              onChange={onChangeHandler}
              className="field overheadWidth"
            >
              {rateOptions.map((rate, index) => {
                return (
                  <option key={index} value={rate}>
                    {rate}
                  </option>
                );
              })}
            </select>
            <div className="dropdown overhead">
              <img src={dropArrow2} alt="option" />
            </div>
            <div className="total">
              <p className="field display labourCost">£{formattedValue}</p>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
export default OverheadInfo;
