// import axios from "axios";
import { store } from "../index";
import { createSlice } from "@reduxjs/toolkit";
// import { getTaskIds } from "./tasks";
import { getWorkingDaysPerMonth } from "./project";
import { getAllocationsByTaskId } from "./allocations";
import { createSelector } from "reselect";
import { recentFetch, reducerNotification } from "../../helpers";
import { apiCallBegan } from "../api";
import { getSelectedProject } from "../user";

const initialState = {
  loading: false,
  lastFetch: 0,
  data: [],
  lastSent: 0,
  lastEdit: 0,
  error: "",
};

const slice = createSlice({
  name: "team",
  initialState,
  reducers: {
    teamRequested: (team, _) => {
      team.loading = true;
    },
    teamRequestFailed: (team, action) => {
      team = initialState;
      reducerNotification("error", action.payload);
    },
    teamReceived: (team, action) => {
      team.data = action.payload;
      team.loading = false;
      team.lastFetch = Date.now();
    },

    sendingEmptyTeam: (team, action) => {
      team.loading = true;
    },

    addTeamMember: (team, action) => {
      const { newPerson, position } = action.payload;
      console.log(newPerson.employment);
      if (newPerson.employment === "subcontract") {
        team.data.push(newPerson);
      } else team.data.splice(position, 0, newPerson);
      team.lastEdit = Date.now();
    },
    deleteTeamMember: (team, action) => {
      const index = team.data.findIndex(
        (person) => person.personId === action.payload.personId
      );
      team.data.splice(index, 1);
      team.lastEdit = Date.now();
    },

    updateTeamMember: (team, action) => {
      const { key, value, personId } = action.payload;
      const index = team.data.findIndex(
        (person) => person.personId === personId
      );
      if (key === "name") {
        const matches = value.match(/\b(\w)/g) || [];
        const acronym = matches.join("").slice(0, 2).toUpperCase();
        team.data[index].name = value;
        team.data[index].acronym = acronym;
      } else if (key === "acronym") {
        const acronym = value.slice(-3);
        team.data[index].acronym = acronym.toUpperCase();
      } else team.data[index][key] = value;
      team.lastEdit = Date.now();
    },
    reorderTeam: (team, action) => {
      const originalIndex = team.data.findIndex(
        (person) => person.personId === action.payload.person.personId
      );
      const newIndex = originalIndex + action.payload.movement;
      const [person] = team.data.splice(originalIndex, 1);
      team.data.splice(newIndex, 0, person);
      team.lastEdit = Date.now();
    },

    apiSuccess: (team, action) => {
      team.loading = false;
      team.lastSent = Date.now();
      // reducerNotification("info", "Team saved");
    },
    apiFailed: (deadlines, _) => {
      deadlines.loading = false;
      reducerNotification("error", "Team failed");
    },
  },
});
export default slice.reducer;

export const {
  teamReceived,
  reorderTeam,
  updateTeamMember,
  addTeamMember,
  deleteTeamMember,
} = slice.actions;

const {
  teamRequested,
  teamRequestFailed,

  sendingEmptyTeam,

  apiSuccess,
  apiFailed,
} = slice.actions;

// Action Creators
const url = "/team";

export const loadTeamData = () => (dispatch, getState) => {
  const { lastFetch } = getState().entities.team;
  // console.log("fetching team data...");
  if (recentFetch(lastFetch)) return;

  dispatch(
    apiCallBegan({
      url: url + "/selected",
      method: "get",
      data: null,
      onStart: teamRequested.type,
      onSuccess: teamReceived.type,
      onError: teamRequestFailed.type,
    })
  );
};

export const initiateTeam = (projectId) =>
  apiCallBegan({
    url: url + "/new",
    method: "post",
    data: {
      projectId,
      data: [],
    },
    onStart: sendingEmptyTeam.type,
    onSuccess: apiSuccess.type,
    onError: apiFailed.type,
  });

export const cloneTeam = (projectId) => (dispatch, getState) => {
  const { data } = getState().entities.team;
  dispatch(
    apiCallBegan({
      url: url + "/new",
      method: "post",
      data: {
        projectId,
        data,
      },
      // onStart: sendingAllocation.type,
      onSuccess: apiSuccess.type,
      onError: apiFailed.type,
    })
  );
};

export const sendUpdatedTeam = () => (dispatch, getState) => {
  // console.log(dispatch, getState);
  const team = getState().entities.team;
  const { lastEdit, lastSent, data } = team;
  if (lastSent >= lastEdit) return;
  // console.log(data);
  dispatch(
    apiCallBegan({
      url: url + "/selected",
      method: "put",
      data,
      // onStart: sendingUpdatedTeam.type,
      onSuccess: apiSuccess.type,
      onError: apiFailed.type,
    })
  );
};

export const deleteProjectTeam = (projectId) =>
  apiCallBegan({
    url: url + "/selected",
    method: "delete",
    data: {
      projectId,
    },
    // onStart: sendingEmptyAssignments.type,
    onSuccess: apiSuccess.type,
    onError: apiFailed.type,
  });

// Selectors

export const getTeamIds = createSelector(
  (state) => state.entities.team,
  (team) => {
    // console.log("getTeamIds");
    const teamIds = [];
    team.data.forEach((person) => {
      teamIds.push(person.personId);
    });
    return teamIds;
  }
);

export const getPersonById = createSelector(
  (state) => state.entities.team,
  (team) => {
    // console.log("getPersonById");
    const teamIds = {};
    team.data.forEach((person) => {
      teamIds[person.personId] = person;
    });
    return teamIds;
  }
);

export const getDayRateById = createSelector(
  (state) => state.entities.team,
  (team) => {
    // console.log("getDayRateById");
    const rates = {};
    team.data.forEach((person) => {
      const { salary, leader, dayRate, personId } = person;
      const daysPerMonth = getWorkingDaysPerMonth(store.getState());
      let rate = 0;
      if (dayRate) rate = dayRate;
      else {
        rate = salary / (daysPerMonth[leader] * 12);
      }
      rates[personId] = rate;
    });
    return rates;
  }
);

export const getTeamByLeader = createSelector(
  (state) => state.entities.team.data,
  (team) => {
    const result = {
      lead: [],
      pOne: [],
      pTwo: [],
      subcontract: [],
    };
    team.forEach((person) => {
      if (person.employment === "subcontract") result.subcontract.push(person);
      else result[person.leader].push(person);
    });
    return result;
  }
);

export const getUtilisations = createSelector(
  (state) => state.entities,
  (entities) => {
    // console.log("getUtilisations");
    // const state = useSelector((state) => state);
    // const taskIds = getTaskIds(store.getState());
    const workingDays = getWorkingDaysPerMonth(store.getState());
    const personById = getPersonById(store.getState());
    const resources = getAllocationsByTaskId(store.getState());
    const allTasks = entities.tasks.data;
    const people = entities.team.data;
    const { projectLength } = entities.project.data.details;
    const utilisations = {};
    const utilisationByQuarter = { quarters: [] };
    const counter = {};
    const personIds = [];
    people.forEach((person) => {
      const id = person.personId;
      utilisations[id] = [];
      utilisationByQuarter[id] = {};
      utilisationByQuarter[id].overutilised = false;
      utilisationByQuarter[id].maxDays = workingDays[person.leader] * 3;
      utilisationByQuarter[id].actualDays = [];
      counter[id] = 0;
      personIds.push(id);
    });

    for (let i = 0; i < projectLength; i++) {
      let quarter = `Q${Math.ceil((i + 1) / 3)}`;
      let qEnd = (i + 1) % 3 === 0;

      for (let j = 0; j < allTasks.length; j++) {
        let lastTask = j === allTasks.length - 1;
        // let taskId = taskIds[j];
        for (let k = 0; k < personIds.length; k++) {
          let personId = personIds[k];
          // personIds.forEach((personId) => {
          const leader = personById[personId].leader;
          const percent = resources[allTasks[j].taskId][personId].percent;
          const days = allTasks[j].schedule[i].value;
          const contribution = (days * percent) / 100;
          const quarterTotal = counter[personId] + contribution;
          counter[personId] = quarterTotal;
          if ((qEnd && lastTask) || (i === projectLength - 1 && lastTask)) {
            utilisationByQuarter[personId].actualDays.push(counter[personId]);
            if (k === personIds.length - 1)
              utilisationByQuarter.quarters.push(quarter);
            if (quarterTotal > workingDays[leader] * 3) {
              utilisationByQuarter[personId].overutilised = true;
              utilisations[personId].push(quarter); // set threshold
            }
            counter[personId] = 0;
          }
        }
      }
    }
    return utilisationByQuarter;
  }
);

export const getAcronyms = createSelector(
  (state) => state.entities.team,
  (team) => {
    // console.log("getAcronyms");
    const acronyms = [];
    team.data.forEach((person) => {
      acronyms.push(person.acronym);
    });
    return acronyms;
  }
);

export const getPersonById2 = createSelector(
  (state) => state.entities.team,
  (team) => {
    // console.log("getPersonById");
    const teamIds = {};
    team.data.forEach((person) => {
      teamIds[person.personId] = person;
    });
    return teamIds;
  }
);

export const getOrderedTeam = createSelector(
  (state) => state.entities.team.data,
  (team) => {
    const list = { lead: [], pOne: [], pTwo: [] };
    team.forEach((person) => {
      list[person.leader].push(person)
    });
    return [...list.lead, ...list.pOne, ...list.pTwo];
  }
);

export const getTeamWarnings = createSelector(
  (state) => state.entities.team.data,
  (state) => state.entities.setup.data,
  (team, _) => {
    const { redSalary, redDayRate } = getSelectedProject(store.getState());
    let check = {
      dayRate: {
        flagged: false,
        message: "Day-rate exceeds limit",
        key: "selectedTeamOption",
        value: "subcontract",
      },
      salary: {
        flagged: false,
        key: "selectedTeamOption",
        value: "staff",
        message: "Salary exceeds limit",
      },
      location: {
        flagged: false,
        key: "selectedTeamOption",
        value: "subcontract",
        message: "Subcontractor outside UK",
      },
    };
    const warnings = [];
    team.forEach((person) => {
      if (person.dayRate > redDayRate && !check.dayRate.flagged) {
        check.dayRate.flagged = true;
        warnings.push(check.dayRate);
      }
      if (person.salary > redSalary && !check.salary.flagged) {
        check.salary.flagged = true;
        warnings.push(check.salary);
      }
      if (person.location === "Other" && !check.location.flagged) {
        check.location.flagged = true;
        warnings.push(check.location);
      }
    });
    // console.log(warnings);
    return warnings;
  }
);
