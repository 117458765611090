import React from "react";
import { useSelector } from "react-redux";
import { getWorkPackageTitles } from "../../store/entities/tasks";
import styled from "styled-components";
import { fontColorGrey, rev2 } from "../../helpers";
import Tippy from "@tippy.js/react";
export default function (props) {
  const titles = useSelector(getWorkPackageTitles);
  const { heading } = props;

  return (
    <Data>
      <div className="title">
        <h3>{heading}</h3>
      </div>
      {titles.map((title, index) => {
        return rev2 ? (
          <Tippy key={index} content={`WP${index + 1} - ${title}`}>
            <div className="titleBox">
              <p className="wpData">
                {title || "Please enter a work package title"}
              </p>
            </div>
          </Tippy>
        ) : (
          <div key={index} className="titleBox">
            <p className="wpData">
              {title || "Please enter a work package title"}
            </p>
          </div>
        );
      })}
    </Data>
  );
}

const Data = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-left: 30px;
  margin-right: 20px;
  .titleBox {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* border: 1px solid red; */
  }
  .wpData {
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 40px;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    font-weight: bold;
    text-align: left;
    color: ${fontColorGrey};
  }
`;
