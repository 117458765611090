import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import EditTaskTemplateRow from "./EditTaskTemplateRow";
import { settingsColor, rev2, handleSendGlobal } from "../../helpers";
import {
  // runRiskCalcs,
  sortTaskTemplates,
  addTaskTemplate,
} from "../../store/entities/global";
import add from "../../images/plusButtonPurple.png";
import FilterCategory from "./FilterCategory";

export default function EditTaskTemplates() {
  const dispatch = useDispatch();
  let tasks = useSelector((state) => state.entities.global.data.tasks.taskData);
  const { selectedRiskType, selectedCategory } = useSelector(
    (state) => state.user
  );

  if (selectedCategory)
    tasks = tasks.filter((template) => template.category === selectedCategory);

  function handleNew() {
    dispatch(addTaskTemplate());
  }

  function sort() {
    dispatch(sortTaskTemplates());
    handleSendGlobal();
  }

  return (
    <Container>
      <div className="editWindow">
        {rev2 ? <FilterCategory /> : null}
        <div className="titles">
          <div className="textarea title taskTitle">
            <p>Task Title</p>
          </div>
          <p className="type title">Task Type</p>
          <p className="cat title end">Cat</p>
        </div>
        <div className="templatesList" dir="ltr">
          {tasks.map((template, index) => {
            return (
              <EditTaskTemplateRow
                key={index}
                template={template}
                selectedRiskType={selectedRiskType}
              />
            );
          })}
        </div>
        <div className="buttonRow">
          <button className="addIcon" onClick={handleNew}>
            <img src={add} alt="add" />
          </button>
          {/* <button className="sort" onClick={runCalcs}>
          run risk calcs
        </button> */}
          <button className="sort" onClick={sort}>
            Sort Templates
          </button>
        </div>
      </div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  z-index: 2;
  position: relative;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  /* } */
  .titles {
    display: flex;
    /* padding: 3px; */
    margin: 3px 30px;
  }
  .title {
    border: none;
    font-weight: bold;
    margin-right: 4px;
    padding: 4px;
    display: flex;
    justify-content: flex-start;
    font-size: 14px;
  }
  .templatesList {
    max-height: calc(100vh - 320px);
    overflow: scroll;
    scroll-snap-type: y mandatory;
  }
  .end {
    margin-right: 0;
  }
  .taskTitle {
    flex-basis: 0;
    flex-grow: 1;
  }
  .type {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    width: 140px;
  }
  .cat {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 40px;
    :focus {
      border: 1px solid ${settingsColor};
    }
  }
  .addIcon {
    max-width: 25px;
    max-height: 25px;
    padding: 0;
    border: none;
  }
  .buttonRow {
    margin: 5px 30px;
    display: flex;
    align-items: center;
    /* border: 1px solid red; */
    .sort {
      margin-left: 10px;
      border: none;
      background-color: ${settingsColor};
      color: white;
      font-weight: bold;
      padding: 5px 10px;
    }
  }
`;
