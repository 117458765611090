import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { setShowComponent, updateUserSelection } from "../../store/user";
import { emailVariables, fontColorGrey, menuPurpleColor } from "../../helpers";
import TableTabs from "../table/TableTabs";
import EmailAccounts from "./EmailAccounts";
import OutputVariables from "../output/OutputVariables";
import EmailProject from "./EmailProject";

export default function EmailTable() {
  const dispatch = useDispatch();
  const { selectedEmail, showComponent, name, email } = useSelector(
    (state) => state.user
  );
  const { productPlatformName } = useSelector(
    (state) => state.entities.project.data.details
  );
  const { gantt, details, team, costs, revenue, setup, risks } = useSelector(
    (state) => state.entities.project.data.status
  );

  const edit = showComponent === "editEmails";

  const mapObj = {
    "{fullName}": name,
    "{firstName}": name.split(" ")[0],
    "{password}": "*generated secretly*",
    "{userEmail}": email,
    "{productPlatformName}": productPlatformName,
    "{section}": "*provided section*",
    "{status}": "*status*",
    "{changedBy}": "*logged in user*",
    "{gantt}": gantt ? "Complete" : "In progress",
    "{details}": details ? "Complete" : "In progress",
    "{team}": team ? "Complete" : "In progress",
    "{costs}": costs ? "Complete" : "In progress",
    "{revenue}": revenue ? "Complete" : "In progress",
    "{setup}": setup ? "Complete" : "In progress",
    "{risks}": risks ? "Complete" : "In progress",
  };

  const tabs = ["Accounts", "Project"];
  const data = {
    tabs,
    selectedTab: selectedEmail,
    select: (selected) => {
      dispatch(
        updateUserSelection({
          key: "selectedEmail",
          value: selected,
        })
      );
    },
  };

  const contents = {
    accounts: <EmailAccounts edit={edit} mapObj={mapObj} />,
    project: <EmailProject edit={edit} mapObj={mapObj} />,
  };

  function showEdit() {
    const value = edit ? "" : "editEmails";
    dispatch(setShowComponent({ value }));
  }

  return (
    <Container>
      <TableTabs data={data} />
      <div className="table">
        <div className="contents">{contents[selectedEmail]}</div>
        <button className="edit" onClick={showEdit}>
          {edit ? "Done" : "Edit"}
        </button>
      </div>
      {edit ? (
        <OutputVariables variables={emailVariables} color={menuPurpleColor} />
      ) : null}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  color: ${fontColorGrey};
  .table {
    padding: 20px 30px;
  }
  .contents {
    max-height: calc(100vh - 275px);
    overflow: scroll;
  }

  h3 {
    margin-top: 10px;
    margin-bottom: 5px;
  }
  p,
  span {
    height: 20px;
  }
  li {
    margin-left: 20px;
    list-style-type: disc;
  }
  .bold {
    font-weight: bold;
  }
  textarea.textarea,
  input.textarea {
    width: 100%;
    resize: vertical;
    height: 120px;
    min-height: 30px;
    border-color: lightgray;
    margin-top: 5px;
    margin-bottom: 10px;
    /* color: ${(props) => props.color}; */
    &:focus {
      border-color: ${menuPurpleColor};
    }
  }

  input.textarea {
    height: 30px;
  }

  button.edit {
    position: absolute;
    right: 20px;
    top: 20px;
    background-color: ${menuPurpleColor};
    color: white;
    font-weight: bold;
    border: none;
    width: 80px;
    height: 25px;
    padding: 0;
  }
`;
