import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  handleSendGlobal,
  riskLevelHighColor,
  riskLevelLowColor,
  riskLevelMediumColor,
  riskLevelVeryHighColor,
  riskLevelVeryLowColor,
  settingsColor,
} from "../../helpers";
import bin from "../../images/bin-grey.png";
import {
  updateTaskTemplate,
  deleteTaskTemplate,
} from "../../store/entities/global";

export default function EditTaskTemplateRow(props) {
  const dispatch = useDispatch();
  const { categories } = useSelector((state) => state.entities.global.data);
  const { taskTypes } = useSelector((state) => state.entities.global.data);
  const { template } = props;
  const { taskTitle, category, taskType, taskId } = template;

  function handleDelete() {
    dispatch(deleteTaskTemplate({ taskId }));
    handleSendGlobal();
  }

  function handleChange(e) {
    const { name, value } = e.target;
    dispatch(updateTaskTemplate({ taskId, key: name, value }));
  }

  return (
    <Container>
      <input
        // id={taskId}
        type="text"
        onChange={handleChange}
        onBlur={handleSendGlobal}
        className="field"
        name="taskTitle"
        value={taskTitle}
      />
      <select
        name="taskType"
        // id="riskId"
        className="field type"
        value={taskType}
        onBlur={handleSendGlobal}
        onChange={handleChange}
      >
        <option hidden>...</option>
        {taskTypes.map((category, index) => {
          return (
            <option key={index} value={category}>
              {category}
            </option>
          );
        })}
      </select>
      <select
        name="category"
        // id="riskId"
        className="field cat end"
        value={category}
        onBlur={handleSendGlobal}
        onChange={handleChange}
      >
        <option hidden>...</option>
        {categories.map((category, index) => {
          return (
            <option key={index} value={category}>
              {category}
            </option>
          );
        })}
      </select>
      <div className="bin hidden">
        <img src={bin} alt="bin" onClick={handleDelete} />
      </div>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  scroll-snap-align: start;
  display: flex;
  margin: 5px 30px;
  &:hover .hidden {
    opacity: 1;
  }
  .field {
    margin-right: 4px;
    padding: 8px 4px;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    display: flex;
    justify-content: flex-start;
    font-size: 14px;
  }
  .cat {
    text-align: center;
    width: 40px;
    :focus {
      border: 1px solid ${settingsColor};
    }
  }
  .type {
    :focus {
      border: 1px solid ${settingsColor};
    }
  }
  .end {
    margin-right: 0;
  }
  input {
    flex-basis: 0;
    flex-grow: 1;
    resize: none;
    &:focus {
      border: 1px solid ${settingsColor};
    }
  }
  .miti {
    flex-grow: 2;
  }
  .toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 55px;
    font-size: 12px;
  }
  .bin {
    margin: auto;
    position: absolute;
    right: -24px;
    top: 0px;
    bottom: 0px;
    max-width: 20px;
    max-height: 20px;
    transition: opacity 0.3s;
    cursor: pointer;
  }
  .addIcon {
    margin: -5px 30px 15px 30px;
    max-width: 25px;
    max-height: 25px;
    padding: 0;
    border: none;
  }
  .hidden {
    opacity: 0;
  }
  .selected {
    background-color: ${settingsColor};
    color: white;
    font-weight: bold;
  }
  p {
    background-color: white;
    border-radius: 5px;
  }
  .Very.High {
    background-color: ${riskLevelVeryHighColor};
    color: white;
  }
  .High {
    background-color: ${riskLevelHighColor};
    color: white;
  }
  .Medium {
    background-color: ${riskLevelMediumColor};
  }
  .Low {
    background-color: ${riskLevelLowColor};
  }
  .Very.Low {
    background-color: ${riskLevelVeryLowColor};
  }
  .buttonRow {
    display: flex;
    align-items: center;
    border: 1px solid red;
  }
`;
