import React from "react";
import { useSelector } from "react-redux";
import { Container } from "./optionsStyling";
// import ListOption from "./optionsList";
import OptionsInput from "./optionsInput";
import OptionsToggle from "./optionsToggle";
import EditRiskTemplates from "../admin/EditRiskTemplates";
import { getSelectedProject } from "../../store/user";
import { rev2 } from "../../helpers";

function RiskOptions() {
  const { showComponent } = useSelector((state) => state.user);
  const {
    projectDesc,

    useManagerial,
    useCommercial,
    useLegal,
    useTechnical,
    useEnvironmental,

    maxEnvRisks,
    maxLegRisks,
    maxComRisks,
    maxTechRisks,
    maxManRisks,

    outputEnvRisks,
    outputLegRisks,
    outputComRisks,
    outputTechRisks,
    outputManRisks,

    maxDescription,
    maxMitigation,
  } = useSelector(getSelectedProject);

  return (
    <Container>
      {showComponent === "editTemplates" ? <EditRiskTemplates /> : null}
      <div className="rows adminContent">
        <div className="row titles leaderTabMargin">
          <h2 className="title project">{projectDesc}</h2>
        </div>

        <div className="row titles leaderTabMargin">
          <h3 className="title description">Risks</h3>
          <h3 className="title value center">Show</h3>
          <h3 className="title value">Max Risks</h3>
          {rev2 ? <h3 className="title value">Output Risks</h3> : null}
        </div>

        <div className="row">
          <p className="field display description">Managerial</p>
          <OptionsToggle
            multiple={true}
            field="useManagerial"
            value={useManagerial}
            classNames={useManagerial ? "highlight" : null}
          />
          {useManagerial ? (
            <div>
              <OptionsInput
                multiple={true}
                field="maxManRisks"
                value={maxManRisks}
              />
              {rev2 ? (
                <OptionsInput
                  multiple={true}
                  field="outputManRisks"
                  value={outputManRisks}
                />
              ) : null}
            </div>
          ) : null}
        </div>

        <div className="row">
          <p className="field display description">Commercial</p>
          <OptionsToggle
            multiple={true}
            field="useCommercial"
            value={useCommercial}
            classNames={useCommercial ? "highlight" : null}
          />
          {useCommercial ? (
            <div>
              <OptionsInput
                multiple={true}
                field="maxComRisks"
                value={maxComRisks}
              />
              {rev2 ? (
                <OptionsInput
                  multiple={true}
                  field="outputComRisks"
                  value={outputComRisks}
                />
              ) : null}
            </div>
          ) : null}
        </div>

        <div className="row">
          <p className="field display description">Technical</p>
          <OptionsToggle
            multiple={true}
            field="useTechnical"
            value={useTechnical}
            classNames={useTechnical ? "highlight" : null}
          />
          {useTechnical ? (
            <div>
              <OptionsInput
                multiple={true}
                field="maxTechRisks"
                value={maxTechRisks}
              />
              {rev2 ? (
                <OptionsInput
                  multiple={true}
                  field="outputTechRisks"
                  value={outputTechRisks}
                />
              ) : null}
            </div>
          ) : null}
        </div>

        <div className="row">
          <p className="field display description">Legal</p>
          <OptionsToggle
            multiple={true}
            field="useLegal"
            value={useLegal}
            classNames={useLegal ? "highlight" : null}
          />
          {useLegal ? (
            <div>
              <OptionsInput
                multiple={true}
                field="maxLegRisks"
                value={maxLegRisks}
              />
              {rev2 ? (
                <OptionsInput
                  multiple={true}
                  field="outputLegRisks"
                  value={outputLegRisks}
                />
              ) : null}
            </div>
          ) : null}
        </div>

        <div className="row">
          <p className="field display description">Environmental</p>
          <OptionsToggle
            multiple={true}
            field="useEnvironmental"
            value={useEnvironmental}
            classNames={useEnvironmental ? "highlight" : null}
          />
          {useEnvironmental ? (
            <div>
              <OptionsInput
                multiple={true}
                field="maxEnvRisks"
                value={maxEnvRisks}
              />
              {rev2 ? (
                <OptionsInput
                  multiple={true}
                  field="outputEnvRisks"
                  value={outputEnvRisks}
                />
              ) : null}
            </div>
          ) : null}
        </div>

        <OptionsInput
          title="Max Description Length (chars)"
          field="maxDescription"
          value={maxDescription}
          characters={3}
        />

        <OptionsInput
          title="Max Mitigation Length (chars)"
          field="maxMitigation"
          value={maxMitigation}
          characters={3}
        />

        {/* <OptionsToggle
        global={true}
        title="Edit Templates"
        field={"editTemplates"}
        // value={useTemplates}
      /> */}
      </div>
    </Container>
  );
}
export default RiskOptions;
