import styled from "styled-components";
import {
  costsColor,
  fontColorGrey,
  menuBlueColor,
  menuBlueColorFade,
  menuBlueHighlightColor,
  okColor,
  tableContentSideMargin,
  warningColor,
  warningColorBack,
} from "../../helpers";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  .total {
    p {
      font-size: 18px;
      color: ${costsColor};
    }
  }
  .highlight {
    background-color: #e1e1e1;
    font-weight: bold;
    margin-left: 0;
    padding-left: ${tableContentSideMargin};
  }
  .small {
    display: flex;
    justify-content: flex-end;
    width: 90px;
    text-align: right;
    text-align-last: right;
    overflow: visible;
  }
  .depreciation {
    width: 105px;
  }
  .symbol {
    position: relative;
    display: flex;
    width: 90px;
    margin-right: 20px;
    /* padding-right: 10px; */
    .percent {
      right: 5px;
      top: 5px;
      position: absolute;
    }
  }
  .pound {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-width: 90px;
    max-width: 90px;
    margin-left: 0;
    margin-right: 20px;
    /* border: 1px solid red; */
    input {
      width: 80px;
      text-align: right;
      margin: 0;
    }
    p {
      position: absolute;
      color: ${fontColorGrey};
      font-size: 16px;
      padding: 0;
      left: 11px;
    }
  }
  .image {
    /* position: absolute; */
    /* right: -15px; */
    margin-left: 3px;
    min-width: 15px;
    width: 15px;
    height: 15px;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  .overhead {
    left: 236px;
    top: 12px;
  }
  .left {
    justify-content: flex-start;
    text-align: left;
    text-align-last: left;
  }
  .large {
    width: 200px;
  }
  .labourNameRole {
    width: 400px;
  }
  .overheadWidth {
    width: 250px
  }
  .labourCost {
    width: 80px;
    text-align: right;
    justify-content: flex-end;
  }
  .labourDays {
    width: 70px;
    text-align: right;
    justify-content: flex-end;
  }
  .labourOverutilised {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* width: 150px; */
    font-weight: 600;
    color: ${warningColor};
    margin: 0 5px;
  }
  .notes {
    margin-left: 15px;
    display: flex;
  }
  .note {
    margin-right: 5px;
    font-size: 14px;
    color: ${fontColorGrey};
  }
  .warningButton {
    border: 1px solid ${warningColor};
    margin-left: 20px;
    display: flex;
    padding: 2px 15px;
    background-color: ${warningColorBack};
  }
  .warningIcon {
    display: flex;
    min-height: 29px;
    min-width: 29px;
    max-height: 29px;
    max-width: 29px;
    border-radius: 6px;
    padding: 5px;
  }
  .deleteIcon {
    /* position: absolute; */
    /* right: -10px; */
    width: 18px;
    padding-top: 4px;
    /* margin-left: 15px; */
  }
  .addIcon {
    height: 35px;
    width: 35px;
    position: absolute;
    transition: transform 0.3s;
    :hover {
      /* text-decoration: underline; */
      transform: scale(1.2);
    }
  }
  button {
    color: white;
    border: none;
    padding: 0;
  }

  .materialsDescription {
    width: 240px;
  }
  .materialsCost,
  .materialsQuantity,
  .materialsTotal {
    width: 90px;
    text-align: right;
    justify-content: flex-end;
  }
  .breakdownTable {
    width: 100%;
    .column.center {
      display: flex;
      justify-content: center;
    }
    .total {
      font-weight: bold;
      height: 45px;
    }
    .column {
      /* flex-grow: 1; */
      /* flex-basis: 0; */
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      /* margin-right: 20px; */
      /* padding-right: 35px; */
      max-width: 140px;
      margin: 0;
      height: 45px;
    }
    .border {
      border-right: 1px solid #c1c1c1;
    }
    .row {
      margin-top: 0;
      margin-bottom: 0;
    }
    .percent {
      font-size: 12px;
      font-weight: bold;
      /* background-color: lightgray; */
      color: white;
      padding: 2px;
      border-radius: 8px;
      width: 65px;
      margin-right: 10px;
      text-align: center;
    }
    .double {
      text-align: right;
      margin-right: 10px;
    }
    .category {
      font-weight: bold;
      /* flex-basis: 20px; */
    }
    .value {
      width: 150px;
    }
    .grey {
      background-color: rgb(200, 200, 200);
    }
    .ok {
      background-color: ${okColor};
    }
    .warn {
      background-color: orange;
    }
    .over {
      background-color: ${warningColor};
    }
    .empty {
      background-color: transparent;
    }
  }
  .newAssignmentTable {
    display: flex;
    align-items: flex-start;
    margin-top: 10px;
    .title {
      /* border: 1px solid red; */
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      /* margin-top: 10px; */
    }
    .totalCost {
      margin: 0;
      width: 100%;
      justify-content: flex-end;
      /* border: 1px solid red */
    }
  }
  .bottomMiddle {
    /* position: absolute; */
    display: flex;
    justify-content: center;
    /* bottom: -60px; */
    /* margin-left: auto;
    margin-right: auto; */
    /* left: 0;
    right: 0; */
    text-align: center;
    margin-top: 20px;
    button {
      text-align: left;
      border: none;
      font-weight: bold;
      color: ${fontColorGrey};
      padding: 10px 40px;
      /* color: ${menuBlueHighlightColor}; */
      color: ${menuBlueColor};
      background-color: ${menuBlueColorFade};
      width: 95%;
    }
  }
`;
