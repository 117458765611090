import { store } from "../store";
import { sendUpdatedAllocations } from "../store/entities/allocations";
import { sendUpdatedAssignments } from "../store/entities/assignments";
import { sendUpdatedCapex } from "../store/entities/capex";
import { sendUpdatedDeadlines } from "../store/entities/deadlines";
import { sendUpdatedGlobalData } from "../store/entities/global";
import { sendUpdatedMaterials } from "../store/entities/materials";
import { sendUpdatedOther } from "../store/entities/other";
import { sendUpdatedDetails } from "../store/entities/project";
import { sendUpdatedRevenue } from "../store/entities/revenue";
import { sendUpdatedRisks } from "../store/entities/risks";
import { sendUpdatedSetup } from "../store/entities/setup";
import { sendUpdatedTasks } from "../store/entities/tasks";
import { sendUpdatedTeam } from "../store/entities/team";
import { sendUpdatedTravel } from "../store/entities/travel";
import { deBounce } from "./generalHelpers";

function sendSetup() {
  store.dispatch(sendUpdatedSetup());
}
export function handleSendSetup() {
  deBounce(sendSetup);
}

function sendProject() {
  console.log('sending');
  store.dispatch(sendUpdatedDetails());
}
export function handleSendProject() {
  deBounce(sendProject);
}

function sendRisks() {
  store.dispatch(sendUpdatedRisks());
}
export function handleSendRisks() {
  deBounce(sendRisks);
}

function sendTasks() {
  store.dispatch(sendUpdatedTasks());
}
export function handleSendTasks() {
  deBounce(sendTasks);
}

function sendDeadlines() {
  store.dispatch(sendUpdatedDeadlines());
}
export function handleSendDeadlines() {
  deBounce(sendDeadlines);
}

function sendTeam() {
  store.dispatch(sendUpdatedTeam());
}
export function handleSendTeam() {
  deBounce(sendTeam);
}

function sendAllocations() {
  store.dispatch(sendUpdatedAllocations());
}
export function handleSendAllocations() {
  deBounce(sendAllocations);
}

function sendMaterials() {
  store.dispatch(sendUpdatedMaterials());
}
export function handleSendMaterials() {
  deBounce(sendMaterials);
}

function sendTravel() {
  store.dispatch(sendUpdatedTravel());
}
export function handleSendTravel() {
  deBounce(sendTravel);
}

function sendCapex() {
  store.dispatch(sendUpdatedCapex());
}
export function handleSendCapex() {
  deBounce(sendCapex);
}

function sendOther() {
  store.dispatch(sendUpdatedOther());
}
export function handleSendOther() {
  deBounce(sendOther);
}

function sendAssignments() {
  store.dispatch(sendUpdatedAssignments());
}
export function handleSendAssignments() {
  deBounce(sendAssignments);
}

function sendRevenue() {
  store.dispatch(sendUpdatedRevenue());
}
export function handleSendRevenue() {
  deBounce(sendRevenue);
}

function sendGlobal() {
  store.dispatch(sendUpdatedGlobalData());
}
export function handleSendGlobal() {
  deBounce(sendGlobal);
}
