// import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import { generateRandomId, recentFetch, reducerNotification } from "../../helpers";
import { apiCallBegan } from "../api";
import { createSelector } from "reselect";

const initialState = {
  loading: false,
  lastFetch: 0,
  data: [],
  error: "",
};

const slice = createSlice({
  name: "deadlines",
  initialState,
  reducers: {
    deadlineDataRequested: (deadlines, _) => {
      deadlines.loading = true;
    },
    deadlineDataRequestFailed: (deadlines, action) => {
      deadlines = initialState;
      reducerNotification("error", action.payload);
    },
    deadlineDataReceived: (deadlines, action) => {
      deadlines.data = action.payload;
      deadlines.loading = false;
      deadlines.lastFetch = Date.now();
    },

    sendingDeadlines: (deadlines, _) => {
      deadlines.loading = true;
    },

    addDeadline: (deadlines, action) => {
      const { type, newPosition } = action.payload;
      const newDeadline = {
        deadlineId: generateRandomId('deadline'),
        type,
        description: "Description...",
        scheduled: 0,
      };
      deadlines.data.splice(newPosition, 0, newDeadline);
    },
    deleteDeadline: (deadlines, action) => {
      const { deadlineId } = action.payload;
      const index = deadlines.data.findIndex(
        (deadline) => deadline.deadlineId === deadlineId
      );
      deadlines.data.splice(index, 1);
    },
    updateDeadline: (deadlines, action) => {
      console.log(action.payload);
      const { deadlineId, key, value } = action.payload;
      const index = deadlines.data.findIndex(
        (deadline) => deadline.deadlineId === deadlineId
      );
      deadlines.data[index][key] = value;
    },
    reorderDeadline: (deadlines, action) => {
      const { deadlineId, movement } = action.payload;
      const originalIndex = deadlines.data.findIndex(
        (deadline) => deadline.deadlineId === deadlineId
      );
      const newIndex = originalIndex + movement;
      const [deadline] = deadlines.data.splice(originalIndex, 1);
      deadlines.data.splice(newIndex, 0, deadline);
    },
    resetDeadlineDate: (deadlines, _) => {
      deadlines.data.forEach((deadline) => (deadline.scheduled = 0));
    },

    apiSuccess: (deadlines, action) => {
      deadlines.loading = false;
      // reducerNotification("info", "Deadline saved");
    },
    apiFailed: (deadlines, _) => {
      deadlines.loading = false;
      reducerNotification("error", "Deadlines failed");
    },
  },
});
export default slice.reducer;

export const {
  deadlineDataReceived,
  reorderDeadline,
  addDeadline,
  deleteDeadline,
  updateDeadline,
  resetDeadlineDate,
} = slice.actions;

const {
  deadlineDataRequested,
  deadlineDataRequestFailed,

  sendingDeadlines,

  apiSuccess,
  apiFailed,
} = slice.actions;

// Action Creators

const url = "/deadlines";

export const loadDeadlineData = () => (dispatch, getState) => {
  const { lastFetch } = getState().entities.deadlines;
  // console.log("fetching deadline data...");
  if (recentFetch(lastFetch)) return;

  dispatch(
    apiCallBegan({
      url: url + "/selected",
      method: "get",
      data: null,
      onStart: deadlineDataRequested.type,
      onSuccess: deadlineDataReceived.type,
      onError: deadlineDataRequestFailed.type,
    })
  );
};

export const sendUpdatedDeadlines = () => (dispatch, getState) => {
  const deadlines = getState().entities.deadlines.data;
  dispatch(
    apiCallBegan({
      url: url + "/selected",
      method: "put",
      data: deadlines,
      // onStart: sendingDeadlines.type,
      onSuccess: apiSuccess.type,
      onError: apiFailed.type,
    })
  );
};

export const initiateDeadline = (projectId) =>
  apiCallBegan({
    url: url + "/new",
    method: "post",
    data: {
      projectId,
      data: [],
    },
    onStart: sendingDeadlines.type,
    onSuccess: apiSuccess.type,
    onError: apiFailed.type,
  });

  export const cloneDeadlines = (projectId) => (dispatch, getState) => {
    const { data } = getState().entities.deadlines;
    dispatch(
      apiCallBegan({
        url: url + "/new",
        method: "post",
        data: {
          projectId,
          data,
        },
        // onStart: sendingAllocation.type,
        onSuccess: apiSuccess.type,
        onError: apiFailed.type,
      })
    );
  };

export const deleteProjectDeadlines = (projectId) =>
  apiCallBegan({
    url: url + "/selected",
    method: "delete",
    data: {
      projectId,
    },
    // onStart: sendingEmptyAssignments.type,
    onSuccess: apiSuccess.type,
    onError: apiFailed.type,
  });

// Selectors

export const getDeliverables = createSelector(
  (state) => state.entities.deadlines.data,
  (deadlines) => {
    // console.log("getDeliverables");
    return deadlines.filter((task) => task.type === "deliverable");
  }
);

export const getMilestones = createSelector(
  (state) => state.entities.deadlines.data,
  (deadlines) => {
    // console.log("getMilestones");
    return deadlines.filter((task) => task.type === "milestone");
  }
);
