import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import Close from "../general/close";
import {
  getUnusedRiskTemplates,
  handleSendRisks,
} from "../../helpers";
import { setShowComponent } from "../../store/user";
import TemplateModalRow from "./templateModalRow";
import { getMaxRisks } from "../../store/entities/setup";
import { addRisk } from "../../store/entities/risks";

function TemplateModal(props) {
  const dispatch = useDispatch();
  const { riskCount } = props;
  const groupedTemplates = useSelector(getUnusedRiskTemplates);
  const { selectedRiskType } = useSelector((state) => state.user);

  function closeModal() {
    handleSendRisks();
    dispatch(setShowComponent({ value: "" }));
  }

  const maxRisks = useSelector(getMaxRisks)[selectedRiskType];
  console.log(riskCount, maxRisks);
  function handleAddBlankRisk() {
    dispatch(addRisk({ riskType: selectedRiskType }));
    if (riskCount >= maxRisks - 1) {
      closeModal();
    }
  }

  const data = {
    message: "",
    providedFunction: closeModal,
  };

  return (
    <Container id="background">
      <div className="editWindow">
        <Close data={data} />
        <div className="instructions">
          <h3>Add a risk from the list below or</h3>
          <button onClick={handleAddBlankRisk}>add a blank row</button>
        </div>
        <div className="titles">
          <div className="text title description">
            <p>Description</p>
          </div>
          <div className="text title mitigation">
            <p>Mitigation</p>
          </div>
          <p className="toggle add"></p>
        </div>
        <div className="templates">
          {groupedTemplates.map((template, index) => {
            return (
              <TemplateModalRow
                key={index}
                template={template}
                close={closeModal}
                riskCount={riskCount}
                maxRisks={maxRisks}
              />
            );
          })}
        </div>
      </div>
    </Container>
  );
}

export default TemplateModal;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: flex-start;

  background-color: rgba(20, 20, 20, 0.6);
  z-index: 100;

  .editWindow {
    position: relative;
    /* min-height: 200px; */
    width: 800px;
    display: flex;
    flex-direction: column;
    margin-top: 160px;
    /* justify-content: space-between; */
    background-color: white;
    border: 1px solid black;
    border-radius: 8px;
    padding: 10px 5px 20px 30px;
  }
  .titles {
    display: flex;
    padding: 3px;
    margin-bottom: 5px;
  }
  .title {
    border: none;
    font-weight: bold;
    margin-right: 4px;
    padding: 4px;
  }
  .instructions {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    color: black;
    width: 100%;
    padding: 10px 6px;
    button {
      display: flex;
      align-items: center;
      padding: 8px;
      background-color: rgba(139, 197, 63, 1);
      border: none;
      color: white;
      margin-left: 5px;
      height: 30px;
      transition: transform 0.3s;
      :hover {
        transform: scale(1.04);
      }
    }
  }
  .templates {
    max-height: 50vh;
    overflow-y: scroll;
  }
  .template {
    width: 100%;
    position: relative;
    display: flex;
    margin: 3px 0;
    &:hover .hidden {
      opacity: 1;
    }
  }
  .field {
    margin-right: 4px;
    padding: 8px 4px;
    border: 1px solid #e1e1e1;
    border: 1px solid #e1e1e1;
    background-color: rgb(240, 240, 240);
  }
  .text {
    flex-basis: 0;
    margin-right: 4px;
  }
  .description {
    flex-grow: 1;
  }
  .mitigation {
    flex-grow: 2;
  }
  .toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90px;
  }
  .add {
    /* position: absolute; */
    /* display: flex; */
    /* align-items: center; */
    /* top: 5px; */
    /* height: 100%; */
    /* border: 1px solid red; */
    margin: auto;
    right: -26px;
    max-width: 25px;
    img {
      cursor: pointer;
    }
  }
  .hidden {
    opacity: 0;
    transition: opacity 0.3s;
  }
  p {
    background-color: white;
    border-radius: 5px;
  }
`;
