export const appWidth = "1400px";
export const navHeight = "90px";
export const appTop = "90px";
export const refHeight = "40px";
export const appTopIfAdmin = "120px"; // appTop + refHeight
export const toastDelay = 2000;
export const lastFetchDuration = 10;

// export const navBackground = "rgba(12, 58, 93, 1)";
export const navBackground = "rgba(12, 58, 93, 1)";
export const navHighlight = "rgba(30, 91, 127, 1)";
export const okColor = "#98c6ad";
export const warningColor = "rgba(240,90,40)";
export const warningColorBack = "rgba(240,90,40, 0.1)";
export const fontColorGrey = "#606060";
export const selectedYes = "rgba(139, 197, 63, 1)";
export const selectedNo = "#b1b1b1";
export const confirmYes = "rgba(138,196,64)";
export const confirmNo = "rgba(234,73,85)";
export const questionColor = "rgba(145,145,145)";
export const wizardBlue = "rgb(54, 139, 197)";
export const wizardBlueLight = "rgba(54, 139, 197, 0.80)";

// export const adminColor = "rgba(162,84,198)";
// export const adminFontColor = "rgba(110,57,135)";
export const menuBlueColor = "rgba(53,120,181)";
export const menuBlueColorFade = "rgba(53,120,181, 0.15)";
export const menuBlueHighlightColor = "rgba(8,72,114)";
export const menuPurpleColor = "rgba(162,94,198)";
export const menuPurpleHighlightColor = "rgba(97,53,122)";

export const projectColor = menuBlueColor; // "rgba(38,169,224)";
export const projectFontColor = menuBlueHighlightColor; // "rgba(27,117,187)";
export const teamColor = menuBlueColor; // "rgba(139, 197, 63, 1)";
export const teamFontColor = menuBlueHighlightColor; // "rgba(0,127,54)";
export const newGreenTeamDarkColor = "rgba(0,127,54)";
export const costsColor = menuBlueColor; // "rgba(38,169,224)";
export const costsFontColor = menuBlueHighlightColor; // "rgba(27,117,187)";
export const revenueColor = menuBlueColor; // "rgba(139, 197, 63, 1)";
export const revenueFontColor = menuBlueHighlightColor; // "rgba(0, 147, 69, 1)";
export const revenueStream = "rgba(139, 197, 63, 0.15)";
export const revenueTotal = "rgba(133, 133, 133, 0.15)";
export const settingsColor = menuPurpleColor; // "rgba(250,172,24)";
export const settingsFontColor = menuPurpleHighlightColor; // "rgba(226,120,23)";

export const projectColors = [
  "rgba(139,197,63)",
  "rgba(86,127,191)",
  "rgba(250,172,24)",
  "rgba(144,39,142)",
  "rgba(150,150,150)",
];

export const tableLeftWidth = "130px";
export const tableRightWidth = "1000px";
export const highlight = "rgba(255, 255, 255, 0.5)";
export const tableMinHeight = "300px";
export const tableContentSideMargin = "30px";
export const tabHeight = "40px";
export const tabBottomMargin = "10px";
export const tableInputMargin = "5px 20px 5px 0px";
export const tableInputUnderline = "rgba(234, 234, 234, 1)";
export const overviewModalHeight = "210px";

export const wpHighlightColor = "rgba(35, 66, 104, 1)";
export const wpInfoColor = "rgba(47, 86, 151, 1)";
export const wpBackground = "rgba(47, 86, 151, 0.15)";
export const wpScheduleColor = "rgba(144, 39, 142, 1)";
export const wpScheduleBackground = "rgba(144, 39, 142, 0.15)";
export const delTitleColor = "rgba(139, 197, 63, 1)";
export const delBackground = "rgba(139, 197, 63, 0.15)";
export const milTitleColor = "rgba(250, 175, 64, 1)";
export const milBackground = "rgba(250, 175, 64, 0.15)";
export const wpHighlight = "rgba(150, 150, 150, 0.2)";

export const wpMarginBottom = "10px";
export const rowHeight = "40px"; // not used
export const taskDeadlineGap = "50px";
export const monthWidth = "40px";
export const totalDaysColor = "rgba(255, 255, 255, 0.8)";

export const schedGapColor = "rgba(12, 58, 93, 1)";

export const riskLevelVeryLowColor = "rgba(255,255,210)";
export const riskLevelLowColor = "rgba(255,255,153)";
export const riskLevelMediumColor = "rgba(249,203,156)";
export const riskLevelHighColor = "rgba(225,147,123)";
export const riskLevelVeryHighColor = "rgba(224,102,102)";
export const manRiskColor = "rgba(248,203,173)";
export const comRiskColor = "rgba(169,208,142)";
export const techRiskColor = "rgba(155,194,230)";
export const legRiskColor = "rgba(255,230,153)";
export const envRiskColor = "rgba(204,204,255)";

export const clientFlavours = ["Review", "Review Plus", "Full Write"];
export const ranking = [
  "Select",
  "Very Low",
  "Low",
  "Medium",
  "High",
  "Very High",
];
export const leaderList = ["lead", "pOne", "pTwo"];
export const leaderListCombined = ["lead", "pOne", "pTwo", "combined"];
export const sections = [
  "Details",
  "Team",
  "Gantt",
  "Costs",
  "Revenue",
  "Risks",
];
export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const printGanttColors = [
  {
    solid: "rgba(110,110,110)",
    fade: "rgba(110,110,110,0.15)",
    fontColor: "white",
  },
  {
    solid: "rgba(40,168,224)",
    fade: "rgba(40,168,224,0.15)",
    fontColor: "white",
  },
  {
    solid: "rgba(54,139,197)",
    fade: "rgba(54,139,197,0.15)",
    fontColor: "white",
  },
  {
    solid: "rgba(46,88,152)",
    fade: "rgba(46,88,152,0.15)",
    fontColor: "white",
  },
  {
    solid: "rgba(4,73,115)",
    fade: "rgba(4,73,115,0.15)",
    fontColor: "white",
  },
  {
    solid: "rgba(214,112,127)",
    fade: "rgba(214,112,127,0.15)",
    fontColor: "white",
  },
  {
    solid: "rgba(146,90,165)",
    fade: "rgba(146,90,165,0.15)",
    fontColor: "white",
  },
  {
    solid: "rgba(144,41,141)",
    fade: "rgba(144,41,141,0.15)",
    fontColor: "white",
  },
  {
    solid: "rgba(139,196,65)",
    fade: "rgba(139,196,65,0.15)",
    fontColor: "white",
  },
  {
    solid: "rgba(0,166,81)",
    fade: "rgba(0,166,81,0.15)",
    fontColor: "white",
  },
  {
    solid: "rgba(10,128,64)",
    fade: "rgba(10,128,64,0.15)",
    fontColor: "white",
  },
  {
    solid: "rgba(248,174,66)",
    fade: "rgba(248,174,66,0.15)",
    fontColor: "white",
  },
  {
    solid: "rgba(241,91,42)",
    fade: "rgba(241,91,42,0.15)",
    fontColor: "white",
  },
];

export const rev2 = false; // false unless paid
// move overhead from costs into company details?
// clickable buttons to swell

// new projects layout
// new accounts layout

// ability for users to have more than one project
// clone project

// generate project outputs with custom sentences and variable imports
// - functionality to turn the feature on and off for clients (review plus?)
// - functionality to turn the editing capability on and off for clients
// - project management
// - costs
// - risks
// - team

// email functionality and customization with client notifications
// - new account
// - updated password
// - closed account
// - forgotten password / reset function
// - project notifications

// toggle section complete on home page.
// prevents marking as complete unless all errors are fixed
// notification option to choose to be notified when section and project complete

// warning on gantt row to highlight which row is incomplete
// - ie if delete a team member
// warning on incomplete risks
// warnings on the homepage navigate to the error
// warning to flag up 2nd user deletions
// reminder to check the data if potential corruption
// function to remove these to streamline data

// risks print
// - resize severity and likelihood to maximize space on the PDF

// gantt print
// - task totals for each month in the header
// - random function on load plus a button
// - move days column to start and middle
// - resize description, days, and resources column widths
// - turn off del and mil indexes

// filter by category on template risks and tasks

// free

// export pdf for risks
// export pdf for gantt
// changed function to add users on project
// casper cannot change global settings
// marked as complete function
// - revenue & risks
// debounce on server
