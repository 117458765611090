import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import LeftMenu from "../components/table/leftMenu";
import LeaderTabs from "../components/table/leaderTabs";
import MarkedComplete from "../components/modals/markedComplete";
import LabourStaff from "../components/costs/labourStaff"; // check this
import OverheadInfo from "../components/costs/overheadInfo"; // check this
import MaterialsInfo from "../components/costs/materialsInfo";
import TravelInfo from "../components/costs/travelInfo";
import LabourSubcontract from "../components/costs/labourSubcontract";
import CapexInfo from "../components/costs/capexInfo";
import OtherInfo from "../components/costs/otherInfo";
import OverviewModal from "../components/modals/overviewModal";
import BreakdownInfo from "../components/costs/breakdown";
import AssignmentInfo from "../components/costs/assignment";
import Spinner from "../components/spinner";
import { getSelectedProject, updateUserSelection } from "../store/user";
import { TableContainer } from "../components/table/tableStyling";
import {
  costsColor,
  costsFontColor,
  loadAll,
  rev2,
  // getWPStatus,
} from "../helpers"; // check this
import MarkAsComplete from "../components/markAsComplete";
import CostOutput from "../components/costs/CostsOutput";

function Costs() {
  const dispatch = useDispatch();
  const { projectDataLoading, admin } = useSelector(
    (state) => state.user
  );
  const { outputAccess } = useSelector(getSelectedProject);
  const selectedOption = useSelector((state) => state.user.selectedCostsOption); // check this
  const status = useSelector(
    (state) => state.entities.project.data.status.costs // check this
  );

  const menuList = [
    "Labour",
    "Overhead",
    "Subcontract",
    "CapEx",
    "Materials",
    "Travel",
    "Other",
    "Assignment",
    "Breakdown",
  ];
  if (rev2 && (outputAccess || admin)) menuList.push("Output");

  const menuData = {
    menuList,
    selectedOption,
    color: costsFontColor, // check this
    backgroundColor: costsColor, // check this
    updateOption: function (value) {
      dispatch(updateUserSelection({ key: "selectedCostsOption", value })); // check this
    },
  };

  const data = {
    backgroundColor: menuData.backgroundColor,
    // maxHeight: "550px",
    marginBottom: 0,
    // showComponent === "" || selectedOption === "breakdown"
    //   ? "-190px "
    //   : "0px",
  };

  const showLeaderTabs = {
    labour: 3,
    overhead: 3,
    materials: 4,
    travel: 4,
    subcontract: 3,
    capex: 4,
    other: 4,
    breakdown: 0,
    assignment: 3,
  };

  function content() {
    if (selectedOption === "labour") return <LabourStaff />;
    if (selectedOption === "subcontract") return <LabourSubcontract />;
    if (selectedOption === "overhead") return <OverheadInfo />;
    if (selectedOption === "materials") return <MaterialsInfo />;
    if (selectedOption === "travel") return <TravelInfo />;
    if (selectedOption === "capex") return <CapexInfo />;
    if (selectedOption === "other") return <OtherInfo />;
    if (selectedOption === "breakdown") return <BreakdownInfo />;
    if (selectedOption === "assignment") return <AssignmentInfo />;
  }

  useEffect(() => {
    loadAll();
  }, []);

  return projectDataLoading ? (
    <Spinner />
  ) : (
    <TableContainer data={data}>
      <div className="displayArea">
        <LeftMenu data={menuData} />
        <div className="content">
          {selectedOption === "output" ? (
            <CostOutput />
          ) : (
            <>
              {showLeaderTabs[selectedOption] ? (
                <LeaderTabs
                  viewCombinedTab={showLeaderTabs[selectedOption] === 4}
                />
              ) : null}
              <MarkAsComplete section="costs" />
              <div className="table">
                {status ? <MarkedComplete /> : null}
                {content()}
              </div>
              <OverviewModal />
            </>
          )}
        </div>
      </div>
    </TableContainer>
  );
}
export default Costs;
