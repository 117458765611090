import React, { useEffect } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import Spinner from "../components/spinner";
import { loadAll, navHeight } from "../helpers";
import UserProjectTable from "../components/UserProjectTable";

export default function UserProjects() {
  const { projectDataLoading, selectedProjectId } = useSelector((state) => state.user);

  useEffect(() => {
    loadAll();
  }, [selectedProjectId]);

  return projectDataLoading ? (
    <Spinner />
  ) : (
    <Container>
      <UserProjectTable />
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  top: ${navHeight};
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
`;
