import React from "react";
import styled from "styled-components";
import { highlight } from "../../helpers";

function LeftMenuButton(props) {
  const { selectedOption, updateOption, color, backgroundColor } = props.data;
  const option = props.option;

  return (
    <Button
      // color={color}
      backgroundColor={backgroundColor}
      style={
        selectedOption === option.toLowerCase()
          ? { backgroundColor: highlight, color: color }
          : null
      }
      onClick={() => updateOption(option.toLowerCase())}
    >
      <h3>{option}</h3>
    </Button>
  );
}
export default LeftMenuButton;

const Button = styled.button`
  border: none;
  background-color: ${props => props.backgroundColor};
  display: flex;
  padding: 15px 10px;
  width: 100%;
  color: white;
  margin-bottom: 10px;
  border-radius: 0;
  white-space: nowrap;
`;
