import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getSelectedProject, updateUserSelection } from "../store/user";
import LeftMenu from "../components/table/leftMenu";
import LeaderTabs from "../components/table/leaderTabs";
import { TableContainer } from "../components/table/tableStyling";
import MarkedComplete from "../components/modals/markedComplete";
import { teamColor, teamFontColor, loadAll, rev2 } from "../helpers";
import TeamTitles from "../components/team/teamTitles";
import TeamInfo from "../components/team/teamData";
import Spinner from "../components/spinner";
import MarkAsComplete from "../components/markAsComplete";
import TeamSummary from "../components/team/TeamOutput";

function Team() {
  const dispatch = useDispatch();
  const { outputAccess } = useSelector(getSelectedProject);
  const selectedOption = useSelector((state) => state.user.selectedTeamOption);
  const { projectDataLoading, admin } = useSelector(
    (state) => state.user
  );
  const status = useSelector(
    (state) => state.entities.project.data.status.team
  );

  const menuList = ["Staff", "Subcontract"];
  if (rev2 && (outputAccess || admin)) menuList.push("Output");

  const menuData = {
    menuList,
    selectedOption,
    color: teamFontColor,
    backgroundColor: teamColor,
    updateOption: function (value) {
      dispatch(updateUserSelection({ key: "selectedTeamOption", value }));
    },
  };

  const data = {
    backgroundColor: menuData.backgroundColor,
    maxHeight: null,
  };

  useEffect(() => {
    loadAll();
  }, []);

  return projectDataLoading ? (
    <Spinner />
  ) : (
    <TableContainer data={data}>
      <div className="displayArea">
        <LeftMenu data={menuData} />
        <div className="content">
          {selectedOption === "output" ? (
            <TeamSummary />
          ) : (
            <>
              <LeaderTabs viewCombinedTab={false} />
              <MarkAsComplete section="team" />
              <div>
                {status ? <MarkedComplete /> : null}
                <TeamTitles />
                <TeamInfo />
              </div>
            </>
          )}
        </div>
      </div>
    </TableContainer>
  );
}
export default Team;
