import React from "react";
import styled from "styled-components";
import OutputTeam from "../output/OutputTeam";
import { useSelector, useDispatch } from "react-redux";
import { addProjectOutputs } from "../../store/entities/project";
import { menuBlueColor } from "../../helpers";

export default function TeamOutput() {
  const dispatch = useDispatch();
  const { outputs } = useSelector((state) => state.entities.project.data);
  const global = useSelector((state) => state.entities.global.data.outputs);

  function copy() {
    dispatch(addProjectOutputs({ global }));
  }

  return (
    <Container>
      <div className="contents">
        {outputs ? (
          <OutputTeam color={menuBlueColor}/>
        ) : (
          <div>
            <h3>
              This project was created prior to rev2 and does not have any
              output templates.
            </h3>
            <h3>To copy the current templates, click copy.</h3>
            <button onClick={copy}>Copy</button>
          </div>
        )}
      </div>
    </Container>
  );
}

const Container = styled.div`
  padding: 20px;
  h3 {
    margin-bottom: 10px;
  }
  button {
    background-color: ${menuBlueColor};
    border: none;
    color: white;
    font-weight: bold;
    padding: 10px 15px;
  }
  .contents {
    max-height: 600px;
    overflow: scroll;
  }
`;
