import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import OutputCostCategory from "./OutputCostCategory";
import OutputCostSalaries from "./OutputCostSalaries";

export default function OutputCostBreakdown(props) {
  const { mapObj } = props;
  const { showComponent } = useSelector((state) => state.user);
  const edit = showComponent === "outputCosts";

  const salaries = [
    { key: "costLabour", type: "staff", title: "Labour" },
    { key: "costOverhead", type: "overheads", title: "Overheads" },
    { key: "costSubcontract", type: "subcontract", title: "Subcontract" },
  ];
  const costs = [
    { key: "capex", title: "Capital Equipment" },
    { key: "materials", title: "Materials Costs" },
    { key: "travel", title: "Travel Costs" },
    { key: "other", title: "Other Costs" },
  ];

  return (
    <Container>
      <h3 className='title' >Budget Breakdown:</h3>
      {salaries.map((category, index) => {
        return (
          <OutputCostSalaries
            key={index}
            category={category}
            edit={edit}
            mapObj={mapObj}
          />
        );
      })}
      {costs.map((category, index) => {
        return (
          <OutputCostCategory key={index} category={category} edit={edit} />
        );
      })}
    </Container>
  );
}

const Container = styled.div`
`;
