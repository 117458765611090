import styled from "styled-components";
import { wpMarginBottom } from "../../helpers";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${wpMarginBottom};
  border-radius: 5px;
  overflow: hidden;
  .packBackground {
    /* background-color: white; */
  }
  button {
    border: none;
    padding: 0;
  }

  .titleBar {
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    padding-left: 30px;
    padding-right: 31px;
    background-color: ${(props) => props.titleBarColor};
    .titleInput {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .title {
      background-color: transparent;
      border-color: transparent;
      color: white;
      font-size: 16px;
      text-overflow: ellipsis;
      padding-left: 5px;
      white-space: nowrap;
      font-weight: 800;
      &:hover {
        border-color: #a1a1a1;
      }
      &:focus {
        border-color: #a1a1a1;
      }
    }
    input.title {
      background-color: rgba(255, 255, 255, 0.1);
    }
    .titleButton {
      margin-left: 5px;
    }
  }

  .deadlineDescription {
    margin-right: 15px;
    width: 400px;
    overflow: hidden;
  }
  .description {
    margin-right: 5px;
    width: 300px;
    overflow: hidden;
  }
  .wider.description {
    &:hover {
      width: 500px;
      position: absolute;
      background-color: #e7e7e7;
      height: 30px;
      transition: width 0.3s;
    }
  }
  .resources {
    width: 100px;
    margin-right: 5px;
  }
  .bars {
    width: 40px;
    margin-right: 5px;
  }
  .days {
    width: 40px;
  }
  .date {
  }
  .bottom {
    display: flex;
    /* background-color: rgba(250, 250, 250, 1); */
    background-color: white;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    padding-left: 33px;
    padding-right: 34px;
    p {
      font-weight: bold;
      text-align: right;
    }
    .confirm {
      width: 50px;
      display: flex;
      justify-content: space-between;
    }
    :hover .delete {
      opacity: 1;
    }
  }
  .actions {
    display: flex;
  }
  .imgButton {
    width: 20px;
    height: 20px;
    margin-left: 3px;
    /* border: 1px solid red; */
  }
  .delete {
    margin-left: 5px;
    opacity: 0;
    transition: opacity 0.3s;
  }
`;
