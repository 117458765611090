import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { getSelectedProject } from "../../store/user";
import { ranking } from "../../helpers";

export default function OutputRiskList() {
  const data = useSelector((state) => state.entities.risks.data);

  const {
    useManagerial,
    useCommercial,
    useLegal,
    useTechnical,
    useEnvironmental,

    outputEnvRisks,
    outputLegRisks,
    outputComRisks,
    outputTechRisks,
    outputManRisks,
  } = useSelector(getSelectedProject);

  const risks = [
    {
      use: useManagerial,
      title: "Managerial",
      risks: data
        .filter((risk) => risk.riskType === "managerial")
        .slice(0, outputManRisks),
    },
    {
      use: useCommercial,
      title: "Commercial",
      risks: data
        .filter((risk) => risk.riskType === "commercial")
        .slice(0, outputComRisks),
    },
    {
      use: useTechnical,
      title: "Technical",
      risks: data
        .filter((risk) => risk.riskType === "technical")
        .slice(0, outputTechRisks),
    },
    {
      use: useLegal,
      title: "Legal",
      risks: data
        .filter((risk) => risk.riskType === "legal")
        .slice(0, outputLegRisks),
    },
    {
      use: useEnvironmental,
      title: "Environmental",
      risks: data
        .filter((risk) => risk.riskType === "environmental")
        .slice(0, outputEnvRisks),
    },
  ];

  return (
    <Container>
      {risks.map((category, index) => {
        return category.use && category.risks.length ? (
          <div key={index}>
            <h3 className='title' >{category.title} Risks</h3>
            {category.risks.map((entry, index) => {
              return (
                <div key={index}>
                  <span className="bold">{entry.riskDescription}</span>
                  <ul>
                    <li>
                      <span className="bold">Likelihood</span>
                      <span> - {ranking[entry.riskPreProbability]}</span>
                    </li>
                    <li>
                      <span className="bold">Severity</span>
                      <span> - {ranking[entry.riskPreConsequence]}</span>
                    </li>
                    <li>
                      <span className="bold">Mitigation</span>
                      <span> - {entry.riskMitigation}</span>
                    </li>
                  </ul>
                </div>
              );
            })}
          </div>
        ) : null;
      })}
    </Container>
  );
}

const Container = styled.div``;
