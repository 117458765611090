import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import OutputSection from "./OutputSection";
import { getSelectedProject } from "../../store/user";

export default function OutputCostValue(props) {
  const { mapObj } = props;
  const { showComponent } = useSelector((state) => state.user);
  const { partners } = useSelector(getSelectedProject);

  const companyP1 = useSelector(
    (state) => state.entities.project.data.lead.companyAcronym
  );
  const companyP2 = useSelector(
    (state) => state.entities.project.data.pOne.companyAcronym
  );
  const companyP3 = useSelector(
    (state) => state.entities.project.data.pTwo.companyAcronym
  );

  const edit = showComponent === "outputCosts";

  return (
    <Container>
      <h3 className='title' >Value for Money:</h3>
      <OutputSection
        objKey="costRequestP1"
        subject={companyP1}
        mapObj={mapObj}
        edit={edit}
      />
      {partners > 1 ? (
        <OutputSection
          objKey="costRequestP2"
          subject={companyP2}
          mapObj={mapObj}
          edit={edit}
        />
      ) : null}
      {partners > 2 ? (
        <OutputSection
          objKey="costRequestP3"
          subject={companyP3}
          mapObj={mapObj}
          edit={edit}
        />
      ) : null}
    </Container>
  );
}

const Container = styled.div``;
