import Tippy from "@tippy.js/react";
import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { navBackground, rev2 } from "../helpers/settings";
import tick from "../images/tick-white.png";
import warn from "../images/warning.png";
import { updateUserSelection } from "../store/user";
import { handleSectionStatus } from "../helpers";

function Section(props) {
  const { index, title, status, warnings } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  function moveToError(warning) {
    const { key, value, ignoreLink, link } = warning;
    if (!rev2 || ignoreLink) return;
    dispatch(updateUserSelection({ key, value }));
    const location = link ? link : title.toLowerCase();
    history.push(location);
  }

  const content = `${title} ${
    status ? "marked as complete" : "in progress"
  }. Click to change.`;

  function change() {
    if (rev2) handleSectionStatus(title.toLowerCase());
  }

  return (
    <Container>
      {rev2 ? (
        <Tippy content={content}>
          <div onClick={change} className="badge swell">
            {status ? (
              <div className="complete">
                <img src={tick} alt="complete" />
              </div>
            ) : null}
            <div className="number">
              <h2>{index + 1}</h2>
            </div>
          </div>
        </Tippy>
      ) : (
        <div className="badge">
          {status ? (
            <Tippy content={`${title} marked as complete`}>
              <div className="complete">
                <img src={tick} alt="complete" />
              </div>
            </Tippy>
          ) : null}
          <div className="number">
            <h2>{index + 1}</h2>
          </div>
        </div>
      )}
      <h3 className="title">{title}</h3>
      {warnings ? (
        <div className="warnings">
          {warnings.map((warning, index) => {
            return (
              <div key={index} className="warning swell">
                <Tippy content={warning.message}>
                  <img
                    src={warn}
                    alt="warning"
                    onClick={() => moveToError(warning)}
                  />
                </Tippy>
              </div>
            );
          })}
        </div>
      ) : null}
    </Container>
  );
}

export default Section;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* position: relative; */
  /* border: 1px solid red; */
  /* height: 200px; */
  flex-basis: 0;
  flex-grow: 1;

  .title {
    color: ${navBackground};
    font-size: 24px;
    margin-top: 70px;
  }
  .badge {
    /* top: -130px; */
    /* left: 50%; */
    /* right: 50%; */
    background-color: ${navBackground};
    position: absolute;
    top: -30px;
    border-radius: 50%;
    height: 80px;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .badge.swell {
    transition: transform 0.3s;
    &:hover {
      transform: scale(1.1);
    }
  }
  .number {
    width: 70px;
    height: 70px;
    border: 3px solid white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .complete {
    border-radius: 50%;
    padding: 2px;
    background-color: ${navBackground};
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
  }
  .warnings {
    position: absolute;
    bottom: 20px;
    display: flex;
  }
  .warning {
    max-height: 25px;
    max-width: 25px;
    margin: 5px;
    cursor: pointer;
  }
  .warning.swell {
    transition: transform 0.3s;
    &:hover {
      transform: scale(1.3);
    }
  }
`;
