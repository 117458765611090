import React from "react";
import styled from "styled-components";
import OutputSection from "./OutputSection";

export default function OutputRisksInfo(props) {
  const { mapObj, edit } = props;

  return (
    <Container>
      <h3 className='title' >Further Information</h3>
      <OutputSection objKey="riskAppendix" mapObj={mapObj} edit={edit} />
      <div className="gap" />
      <OutputSection
        objKey="riskLast"
        subject="Regulatory Requirements"
        mapObj={mapObj}
        edit={edit}
      />
    </Container>
  );
}

const Container = styled.div`
  .gap {
    height: 5px;
  }
`;
