import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { sendSelectedProject, updateUserSelection } from "../../store/user";
import {
  updateProjectDesc,
  updateProjectColor,
  updatePartner,
} from "../../store/entities/setup";
import ConfirmModal from "../modals/confirmModal";
import {
  clientFlavours,
  handleClientFlavour,
  handleSendSetup,
  projectDownload,
} from "../../helpers";

import {
  // clientFlavours,
  navBackground,
  projectColors,
} from "../../helpers";
import bin from "../../images/bin-grey.png";
import exp from "../../images/export.png";
import Tippy from "@tippy.js/react";
import { deleteProject } from "../../helpers/deleteProject";
import { getAllocationsByTaskId } from "../../store/entities/allocations";
import { getWorkPackageIds } from "../../store/entities/tasks";
import { getOtherIds } from "../../store/entities/other";
import {
  getUsersById,
  getUsersByProjectId,
  sendUserProjects,
  userAddProject,
  userRemoveProject,
} from "../../store/entities/users";

function projectCard(props) {
  const dispatch = useDispatch();
  const { project } = props;
  const {
    projectDesc,
    lead,
    pOne,
    pTwo,
    projectId,
    color,
    partners,
    clientFlavour,
  } = project;
  const { selectedProjectId, showComponent } = useSelector(
    (state) => state.user
  );
  const selected = projectId === selectedProjectId;

  const allocations = useSelector(getAllocationsByTaskId);
  const workPackageIds = useSelector(getWorkPackageIds);
  const otherIds = useSelector(getOtherIds);
  const projectAccess = useSelector(getUsersByProjectId)[projectId];
  const userById = useSelector(getUsersById);
  const allUsers = useSelector((state) => state.entities.users.data);

  const unAssignedUsers = allUsers.filter(
    (user) =>
      !user.admin &&
      !projectAccess.includes(user.userId) &&
      !user.projects.length
  );
  function selectProject() {
    dispatch(sendSelectedProject({ projectId }));
  }

  function handleModal(e) {
    e.stopPropagation();
    dispatch(updateUserSelection({ key: "showComponent", value: projectId }));
  }

  function handleColor(currentColorIndex) {
    let color = (currentColorIndex + 1) % projectColors.length;
    if (!color) color = 0;
    dispatch(updateProjectColor({ projectId, color }));
  }

  function handleProjectDescription(e) {
    let desc = e.target.value;
    dispatch(updateProjectDesc({ projectId, desc }));
  }

  function handleFlavour(e) {
    const value = e.target.value;
    handleClientFlavour(value);
  }

  function handlePartner(e) {
    const value = parseInt(e.target.value);
    dispatch(updatePartner({ projectId, value }));
  }

  function handleNewAccess(e) {
    const userId = e.target.value;
    dispatch(userAddProject({ userId, projectId }));
    dispatch(sendUserProjects(userId));
  }

  function removeProject(userId) {
    dispatch(userRemoveProject({ userId, projectId }));
    dispatch(sendUserProjects(userId));
  }

  const description = projectDesc ? projectDesc : "";

  function handleDelete() {
    const project = {
      // delete project
      projectId,
      // remove the project from each of the users
      lead,
      pOne,
      pTwo,
    };
    deleteProject(project);
  }

  function handleDownload() {
    const data = {
      partners,
      projectDesc,
      allocations,
      workPackageIds,
      otherIds,
    };
    projectDownload(data);
  }

  const partner = {
    1: "Lead Partner",
    2: "Partner Two",
    3: "Partner Three",
  }

  return (
    <Container color={projectColors[color]} selected={selected}>
      {showComponent === projectId ? (
        <ConfirmModal
          question={`Delete ${description}?`}
          comment="All data will be deleted"
          confirm={handleDelete}
        />
      ) : null}
      {selected ? null : (
        <div onClick={selectProject} className="selectProject">
          {selected ? null : (
            <Tippy content="Delete project">
              <div onClick={handleModal} className="delete">
                <img src={bin} alt="delete" />
              </div>
            </Tippy>
          )}
        </div>
      )}

      <div
        className="color"
        onClick={() => handleColor(color)}
        onBlur={handleSendSetup}
        contentEditable
      />
      <div className="contents">
        <input
          className="title"
          value={description}
          onChange={handleProjectDescription}
          onBlur={handleSendSetup}
        />
      </div>

      <div className="options">
        <div className="buttons">
          <div className="button">
            <label htmlFor="partners">Partners</label>
            <select
              value={partners}
              name="partners"
              onChange={handlePartner}
              onBlur={handleSendSetup}
              dir="ltr"
            >
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
            </select>
          </div>

          <div className="button">
            <label htmlFor="Flavour">Flavour</label>
            <select
              onChange={handleFlavour}
              // onBlur={handleSendSetup}
              value={clientFlavour}
              name="clientFlavour"
            >
              {clientFlavours.map((flavour, index) => {
                return (
                  <option key={index} value={flavour}>
                    {flavour}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

        {selected ? (
          <Tippy content="Export">
            <div
              onClick={handleDownload}
              // download="tick-orange.png"
              className="icon"
            >
              <img src={exp} alt="export" />
            </div>
          </Tippy>
        ) : null}
      </div>
      <div className="access">
        <h3>Project Access</h3>
        {projectAccess.map((person, index) => {
          return (
            <div key={index} className="person">
              <p>{userById[person]}</p>
              <div onClick={() => removeProject(person)} className="icon">
                <img src={bin} alt="delete" />
              </div>
            </div>
          );
        })}
        {projectAccess.length < 3 ? (
          <select onChange={handleNewAccess} name="user" value="blank">
            <option value="blank" disabled>
              Click here to add a user...
            </option>
            {unAssignedUsers.map((user, index) => {
              return (
                <option key={index} value={user.userId}>
                  {user.name}
                </option>
              );
            })}
          </select>
        ) : null}
      </div>
    </Container>
  );
}

export default projectCard;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 400px;
  background-color: white;
  margin: 15px;
  box-shadow: 8px 8px 12px rgba(1, 1, 1, 0.5);
  border-radius: 6px;
  overflow: hidden;
  padding-bottom: 10px;
  button {
    margin-right: 20px;
  }
  div {
    /* border: 1px solid red; */
  }
  .color {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: ${(props) => props.color};
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    transition: transform 0.3s;
    &:hover {
      transform: scale(1.2);
    }
  }
  .selectProject {
    border: none;
    /* border: 1px solid red; */
    border-radius: 0;
    margin: 0;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: 1;
    transition: background-color 0.3s;
    cursor: pointer;
    &:hover {
      background-color: rgba(0, 0, 0, 0.12);
    }
  }
  .selected {
    position: absolute;
    top: -15px;
    right: -15px;
    background-color: red;
    border-color: ${(props) => props.projectColor};
    border-radius: 50%;
    max-width: 35px;
    max-height: 35px;
  }
  .title {
    padding: 0 25px;
    margin-bottom: 10px;
  }
  .contents {
    width: 100%;
    padding: 15px 0 0 0;
  }
  .partner {
    position: relative;
    width: 100%;
    padding: 0 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:hover .hidden {
      opacity: 1;
    }
  }
  .name {
    display: flex;
  }
  .prefix {
    width: 70px;
  }
  .addPartner {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding: 5px 25px;
  }
  .delPartner {
    position: absolute;
    right: 3px;
    max-width: 20px;
    max-height: 20px;
  }
  .hidden {
    transition: opacity 0.3s;
    opacity: 0;
  }
  .image {
    max-width: 20px;
    max-height: 20px;
  }
  .options {
    padding: 5px 25px 5px 25px;
    width: 100%;
    /* height: 35px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .access {
    padding: 5px 25px 5px 25px;
    width: 100%;
  }
  .buttons {
    display: flex;
  }
  .button {
    display: flex;
    flex-direction: column;
    width: 100px;
    label {
      color: black;
      margin-bottom: 5px;
    }
  }
  .icon {
    /* margin: auto; */
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 20px;
    max-height: 20px;
    background-color: white;
    border-radius: 50%;
  }
  .person {
    display: flex;
    width: 100%;
    justify-content: space-between;
    :hover .icon {
      opacity: 1;
    }
    .icon {
      opacity: 0;
    }
  }
  .delete {
    position: absolute;
    right: 25px;
    bottom: 30px;
    max-width: 20px;
    max-height: 20px;
  }
  .reset {
    max-width: 15px;
    max-height: 15px;
    margin-left: 10px;
  }
  h3,
  p {
    color: ${navBackground};
    margin: 5px 0;
  }
  input {
    margin: 5px 0;
    padding: 0;
    font-size: 16px;
    font-weight: bold;
    color: ${navBackground};
    width: 95%;
    /* border: 1px solid red; */
    overflow: hidden;
    text-overflow: ellipsis;
  }
  select {
    padding: 0;
    cursor: pointer;
  }
  img {
    cursor: pointer;
  }
  select {
    padding: 5px;
    /* width: 110px; */
    border-color: ${navBackground};
    color: ${navBackground};
    margin-right: 10px;
  }
`;
