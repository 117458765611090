import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  appWidth,
  logout,
  navBackground,
  navHeight,
  navHighlight,
  projectDownload,
  rev2,
} from "./helpers";
import navHome from "./images/navHome.png";
import WizardOfferModal from "./components/modals/wizardOfferModal";
import WizardModal from "./components/modals/wizardModal";
import burger from "./images/navBurger.png";
import { getSelectedProject, setShowComponent } from "./store/user";
import { getAllocationsByTaskId } from "./store/entities/allocations";
import { getWorkPackageIds } from "./store/entities/tasks";
import { getOtherIds } from "./store/entities/other";

function Navigation() {
  const dispatch = useDispatch();
  const { admin, name } = useSelector((state) => state.user);
  const multiple = useSelector((state) => state.entities.setup.data.length > 1);
  let flavour = false;
  const project = useSelector(getSelectedProject);
  if (project) flavour = project.clientFlavour;

  function launchWizard() {
    dispatch(setShowComponent({ value: "wizard" }));
  }

  const { partners, projectDesc } = project;
  const allocations = useSelector(getAllocationsByTaskId);
  const workPackageIds = useSelector(getWorkPackageIds);
  const otherIds = useSelector(getOtherIds);

  function handleDownload() {
    const data = {
      partners,
      projectDesc,
      allocations,
      workPackageIds,
      otherIds,
    };
    projectDownload(data);
  }

  return (
    <Container appWidth={appWidth}>
      <WizardOfferModal />
      <WizardModal />
      <div className="user">
        <p>{name}</p>
      </div>
      <div className="burger">
        <img src={burger} alt="menu" />
        <div className="menuContainer">
          <div className="menu">
            {/* <button>Export JSON</button> */}
            {flavour === "Full Write" ? (
              <button onClick={launchWizard}>Launch Wizard</button>
            ) : null}
            <NavLink exact to="/printRisks">
              <button>Download Risk PDF</button>
            </NavLink>
            <NavLink exact to="/printGantt">
              <button>Download Gantt PDF</button>
            </NavLink>
            <button onClick={handleDownload}>Download Data</button>
            <button onClick={logout}>Logout</button>
          </div>
        </div>
      </div>
      <div className="navBar">
        <div className="pageLinks">
          {admin ? (
            <NavLink
              exact
              to="/admin"
              className="navButton"
              activeClassName="selected"
            >
              <h3>Admin</h3>
            </NavLink>
          ) : null}
          {multiple && admin && rev2 ? ( // change to !admin for rev2
            <NavLink
              exact
              to="/projects"
              className="navButton"
              activeClassName="selected"
            >
              <h3>Projects</h3>
            </NavLink>
          ) : null}
          <NavLink
            exact
            to="/"
            className="navButton icon"
            activeClassName="selected"
          >
            <img src={navHome} alt="home" />
          </NavLink>
          <NavLink
            exact
            to="/details"
            className="navButton"
            activeClassName="selected"
          >
            <h3>Details</h3>
          </NavLink>
          <NavLink
            exact
            to="/team"
            className="navButton"
            activeClassName="selected"
          >
            <h3>Team</h3>
          </NavLink>
          <NavLink to="/gantt" className="navButton" activeClassName="selected">
            <h3>Gantt Chart</h3>
          </NavLink>
          <NavLink
            exact
            to="/costs"
            className="navButton"
            activeClassName="selected"
          >
            <h3>Costs</h3>
          </NavLink>
          <NavLink
            exact
            to="/revenue"
            className="navButton"
            activeClassName="selected"
          >
            <h3>Revenue</h3>
          </NavLink>
          <NavLink
            exact
            to="/risks"
            className="navButton"
            activeClassName="selected"
          >
            <h3>Risks</h3>
          </NavLink>
        </div>
      </div>
    </Container>
  );
}
export default Navigation;

const Container = styled.nav`
  // full width of screen
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  height: ${navHeight};
  background-color: ${navBackground};
  z-index: 11;

  .navBar {
    width: 100%;
    max-width: 920px;
    /* height: 100%; */
    display: flex;
    justify-content: space-between;
    justify-content: center;
    align-items: center;
  }
  .pageLinks {
    display: flex;
  }
  .navButton {
    display: flex;
    justify-content: center;
    color: white;
    border-radius: 5px;
    padding: 5px 15px;
    margin: 0 5px;
    &:hover h3 {
      border-bottom: 2px solid rgba(255, 255, 255, 1);
    }
  }
  .navButton.selected:hover h3 {
    border-bottom: 2px solid ${navHighlight};
  }
  .icon {
    height: 40px;
    padding: 10px 15px;
  }
  img {
    max-width: 100%;
    max-height: 100%;
  }
  .selected {
    background-color: ${navHighlight};
    h3 {
      border-bottom: 2px solid ${navHighlight};
    }
  }
  h3 {
    border-top: 2px solid rgba(255, 255, 255, 0);
    border-bottom: 2px solid rgba(255, 255, 255, 0);
    padding: 2px 0px;
    transition: border-bottom 0.6s;
  }
  .user {
    position: absolute;
    top: 5px;
    left: 5px;
    display: flex;
    align-items: center;
    p {
      color: rgba(255, 255, 255, 0.8);
      font-weight: bold;
      font-size: 12px;
    }
    /* .logout {
      background-color: rgba(255, 255, 255, 0.8);
      padding: 1px 5px;
      margin-right: 5px;
      p {
        color: ${navBackground};
      }
    } */
  }
  .burger {
    position: absolute;
    right: 0;
    top: 0;
    padding: 5px;
    width: 25px;
    height: 25px;
    :hover {
      /* opacity: 0; */
      .menuContainer {
        right: 0px;
      }
    }
  }
  .menuContainer {
    background-color: ${navBackground};
    position: absolute;
    right: 0;
    right: -181px;
    transition: right 0.3s;
    top: 0;
    width: 180px;
    height: 100vh;
    color: white;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 10px;
    .menu {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin: 10px;
    }
    a {
      width: 100%;
      margin: 0;
    }
    button {
      border: none;
      background-color: rgba(255, 255, 255, 0.75);
      font-weight: bold;
      color: black;
      font-size: 12px;
      width: 100%;
      margin-bottom: 15px;
      margin-top: 0;

    }
  }
`;
