import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { settingsColor } from "../../helpers";
import { updateUserSelection } from "../../store/user";

export default function FilterCategory() {
  const dispatch = useDispatch();
  const { categories } = useSelector((state) => state.entities.global.data);
  const { selectedCategory } = useSelector(
    (state) => state.user
  );

  function filter(input) {
    const value = input === selectedCategory ? "" : input;
    dispatch(updateUserSelection({ key: "selectedCategory", value }));
  }

  return (
    <Container>
      {categories.map((category, index) => {
        return (
          <button
            key={index}
            onClick={() => filter(category)}
            className={`category ${
              selectedCategory === category ? "selectedCategory" : null
            }`}
          >
            {category}
          </button>
        );
      })}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  padding: 0 30px;
  .category {
    width: 50px;
    padding: 2px;
    border-color: #a8a4a4;
    color: #a8a4a4;
    font-weight: bold;
    transition: transform 0.3s;
    &:hover {
      transform: scale(1.1)
    }
  }
  .selectedCategory {
    background-color: ${settingsColor};
    border-color: ${settingsColor};
    color: white;
  }
`;
