import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { sendSelectedProject, updateUserSelection } from "../../store/user";
import {
  updateProjectColor,
  updateProject,
  updatePartner,
} from "../../store/entities/setup";
import ConfirmModal from "../modals/confirmModal";
import {
  clientFlavours,
  cloneWholeProject,
  handleClientFlavour,
  handleSendSetup,
  highlight,
  menuPurpleColor,
  menuPurpleHighlightColor,
  projectDownload,
  settingsColor,
} from "../../helpers";

import { projectColors } from "../../helpers";
import bin from "../../images/bin-grey.png";
import exp from "../../images/export.png";
import Tippy from "@tippy.js/react";
import { deleteProject } from "../../helpers/deleteProject";
import { getAllocationsByTaskId } from "../../store/entities/allocations";
import { ImCopy } from "react-icons/im";
import {
  getUsersByProjectId,
  getUsersById,
  sendUserProjects,
  userAddProject,
  userRemoveProject,
} from "../../store/entities/users";

export default function ProjectRow(props) {
  const dispatch = useDispatch();
  const { project } = props;
  const {
    projectDesc,
    projectId,
    color,
    partners,
    clientFlavour,
    productPlatformName,
  } = project;
  const { selectedProjectId, showComponent } = useSelector(
    (state) => state.user
  );

  const selected = projectId === selectedProjectId;
  const allUsers = useSelector((state) => state.entities.users.data);
  const projectAccess = useSelector(getUsersByProjectId)[projectId];
  const userById = useSelector(getUsersById);
  const unAssignedUsers = allUsers.filter(
    (user) => !user.admin && !projectAccess.includes(user.userId)
  );

  function selectProject() {
    dispatch(sendSelectedProject({ projectId }));
  }

  function showDeleteModal(e) {
    e.stopPropagation();
    dispatch(updateUserSelection({ key: "showComponent", value: projectId }));
  }

  function handleColor(currentColorIndex) {
    let color = (currentColorIndex + 1) % projectColors.length;
    if (!color) color = 0;
    dispatch(updateProjectColor({ projectId, color }));
  }

  function handleChange(e) {
    const key = e.target.name;
    const value = e.target.value;
    console.log(value);
    dispatch(updateProject({ projectId, key, value }));
  }

  function handlePartner(e) {
    const value = parseInt(e.target.value);
    dispatch(updatePartner({ projectId, value }));
  }

  function handleFlavour(e) {
    const value = e.target.value;
    handleClientFlavour(value);
  }

  function handleDelete() {
    deleteProject({ projectId });
  }

  function clone() {
    cloneWholeProject(dispatch);
  }

  function handleNewAccess(e) {
    const userId = e.target.value;
    dispatch(userAddProject({ userId, projectId }));
    dispatch(sendUserProjects(userId));
  }

  function removeProject(userId) {
    dispatch(userRemoveProject({ userId, projectId }));
    dispatch(sendUserProjects(userId));
  }

  const allocations = useSelector(getAllocationsByTaskId);
  function handleExport() {
    const data = { partners, projectDesc, allocations };
    projectDownload(data);
  }

  return (
    <Container color={projectColors[color]} selected={selected}>
      {showComponent === projectId ? (
        <ConfirmModal
          question={`Delete ${projectDesc}?`}
          comment="All data will be deleted"
          confirm={handleDelete}
        />
      ) : null}
      {selected ? null : (
        <div onClick={selectProject} className="selectProject">
          <Tippy content="Delete Project">
            <div onClick={showDeleteModal} className="icon delete hidden">
              <img src={bin} alt="delete" />
            </div>
          </Tippy>
        </div>
      )}

      <div className="indicatorSection">
        <div className="indicator">
          <h4>{productPlatformName}</h4>
        </div>
      </div>

      <div className="gap">
        <div className="contents">
          <input
            className="description"
            value={projectDesc}
            onChange={handleChange}
            onBlur={handleSendSetup}
            name="projectDesc"
          />

          <div className="partners">
            <select
              value={partners}
              name="partners"
              onChange={handlePartner}
              onBlur={handleSendSetup}
              dir="ltr"
            >
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
            </select>
          </div>

          <div className="flavour">
            <select
              onChange={handleFlavour}
              value={clientFlavour}
              name="clientFlavour"
            >
              {clientFlavours.map((flavour, index) => {
                return (
                  <option key={index} value={flavour}>
                    {flavour}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="optionsContainer">
            <div className="projectColor" />
            <div className="options">
              <Tippy content="Clone Project">
                <div onClick={clone} className="icon">
                  <ImCopy />
                </div>
              </Tippy>
              <Tippy content="Export as JSON">
                <div onClick={handleExport} className="icon">
                  <img src={exp} alt="export" />
                </div>
              </Tippy>
              <Tippy content="Toggle Project Colour">
                <div
                  className="projectColor"
                  onClick={() => handleColor(color)}
                  onBlur={handleSendSetup}
                  contentEditable
                />
              </Tippy>
              <select onChange={handleNewAccess} name="user" value="blank">
                <option value="blank" disabled>
                  Click here to add a user...
                </option>
                {unAssignedUsers.map((user, index) => {
                  return (
                    <option key={index} value={user.userId}>
                      {user.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="access">
            {projectAccess.map((id, index) => {
              return (
                <div className="user" key={index}>
                  <p>{userById[id]}</p>
                  <Tippy content="Remove from project">
                    <div
                      onClick={() => removeProject(id)}
                      className="icon hidden"
                    >
                      <img src={bin} alt="delete" />
                    </div>
                  </Tippy>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background-color: white;
  background-color: ${settingsColor};
  :hover .delete {
    opacity: 1;
  }
  select {
    padding: 0;
    cursor: pointer;
    /* width: 100%; */
  }
  .selectProject {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    padding-right: 3px;
    align-items: center;
    width: 100%;
    height: 100%;
    /* border: 1px solid red; */
    transition: background-color 0.3s;
    cursor: pointer;
    :hover {
      background-color: rgba(200, 200, 200, 0.2);
    }
  }
  .gap {
    width: 100%;
    height: 100%;
    padding: 6px 25px;
    background-color: white;
    /* background-color: ${(props) =>
      props.selected ? "rgb(245, 245, 245)" : "white"}; */
  }
  .contents {
    display: flex;
    align-items: center;
    border: 1px solid lightgray;
    width: 100%;
    min-height: 40px;
    /* border: ${(props) => (props.selected ? null : "1px solid lightgray")}; */
    border-radius: 4px;
    height: 100%;
    padding-right: 5px;
    background-color: white;
  }
  .description {
    padding: 3px 0;
  }
  .partners {
    display: flex;
    /* justify-content: center; */
  }
  .flavour {
    padding: 0;
    border: none;
    text-align: left;
  }
  .optionsContainer {
    display: flex;
    cursor: pointer;
    :hover .options {
      display: flex;
    }
  }
  .projectColor {
    border-radius: 5px;
    background-color: ${(props) => props.color};
    height: 20px;
    width: 25px;
    cursor: pointer;
  }
  .options {
    display: none;
    /* display: flex; */
    background-color: white;
    position: absolute;
    right: 200px;
    top: 0;
    bottom: 0;
    margin: auto;
    padding: 3px 0px;
    border: 1px solid ${menuPurpleColor};
    border-radius: 8px;
    justify-content: space-evenly;
    align-items: center;
    width: 400px;
    height: 50px;
    button {
      /* border: none; */
    }
  }
  .access {
    padding: 5px 0;
    /* flex-direction: column;
    display: flex;
    justify-content: flex-start; */
  }
  .user {
    display: flex;
    justify-content: space-between;
    width: 140px;
    :hover .hidden {
      opacity: 1;
    }
  }
  .hidden {
    opacity: 0;
  }
  .indicatorSection {
    height: 100%;
  }
  .indicator {
    width: 100%;
    height: 40px;
    background-color: ${(props) => (props.selected ? highlight : null)};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    h4 {
      font-size: 14px;
      color: ${(props) => (props.selected ? menuPurpleHighlightColor : 'white')};
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 150px;
      overflow: hidden;
    }
  }
  .icon {
    display: flex;
    max-width: 20px;
    max-height: 20px;
    padding: 0;
    cursor: pointer;
  }
`;
