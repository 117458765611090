import { store } from "../store";
import { deleteProjectAllocations } from "../store/entities/allocations";

import { deleteProjectAssignments } from "../store/entities/assignments";
import { deleteProjectCapex } from "../store/entities/capex";
import { deleteProjectDeadlines } from "../store/entities/deadlines";
import { deleteProjectMaterials } from "../store/entities/materials";
import { deleteProjectOthers } from "../store/entities/other";
import { deleteProjectDetails } from "../store/entities/project";
import { deleteProjectRevenue } from "../store/entities/revenue";
import { deleteProjectRisks } from "../store/entities/risks";
import { deleteProjectSetup } from "../store/entities/setup";
import { deleteProjectTasks } from "../store/entities/tasks";
import { deleteProjectTeam } from "../store/entities/team";
import { deleteProjectTravel } from "../store/entities/travel";
import { sendUserProjects, userRemoveProject } from "../store/entities/users";
import { sendSelectedProject } from "../store/user";

export function deleteProject(project) {
  const { projectId, lead, pOne, pTwo } = project;
  // remove users
  if (lead) {
    console.log("removing Lead");
    store.dispatch(userRemoveProject({ projectId, userId: lead }));
    store.dispatch(sendUserProjects(lead));
  }
  if (pOne) {
    console.log("removing pOne");
    store.dispatch(userRemoveProject({ projectId, userId: pOne }));
    store.dispatch(sendUserProjects(pOne));
  }
  if (pTwo) {
    console.log("removing pTwo");
    store.dispatch(userRemoveProject({ projectId, userId: pTwo }));
    store.dispatch(sendUserProjects(pTwo));
  }
  // delete project
  store.dispatch(deleteProjectAllocations(projectId));
  store.dispatch(deleteProjectAssignments(projectId));
  store.dispatch(deleteProjectCapex(projectId));
  store.dispatch(deleteProjectDeadlines(projectId));
  store.dispatch(deleteProjectDetails(projectId));
  store.dispatch(deleteProjectMaterials(projectId));
  store.dispatch(deleteProjectOthers(projectId));
  store.dispatch(deleteProjectRevenue(projectId));
  store.dispatch(deleteProjectRisks(projectId));
  store.dispatch(deleteProjectSetup(projectId));
  store.dispatch(deleteProjectTasks(projectId));
  store.dispatch(deleteProjectTeam(projectId));
  store.dispatch(deleteProjectTravel(projectId));

  const { selectedProjectId } = store.getState().user;
  if (selectedProjectId === projectId) {
    console.log("Selected project deleted");
    store.dispatch(sendSelectedProject({ projectId }));
  }
}
