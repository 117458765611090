import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import closeImg from "../../images/close-grey-white.png";
import { setShowComponent } from "../../store/user";
import { toastNotification } from "../../helpers";

function Close(props) {
  const { message, providedFunction } = props.data;
  const dispatch = useDispatch();

  function close() {
    if (message) {
      toastNotification("info", message);
    } else {
      if (providedFunction) {
        providedFunction();
      } else dispatch(setShowComponent({ value: "" }));
    }
  }

  return (
    <Container onClick={close}>
      <img src={closeImg} alt="close" />
    </Container>
  );
}
export default Close;

const Container = styled.div`
  position: absolute;
  top: 3px;
  right: 3px;
  height: 20px;
  width: 20px;
  z-index: 500;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
  }
`;
