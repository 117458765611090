import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import {
  teamColor,
  tableInputUnderline,
  fontColorGrey,
  confirmNo,
  confirmYes,
  toastNotification,
  rev2,
  notifyResetPassword,
  notifyNewClient,
  generateRandomId,
  generatePassword,
} from "../../helpers";
import { setShowComponent } from "../../store/user";
import {
  addNewUser,
  sendUpdatedUser,
  updateUser,
} from "../../store/entities/users";

function userModal(props) {
  const { user } = props;
  const dispatch = useDispatch();
  const newUserId = generateRandomId("user");
  const newPassword = generatePassword();
  const [editUser, setEditUser] = useState({
    ...user,
    userId: user ? user.userId : newUserId,
    name: user ? user.name : "Enter name...",
    email: user ? user.email : "Enter email / username",
    projects: user ? user.projects : [],
    password: rev2 ? newPassword : "",
  });

  function onChangeHandler(e) {
    const key = e.target.name;
    let value = e.target.value;
    setEditUser({ ...editUser, [key]: value });
  }

  function cancel() {
    dispatch(setShowComponent({ value: "" }));
  }

  function ok() {
    if (editUser.password.length < 6)
      return toastNotification(
        "error",
        "Password must be 6 characters minimum"
      );
    if (user) {
      dispatch(updateUser({ user: editUser }));
      dispatch(sendUpdatedUser(user.userId));
      if (rev2) notifyResetPassword(editUser);
      dispatch(setShowComponent({ value: "" }));
    } else {
      dispatch(addNewUser(editUser));
      if (rev2) notifyNewClient(editUser);
      dispatch(setShowComponent({ value: "" }));
    }
  }

  return (
    <Container id="background">
      <div className="editWindow">
        <h3 className="title">{user ? "Edit" : "New"} User</h3>
        <label className="label" htmlFor="name">
          Full name
        </label>
        <input
          name="name"
          value={editUser.name}
          onChange={onChangeHandler}
          className="input"
        />
        <label className="label" htmlFor="email">
          Email / Username
        </label>
        <input
          name="email"
          value={editUser.email}
          onChange={onChangeHandler}
          className="input"
        />
        <label className="label" htmlFor="password">
          Password
        </label>
        <input
          name="password"
          type="password"
          value={editUser.password || ""}
          onChange={onChangeHandler}
          className="input"
        />

        <div className="modalButtons">
          <button onClick={cancel} className="modalCancel modalButton">
            Cancel
          </button>
          <button onClick={ok} className="modalConfirm modalButton">
            Confirm
          </button>
        </div>
      </div>
      {/* <div className="content" /> */}
    </Container>
  );
}

export default userModal;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(20, 20, 20, 0.6);
  z-index: 20;

  .editWindow {
    position: relative;
    min-height: 200px;

    background-color: white;
    border: 1px solid black;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    /* justify-content: flex-start; */
    padding: 15px;
    /* z-index: 10; */
    .title {
      text-align: center;
    }
    .input {
      min-width: 350px;
      max-width: 350px;
      position: relative;
      padding: 5px;
      margin: 0;
      margin-top: 5px;
      min-height: 30px;
      border-radius: 0;
      padding: 0;
      border-bottom: 2px solid ${tableInputUnderline};
      &:focus {
        border: none;
        border-bottom: 2px solid ${teamColor};
      }
    }
    .label {
      display: flex;
      align-items: center;
      min-height: 30px;
      margin-top: 5px;
      border: none;
      color: ${teamColor};
      min-width: 0px;
      font-weight: bold;
    }
    textarea.input {
      border: 2px solid ${tableInputUnderline};
      height: 200px;
      border-radius: 5px;
      padding: 3px;
      &:focus {
        border: 2px solid ${teamColor};
      }
    }
    h3 {
      color: ${fontColorGrey};
    }
  }
  .modalButtons {
    position: relative;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    .modalButton {
      width: 100px;
      height: 35px;
      font-weight: bold;
      transition: transform 0.3s;
      color: white;
      :hover {
        transform: scale(1.08);
      }
    }
    .modalCancel {
      border: 2px solid ${confirmNo};
      background-color: ${confirmNo};
      margin-right: 20px;
    }
    .modalConfirm {
      border: 2px solid ${confirmYes};
      background-color: ${confirmYes};
    }
  }
`;
