import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getSelectedProject, updateUserSelection } from "../store/user";
import LeftMenu from "../components/table/leftMenu";
import LeaderTabs from "../components/table/leaderTabs";
import { TableContainer } from "../components/table/tableStyling";
import MarkedComplete from "../components/modals/markedComplete";
import Spinner from "../components/spinner";
import { projectColor, projectFontColor, loadAll, rev2 } from "../helpers";
import ProjectRows from "../components/details/projectRows";
import OptionsRows from "../components/details/optionsRows";
import CompanyRows from "../components/details/companyRows";
import MarkAsComplete from "../components/markAsComplete";
import DetailsOutput from "../components/details/DetailsOutput";
import ProjectNotifications from "../components/details/ProjectNotifications";

function Details() {
  const dispatch = useDispatch();
  const { projectDataLoading, admin } = useSelector(
    (state) => state.user
  );
  const status = useSelector(
    (state) => state.entities.project.data.status.details
  );
  const { outputAccess } = useSelector(getSelectedProject);

  const selectedOption = useSelector(
    (state) => state.user.selectedDetailsOption
  );

  const menuList = ["Organisation", "Project", "Options"];
  if (rev2 && (outputAccess || admin)) menuList.push("Output", "Notifications");

  const menuData = {
    menuList,
    selectedOption,
    color: projectFontColor,
    backgroundColor: projectColor,
    updateOption: function (value) {
      dispatch(updateUserSelection({ key: "selectedDetailsOption", value }));
    },
  };

  // const company = useSelector(getSelectedCompany);
  const maxHeight = {
    organisation: "550px",
    project: "350px",
    options: "550px", // min height applies
  };

  const data = {
    backgroundColor: menuData.backgroundColor,
    maxHeight: maxHeight[selectedOption],
  };

  const showLeaderTabs = {
    organisation: 3,
    project: 0,
    options: 0,
  };

  function content() {
    if (selectedOption === "project") return <ProjectRows />;
    if (selectedOption === "options") return <OptionsRows />;
    if (selectedOption === "organisation") return <CompanyRows />;
  }

  useEffect(() => {
    loadAll();
  }, []);

  return projectDataLoading ? (
    <Spinner />
  ) : (
    <TableContainer data={data}>
      <div className="displayArea">
        <LeftMenu data={menuData} />
        <div className="content">
          {selectedOption === "output" ? (
            <DetailsOutput />
          ) : selectedOption === "notifications" ? (
            <ProjectNotifications />
          ) : (
            <>
              <MarkAsComplete section="details" />
              {showLeaderTabs[selectedOption] ? (
                <LeaderTabs
                  viewCombinedTab={showLeaderTabs[selectedOption] === 4}
                />
              ) : null}
              <div>
                {status ? <MarkedComplete /> : null}
                <div className="leaderTabMargin">{content()}</div>
              </div>
            </>
          )}
        </div>
      </div>
    </TableContainer>
  );
}
export default Details;
