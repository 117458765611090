import React from "react";
import { useDispatch, useSelector } from "react-redux";
import WizardQuestionSingle from "./wizardQuestionSingle";
import WizardQuestionMulti from "./wizardQuestionMulti";
import {
  wizardNext,
  wizardPrevious,
  wizardReset,
} from "../../store/entities/project";
import { questions } from "../../helpers";
import Close from "./close";
import { setShowComponent } from "../../store/user";

function wizard(props) {
  const dispatch = useDispatch();

  const { currentQuestionIndex } = useSelector(
    (state) => state.entities.project.data.wizard
  );

  const { question, data, line2, note } = questions[currentQuestionIndex];
  function next() {
    dispatch(wizardNext());
  }
  function previous() {
    dispatch(wizardPrevious());
  }

  function close() {
    dispatch(setShowComponent({ value: "" }));
    dispatch(wizardReset());
  }

  const closeData = {
    providedFunction: close,
  };

  return (
    <div className="modal">
      <Close data={closeData} />
      <h3 className="title">Setup Wizard</h3>
      <div className="questionContainer">
        <p className="bold">{question}</p>
        <p className="bold">{line2}</p>
        <p className="">{note}</p>
      </div>
      <div className="buttons">
        {data ? <WizardQuestionMulti /> : <WizardQuestionSingle />}
      </div>
      <div className="backNext">
        {currentQuestionIndex > 0 ? (
          <button onClick={previous} className="back">
            back
          </button>
        ) : (
          <div />
        )}
        <div />
        {data ? (
          <button onClick={next} className="next">
            next
          </button>
        ) : null}
      </div>
      <div className="progress">
        <p>{props.progress}</p>
        <div className="progressBar">
          <div className="bar" />
        </div>
      </div>
    </div>
  );
}

export default wizard;
