import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container } from "./optionsStyling";
import { getSelectedProject, updateUserSelection } from "../../store/user";
import AdminModal from "../modals/adminModal";
import { generateArray, handleSendSetup } from "../../helpers";
import arrow from "../../images/dropArrow2.png";
import { updateSetup } from "../../store/entities/setup";
import TableTabs from "../table/TableTabs";

function ProjectOptions() {
  const dispatch = useDispatch();
  const {
    maxProjectLength,
    maxWorkPackages,
    maxTasksPerPackage,
    maxDeadlines,
    projectDesc,
  } = useSelector(getSelectedProject);
  const { globalSettings, showComponent, selectedProjectId } = useSelector(
    (state) => state.user
  );

  const {
    orgTypes,
    orgTypeDefault,
    orgSizes,
    orgSizeDefault,
    natures,
    natureDefault,
    ipProtections,
    ipProtectionDefault,
  } = useSelector((state) => state.entities.global.data);

  function showModal(field) {
    const value = showComponent === field ? "" : field;
    dispatch(updateUserSelection({ key: "showComponent", value }));
  }

  const lengths = generateArray(3, 36, 1);
  const limits = generateArray(1, 10, 1);

  const modalData = {
    orgTypes: {
      title: "Organisation Types",
      list: orgTypes,
      defaultOption: orgTypeDefault,
      listKey: "orgTypes",
      defaultKey: "orgTypeDefault",
      global: true,
    },
    orgSizes: {
      title: "Organisation Sizes",
      global: true,
      list: orgSizes,
      defaultOption: orgSizeDefault,
      listKey: "orgSizes",
      defaultKey: "orgSizeDefault",
    },
    natures: {
      title: "Product / Platform Natures",
      global: true,
      list: natures,
      defaultOption: natureDefault,
      listKey: "natures",
      defaultKey: "natureDefault",
    },
    ipProtections: {
      title: "IP Protection Strategies",
      global: true,
      list: ipProtections,
      defaultOption: ipProtectionDefault,
      listKey: "ipProtections",
      defaultKey: "ipProtectionDefault",
    },
  };

  function changeHandler(e) {
    const key = e.target.name;
    const value = parseInt(e.target.value);
    dispatch(updateSetup({ key, value, projectId: selectedProjectId }));
    handleSendSetup();
  }

  const tab = projectDesc ? projectDesc : "Loading";
  // tabs
  const data = {
    tabs: [tab, "Global"],
    selectedTab: globalSettings ? "global" : tab,
    select: (selected) => {
      let value;
      if (selected === "global") value = true;
      else value = false;
      dispatch(
        updateUserSelection({
          key: "globalSettings",
          value,
        })
      );
    },
  };

  return (
    <Container>
      {showComponent === "orgTypes" ||
      showComponent === "ipProtections" ||
      showComponent === "orgSizes" ||
      showComponent === "natures" ? (
        <AdminModal data={modalData[showComponent]} />
      ) : null}

      <div className="rows adminContent">
        <TableTabs data={data} />

        {globalSettings ? (
          <>
            <div className="row titles leaderTabMargin">
              <h3 className="title description">Partner Options</h3>
            </div>

            <div className="row">
              {/* {global ? <Global /> : null} */}
              <p className="field display description">Organisation Types</p>
              <button
                className="showModal value"
                onClick={() => showModal("orgTypes")}
              >
                Show options
              </button>
            </div>

            <div className="row">
              {/* {global ? <Global /> : null} */}
              <p className="field display description">Organisation Sizes</p>
              <button
                className="showModal value"
                onClick={() => showModal("orgSizes")}
              >
                Show options
              </button>
            </div>

            <div className="row titles leaderTabMargin">
              <h3 className="title description">Project Options</h3>
            </div>

            <div className="row">
              {/* {global ? <Global /> : null} */}
              <p className="field display description">
                Product / Platform Natures
              </p>
              <button
                className="showModal value"
                onClick={() => showModal("natures")}
              >
                Show options
              </button>
            </div>

            <div className="row">
              {/* {global ? <Global /> : null} */}
              <p className="field display description">
                IP Protection Strategies
              </p>
              <button
                className="showModal value"
                onClick={() => showModal("ipProtections")}
              >
                Show options
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="row titles leaderTabMargin">
              <h3 className="title description">Project Constraints</h3>
              <h3 className="title ">Value</h3>
            </div>

            <div className="row">
              {/* {global ? <Global /> : null} */}
              <p className="field display description">Max Project Length</p>
              <select
                name="maxProjectLength"
                id="maxProjectLength"
                value={maxProjectLength}
                className="field dropDown"
                onChange={changeHandler}
              >
                {lengths.map((length, index) => {
                  return (
                    <option key={index} value={length}>
                      {length}
                    </option>
                  );
                })}
              </select>
              <div className="dropArrow">
                <img src={arrow} alt="drop" />
              </div>
            </div>

            <div className="row">
              {/* {global ? <Global /> : null} */}
              <p className="field display description">Max Work Packages</p>
              <select
                name="maxWorkPackages"
                id="maxWorkPackages"
                value={maxWorkPackages}
                className="field dropDown"
                onChange={changeHandler}
              >
                {limits.map((length, index) => {
                  return (
                    <option key={index} value={length}>
                      {length}
                    </option>
                  );
                })}
              </select>
              <div className="dropArrow">
                <img src={arrow} alt="drop" />
              </div>
            </div>

            <div className="row">
              {/* {global ? <Global /> : null} */}
              <p className="field display description">
                Max Tasks per Work Package
              </p>
              <select
                name="maxTasksPerPackage"
                id="maxTasksPerPackage"
                value={maxTasksPerPackage}
                className="field dropDown"
                onChange={changeHandler}
              >
                {limits.map((length, index) => {
                  return (
                    <option key={index} value={length}>
                      {length}
                    </option>
                  );
                })}
              </select>
              <div className="dropArrow">
                <img src={arrow} alt="drop" />
              </div>
            </div>

            <div className="row">
              {/* {global ? <Global /> : null} */}
              <p className="field display description">
                Max Deliverables / Milestones
              </p>
              <select
                name="maxDeadlines"
                id="maxDeadlines"
                value={maxDeadlines}
                className="field dropDown"
                onChange={changeHandler}
              >
                {limits.map((length, index) => {
                  return (
                    <option key={index} value={length}>
                      {length}
                    </option>
                  );
                })}
              </select>
              <div className="dropArrow">
                <img src={arrow} alt="drop" />
              </div>
            </div>
          </>
        )}
      </div>
    </Container>
  );
}
export default ProjectOptions;
