import { store } from "../store";
import { downloadAsJson, ranking } from "./";
import { getOtherIds } from "../store/entities/other";
import { getSelectedProject } from "../store/user";
import { getAllocationsByTaskId } from "../store/entities/allocations";
import { getWorkPackageIds } from "../store/entities/tasks";

function getLeadData() {
  const {
    companyName,
    companyAcronym,
    organisationType,
    organisationSize,
    postcode,
    turnover,
    lastFinancialMonth,
    lastFinancialYear,
    lawyer,
    bankHolidays,
    annualLeave,
    numEmployees,
    overheadRate,
    partnerFunding,
    fundingLevel,
    matchFundingSource,
    investorName,
  } = store.getState().entities.project.data.lead;

  const lead = [
    { name: "nameP1", dataValue: companyName },
    { name: "acronymP1", dataValue: companyAcronym },
    { name: "orgTypeP1", dataValue: organisationType },
    { name: "orgSizeP1", dataValue: organisationSize },
    { name: "postcodeP1", dataValue: postcode },
    { name: "receivesIUKFundingP1", dataValue: partnerFunding },
    { name: "fundingLevelP1", dataValue: fundingLevel / 100 },
    { name: "overheadRateP1", dataValue: overheadRate / 100 },
    { name: "turnoverP1", dataValue: parseInt(turnover) },
    {
      name: "finYearP1",
      dataValue: `${lastFinancialMonth} ${lastFinancialYear}`,
    },
    { name: "bankHolidaysP1", dataValue: parseInt(bankHolidays) },
    { name: "annualLeaveP1", dataValue: parseInt(annualLeave) },
    { name: "matchFundSourceP1", dataValue: matchFundingSource },
    { name: "investorNameP1", dataValue: investorName },
    { name: "numStaffCurrentP1", dataValue: parseInt(numEmployees) },
    { name: "iPLawyerNameP1", dataValue: lawyer },
  ];
  return lead;
}

function getP1Data(partners) {
  console.log(partners);
  if (partners < 2) return [];
  const {
    companyName,
    companyAcronym,
    organisationType,
    organisationSize,
    postcode,
    turnover,
    lastFinancialMonth,
    lastFinancialYear,
    lawyer,
    bankHolidays,
    annualLeave,
    numEmployees,
    overheadRate,
    partnerFunding,
    fundingLevel,
    matchFundingSource,
    investorName,
  } = store.getState().entities.project.data.pOne;

  const pOne = [
    { name: "nameP2", dataValue: companyName },
    { name: "acronymP2", dataValue: companyAcronym },
    { name: "orgTypeP2", dataValue: organisationType },
    { name: "orgSizeP2", dataValue: organisationSize },
    { name: "postcodeP2", dataValue: postcode },
    { name: "receivesIUKFundingP2", dataValue: partnerFunding },
    { name: "fundingLevelP2", dataValue: fundingLevel / 100 },
    { name: "overheadRateP2", dataValue: overheadRate / 100 },
    { name: "turnoverP2", dataValue: parseInt(turnover) },
    {
      name: "finYearP2",
      dataValue: `${lastFinancialMonth} ${lastFinancialYear}`,
    },
    { name: "bankHolidaysP2", dataValue: parseInt(bankHolidays) },
    { name: "annualLeaveP2", dataValue: parseInt(annualLeave) },
    { name: "matchFundSourceP2", dataValue: matchFundingSource },
    { name: "investorNameP2", dataValue: investorName },
    { name: "numStaffCurrentP2", dataValue: parseInt(numEmployees) },
    { name: "iPLawyerNameP2", dataValue: lawyer },
  ];
  return pOne;
}

function getP2Data(partners) {
  if (partners < 3) return [];
  const {
    companyName,
    companyAcronym,
    organisationType,
    organisationSize,
    postcode,
    turnover,
    lastFinancialMonth,
    lastFinancialYear,
    lawyer,
    bankHolidays,
    annualLeave,
    numEmployees,
    overheadRate,
    partnerFunding,
    fundingLevel,
    matchFundingSource,
    investorName,
  } = store.getState().entities.project.data.pTwo;

  const pTwo = [
    { name: "nameP3", dataValue: companyName },
    { name: "acronymP3", dataValue: companyAcronym },
    { name: "orgTypeP3", dataValue: organisationType },
    { name: "orgSizeP3", dataValue: organisationSize },
    { name: "postcodeP3", dataValue: postcode },
    { name: "receivesIUKFundingP3", dataValue: partnerFunding },
    { name: "fundingLevelP3", dataValue: fundingLevel / 100 },
    { name: "overheadRateP3", dataValue: overheadRate / 100 },
    { name: "turnoverP3", dataValue: parseInt(turnover) },
    {
      name: "finYearP3",
      dataValue: `${lastFinancialMonth} ${lastFinancialYear}`,
    },
    { name: "bankHolidaysP3", dataValue: parseInt(bankHolidays) },
    { name: "annualLeaveP3", dataValue: parseInt(annualLeave) },
    { name: "matchFundSourceP3", dataValue: matchFundingSource },
    { name: "investorNameP3", dataValue: investorName },
    { name: "numStaffCurrentP3", dataValue: parseInt(numEmployees) },
    { name: "iPLawyerNameP3", dataValue: lawyer },
  ];
  return pTwo;
}

function getProjectData(partners) {
  const {
    productPlatformName,
    applicationNumber,
    nature,
    ipProtection,
    projectName,
    projectLength,
    // ganttWidth,
    startMonth,
    startYear,
    projectManager,
    software,
    // matchFunding,
    ganttRef,
    riskRef,
    // competitor,
    // funding,
  } = store.getState().entities.project.data.details;

  const details = [
    { name: "collaboration", dataValue: partners },
    { name: "productName", dataValue: productPlatformName },
    { name: "applicationNumber", dataValue: applicationNumber },
    { name: "productServiceNature", dataValue: nature },
    { name: "iprTypes", dataValue: ipProtection },
    { name: "projectName", dataValue: projectName },
    { name: "projectLength", dataValue: parseInt(projectLength) },
    // { name: "projectQuarters", dataValue: 100 },
    { name: "projectStartMonth", dataValue: startMonth },
    { name: "projectStartYear", dataValue: parseInt(startYear) },
    { name: "projectManager", dataValue: projectManager },
    { name: "projectManagementSoftware", dataValue: software },
    { name: "appendixNumGantt", dataValue: ganttRef },
    { name: "appendixNumRisks", dataValue: riskRef },
  ];
  return details;
}

function getTaskListData() {
  const tasks = store.getState().entities.tasks.data;
  let currentWP = "";
  let taskCount = 0;
  const taskOutput = { name: "taskListRange", dataValue: [] };
  tasks.forEach((task) => {
    if (task.workPackageId === currentWP) {
      taskOutput.dataValue.push(task.description);
      taskCount--;
    } else {
      for (let i = 0; i < taskCount; i++) {
        taskOutput.dataValue.push("");
      }
      currentWP = task.workPackageId;
      taskCount = 10;
      taskOutput.dataValue.push(task.workPackageTitle);
      taskOutput.dataValue.push(task.description);
      taskCount--;
    }
  });
  while (taskOutput.dataValue.length < 110) {
    taskOutput.dataValue.push("");
  }
  const result = [taskOutput];
  return result;
}

function getWorkPackageSchedule() {
  const tasks = store.getState().entities.tasks.data;
  const { projectLength } = store.getState().entities.project.data.details;

  const blankSchedule = new Array(projectLength).fill("");
  let currentWP = tasks[0].workPackageId;
  let taskCount = 10;
  let wpCount = 0;

  const chart = [
    { name: "daysRangeWP1", dataValue: [], numCols: projectLength },
    { name: "daysRangeWP2", dataValue: [], numCols: projectLength },
    { name: "daysRangeWP3", dataValue: [], numCols: projectLength },
    { name: "daysRangeWP4", dataValue: [], numCols: projectLength },
    { name: "daysRangeWP5", dataValue: [], numCols: projectLength },
    { name: "daysRangeWP6", dataValue: [], numCols: projectLength },
    { name: "daysRangeWP7", dataValue: [], numCols: projectLength },
    { name: "daysRangeWP8", dataValue: [], numCols: projectLength },
    { name: "daysRangeWP9", dataValue: [], numCols: projectLength },
    { name: "daysRangeWP10", dataValue: [], numCols: projectLength },
  ];

  tasks.forEach((task) => {
    if (task.workPackageId !== currentWP) {
      for (let i = 0; i < taskCount; i++) {
        chart[wpCount].dataValue = [
          ...chart[wpCount].dataValue,
          ...blankSchedule,
        ];
      }
      currentWP = task.workPackageId;
      taskCount = 10;
      wpCount++;
    }
    task.schedule.forEach((month) => {
      const value = month.value ? month.value : "";
      chart[wpCount].dataValue.push(value);
    });
    taskCount--;
  });
  for (let i = 0; i < taskCount; i++) {
    chart[wpCount].dataValue = [...chart[wpCount].dataValue, ...blankSchedule];
  }
  const result = chart.slice(0, wpCount + 1);
  return result;
}

function getDeadlineData() {
  const deadlines = store.getState().entities.deadlines.data;
  const { projectLength } = store.getState().entities.project.data.details;
  let d = 0;
  let m = 0;

  const deliverables = [];
  const milestones = [];
  const delPositions = {
    name: "deliverablePositionRange",
    dataValue: [],
    numCols: projectLength,
  };
  const milPositions = {
    name: `milestonePositionRange`,
    dataValue: [],
    numCols: projectLength,
  };

  deadlines.forEach((deadline) => {
    const position = new Array(projectLength).fill("");
    if (deadline.type === "deliverable") {
      d++;
      const entry = { name: `titleD${d}`, dataValue: deadline.description };
      deliverables.push(entry);
      position[deadline.scheduled] = `D${d}`;
      delPositions.dataValue = [...delPositions.dataValue, ...position];
    } else {
      m++;
      const entry = { name: `titleM${m}`, dataValue: deadline.description };
      milestones.push(entry);
      position[deadline.scheduled] = `M${m}`;
      milPositions.dataValue = [...milPositions.dataValue, ...position];
    }
  });
  const data = [delPositions, milPositions, ...deliverables, ...milestones];
  return data;
}

function getTeamData() {
  const team = store.getState().entities.team.data;

  const count = {
    lead: { staff: 0, subcontract: 0 },
    pOne: { staff: 0, subcontract: 0 },
    pTwo: { staff: 0, subcontract: 0 },
  };
  const staff = {
    lead: {
      staff: ["teamNameP1_", "teamFullNameP1_", "teamRoleP1_", "teamSalaryP1_"],
      subcontract: [
        "subNameP1_",
        "subFullNameP1_",
        "subRoleP1_",
        "subDayRateP1_",
      ],
    },
    pOne: {
      staff: ["teamNameP2_", "teamFullNameP2_", "teamRoleP2_", "teamSalaryP2_"],
      subcontract: [
        "subNameP2_",
        "subFullNameP2_",
        "subRoleP2_",
        "subDayRateP2_",
      ],
    },
    pTwo: {
      staff: ["teamNameP3_", "teamFullNameP3_", "teamRoleP3_", "teamSalaryP3_"],
      subcontract: [
        "subNameP3_",
        "subFullNameP3_",
        "subRoleP3_",
        "subDayRateP3_",
      ],
    },
  };

  const output = [];

  team.forEach(
    ({
      name,
      role,
      salary,
      leader,
      acronym,
      employment,
      dayRate,
      // website,
      // linkedIn,
      // profile,
      // location,
    }) => {
      count[leader][employment]++;
      const [acronymKey, nameKey, roleKey, rateKey] = staff[leader][employment];

      const acronymData = {
        name: acronymKey + count[leader][employment],
        dataValue: acronym,
      };
      const nameData = {
        name: nameKey + count[leader][employment],
        dataValue: name,
      };
      const roleData = {
        name: roleKey + count[leader][employment],
        dataValue: role,
      };
      const rateDate = {
        name: rateKey + count[leader][employment],
        dataValue: employment === "staff" ? salary : dayRate,
      };

      output.push(acronymData);
      output.push(nameData);
      output.push(roleData);
      output.push(rateDate);
    }
  );

  return output;
}

function getAllocationData(allocations) {
  const tasks = store.getState().entities.tasks.data;
  // const allocations = store.getState().entities.allocations.data;
  const team = store.getState().entities.team.data;
  const count = {
    lead: { staff: 0, subcontract: 0 },
    pOne: { staff: 0, subcontract: 0 },
    pTwo: { staff: 0, subcontract: 0 },
  };
  const staff = {
    lead: { staff: "teamEffortP1_", subcontract: "subEffortP1_" },
    pOne: { staff: "teamEffortP2_", subcontract: "subEffortP2_" },
    pTwo: { staff: "teamEffortP3_", subcontract: "subEffortP3_" },
  };
  const result = [];

  team.forEach(({ personId, leader, employment }) => {
    count[leader][employment]++;
    const taskOutput = {
      name: staff[leader][employment] + count[leader][employment],
      dataValue: [],
    };
    let currentWP = "";
    let taskCount = 0;

    tasks.forEach((task) => {
      if (task.workPackageId === currentWP) {
        taskOutput.dataValue.push(
          allocations[task.taskId][personId].percent / 100
        );
        taskCount--;
      } else {
        for (let i = 0; i < taskCount; i++) {
          taskOutput.dataValue.push(0);
        }
        currentWP = task.workPackageId;
        taskCount = 10;
        taskOutput.dataValue.push(0);
        taskOutput.dataValue.push(
          allocations[task.taskId][personId].percent / 100
        );
        taskCount--;
      }
    });
    while (taskOutput.dataValue.length < 110) {
      taskOutput.dataValue.push(0);
    }
    result.push(taskOutput);
  });
  return result;
}

function getMaterialsData() {
  const materials = store.getState().entities.materials.data;
  const count = { lead: 0, pOne: 0, pTwo: 0 };
  const staff = {
    lead: [
      "materialsDescriptionP1_",
      "materialsQuantityP1_",
      "materialsCostEachP1_",
    ],
    pOne: [
      "materialsDescriptionP2_",
      "materialsQuantityP2_",
      "materialsCostEachP2_",
    ],
    pTwo: [
      "materialsDescriptionP3_",
      "materialsQuantityP3_",
      "materialsCostEachP3_",
    ],
  };
  const result = [];

  materials.forEach(({ leader, description, cost, quantity }) => {
    count[leader]++;
    const [descriptionKey, quantityKey, costKey] = staff[leader];
    const costData = {
      name: costKey + count[leader],
      dataValue: parseInt(cost),
    };
    const descriptionData = {
      name: descriptionKey + count[leader],
      dataValue: description,
    };
    const quantityData = {
      name: quantityKey + count[leader],
      dataValue: parseInt(quantity),
    };
    result.push(costData);
    result.push(descriptionData);
    result.push(quantityData);
  });
  return result;
}

function getCapexData() {
  const capex = store.getState().entities.capex.data;
  const count = { lead: 0, pOne: 0, pTwo: 0 };
  const staff = {
    lead: [
      "capExDescriptionP1_",
      "capExNewExistP1_",
      "capExDepreciationP1_",
      "capExValueP1_",
      "capExUtilisationP1_",
    ],
    pOne: [
      "capExDescriptionP2_",
      "capExNewExistP2_",
      "capExDepreciationP2_",
      "capExValueP2_",
      "capExUtilisationP2_",
    ],
    pTwo: [
      "capExDescriptionP3_",
      "capExNewExistP3_",
      "capExDepreciationP3_",
      "capExValueP3_",
      "capExUtilisationP3_",
    ],
  };
  const result = [];

  capex.forEach(
    ({
      leader,
      // capexId,
      condition,
      description,
      depreciation,
      currentValue,
      // residualValue,
      utilisation,
    }) => {
      count[leader]++;
      const [
        descriptionKey,
        conditionKey,
        depreciationKey,
        valueKey,
        utilisationKey,
      ] = staff[leader];
      const conditionData = {
        name: conditionKey + count[leader],
        dataValue: condition,
      };
      const descriptionData = {
        name: descriptionKey + count[leader],
        dataValue: description,
      };
      const depreciationData = {
        name: depreciationKey + count[leader],
        dataValue: parseInt(depreciation),
      };
      const valueData = {
        name: valueKey + count[leader],
        dataValue: parseInt(currentValue),
      };
      const utilisationData = {
        name: utilisationKey + count[leader],
        dataValue: parseInt(utilisation),
      };
      result.push(conditionData);
      result.push(descriptionData);
      result.push(depreciationData);
      result.push(valueData);
      result.push(utilisationData);
    }
  );
  return result;
}

function getTravelData() {
  const travel = store.getState().entities.travel.data;
  const count = { lead: 0, pOne: 0, pTwo: 0 };
  const staff = {
    lead: ["travelDescriptionP1_", "travelTimesP1_", "travelCostEachP1_"],
    pOne: ["travelDescriptionP2_", "travelTimesP2_", "travelCostEachP2_"],
    pTwo: ["travelDescriptionP3_", "travelTimesP3_", "travelCostEachP3_"],
  };
  const result = [];

  travel.forEach(({ leader, description, cost, quantity }) => {
    count[leader]++;
    const [descriptionKey, quantityKey, costKey] = staff[leader];
    const costData = {
      name: costKey + count[leader],
      dataValue: parseInt(cost),
    };
    const descriptionData = {
      name: descriptionKey + count[leader],
      dataValue: description,
    };
    const quantityData = {
      name: quantityKey + count[leader],
      dataValue: parseInt(quantity),
    };
    result.push(costData);
    result.push(descriptionData);
    result.push(quantityData);
  });
  return result;
}

function getOtherData() {
  const other = store.getState().entities.other.data;
  const count = { lead: 0, pOne: 0, pTwo: 0 };
  const staff = {
    lead: ["otherCostDescriptionP1_", "otherCostTotalP1_"],
    pOne: ["otherCostDescriptionP2_", "otherCostTotalP2_"],
    pTwo: ["otherCostDescriptionP3_", "otherCostTotalP3_"],
  };
  const result = [];

  other.forEach(({ leader, description, cost }) => {
    count[leader]++;
    const [descriptionKey, costKey] = staff[leader];
    const costData = {
      name: costKey + count[leader],
      dataValue: parseInt(cost),
    };
    const descriptionData = {
      name: descriptionKey + count[leader],
      dataValue: description,
    };
    result.push(costData);
    result.push(descriptionData);
  });
  return result;
}

function getAssignedCosts(workPackageIds) {
  const assignments = store.getState().entities.assignments.data;
  const allocations = store.getState().entities.allocations.data;
  const tasks = store.getState().entities.tasks.data;
  const team = store.getState().entities.team.data;
  const namedCosts = ["materials", "capex", "subcontract", "travel"];
  const otherCosts = getOtherIds(store.getState());
  const leaders = ["lead", "pOne", "pTwo"];
  const dataValue = new Array(10 * 9).fill("NO");

  // lookup object for getting packID from task
  const packID = {};
  tasks.forEach((task) => {
    packID[task.taskId] = task.workPackageId;
  });

  // lookup object for getting subcontract boolean from personId
  const isSubcontract = {};
  team.forEach((person) => {
    isSubcontract[person.personId] = person.employment === "subcontract";
  });

  // lookup object for getting subcontract within WP from wpID
  const containsSubcontract = {};
  for (const key of workPackageIds) {
    containsSubcontract[key] = "NO";
  }
  allocations.forEach(({ taskId, personId }) => {
    if (isSubcontract[personId]) containsSubcontract[packID[taskId]] = "YES";
  });

  leaders.forEach((leader) => {
    let index = 0;
    const costs = [...namedCosts, ...otherCosts[leader]];
    costs.forEach((category) => {
      workPackageIds.forEach((wp) => {
        if (category === "subcontract")
          dataValue[index] = containsSubcontract[wp];
        else {
          const assignedData = assignments[leader][category];
          let assigned = false;
          if (assignedData) assigned = assignedData.includes(wp);
          if (assigned) dataValue[index] = "YES";
        }
        index++;
      });
      index = index + 10 - workPackageIds.length;
    });
  });
  const result = [{ name: "wPYesNoRange", dataValue, numCols: 10 }];
  return result;
}

function getRiskData() {
  const risks = store.getState().entities.risks.data;
  let manRisks = [];
  let tecRisks = [];
  let comRisks = [];
  let legRisks = [];
  let envRisks = [];
  const manTrues = [];
  const tecTrues = [];
  const comTrues = [];
  const legTrues = [];
  const envTrues = [];

  risks.forEach((risk) => {
    const data = [
      risk.riskDescription ? risk.riskDescription : "",
      ranking[risk.riskPreProbability],
      ranking[risk.riskPreConsequence],
      risk.riskOwner ? risk.riskOwner : "",
      risk.riskMitigation ? risk.riskMitigation : "",
      ranking[risk.riskPostProbability],
      ranking[risk.riskPostConsequence],
    ];
    if (risk.riskType === "managerial") {
      manRisks = manRisks.concat(data);
      manTrues.push(true);
    }
    if (risk.riskType === "technical") {
      tecRisks = tecRisks.concat(data);
      tecTrues.push(true);
    }
    if (risk.riskType === "commercial") {
      comRisks = comRisks.concat(data);
      comTrues.push(true);
    }
    if (risk.riskType === "legal") {
      legRisks = legRisks.concat(data);
      legTrues.push(true);
    }
    if (risk.riskType === "environmental") {
      envRisks = envRisks.concat(data);
      envTrues.push(true);
    }
  });

  const result = [
    { name: "manRiskInput", dataValue: manRisks, numCols: 7 },
    { name: "manRiskInclude", dataValue: manTrues },
    { name: "techRiskInput", dataValue: tecRisks, numCols: 7 },
    { name: "techRiskInclude", dataValue: tecTrues },
    { name: "comRiskInput", dataValue: comRisks, numCols: 7 },
    { name: "comRiskInclude", dataValue: comTrues },
    { name: "legRiskInput", dataValue: legRisks, numCols: 7 },
    { name: "legRiskInclude", dataValue: legTrues },
    { name: "envRiskInput", dataValue: envRisks, numCols: 7 },
    { name: "envRiskInclude", dataValue: envTrues },
  ];
  return result;
}

export function projectDownload(data) {

  const downloadData = [
    ...getLeadData(),
    ...getP1Data(data.partners),
    ...getP2Data(data.partners),
    ...getProjectData(data.partners),
    ...getTaskListData(),
    ...getWorkPackageSchedule(),
    ...getDeadlineData(),
    ...getTeamData(),
    ...getAllocationData(data.allocations),
    ...getMaterialsData(),
    ...getCapexData(),
    ...getTravelData(),
    ...getOtherData(),
    ...getAssignedCosts(data.workPackageIds),
    ...getRiskData(),
  ];

  downloadData.forEach((entry) => {
    entry.dataType = typeof entry.dataValue;
    if (entry.dataType === "object" && Array.isArray(entry.dataValue)) {
      entry.dataType = "array";
      entry.arrayLength = entry.dataValue.length;
      if (!entry.numCols) entry.numCols = 1;
    }
  });

  downloadAsJson(downloadData, data.projectDesc);
}
