import { deleteTask } from "../store/entities/tasks";
import { deleteTaskAllocations } from "../store/entities/allocations";
import { handleSendAllocations, handleSendTasks } from "./handleSendToServer";

export function handleDeleteTask(dispatch, taskId) {
  dispatch(deleteTaskAllocations({ taskId }));
  dispatch(deleteTask({ taskId }));
  handleSendAllocations();
  handleSendTasks();
}
