import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  fontColorGrey,
  getWPCost,
  numberToCurrency,
  selectedNo,
  selectedYes,
} from "../../helpers";
import { getWorkPackageLabourCost } from "../../store/entities/allocations";

export default function (props) {
  const { packId } = props;

  const labourCosts = useSelector(getWorkPackageLabourCost)[packId];
  const additionalCosts = useSelector(getWPCost)[packId].combined;
  const totalCost = labourCosts + additionalCosts;
  const formattedCost = numberToCurrency(totalCost);

  return (
    <Total >
      <p>£{formattedCost}</p>
    </Total>
  );
}

const Total = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100px;
  height: 40px;
  /* border: 1px solid red; */
  font-weight: bold;
  color: ${fontColorGrey};
  .toggle {
    width: 55px;
    height: 25px;
    transition: transform 0.3s;
    :hover {
      transform: scale(1.08);
    }
  }
  .yes {
    background-color: ${selectedYes};
  }
  .no {
    background-color: ${selectedNo};
  }
`;
